import React from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../components/common/Button";
import CustomInput from "../../../components/common/Input";
import CustomSwitch from "../../../components/common/Switch";
import { RefreshIcon, SaveIcon } from "../../../components/svg";
import NormalSelect from "../../../components/common/Select/NormalSelect";
import { wmsOptions } from "../../../constants/apollo";
import { logger } from "../../../helper/logger";
import {
	ADD_UPDATE_CART,
	ADD_UPDATE_PRODUCT,
} from "../../../api/mutations/WMSMutations";

export default function CreateCartfForm({ locationId, type = "CART", handleClose }) {
	// Pass the useFormik() hook initial form values and a submit function that will
	// be called when the form is submitted
	const [AddOrUpdateProductMutation] = useMutation(
		ADD_UPDATE_CART,
		wmsOptions
	);

	const categoryOptions = [
		{ value: "CLOTHES", label: "Clothes" },
		{ value: "ELECTRONICS", label: "Electronics" },
		{ value: "GROCERIES", label: "Groceries" },
		{ value: "PERFUME", label: "Perfume" },
		{ value: "SIM_CARDS", label: "Sim Cards" },
	];

	const formik = useFormik({
		initialValues: {
			id: 0,
			locationCode: "",
			parentLocationId: parseInt(locationId),
			type: type,
			isActive: true,
		},
		validationSchema: Yup.object({
			locationCode: Yup.string()
				.max(15, "Must be 15 characters or less")
				.required("Required"),
		}),
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
			AddOrUpdateProductMutation({
				variables: {
					locations: [
						{
							...values,
							parentLocationId: parseInt(values.parentLocationId),
						},
					],
				},
			}).then((res) => {
				logger("MUTATION", res);
				handleClose();
			});
		},
	});
	return (
		<form onSubmit={formik.handleSubmit}>
			<div className='w-full flex flex-col space-y-5 mb-12 mt-5'>
				<div className='flex justify-between items-start space-x-3'>
					<div className='w-full flex flex-col'>
						<CustomInput
							id='nalocationCodeme'
							name='locationCode'
							type='text'
							inputName='Location Code'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.locationCode}
						/>
						{formik.touched.locationCode &&
							formik.errors.locationCode ? (
							<div className='error'>
								{formik.errors.locationCode}
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div className='flex items-start justify-between space-x-3'>
				<CustomButton
					type='button'
					onClick={handleClose}
					variant='outlined'
					color='warning'
				>
					cancel
				</CustomButton>
				<CustomButton
					type='submit'
					variant='contained'
					color='warning'
					onClick={() => { }}
				>
					<div className='flex space-x-2'>
						<SaveIcon />
						<span>save</span>
					</div>
				</CustomButton>
			</div>
		</form>
	);
}
