import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { options } from "../../../constants/sortation";

export default function SplitButton({
	setSectionType,
	setModalOpen,
	disabled,
}: SortationSelectProps) {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = React.useState<string>(
		Object.keys(options)[0]
	);

	const handleClick = () => {
		setSectionType(options[selectedIndex]);
		setModalOpen(true);
	};

	const handleMenuItemClick = (key: any, index: number) => {
		setSelectedIndex(key);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	return (
		<div
			style={{
				height: "40px",
			}}
		>
			<Button
				color="warning"
				variant="contained"
				sx={{
					marginRight: 1,
				}}
				onClick={() => {
					setSectionType("addOrderToWarehouse");
					setModalOpen(true);
				}}
			>
				Add orders to warehouse
			</Button>

			<ButtonGroup
				color="warning"
				variant="contained"
				ref={anchorRef}
				aria-label="split button"
			>
				<Button disabled={disabled} onClick={handleClick}>
					{options[selectedIndex]}
				</Button>
				<Button
					disabled={disabled}
					sx={{
						backgroundColor: "rgb(179, 107, 1)",
					}}
					size="small"
					aria-controls={open ? "split-button-menu" : undefined}
					aria-expanded={open ? "true" : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				sx={{ zIndex: 10 }}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom"
									? "center top"
									: "center bottom",
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{Object.keys(options).map((key, index) => {
										console.log(options, key);
										return (
											<MenuItem
												key={index}
												selected={key === selectedIndex}
												onClick={() => {
													handleMenuItemClick(
														key,
														index
													);
													setSectionType(
														options[key]
													);
													setModalOpen(true);
												}}
											>
												{options[key]}
											</MenuItem>
										);
									})}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}

type SortationSelectProps = {
	setSectionType: (name: string) => void;
	setModalOpen: (toggle: boolean) => void;
	disabled: boolean;
};
