import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, useMapEvents } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { toast } from 'react-toastify'
import L from '../../../helper/CustomLeaflet'
import { LeafletDriverIcon } from '../../../components/common/leaflet-icons'
import { Alert, AlertTitle } from '@mui/material'


export default function LastSeenMap ({ driver }) {

    return (
        <div className='max-h-screen w-full flex-1 z-0'>
            {driver?.longitude && driver?.latitude ? <MapContainer
                center={[driver?.latitude, driver?.longitude]} // Riyadh
                zoom={12}
                zoomControl={true}
                style={{ height: '100vh', position: 'relative' }}
                tap={false}
                attributionControl={false}
            >
                <ReactLeafletGoogleLayer
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    type={'roadmap'}
                />
                <Marker position={[driver.latitude, driver.longitude]} icon={LeafletDriverIcon}></Marker>
            </MapContainer> :
                <Alert variant='outlined' severity='warning'><AlertTitle>Driver's last location is unknown</AlertTitle></Alert>}
        </div>
    )
}
