import { getSortationOrder } from "../../../helper/GetSortOrder";

export default function useWMSProducts() {
    const getOrderObj = (sortName, sortOrder) => {
        if (sortName !== "" && sortOrder != "DEFAULT") {
            switch (sortName) {
                case "sKU":
                    return {
                        order: { sKU: sortOrder },
                    };
                case "name":
                    return {
                        order: { name: sortOrder },
                    };
                case "category":
                    return {
                        order: {
                            category: sortOrder,
                        }
                    };
                case "status":
                    return {
                        order: {
                            isActive: sortOrder,
                        }
                    }
                case "createdAt":
                    return {
                        order: {
                            createdAt: sortOrder,
                        }
                    }
            }
        } else {
            return {
                order: { id: "DESC" }
            };
        }
    }
    const getSortOrder = (num) => {
        return getSortationOrder(num)
    }

    const getFilterObj = (filters) => {
        let filterList = [
        ]

        if (filters?.category != "all") {
            filterList.push({
                categoryType: {
                    eq: filters.category,
                },
            });
        }
        if (filters?.status != "all") {
            filterList.push({
                isActive: {
                    eq: String(filters.status) === "true",
                },
            });
        }

        return {
            where: {
                and: [...filterList],
            }
        }
    }

    const getSearchObj = (search) => {
        let searchRes = { query: "" }
        if (search.trim() != "") {
            searchRes.query = search
        }
        return searchRes
    }
    return {
        getOrderObj,
        getSortOrder,
        getFilterObj,
        getSearchObj
    };
}