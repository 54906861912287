

export const defaultShipmentMapFilters = {
    shipmentMerchant: "all",
    shipmentType: [],
    shipmentOrderStatus: [],
    shipmentHubOpen: "all",
    shipmentReadySince: 60,
}

export const defaultDriverMapFilters = {
    driverCity: "all",
    driverDevice: "all",
    driverVehicle: "all",
    driverUniform: "all",
    driverStatus: "all",
    driverLastSeen: 30,
}

export const defFilters = {
    ...defaultShipmentMapFilters,
    ...defaultDriverMapFilters
};

