import moment from "moment";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { backgroundColors } from "./colors";

function DriverCard({
  data,
  onClick = () => {},
  selectedDriverId,
  selectedRef,
  inSelectedCards = false,
  inActiveDrivers = false,
}) {
  const { t } = useTranslation("drivers");
  const color = data?.is_busy ? "danger" : "success";
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  // identify status
  let statusColor = ""; // default-unassigned, success-completed, danger-cancelled, warning-assigned
  if (data?.isOnline) {
    if (data?.isBusy) {
      statusColor = "onlineBusy";
    } else {
      statusColor = "onlineFree";
    }
  } else {
    statusColor = "offline";
  }

  let statusText = "";
  if (data?.isBusy) {
    statusText = t("busy");
  } else {
    statusText = t("available");
  }

  useEffect(() => {
    if (selectedDriverId === data?.id) {
      setIsSelected(true);
      setIsOpen(true);
    } else {
      setIsSelected(false);
      setIsOpen(false);
    }
    if (!selectedDriverId) {
      setIsSelected(false);
    }
  }, [selectedDriverId]);

  if ((inSelectedCards && isSelected) || (!isSelected && !inSelectedCards)) {
    return (
      <div
        ref={selectedDriverId === data.id ? selectedRef : null}
        className={`max-w-[383px] rounded-xl ${
          isSelected ? "bg-lightBlue" : "bg-white hover:bg-lightGray"
        } text-xs hover:cursor-pointer transition-all`}
        onClick={(e) => {
          onClick(data);
          setIsSelected(!isSelected);
        }}
      >
        <div
          className={`my-2 px-4 py-2 capitalize  flex items-center justify-between flex-col space-y-2`}
        >
          <div className="w-full flex justify-between">
            <div className="flex items-center justify-center space-x-1">
              <span
                className={`px-2 py-0.5 rounded-md text-white capitalize ${backgroundColors[statusColor]}`}
              >
                {data?.id} / {statusText}
              </span>
            </div>
            <span
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
              className="text-primaryColor font-medium hover:underline"
            >
              {isOpen ? t("less_info") : t("show_more")}
            </span>
          </div>
          <div className="flex flex-row items-end justify-between text-left w-full space-x-1">
            <div className="flex flex-col w-1/3">
              <div className="flex items-center">
                {data?.avatarUrl && (
                  <img
                    className="h-4 w-4 mr-1 rounded-full border border-transparent bg-white"
                    src={data?.avatarUrl}
                  />
                )}
                {isOpen ? (
                  <a
                    className="hover:underline"
                    target="_blank"
                    href={`${
                      process.env.REACT_APP_MODE === "production"
                        ? "https://barqfleet.com/admin/couriers/" + data?.id
                        : "https://staging.barqfleet.com/admin/couriers/" +
                          data?.id
                    }`}
                  >
                    <span className="text-[14px]">
                      {data?.firstName || ""} {data?.lastName || ""}
                    </span>
                  </a>
                ) : (
                  <span className="text-[14px]">
                    {data?.firstName || ""} {data?.lastName || ""}
                  </span>
                )}
              </div>
              <span className="text-lightColor">{t("knight_name")}</span>
            </div>

            <div className="flex flex-col w-1/3">
              <span>{data?.city?.name || ""}</span>
              <span className="text-lightColor">{t("city")}</span>
            </div>
            <div className="flex flex-col w-1/3">
              <span>+{data?.mobileNumber || data?.emergencyNumber || ""} </span>
              <span className="text-lightColor">{t("phone_number")}</span>
            </div>
          </div>

          {isOpen && (
            <>
              <div className="flex flex-row items-end justify-between text-left w-full space-x-1">
                <div className="flex flex-col w-1/3">
                  <span className="text-primaryColor">
                    {data?.activeShipmentsCount || ""}
                  </span>
                  <span className="text-lightColor">
                    {t("active_shipments")}
                  </span>
                </div>

                <div className="flex flex-col w-1/3">
                  <span className="text-[12px]">
                    {data?.lastSeen
                      ? moment(data?.lastSeen).format("hh:mm / D.M.YYYY")
                      : "-"}{" "}
                  </span>
                  <span className="text-lightColor">Last Seen</span>
                </div>
                <div className="flex flex-col w-1/3">
                  <span>{data?.partner?.name || "-"}</span>
                  <span className="text-lightColor">Partner Name</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  if (inActiveDrivers) {
    return (
      <div
        ref={selectedDriverId === data.id ? selectedRef : null}
        className={`max-w-[383px] rounded-xl ${
          isSelected ? "bg-lightBlue" : "bg-white hover:bg-lightGray"
        } text-xs hover:cursor-pointer transition-all`}
      >
        <div
          className={`my-2 px-4 py-2 capitalize  flex items-center justify-between flex-col space-y-2`}
        >
          <div className="w-full flex justify-between">
            <div className="flex items-center justify-center space-x-1">
              <span
                className={`px-2 py-0.5 rounded-md text-white capitalize ${backgroundColors[statusColor]}`}
              >
                {data?.id} / {statusText}
              </span>
            </div>
            <span
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
              className="text-primaryColor font-medium hover:underline"
            >
              {isOpen ? "Less info" : "Show more"}
            </span>
          </div>
          <div className="flex flex-row items-end justify-between text-left w-full space-x-1">
            <div className="flex flex-col w-1/3">
              <div className="flex items-center">
                {data?.avaratUrl && (
                  <img
                    className="h-4 w-4 mr-1 rounded-full border border-transparent bg-white"
                    src={data?.avatarUrl}
                  />
                )}
                <span className="text-[14px]">
                  {data?.firstName || ""} {data?.lastName || ""}
                </span>
              </div>
              <span className="text-lightColor">{t("knight_name")}</span>
            </div>

            <div className="flex flex-col w-1/3">
              <span>{data?.city?.name || ""}</span>
              <span className="text-lightColor">{t("city")}</span>
            </div>
            <div className="flex flex-col w-1/3">
              <span>+{data?.mobileNumber || data?.emergencyNumber || ""} </span>
              <span className="text-lightColor">{t("phone_number")}</span>
            </div>
          </div>

          {isOpen && (
            <>
              <div className="flex flex-row items-end justify-between text-left w-full space-x-1">
                <div className="flex flex-col w-1/3">
                  <span className="text-primaryColor">
                    {data?.activeShipmentsCount || ""}
                  </span>
                  <span className="text-lightColor">
                    {t("active_shipments")}
                  </span>
                </div>

                <div className="flex flex-col w-1/3">
                  <span>{data?.rating}</span>
                  <span className="text-lightColor">{t("partner")}</span>
                </div>
                <div className="flex flex-col w-1/3">
                  <span>{data?.payment_type?.split("_")?.join(" ")}</span>
                  <span className="text-lightColor">{t("payment_method")}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default React.memo(DriverCard);
