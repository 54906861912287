import CustomSelect from "../../../components/common/Select";
import NormalSelect from "../../../components/common/Select/NormalSelect";

const data = [
    {
        name: "Filter by Status",
        value: "status",
        id: 2,
        options: [
            { value: "0", label: "Unassigned" },
            { value: "1", label: "Assigned" },
            { value: "2", label: "On Hold" },
            { value: "3", label: "Not Ready" },
            { value: "4", label: "Completed" },
            { value: "5", label: "Cancelled" },
            { value: "6", label: "Courier Arrived" },
            { value: "7", label: "Unaccepted" },
        ],
    },
];

export default function OrderFilters({ filters, handleChange, selectedFilters }) {
    return (
        <div className="w-full flex flex-col space-y-5 mb-12 mt-5">
            <div className="flex flex-wrap">
                {
                    filters?.map((filter) => (
                        <div key={filter.id} className="flex basis-1/2 p-3">
                            <NormalSelect
                                key={filter.id}
                                filter={filter}
                                defaultValue={filter.options.find(option => option?.value == selectedFilters[filter?.value]
                                )}
                                handleChange={(e) => handleChange(e.value, filter.value)}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
}
