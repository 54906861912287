import React, { useState } from 'react';
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch' } };

export default function CustomSwitch({ color = "warning", text = "",handleChange=(isChecked)=>{} }) {
    const [isChecked, setIsChecked] = useState(true)
    return (
        <div>
            <Switch color={color} {...label} checked={isChecked} onChange={(e) => { setIsChecked(old => !old); handleChange(e.target.checked) }} />
            <span>{isChecked ? "Active" : "Inactive"}</span>
        </div>
    );
}
