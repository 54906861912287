import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import cn from "classnames";
import { SearchIcon } from "../../svg";

type SearchProps = {
  hasIcon?: boolean;
  onChange: (value?: any) => void;
  filterName: string;
  infoText?: string;
  placeholder?: string;
  error?: string;
  beforeText?: string;
  defaultValue?: string;
  handleClearSearch?: () => void;
  onKeyPress?: (e: any) => void;
  value?: string;
  hasValue?: boolean;
  style?: object;
};

function NewSearch(
  {
    hasIcon = true,
    filterName,
    onChange = (text: String) => {},
    infoText = "",
    error,
    placeholder = "",
    beforeText = "",
    defaultValue = "",
    handleClearSearch = () => {},
    onKeyPress = (e) => {},
    value = "",
    hasValue = false,
    style,
  }: SearchProps,
  ref: any
) {
  const [val, setVal] = useState("");

  const onClearSearchClick = () => {
    handleClearSearch();
    setVal("");
  };
  return (
    <div className="flex-1 flex flex-col">
      <div
        style={style}
        className={cn(
          "relative  flex items-center justify-between min-w-64 border  rounded-lg pl-3 py-2 min-w-36",
          {
            "border-red-500": error?.length,
          }
        )}
      >
        <span className="text-gray-400 text-sm mr-3">{beforeText}</span>
        <input
          className="flex flex-1 ouline-none focus:outline-none w-full"
          onChange={(e) => {
            onChange(e);
            setVal(e.target.value);
          }}
          ref={ref}
          value={hasValue ? value : val}
          onKeyDown={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onKeyPress={(e) => onKeyPress(e)}
        />
        <div
          className={cn(
            "absolute w-auto h-4 bg-white -top-[11px] px-1 left-3 text-sm cursor-default select-none",
            [error?.length ? "text-red-500" : "text-gray-500"]
          )}
        >
          {filterName}
        </div>
        {hasIcon && val == "" ? (
          <span className="cursor-pointer mr-3">
            <SearchIcon />
          </span>
        ) : (
          hasIcon &&
          val != "" && (
            <span onClick={onClearSearchClick} className="cursor-pointer mr-3">
              <CloseIcon />
            </span>
          )
        )}
      </div>
      <span
        className={cn("text-[12px] ", [
          error?.length ? "text-red-500" : "text-mediumGray",
        ])}
      >
        {error?.length ? error : infoText}
      </span>
    </div>
  );
}

export default React.forwardRef(NewSearch);
