function WarehouseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.85896 2.70226C7.72106 2.77683 2.78894 6.29564 2.73872 6.35531C2.70998 6.38944 2.68096 6.44755 2.67421 6.48445C2.66746 6.52137 2.66475 6.92826 2.66817 7.38868C2.67421 8.2004 2.67571 8.22759 2.718 8.28459C2.79012 8.38185 2.88915 8.43715 2.99118 8.43715C3.09922 8.43715 3.15506 8.41062 3.36681 8.25875C3.45371 8.19644 3.53277 8.14545 3.54253 8.14545C3.55228 8.14545 3.56026 9.23811 3.56026 10.5736C3.56026 12.922 3.56158 13.0043 3.60003 13.079C3.65121 13.1785 3.73806 13.2603 3.83578 13.3011C3.89839 13.3272 4.00749 13.3334 4.40378 13.3334H4.89427V10.6665V7.99961H8.00001H11.1058V10.6665V13.3334L11.6008 13.333C12.1464 13.3325 12.1999 13.3222 12.3188 13.1954C12.4467 13.0591 12.4398 13.211 12.4398 10.5528V8.12493L12.6693 8.28105C12.8768 8.42221 12.9085 8.43715 12.9997 8.43715C13.1115 8.43715 13.2073 8.38535 13.282 8.28459C13.3243 8.22754 13.3258 8.2004 13.332 7.37826C13.3361 6.83832 13.3306 6.50404 13.3169 6.45722C13.3051 6.41678 13.2647 6.35452 13.2272 6.31887C13.1353 6.23168 8.17865 2.71095 8.11216 2.68568C8.03241 2.65538 7.93373 2.66184 7.85896 2.70226ZM5.56128 9.21847V9.7706H8.00001H10.4388V9.21847V8.66633H8.00001H5.56128V9.21847ZM5.56128 10.9999V11.5624H8.00001H10.4388V10.9999V10.4373H8.00001H5.56128V10.9999ZM5.56128 12.7813V13.3334H8.00001H10.4388V12.7813V12.2291H8.00001H5.56128V12.7813Z"
        fill="#FF9800"
      />
    </svg>
  );
}

export default WarehouseIcon;
