import React from "react";
import cn from "classnames";
import { SearchIcon } from "../../svg";

function CustomInput (
	{
		inputName,
		onChange = (input) => { },
		infoText = "",
		placeholder = "",
		showInfo = false,
		disabled = false,
		icon = <></>,
		style = {},
		...rest
	},
	ref
) {
	return (
		<div className='flex-1 flex flex-col'>
			<div
				style={style}
				className={cn(
					"relative  flex items-center justify-between min-w-64 border  rounded-lg px-3 py-2 min-w-36"
				)}
			>
				<input
					ref={ref}
					disabled={disabled}
					className='flex flex-1 ouline-none focus:outline-none bg-white'
					onChange={onChange}
					// onKeyDown={onChange}
					placeholder={placeholder}
					{...rest}
				/>
				<div
					className={cn(
						"absolute w-auto h-4 bg-white -top-[11px] px-1 left-3 text-sm cursor-default select-none text-gray-500"
					)}
				>
					{inputName}
				</div>
				{icon && icon}
			</div>
			<span className="text-[#00000099] text-[12px] pl-3">{infoText}</span>
		</div>
	);
}

export default React.forwardRef(CustomInput);
