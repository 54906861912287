import NormalSelect from '../../../components/common/Select/NormalSelect';
import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import CustomInput from "../../../components/common/Input";
import SelectAsyncPaginate from "../../../components/common/Select/AsyncPaginateSelect";
import moment from 'moment';
import CustomButton from '../../../components/common/Button';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_COURIER_PARTNERS } from 'api/queries/Queries';
import MUIDatePicker from '../../../components/common/DatePicker';
import { stagingOptions } from 'constants/apollo';
import * as Yup from 'yup'

export default function DriverInfoTab ({ driver, handleUpdateDriver }) {
    const [fetchDriverPartners, { data }] = useLazyQuery(GET_ALL_COURIER_PARTNERS, stagingOptions)
    const formik = useFormik({
        initialValues: {
            firstName: driver?.firstName || "",
            lastName: driver?.lastName || "",
            mobileNumber: driver?.mobileNumber || "",
            email: driver?.email || "",
            joinedAt: driver?.joinedAt || "",
            idNumber: driver?.idNumber || "",
            dateOfBirth: driver?.dateOfBirth || "",
            nationality: driver?.nationality || "",
            passportNo: driver?.passportNo || "",
            passportCountry: driver?.passportCountry || "",
            courierType: driver?.courierType || "",
            partnerId: driver?.partner?.id || "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            mobileNumber: Yup.string().required("Required"),
            email: Yup.string().email().required("Required"),
            idNumber: Yup.number()
                .test('len', 'Must be no more than 12 characters', val => val.toString().length <= 12)
                .test('len', 'Must be no less than 10 characters', val => val.toString().length >= 10)
                .required("Required"),
        }),
        onSubmit: (values) => {
            handleUpdateDriver({
                ...values,
                partnerId: parseInt(values.partnerId)
            })
            // setIsSubmitting(true)
            // alert(JSON.stringify(values, null, 2));
        },
    });

    const fetch_partners = ({ page = 1, searchQuery = "" } = {}) => {
        const searchObj = {}

        // const searchObj = getSearchObj(searchQuery);

        return fetchDriverPartners({
            variables: {
                offset: (page - 1) * 20,
                ...searchObj,
            },
        }).then((res) => {
            // setMerchants(res?.data?.fetchAllCourierPartners)
            return {
                options: res?.data?.fetchAllCourierPartners,
                hasMore: res?.data?.fetchAllCourierPartners?.length >= 1,
                additional: {
                    page: searchQuery ? 2 : page + 1,
                }
            }
        });
    };

    useEffect(() => {
        if (data) {
            console.log(data)
        }
    }, [data])

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit()
                }}
                className='main-wrapper flex flex-col justify-between space-y-5'
            >
                <div
                    className="flex space-x-2 py-4"
                >
                    <div className="w-1/5 flex flex-col">
                        <CustomInput
                            id="firstName"
                            name="firstName"
                            type="text"
                            inputName="First Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                        />
                        {formik.touched.firstName && formik.errors.firstName &&
                            <div className="error" key={0}>{formik.errors.firstName}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col">
                        <CustomInput
                            id="lastName"
                            name="lastName"
                            type="text"
                            inputName="Last Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                        />
                        {formik.touched.lastName && formik.errors.lastName &&
                            <div className="error" key={0}>{formik.errors.lastName}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col">
                        <CustomInput
                            id="mobileNumber"
                            name="mobileNumber"
                            type="text"
                            inputName="Phone Number *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobileNumber}
                        />
                        {formik.touched.mobileNumber && formik.errors.mobileNumber &&
                            <div className="error" key={0}>{formik.errors.mobileNumber}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col">
                        <CustomInput
                            id="email"
                            name="email"
                            type="text"
                            inputName="Email Address *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email &&
                            <div className="error" key={0}>{formik.errors.email}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col">
                        <MUIDatePicker
                            id="joinedAt"
                            name="joinedAt"
                            label="Joining date"
                            value={moment(formik.values.joinedAt, "YYYY MM DD")}
                            onChange={(date) => {
                                formik.setFieldValue("joinedAt", moment(date).format("YYYY-MM-DD"))
                            }}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.joinedAt && formik.errors.joinedAt &&
                            <div className="error" key={0}>{formik.errors.joinedAt}</div>
                        }
                    </div>

                </div>
                <div
                    className="flex space-x-2  py-6 border-t-2 border-gray-100"
                >
                    <div className="w-1/5 flex flex-col">
                        <CustomInput
                            id="idNumber"
                            name="idNumber"
                            type="text"
                            inputName="ID Number *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.idNumber}
                        />
                        {formik.touched.idNumber && formik.errors.idNumber &&
                            <div className="error" key={0}>{formik.errors.idNumber}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col">
                        <MUIDatePicker
                            id="dateOfBirth"
                            name="dateOfBirth"
                            label="Date Of Birth"
                            value={moment(formik.values.dateOfBirth, "YYYY MM DD")}
                            onChange={(date) => {
                                formik.setFieldValue("dateOfBirth", moment(date).format("YYYY-MM-DD"))
                            }}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.dateOfBirth && formik.errors.dateOfBirth &&
                            <div className="error" key={0}>{formik.errors.dateOfBirth}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col">
                        <CustomInput
                            id="nationality"
                            name="nationality"
                            type="text"
                            inputName="Nationality"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.nationality}
                        />
                        {formik.touched.nationality && formik.errors.nationality &&
                            <div className="error" key={0}>{formik.errors.nationality}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col">
                        <CustomInput
                            id="passportNo"
                            name="passportNo"
                            type="text"
                            inputName="Passport Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.passportNo}
                        />
                        {formik.touched.passportNo && formik.errors.passportNo &&
                            <div className="error" key={0}>{formik.errors.passportNo}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col">
                        <CustomInput
                            id="passportCountry"
                            name="passportCountry"
                            type="text"
                            inputName="Passport Country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.passportCountry}
                        />
                        {formik.touched.passportCountry && formik.errors.passportCountry &&
                            <div className="error" key={0}>{formik.errors.passportCountry}</div>
                        }
                    </div>

                </div>
                <div className='flex py-6 border-t-2 border-gray-100'>
                    <div className="w-1/5 flex flex-col pr-2">
                        <NormalSelect
                            styles={{ width: "100%" }}
                            key={44}
                            filter={
                                {
                                    name: "Employment Type",
                                    value: "courierType",
                                    id: 1,
                                    options: [
                                        { value: "paid", label: "Paid" },
                                        { value: "freelance", label: "Freelance" },
                                        { value: "partner_provided", label: "Contractor" },
                                    ],
                                }
                            }
                            defaultValue={[
                                { value: "paid", label: "Paid" },
                                { value: "freelance", label: "Freelance" },
                                { value: "partner_provided", label: "Contractor" },
                            ].find(option => option.value == formik.values.courierType
                            )}
                            handleChange={(e) => {
                                if (e.value === "freelance" || e.value === "paid")
                                    formik.setFieldValue("partnerId", null)
                                formik.setFieldValue("courierType", e.value)
                            }}
                        />
                        {formik.touched.courierType && formik.errors.courierType &&
                            <div className="error" key={0}>{formik.errors.courierType}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col pr-2">
                        {/* {
            
                        loadMore: (searchQuery, page) =>
                        fetch_cities({page: page, searchQuery: searchQuery }),
                                } */}
                        {(driver?.partner?.id || formik.values.courierType === "partner_provided") && <SelectAsyncPaginate
                            isSearchable={true}
                            key={23}
                            onChange={(e) => {
                                formik.setFieldValue("partnerId", e.value)
                            }}
                            defaultValue={{ value: driver?.partner?.id, label: driver?.partner?.name }}
                            // value={() => filter?.getSelectedVal(selectedFilters[filter?.value])}
                            // getDefaultValue={{ value: driver?.partner?.id, label: driver?.partner?.name }}
                            loadMore={(searchQuery, page) =>
                                fetch_partners({ page: page, searchQuery: searchQuery })}
                            filterName={"Contractor"}
                        // getOptionLabel={(option) => option.name}
                        // getOptionValue={(option) => option.id}
                        // isSearchable={true}
                        />}



                        {/* <CustomInput
                            id="partner"
                            name="partner"
                            type="text"
                            inputName="Contractor"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.partner}
                        /> */}
                        {
                            formik.touched.partner && formik.errors.partner &&
                            <div className="error" key={0}>{formik.errors.partner}</div>
                        }
                    </div>

                </div>
                <div className='flex w-full justify-end space-x-6'>
                    <div className="w-1/8">
                        <CustomButton
                            type='button'
                            variant='outlined'
                            color="warning"
                            onClick={() => {
                                // formik.resetForm()
                            }}
                            style={{ width: "100%", height: "38px" }}
                        >
                            Cancel
                        </CustomButton>
                    </div>
                    <div className="w-1/7">
                        <CustomButton
                            type='submit'
                            variant='contained'
                            color="warning"
                            onClick={() => { }}
                            style={{ width: "100%", height: "38px" }}
                        >
                            Update
                        </CustomButton>
                    </div>
                </div>
            </form>
        </div>
    )
}
