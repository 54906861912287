import React, { Suspense } from "react";
import { BrowserRouter, Route, Navigate } from "react-router-dom";
import Layout from "../components/common/layout/Layout";
import Sidebar from "../components/sidebar/Sidebar";

import routes from "../constants/routes";
import { logger } from "../helper/logger";
import Home from "../pages/Home";

// import Login from "../pages/Login/Login"
// import ResetPassword from "../pages/Login/ResetPassword"
// import { useSelector } from 'react-redux'
// logger(routes)
// import Fallback from '@/components/loading/index.jsx'
function Router() {
	logger(routes);
	const accessToken = true;
	// const accessToken = useSelector(el => el.auth.accessToken)
	// const UserId = useSelector(el => el.auth.user_role_id)
	function Loader({ path, children }) {
		const Component = path;
		return (
			// <Suspense fallback={<Fallback />}>
			<Component>{children}</Component>
			// </Suspense>
		);
	}

	return (
		<BrowserRouter>
			<Sidebar></Sidebar>
		</BrowserRouter>
	);
}

export default Router;
