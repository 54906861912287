import { useLazyQuery } from '@apollo/client';
import { GET_ALL_CITIES_FOR_FILTER, GET_ALL_HUBS_FOR_FILTER, GET_ALL_MERCHANTS_FOR_FILTER, GET_ALL_NATIONALITIES_FOR_FILTER, GET_ALL_NEIGBORHOODS_FOR_FILTER, GET_ALL_VEHICLE_MODELS_FOR_FILTER, GET_CITY_FOR_FILTER, GET_MERCHANT_FOR_FILTER } from 'api/queries/FilterQueries';
import { stagingOptions } from 'constants/apollo';

export default function useAsyncFilterFns () {

    const [getCity] = useLazyQuery(GET_CITY_FOR_FILTER, stagingOptions);
    const [getCities] = useLazyQuery(GET_ALL_CITIES_FOR_FILTER, stagingOptions);
    const [getMerchants] = useLazyQuery(GET_ALL_MERCHANTS_FOR_FILTER, stagingOptions);
    const [getMerchant] = useLazyQuery(GET_MERCHANT_FOR_FILTER, stagingOptions);
    const [getHubs] = useLazyQuery(GET_ALL_HUBS_FOR_FILTER, stagingOptions);
    const [getNeighborhoods] = useLazyQuery(GET_ALL_NEIGBORHOODS_FOR_FILTER, stagingOptions);
    const [getNationalities] = useLazyQuery(GET_ALL_NATIONALITIES_FOR_FILTER, stagingOptions);
    const [getVehicleModels] = useLazyQuery(GET_ALL_VEHICLE_MODELS_FOR_FILTER, stagingOptions);


    const fetch_cities = ({ page = 1, searchQuery = "" } = {}) => {
        return getCities({
            variables: {
                offset: (page - 1) * 20,
                query: searchQuery
            }
        }).then((res) => {
            return {
                options: res?.data?.fetchAllCities,
                hasMore: res?.data?.fetchAllCities?.length >= 1,
                additional: {
                    page: searchQuery ? 2 : page + 1,
                }
            }
        });
    }

    const fetch_nationalities = ({ page = 1, searchQuery = "" } = {}) => {
        return getNationalities().then((res) => {
            let options: any[] = []
            res?.data?.fetchAllNationalities.items.map((el: string) => {
                let nationality = { value: el, label: el }
                options.push(nationality)
            }
            )
            return {
                options: options,
                hasMore: false,
            }
        });
    }

    const fetch_vehicleModels = ({ page = 1, searchQuery = "" } = {}) => {
        return getVehicleModels().then((res) => {
            let options: any[] = []
            res?.data?.fetchVehicleEnums.models.map((el: string) => {
                let nationality = { value: el, label: el }
                options.push(nationality)
            }
            )
            return {
                options: options,
                hasMore: false,
            }
        });
    }

    const fetch_city = (id: any) => {
        return getCity({
            variables: {
                id: parseInt(id)
            }
        }).then((res) => {
            let data = res?.data?.fetchCity
            return data
        });
    }

    const fetch_merchants = ({ page = 1, searchQuery }: { page?: number, searchQuery?: string } = {}) => {
        return getMerchants({
            variables: {
                offset: (page - 1) * 20,
                query: searchQuery,
                includeInactive: false,
            }
        }).then((res) => {
            return {
                options: res?.data?.fetchAllMerchants,
                hasMore: res?.data?.fetchAllMerchants?.length >= 1,
                additional: {
                    page: searchQuery ? 2 : page + 1,
                }
            }
        });
    }

    const fetch_merchant = ({ id }: { id: string }) => {
        return getMerchant({
            variables: {
                id: parseInt(id)
            }
        }).then((res) => {
            let data = res?.data?.fetchMerchant
            return data
        });
    }

    const fetch_hubs = ({ page = 1, searchQuery }: { page?: number, searchQuery?: string } = {}) => {
        return getHubs({
            variables: {
                offset: (page - 1) * 20,
                includeInactive: false,
                // query: searchQuery
            }
        }).then((res) => {
            return {
                options: res?.data?.fetchAllHubs,
                hasMore: res?.data?.fetchAllHubs?.length >= 1,
                additional: {
                    page: searchQuery ? 2 : page + 1,
                }
            }
        });
    }

    const fetch_neighborhoods = ({ page = 1, searchQuery }: { page?: number, searchQuery?: string } = {}) => {
        return getNeighborhoods({
            variables: {
                offset: (page - 1) * 20,
                includeInactive: false,
                // query: searchQuery
            }
        }).then((res) => {

            return {
                options: res?.data?.fetchAllNeighborhoods,
                hasMore: res?.data?.fetchAllNeighborhoods?.length >= 1,
                additional: {
                    page: searchQuery ? 2 : page + 1,
                }
            }
        });
    }

    return {
        fetch_cities,
        fetch_city,
        fetch_merchants,
        fetch_merchant,
        fetch_hubs,
        fetch_neighborhoods,
        fetch_nationalities,
        fetch_vehicleModels
    }
}