import { useLazyQuery as apolloUseLazyQuery } from "@apollo/client";

import useLazyQuery from "hooks/useLazyQuery";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import PurchaseOrderFilters from "../PurchaseOrder/components/PurchaseOrderFilters";
import PurchaseOrderTable from "../PurchaseOrder/components/PurchaseOrderTable";
import CustomButton from "../../../components/common/Button";
import { GridFilterListIcon } from "@mui/x-data-grid";
import CustomSearch from "../../../components/common/Search";
import MuiFilterButton from "../../../components/common/FilterButton/MuiFilterButton";
import CustomTable from "../../../components/Table";
import Modal from "../../../components/common/Modal";
import { LoadingButton } from "@mui/lab";
import CustomInput from "../../../components/common/Input";
import PaginatedTable from "../../../components/Table/paginatedTable";

import CustomSwitch from "../../../components/common/Switch";
import { objFlatten } from "../../../helper/objFlatten";
import {
  DefaultWarehouseIcon,
  LocationIcon,
  SaveIcon,
} from "../../../components/svg";
import CustomSelect from "../../../components/common/Select";
import NormalSelect from "../../../components/common/Select/NormalSelect";
import WarehouseIcon from "../../../assets/icons/WarehouseIcon";
import { useDebounce } from "../../../hooks/useDebounce";
import { GET_ALL_WAREHOUSES } from "../../../api/queries/WMSqueries";
import { wmsOptions } from "../../../constants/apollo";
import useWarehouse from "./useWarehouse";
import { logger } from "../../../helper/logger";
import NewSearch from "../../../components/common/Search/new-search";
import FiltersList from "../../../components/FiltersList";
import { WMS_FILTER_MERCHANTS } from "../../../api/queries/WMSFilterQueries";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import { useSearchParams } from "react-router-dom";
// import CreateWarehouseForm from "./CreateWarehouseForm";

const cols = [
  {
    name: "Warehouse ID",
    value: "id",
    canBeSorted: true,
    type: "link",
    link: "id",
  },
  {
    name: "Warehouse Name && Code",
    value: "referenceName",
    type: "warehouse",
    canBeSorted: true,
    render: (data: any) =>
      `${data?.referenceName || ""} ${
        data?.locationCode ? `(${data?.locationCode})` : ""
      }`,
  },
  {
    name: "Merchant",
    value: "inventory.0.merchant.name",
  },

  {
    name: "Carts Count",
    value: "cartsCount",
  },
  {
    name: "Products",
    value: "products",
    render: (data: any) => `${data?.data?.inventory?.serials?.length || "-"} `,
  },
  { name: "Shelves", value: "shelvesCount" },
  { name: "City", value: "city", canBeSorted: true },
  {
    name: "Status",
    value: "status",
    type: "status",
    styles: "uppercase",
    canBeSorted: true,
  },
];

const filters = {
  merchantId: "all",
  cityId: "all",
  status: "all",
};

export default function Warehouses() {
  const localStorageFilters = JSON.parse(
    window?.localStorage?.getItem("wmsWarehousesFilters") || "{}"
  );
  const [searchParams, setSearchParams] = useSearchParams(
    localStorageFilters || {}
  );

  const [searchText, setSearchText] = useState(
    localStorageFilters?.searchText ||
      Object.fromEntries([...searchParams]).searchText ||
      ""
  );
  const [filterOpen, setFilterOpen] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 500);
  const [productFilters, setProductFilters] = useState({
    ...filters,
    ...Object.fromEntries([...searchParams]),
  });
  const [page, setPage] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);

  const [rows, setRows] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [filterByBin, setFilterByBin] = React.useState("");

  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [newPurchaseOrder, setNewPurchaseOrder] = React.useState(false);

  const [merchants, setMerchants] = useState([]);
  const [getMerchants] = apolloUseLazyQuery(WMS_FILTER_MERCHANTS, wmsOptions);

  const [getInventoryLogs] = useLazyQuery(GET_ALL_WAREHOUSES, wmsOptions);
  const [warehouseList, setWarehouseList] = useState([]);
  const [sortCol, setSortCol] = useState({
    label: "id",
    value: 0,
  });

  const { getSortOrder, getOrderObj, getSearchObj, getFilterObj } =
    useWarehouse();

  const handleQueryChange = (obj: any) => {
    // updare router query based on implemented filters
    let mergedObj: any = { ...Object.fromEntries([...searchParams]), ...obj };
    let modified: any = {};

    Object.keys(mergedObj).map((key) => {
      if (mergedObj[key] != "all" && mergedObj[key] != "") {
        if (key in obj) {
          modified[key] = obj[key];
        } else {
          modified[key] = mergedObj[key];
        }
      }
    });

    setSearchParams(modified);
    localStorage.setItem("wmsWarehousesFilters", JSON.stringify({ ...modified }));

  };
  useEffect(() => {
    handleSearch(searchText);
    handleQueryChange({ searchText: searchText });
  }, [debouncedSearchText]);

  const fetchWarehouses = (
    skip = 0,
    search = searchText,
    sortName = sortCol.label,
    sortOrder = getSortOrder(sortCol.value),
    filters = productFilters
  ): any => {
    logger("sortName", sortName);

    let orderObj = getOrderObj(sortName, sortOrder);
    let searchObj = getSearchObj(search);
    let filterObj = getFilterObj(filters);

    getInventoryLogs({
      variables: {
        skip: skip,

        ...searchObj,
        ...filterObj,

        ...orderObj,
      },
    }).then((res) => {
      setItemsCount(res.data.locations.totalCount);

      const warehouseList = res.data.locations.items || [];

      const warehouseListFlattened = warehouseList.map((item: any) => {
        let status;
        if (item.isActive) {
          status = "active";
        } else {
          status = "inactive";
        }

        let shelvesCount = item?.shelves?.length || 0;
        let cartsCount = item?.carts?.length || 0;

        let purchaseOrdersCount = item?.purchaseOrders?.length || 0;

        return {
          ...objFlatten({
            ...item,
            status,
            shelvesCount: shelvesCount,
            purchaseOrdersCount: purchaseOrdersCount,
            cartsCount: cartsCount,
          }),
          data: item,
        };
      });

      setWarehouseList(warehouseListFlattened);
      logger(warehouseList, warehouseListFlattened);
    });
  };

  const fetch_merchants = ({ page = 1, searchQuery = "" } = {}) => {
    logger("fetch_merchants", page, searchQuery);

    const searchObj = getSearchObj(searchQuery);

    return getMerchants({
      variables: {
        where: {},
        skip: (page - 1) * 20,
        ...searchObj,
      },
    }).then((res) => {
      setMerchants(res?.data?.merchants?.items);

      return {
        options: res?.data?.merchants?.items,
        hasMore: res?.data?.merchants?.totalCount >= page * 20,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    });
  };

  const fetch_merchant = ({ id = "0" } = {}) => {
    return getMerchants({
      variables: {
        where: { id: { eq: parseInt(id) } },
      },
    }).then((res) => {
      let data = res?.data?.merchants?.items[0];
      return data;
    });
  };

  const handleSort = (sortVal = "") => {
    if (sortCol.label === sortVal) {
      // sort Desc
      const sortOrder = getSortOrder(sortCol.value + 1);
      sortCol.value += 1;

      fetchWarehouses(0, searchText, sortVal, sortOrder);
    } else {
      setSortCol((old: any) => ({
        ...old,
        label: sortVal,
        value: 0,
      }));
      fetchWarehouses(0, searchText, sortVal, "ASC");
    }

    setPage(0);
  };
  function handleSearch(search: any) {
    fetchWarehouses(0, search, sortCol.label, getSortOrder(sortCol.value));
    setPage(0);
  }

  function handleFilter() {
    handleQueryChange(productFilters);

    fetchWarehouses(
      0,
      searchText,
      sortCol.label,
      getSortOrder(sortCol.value),
      productFilters
    );
  }

  function handleFilterReset() {
    setProductFilters(filters);
    handleQueryChange(filters);

    fetchWarehouses(
      0,
      searchText,
      sortCol.label,
      getSortOrder(sortCol.value),
      filters
    );
  }

  useEffect(() => {
    handleSearch(searchText);
  }, [debouncedSearchText]);
  useEffect(() => {
    getMerchants().then((res) => setMerchants(res?.data?.merchants?.items));
  }, []);

  return (
    <>
      <div>
        <div className="main-wrapper flex justify-between space-x-3 border-lightBgColor border-t-2 ">
          <div className="w-[450px] flex space-x-3">
            <NewSearch
              hasValue={true}
              value={searchText}
              filterName="Search"
              infoText=""
              placeholder="Type here..."
              onChange={(e: any) => setSearchText(e.target.value)}
              handleClearSearch={() => setSearchText("")}
            />
            <div
              onClick={() => setFilterOpen(true)}
              className="flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg"
            >
              <GridFilterListIcon style={{ color: "#FF9800" }} />
              <span className="text-warningColor font-medium uppercase ml-2">{`Filters By`}</span>
            </div>
          </div>
        </div>
        <PaginatedTable
          hasCheckbox
          sortCol={sortCol}
          count={itemsCount}
          page={page}
          setPage={setPage}
          onPageChange={() => {}}
          cols={cols}
          data={warehouseList}
          loading={false}
          filterByBin={filterByBin}
          onSortClick={(sortVal) => {
            handleSort(sortVal);
          }}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
      {/* IMPLEMENT CREATE WAREHOUSE FUNCTIONALITY LATER ON */}
      <Modal
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        footer={(handleClose: any) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={handleFilter}
            handleReset={handleFilterReset}
          />
        )}
        onClose={() => {}}
        title={"Filter Products"}
      >
        <FiltersList
          selectedFilters={productFilters}
          filters={[
            {
              name: "Filter by Merchant",
              value: "merchantId",
              id: 2,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  let sel = fetch_merchant({ id: id });
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: any, page: any) =>
                fetch_merchants({ page: page, searchQuery: searchQuery }),
            },
            // {
            //   name: "Filter by City",
            //   value: "cityId",
            //   id: 3,
            //   options: [{ value: "all", label: "All" }, ...locations],
            // },
            {
              name: "Filter by Status",
              value: "status",
              id: 5,
              options: [
                { value: "all", label: "All" },
                { value: true, label: "Active" },
                { value: false, label: "Inactive" },
              ],
            },
          ]}
          handleChange={(filterVal: any, filterName: string) => {
            setProductFilters((old) => ({
              ...old,
              [filterName]: filterVal,
            }));
          }}
        />
      </Modal>
    </>
  );
}
