import { DriverCalendarIcon, DriverLastSeenIcon, DriverLoactionIcon, FlagIcon, MapIcon, PhoneIcon, WalletIcon } from '../../../components/svg'
import React, { useEffect, useState } from 'react'
import InfoCard from '../../../components/common/InfoCard'
import moment from 'moment'
import PaginatedTable from '../../../components/Table/paginatedTable'
import { objFlatten } from 'helper/objFlatten'
import { logger } from 'helper/logger'
import Modal from '../../../components/common/Modal'
import LastSeenMap from './LastSeenMap'
import { alpha, styled } from '@mui/material/styles';
import { blue, green, pink } from '@mui/material/colors';
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import CustomButton from '../../../components/common/Button'


const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: pink[600],
        '&:hover': {
            backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: pink[600],
    },
}));

const cols = [
    { name: "Tracking #", value: "trackingNo", type: "customHTML" },
    { name: "Shipment Store", value: "hub.code" },
    { name: "# Orders", value: "orderCount" },
    { name: "Driving Time", value: "drivingTime", type: "customHTML" },
    { name: "Orders Distance", value: "distance" },
    { name: "Shipment Reward", value: "reward" },
    { name: "City", value: "hub.city" },
    { name: "Status", value: "shipmentStatus", type: "status", styles: "uppercase" },
];


export default function DriverHomeTab ({ driver, tableData, openChangePasswordModal, handleUpdateDriver }: any) {
    const totalCount = driver?.activeShipmentsCount
    const [mapModalOpen, setMapModalOpen] = useState(false)
    return (
        <>
            <div className='flex flex-col space-y-2 bg-gray-50'>
                <div className='flex flex-col'>
                    <div className='flex w-full justify-between p-4 bg-gray-200 space-x-5 mb-1'>
                        <FormGroup>
                            <div className='flex items-center space-x-2'>
                                <span>Actions</span>
                                <FormControlLabel control={<PinkSwitch checked={driver?.isBanned} onClick={() => { handleUpdateDriver({ isBanned: !driver?.isBanned }) }} />} label="Blocked/Banned" />
                                <FormControlLabel control={<Switch checked={driver?.onProbation} onClick={() => { handleUpdateDriver({ onProbation: !driver?.onProbation }) }} />} label="Under Test" />
                            </div>
                        </FormGroup>
                        <div className="w-1/7">
                            <CustomButton
                                type='button'
                                variant='outlined'
                                color="danger"
                                onClick={openChangePasswordModal}
                                style={{ width: "100%", height: "38px" }}
                            >
                                Reset Password
                            </CustomButton>
                        </div>

                    </div>
                    <div className="flex w-full justify-between p-4 bg-gray-200 space-x-5">
                        <InfoCard
                            key={0}
                            title="Joining Date"
                            info={moment(driver?.joinedAt).format("D-M-YYYY / HH:mm")}
                            icon={<DriverCalendarIcon />}
                            additionalStyles={'w-1/4'}
                        />
                        <InfoCard
                            key={1}
                            title="Activation Date"
                            info={moment(driver?.joinedAt).format("D-M-YYYY / HH:mm")}
                            icon={<DriverCalendarIcon />}
                            additionalStyles={'w-1/4'}
                        />
                        <InfoCard
                            key={2}
                            title="Last Neighborhood"
                            info={driver?.lastNeighborhood?.name || '-'}
                            icon={<DriverLoactionIcon />}
                            additionalStyles={'w-1/4'}
                        />
                        <InfoCard
                            key={4}
                            title="Phone Number"
                            info={driver?.mobileNumber}
                            icon={<PhoneIcon />}
                            additionalStyles={'w-1/4'}
                        />

                    </div>
                    <div className="flex w-full justify-between p-4 bg-gray-200 space-x-5">


                        <InfoCard
                            key={5}
                            title="Nationality"
                            info={driver?.nationality}
                            icon={<FlagIcon />}
                            additionalStyles={'w-1/4'}
                        />
                        <InfoCard
                            key={6}
                            title="Wallet Balance"
                            info={driver?.wallet?.balance}
                            icon={<WalletIcon />}
                            additionalStyles={'w-1/4'}
                        />
                        <InfoCard
                            key={3}
                            title="Last Seen"
                            info={driver?.lastSeen && moment(driver?.lastSeen).format("D-M-YYYY / HH:mm") || "-"}
                            icon={<DriverLastSeenIcon />}
                            additionalStyles={'w-1/4'}
                        />
                        <InfoCard
                            title="Last Seen Location"
                            styles='cursor-pointer border-b border-orange-400 px-1 text-orange-400'
                            onClick={() => setMapModalOpen(true)}
                            // info={`${driver?.latitude} ${driver?.longitude}`}
                            info="Open Map"
                            icon={<MapIcon />}
                            additionalStyles={'w-1/4'}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-full justify-between mt-1 bg-gray-200">
                    <span className="text-xl p-4">Active Shipments</span>
                    <PaginatedTable
                        bgColor={"bg-gray-200"}
                        // hasCheckbox
                        filterByBin={''}
                        sortCol={{ label: "", value: 0 }}
                        onSortClick={(sortVal) => {
                            // handleSort(sortVal);
                        }}
                        count={totalCount || 0}
                        page={0}
                        setPage={() => { }}
                        onPageChange={() => { }}
                        cols={cols}
                        data={tableData}
                        selectedRows={[]}
                        setSelectedRows={() => { }}
                        loading={false}
                    />
                </div>
            </div>
            <Modal
                footer={() => (
                    <></>
                )}
                onDismiss={() => {
                    setMapModalOpen(false);
                }}
                hidden
                autoOpen={mapModalOpen}
                onClose={() => { }}
                title={""}
            >
                <LastSeenMap driver={driver} />
            </Modal>
        </>
    )
}
