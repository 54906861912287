import React from "react";

export default function OrderStarted() {
	return (
		<svg
			width="12"
			height="15"
			viewBox="0 0 12 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.347 4H1.7198C1.08876 4 0.612684 4.57766 0.700885 5.20251C0.981811 7.19272 1.13673 9.76941 1 11L0.694066 12.8356C0.592478 13.4451 1.06252 14 1.68046 14H10.5C11.0523 14 11.3997 13.6341 11.3121 13.0888C11.2376 12.6246 11.1362 11.9533 11 11C10.6961 8.87296 11.0463 6.61126 11.3052 5.13552C11.4104 4.53613 10.9555 4 10.347 4Z"
				fill="white"
			/>
			<path
				d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V3H0V1Z"
				fill="white"
			/>
			<rect
				x="6.33398"
				y="10.1431"
				width="0.666667"
				height="1.52381"
				fill="#4CAF50"
			/>
			<path d="M6 10.1429L6.66667 9L7.33333 10.1429H6Z" fill="#4CAF50" />
			<rect
				x="8"
				y="10.1431"
				width="0.666667"
				height="1.52381"
				fill="#4CAF50"
			/>
			<path
				d="M7.66602 10.1429L8.33268 9L8.99935 10.1429H7.66602Z"
				fill="#4CAF50"
			/>
			<rect x="6" y="12" width="3" height="0.333333" fill="#4CAF50" />
		</svg>
	);
}
