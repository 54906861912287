import { useLazyQuery as useNativeQuery } from "@apollo/client";
import React, { useContext } from "react";

function useLazyQuery(...options) {
	const [fetch, { loading: defaultLoading }] = useNativeQuery(...options);

	React.useEffect(() => {
		if (defaultLoading) {
			document.body.className = document.body.className + " loading";
		} else {
			document.body.className = document.body.className.slice(
				0,
				document.body.className.length - 8
			);
		}
	}, [defaultLoading]);

	return [fetch];
}

export default useLazyQuery;
