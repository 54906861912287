import React from "react";
import {
	AppBar,
	Button,
	IconButton,
	Toolbar,
	Typography,
	Container,
} from "@mui/material";

export default function Header({ data }: { data: any }) {
	return (
		<AppBar position="static" color="transparent">
			<Container>
				<Toolbar>
					<Typography
						variant="h6"
						component="div"
						sx={{ flexGrow: 1 }}
					>
						Tracking No. {data?.data?.orderTracking.trackingNo}
					</Typography>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
