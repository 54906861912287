import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';


export default function MUIDatePicker ({ label, value, onChange }) {
    return (
        <DatePicker
            size="small"
            label={label}
            value={value}
            onChange={onChange}
            inputFormat="yyyy/MM/dd"
            format="YYYY/MM/DD"
            sx={{
                '.MuiInputBase-input': { padding: '10px' },
                'fieldset.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.10)',
                    borderRadius: "8px"
                },
                '.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
                    border: '1px solid rgba(0, 0, 0, 0.10)',
                    borderRadius: "8px"
                },
                '.MuiFormLabel-root': { top: '-5px', color: 'gray' },
                ".MuiFormLabel-filled": { color: "black", fontSize: "1.2rem", paddingTop: "5px !important", background: "white", paddingRight: "5px", color: 'gray', zIndex: "1" },

                // '&.MuiPickersPopper-root': { border: '4px solid red' }
            }} />
    );
}