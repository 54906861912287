import { useLazyQuery as apolloUseLazyQuery } from "@apollo/client";
import { Buffer } from 'buffer';
import CustomButton from "../../../components/common/Button";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { downloadExcelFromBinaryData } from "../../../helper/excel";
import { GridFilterListIcon } from "@mui/x-data-grid";
import { wmsOptions } from "../../../constants/apollo";
import { logger } from "../../../helper/logger";
import { useDebounce } from "../../../hooks/useDebounce";
import NewSearch from "../../../components/common/Search/new-search";
import PaginatedTable from "../../../components/Table/paginatedTable";
import { getSortationOrder } from "../../../helper/GetSortOrder";
import FiltersList from "../../../components/FiltersList";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import Modal from "../../../components/common/Modal";
import {WMS_EXPORT_INVENTORY_SUMMARY_PER_MERCHANT} from "../../../api/queries/WMSqueries";
import {
	WMS_FILTER_MERCHANTS,
	WMS_INVENTORY_SUMMARY_PER_MERCHANT,
} from "../../../api/queries/WMSFilterQueries";
import { useSearchParams } from "react-router-dom";

const columns = [
	{
		name: "merchant Name",
		value: "merchantName",
  },
	{
		name: "locationType",
		value: "locationType",
		canBeSorted: true,
	},
	{
		name: "productCount",
		value: "productCount",
		styles: "text-warningColor",
		canBeSorted: true,
	}
];

const filters = { merchantId: "all" };

export default function InventorySummary () {
	const localStorageFilters = JSON.parse(
		window?.localStorage?.getItem("currInvFilters") || "{}"
	);
	const [searchParams, setSearchParams] = useSearchParams(
		localStorageFilters || {}
	);

	const [selectedMerchantId, setSelectedMerchantId] = useState(+(searchParams.get("merchantId") || 0))

	const [filterOpen, setFilterOpen] = useState(false);
	const [searchText, setSearchText] = useState(
		localStorageFilters?.searchText ||
		Object.fromEntries([...searchParams]).searchText ||
		""
	);

	const debouncedSearchText = useDebounce(searchText, 500);
	const [productFilters, setProductFilters] = useState(
		{
			...filters,
			...Object.fromEntries([...searchParams]),
		}
	);

	const [page, setPage] = useState(0);
	const [itemsCount, setItemsCount] = useState(0);
	const [filterByBin, setFilterByBin] = React.useState("");
	const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
	const [merchants, setMerchants] = useState([]);
	const [inventorySummaryPerMerchant, setInventorySummaryPerMerchant] = useState([]);

	const [getMerchants] = apolloUseLazyQuery(WMS_FILTER_MERCHANTS, wmsOptions);
	const [getInventorySummaryPerMerchant] = apolloUseLazyQuery(WMS_INVENTORY_SUMMARY_PER_MERCHANT, wmsOptions);
	const [getExportInventorySummaryPerMerchant] = apolloUseLazyQuery(WMS_EXPORT_INVENTORY_SUMMARY_PER_MERCHANT, wmsOptions);

	const [sortColumn, setSortColumn] = useState({
		label: "updatedAt",
		value: 1,
	});
	const lockedFields = searchParams?.get("locked")?.split("-") || [];
	const isMerchantDash = searchParams?.get("merchantDashboard") == "true";

	if (isMerchantDash) {
		lockedFields.push("merchantId");
	}

	const handleQueryChange = (obj: any) => {
		let mergedObj: any = {
			...Object.fromEntries([...searchParams]),
			...obj,
		};
		let modified: any = {};

		Object.keys(mergedObj).map((key) => {
			if (mergedObj[key] != "all" && mergedObj[key] != "") {
				if (key in obj) {
					modified[key] = obj[key];
				} else {
					modified[key] = mergedObj[key];
				}
			}
		});

		setSearchParams(modified);
		localStorage.setItem("currInvFilters", JSON.stringify({ ...modified }));
	};

	useEffect(() => {
		handleSearch(searchText);
		handleQueryChange({ searchText: searchText });
	}, [debouncedSearchText]);

	const fetchInventorySummaryPerMerchant = async (
		search = searchText,
		merchantId = selectedMerchantId
	) => {
		try {
			const searchObj = getSearchObj(search);
	
			const res = await getInventorySummaryPerMerchant({
				variables: {
					...searchObj,
					merchantId: merchantId,
				},
			});
	
			setInventorySummaryPerMerchant(res?.data?.inventorySummaryPerMerchant?.items);
			setItemsCount(res?.data?.inventorySummaryPerMerchant?.items?.totalCount);
		} catch (error) {
			logger("Error fetching inventory summary per merchant:", error);
		}
	};

	const handleSort = (sortValue = "") => {
		if (sortColumn.label === sortValue) {
			// sort Desc
			const sortOrder = getSortationOrder(sortColumn.value + 1);
			sortColumn.value += 1;
		} else {
			setSortColumn((old: any) => ({
				...old,
				label: sortValue,
				value: 0,
			}));
		}

		setPage(0);
	};
	function handleSearch (search: any) {
		fetchInventorySummaryPerMerchant(search);
		setPage(0);
	}

	const fetch_merchants = async ({ page = 1, searchQuery = "" } = {}) => {
		try {
			logger("fetch_merchants", page, searchQuery);
	
			const searchObj = getSearchObj(searchQuery);
	
			const res = await getMerchants({
				variables: {
					where: {},
					skip: (page - 1) * 20,
					...searchObj,
				},
			});

			setMerchants(res?.data?.merchants?.items);

			return {
				options: res?.data?.merchants?.items,
				hasMore: res?.data?.merchants?.totalCount >= page * 20,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		} catch (error) {
			logger("Error fetching merchants:", error);
		}
	};

	const fetch_merchant = async ({ id = "0" } = {}) => {
		try {
			const res = await getMerchants({
				variables: {
					where: { id: { eq: parseInt(id) } },
				},
			});
			let data = res?.data?.merchants?.items[0];
			return data;
		} catch (error) {
			logger("Error fetching merchant:", error);
		}
	};

	const getSearchObj = (search: String) => {
		let searchObj = {}
		if (search.trim() != "") {
			searchObj = {
				query: search
			}
		}
		return searchObj
	}

	function handleFilter () {
		handleQueryChange(productFilters);
		setSelectedMerchantId(parseInt(productFilters.merchantId));

		fetchInventorySummaryPerMerchant(
			searchText,
			parseInt(productFilters.merchantId)
		);
	}

	function handleFilterReset () {
		const additional: any = {}
		if (isMerchantDash) {
			additional["merchantId"] = productFilters.merchantId
			additional["merchantDashboard"] = true
		}
		handleQueryChange({ ...filters, ...additional });
		setSelectedMerchantId(0);
		setInventorySummaryPerMerchant([]);
		setItemsCount(0);
		setProductFilters({ ...filters, ...additional });
		setFilterOpen(false);
	}

	const downloadInventorySummary = async () => {
		try {
			const res = await getExportInventorySummaryPerMerchant({
				variables: {
					merchantId: selectedMerchantId,
				},
			});

			const base64Data = res.data.exportInventorySummaryPerMerchant;
			const binaryData = Buffer.from(base64Data, 'base64');	
			downloadExcelFromBinaryData(binaryData, 'Inventory Summary');
		} catch (error) {
			logger('Error generating Excel:', error);
		}
	};

	return (
		<>
			<div>
				<div className='main-wrapper flex justify-between space-x-3'>
					<div className='w-[450px] flex space-x-3'>
						<NewSearch
							hasValue={true}
							value={searchText}
							filterName='Search'
							infoText=''
							placeholder='Type here...'
							onChange={(e: any) => setSearchText(e.target.value)}
							handleClearSearch={() => setSearchText("")}
						/>
						<div
							onClick={() => setFilterOpen(true)}
							className='flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg'
						>
							<GridFilterListIcon color='warning' />
							<span className='text-orange-500 font-medium uppercase ml-2'>{`Filters By`}</span>
						</div>
					</div>
					<div className='flex space-x-3'>
						<CustomButton
							variant='contained'
							color='success'
							onClick={downloadInventorySummary}
							style={{
								display: "flex",
								alignItems: "center",
								justifyConttent: "center",
							}}
						>
							Export Summary
						</CustomButton>
					</div>
				</div>
				<PaginatedTable
					sortCol={sortColumn}
					count={itemsCount}
					page={page}
					setPage={setPage}
					onPageChange={fetchInventorySummaryPerMerchant}
					cols={columns}
					data={inventorySummaryPerMerchant}
					selectedRows={selectedRows}
					loading={false}
					filterByBin={filterByBin}
					onSortClick={(sortValue) => {
						handleSort(sortValue);
					}}
				/>
			</div>

			<Modal
				onDismiss={() => {
					setFilterOpen(false);
				}}
				hidden
				autoOpen={filterOpen}
				footer={(handleClose: any) => (
					<ModalFooter
						handleClose={handleClose}
						handleSubmit={handleFilter}
						handleReset={handleFilterReset}
					/>
				)}
				onClose={() => { }}
				title={"Filter Products"}
			>
				<FiltersList
					selectedFilters={productFilters}
					lockedFields={lockedFields}
					filters={[
						{
							name: "Filter by Merchant",
							value: "merchantId",
							id: 2,
							isAsync: true,
							isSearchable: true,
							getSelectedVal: (id: any) => {
								if (id && id != "all") {
									let sel = fetch_merchant({ id: id });
									return sel;
								} else return { value: "all", label: "All" };
							},
							loadMore: (searchQuery: any, page: any) =>
								fetch_merchants({
									page: page,
									searchQuery: searchQuery,
								}),
						},
					]}
					handleChange={(filterVal: any, filterName: string) => {
						setProductFilters((old: any) => ({
							...old,
							[filterName]: filterVal,
						}));
					}}
				/>
			</Modal>
		</>
	);
}
