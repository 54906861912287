export const options: any = {
	set_bin: "Set bin",
	transit: "Ready for Transit",
	transit_middle: "In transit Middle Mile",
	assign_driver: "Assign to driver",
	// round_robin: "Round Robin",
	broadcast: "Broadcast",
	partner: "Send with Partner",
	remove: "Remove from warehouse",
	// validate: "Validate",
	// cod_collection: "COD collection Debrief",
};

export const broadcastTypes: any = {
	na: "na",
	broadcast: "broadcast",
	round_robin: "round_robin",
	courier: "courier",
	external: "external",
};
