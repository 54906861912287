import styled from "@emotion/styled";
import { SafetyCheckRounded } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import React from "react";
import FormGroup from '@mui/material/FormGroup';

const NewCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#B4B4B4",
  "&.Mui-checked": {
    color: "#B4B4B4",
  },
}));
export default function CustomCheckBox({ checked = false, label, setChecked = (e) => { } }) {
  return <FormGroup>
    <FormControlLabel control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />} label={label} />
  </FormGroup>;
}
