import CustomButton from '../../../../components/common/Button'
import React, { useState, useEffect } from 'react'
import { Router, useParams } from 'react-router-dom'
import InfoCard from '../../../../components/common/InfoCard';
import CustomInput from "../../../../components/common/Input";
import MUIDatePicker from '../../../../components/common/DatePicker';
import moment from 'moment';
import NormalSelect from '../../../../components/common/Select/NormalSelect';
import PaginatedTable from '../../../../components/Table/paginatedTable';
import { LOAD_TRANSACTIONS_BY_WALLET_ID } from 'api/queries/FinanceQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { stagingOptions } from 'constants/apollo';
import { toast } from 'react-toastify';
import { objFlatten } from 'helper/objFlatten';

const orderCols = [
  {
    name: "ID",
    value: "id"
  },
  {
    name: "TX Status",
    value: "status"
  },
  {
    name: "TX Amount",
    value: "amount",
    type: 'price'

  },
  {
    name: "Balance Before",
    value: "prevBalance",
    type: 'number'
  },
  {
    name: "Balance After",
    value: "afterBalance",
    type: 'number'

  },
  {
    name: "Note",
    value: "notes"
  },
  {
    styles: "text-warningColor",
    name: "Tracking No.",
    value: "trackingNo"
  },
  {
    name: "Created At",
    value: "createdAt"
  },
]

export default function WalletInfo () {
  const { wallet: id } = useParams();
  const [page, setPage] = useState(0)
  const [tableData, setTableData] = useState([])
  const [billingData, setBillingData] = useState(null);
  const [discountVal, setDiscountVal] = useState(0)
  const [itemsCount, setItemsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [discountMode, setDiscountMode] = useState('percent')
  const [walletInfo, setWalletInfo] = useState({})
  const [fetchData, { data, error }] = useLazyQuery(LOAD_TRANSACTIONS_BY_WALLET_ID, { ...stagingOptions, fetchPolicy: "network-only" });

  const fetch = (
    skip = 0
  ) => {
    try {

      fetchData(
        {
          variables: {
            id: parseInt(id),
            offset: skip,
          }
        }
      )
      // setLoading(true)
    } catch (e) {
      toast.error("An error occured");
      // setLoading(false)
    }
  };

  useEffect(() => {
    if (data) {
      setLoading(false)
      setItemsCount(data?.fetchTransactionsByWalletId?.totalCount);

      const dataList = data?.fetchTransactionsByWalletId?.items;
      const dataListFlattened = dataList?.map((item) => {

        let ownerName = ''
        if (item.wallet.owner.type.toLowerCase() === "merchant") {
          ownerName = item.wallet.owner.name
        } else {
          // it is a courier
          ownerName = item.wallet.owner.firstName + ' ' + item.owner.lastName
        }

        setWalletInfo((old) => ({
          ...old,
          owner: ownerName,
          balance: item.wallet.balance,
          creditLimit: item.wallet.creditLimit
        }))

        return objFlatten({
          ...item,
        });
      });

      setTableData(dataListFlattened);
    }
  }, [data]);

  useEffect(() => {
    fetch()
  }, [])

  const handleApplyDiscount = () => {
    // apply disc mutation logic goes here
  }

  return (
    <>
      <div className="flex flex-col bg-gray-50">
        <div className='flex bg-white my-2 space-x-2 py-2 px-4 justify-between'>
          <div className='flex gap-6 items-center'>
            <span className='font-bold text-xl text-warningColor'>{walletInfo.owner}</span>
            <span className='inline-block w-[1px] h-[60%] bg-black'></span>
            <span className='font-bold text-xl'>Balance: {walletInfo.balance}</span>
            <span className='inline-block w-[1px] h-[60%] bg-black'></span>
            <span className='font-bold text-xl'>Credit Limit: {walletInfo.creditLimit}</span>
          </div>
          <div className='w-48'>
            <CustomButton
              variant="contained"
              color="warning">
              Compensation
            </CustomButton>
          </div>
        </div>

        <div>
          <PaginatedTable
            tableHeadStyles={"bg-white"}
            count={itemsCount}
            page={page}
            setPage={setPage}
            onPageChange={(skip) => fetch(skip)}
            cols={orderCols}
            data={tableData}
            loading={false}
          />
        </div>
      </div>
    </>
  )
}
