import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { logger } from "../../../helper/logger";

const SelectAsyncPaginate = (
	{
		value,
		onChange,
		loadMore,
		filterName = "Filter",
		placeholder = "Select...",
		isSearchable = false,
		defaultValue,
		getDefaultValue = () => { },
		getOptionValue = (option) => option.value,
		getOptionLabel = (option) => option.label,
		defaultOptions = [{
			label: "All",
			value: "all"
		}]
	},
	ref
) => {
	const [selected, setSelected] = useState()

	// TODO: Implement debounced search
	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		return await loadMore(searchQuery, page);
	};

	useEffect(async () => {

		let def = await getDefaultValue()
		setSelected(def)
	}, [])

	return (
		<div className='relative w-full'>
			<AsyncPaginate
				maxMenuHeight={200}
				key={"1_async"}
				defaultValue={defaultValue}
				defaultOptions={defaultOptions}
				value={value || selected}
				loadOptions={loadOptions}
				getOptionValue={getOptionValue}
				getOptionLabel={getOptionLabel}
				onChange={(e) => {
					onChange(e);
					setSelected(e)
				}}
				isSearchable={isSearchable}
				placeholder={placeholder}
				additional={{
					page: 1,
				}}
				selectRef={ref}
			/>
			<div className='absolute w-auto h-4 bg-white -top-[11px] px-1 left-3 text-sm text-gray-500 cursor-default select-none'>
				{filterName}
			</div>
		</div>
	);
};

// SelectAsyncPaginate.propTypes = {
//     regionName: PropTypes.string.isRequired,
//     value: PropTypes.object,
//     onChange: PropTypes.func,
// };

export default React.forwardRef(SelectAsyncPaginate);
