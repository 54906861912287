import { Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import "leaflet/dist/leaflet.css";
import classNames from "classnames";
import { LeafletShipmentLocationIcon } from "../../../components/common/leaflet-icons";
import { useMapEvents } from "react-leaflet";
import { CustomTooltip } from "../../../components/common/Tooltip";
import polyline from "@mapbox/polyline";

import L from "../../../helper/CustomLeaflet";

import Route from "../../../services/Route";
import { routeColors } from "../../../components/colors";

export default function Map({ showMap, setShowMap, data }) {
	const [map, setMap] = useState();

	const showMarkers = () => {
		map?.setView(
			[
				data?.data?.orderTracking?.currentLocation?.latitude,
				data?.data?.orderTracking?.currentLocation?.longitude,
			],
			9
		);

		let driverData = {
			id: Number(1),
			currentLocation: {
				latitude: data?.data?.orderTracking?.currentLocation?.latitude,
				longitude:
					data?.data?.orderTracking?.currentLocation?.longitude,
			},
		};
		const newShipmentObj = [
			{
				id: Number(1),
				latitude: data?.data?.orderTracking?.origin?.latitude,
				longitude: data?.data?.orderTracking?.origin?.longitude,
				promise: 0, // idk what it is
				dropoffs: [
					{
						latitude:
							data?.data?.orderTracking?.destination?.latitude,
						longitude:
							data?.data?.orderTracking?.destination?.longitude,
					},
				],
			},
		];

		let datas = {
			driver: driverData,
			shipments: newShipmentObj,
			options: {
				g: true,
			},
		};

		Route.optimizeRoute(datas).then((res) => {
			res?.forEach((route, index) => {
				if (route.type !== "end" && route.type !== "start") {
					// add route's markers to the map
					addRouteMarker(
						route,
						routeColors[0],
						route.type === "completed"
					); // adds start/pickup/delivery markers
				}
				if (
					route.type == "start" &&
					driverData.latitude !== newShipmentObj[0].latitude &&
					driverData.longitude !== newShipmentObj[0].longitude
				) {
					addRouteMarker(
						route,
						routeColors[0],
						route.type === "completed"
					); // adds start/pickup/delivery markers
				}
				let polylineRes = route?.geometry;

				let lineOpacity = "1";

				if (
					polylineRes &&
					index !== res.length - 2 &&
					route.type !== "end"
				) {
					// shipment not started but is assigned: add driver->pickup dash route && opacity: 0.5

					let newRoute = L.polyline(
						polyline.decode(polylineRes)
					).addTo(map);

					// Add dashed route if not completed
					if (route.type != "completed") {
						let dashRoute = L.polyline(
							polyline.decode(polylineRes)
						).addTo(map);
						dashRoute.setStyle({
							color: "white",
							weight: 1,
							dashArray: "6, 6",
							dashOffset: "2",
						});

						// dashRoute.id = shipmentList[0].id;
					}
					// Make opacity less if not completed route
					if (route.type != "completed") {
						lineOpacity = "0.5";
					}
					newRoute.setStyle({
						color: routeColors[0],
						opacity: lineOpacity,
						weight: 9,
					});
				}
			});
		});
	};

	const addRouteMarker = (marker, markerouteColor, isCompleted) => {
		let pickupMarker = window.L.marker(
			[marker.latitude, marker.longitude],
			{
				icon: LeafletShipmentLocationIcon,
			}
		).addTo(map);

		// bind label to marker
		pickupMarker.bindTooltip(
			CustomTooltip(
				marker?.type,
				marker?.sequence,
				isCompleted ? "finished" : "notFinished",
				markerouteColor
			),
			{
				permanent: true,
				direction: "center",
				offset: [13, -18],
				class: "rounded-full",
				style: "background: transparent",
			}
		);
		// assign id to differentiate from other markers
		pickupMarker.id = marker.id;
	};

	useEffect(() => {
		if (data?.data?.orderTracking?.currentLocation?.latitude) {
			showMarkers();
		}
	}, [data]);

	useEffect(() => {
		window.L.tileLayer(
			"http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
			{
				maxZoom: 20,
				subdomains: ["mt0", "mt1", "mt2", "mt3"],
			}
		);
	}, []);

	return (
		<div
			className={classNames(
				"bg-white md:w-3/5 m-2 h-96 md:h-auto rounded overflow-hidden md:flex-1 z-0 p-2",
				{
					"hidden md:block": showMap,
				}
			)}
		>
			<MapContainer
				center={[24.719127, 46.669663]} // Riyadh
				zoom={5}
				zoomControl={true}
				style={{
					height: "100%",
					position: "relative",
					borderRadius: 4,
				}}
				tap={true}
				attributionControl={true}
				dragging={true}
			>
				<ReactLeafletGoogleLayer
					apiKey={"AIzaSyBx4euxjpf8VPHecQk2UrrSUOF3hDcp3Lg"}
					type={"roadmap"}
				/>
				<Checker setMap={setMap} />
			</MapContainer>
		</div>
	);
}

const Checker = ({ setMap }) => {
	const map = useMapEvents({});

	setMap(map);

	return <></>;
};
