import React from "react";
import cn from "classnames";
import DriverCard from "../../../components/DriverCard";
import { useTranslation } from "react-i18next";

function ActiveDrivers({
  drivers,
  handleClick,
  selectedRef,
  selectedDriverId,
}) {
  const { t } = useTranslation("common");

  return (
    <div className={cn({ "border-b border-gray-500": drivers.length > 0 })}>
      <h3 className={cn({ hidden: drivers.length == 0 })}>
        {t("assigned_drivers")}
      </h3>
      {drivers?.map((driver) => (
        <DriverCard
          selectedRef={selectedRef}
          key={driver.id}
          data={driver}
          onClick={handleClick}
          selectedDriverId={selectedDriverId}
          inActiveDrivers={true}
        />
      ))}
    </div>
  );
}

export default React.memo(ActiveDrivers);
