import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import CustomModal from "../../components/common/Modal";
import CustomSearch from "../../components/common/Search";
import InboundFilters from "./components/InboundFilters";
import InboundMenu from "./components/InboundMenu";
import InboundTable from "./components/InboundTable";
import { FETCH_SHIPMENTS } from "../../api/queries/Queries";
import { useMutation, useQuery } from "@apollo/client";
import useLazyQuery from "hooks/useLazyQuery";
import { stagingOptions } from "../../constants/apollo";
import { markShipmentProcessing } from "../../api/mutations/InboundMutation";
import { toast } from "react-toastify";

export default function Inbound() {
	const [barcode, setBarcode] = React.useState();
	const [trackingNo, setTrackingNo] = React.useState(
		window.localStorage.getItem("trackingNo") || ""
	);
	const [rows, setRows] = React.useState([]);
	const [options, setOptions] = React.useState({});
	const [modalOpen, setModalOpen] = React.useState(true);
	const [error, setError] = React.useState("");
	const [shipmentLoading, setShipmentLoading] = React.useState(false);
	const navigate = useNavigate();

	const [refetch] = useLazyQuery(FETCH_SHIPMENTS, stagingOptions);
	const [markShipmentProcessingMutation] = useMutation(
		markShipmentProcessing,
		stagingOptions
	);

	React.useEffect(() => {
		if (trackingNo) {
			onSubmit();
		}
	}, []);

	const onDismiss = React.useCallback(() => {
		return navigate("/assets/sortation");
	}, []);

	const onChangeBarCode = (event) => {
		if (event.keyCode === 13) {
			onSubmit();
		} else if (event.target.value?.length) {
			let value = event.target.value.trim();
			localStorage.setItem("trackingNo", value);
			setTrackingNo(value);
		}
	};

	const onSubmit = React.useCallback(async () => {
		try {
			setShipmentLoading(true);
			let {
				data: { fetchShipment: shipment },
			} = await refetch({
				variables: {
					trackingNo: trackingNo,
				},
			});
			if (!shipment) throw new Error("Shipment not found");

			setRows(
				shipment.orders.map((item) => {
					if (item?.orderStatus == "processing_at_warehouse") {
						localStorage.removeItem("trackingNo");
						throw new Error(
							"This shipment is already in the warehouse"
						);
					}

					return {
						...item,
						status: "not_scanned",
					};
				})
			);

			let res = await markShipmentProcessingMutation({
				variables: {
					input: {
						trackingNo,
						limit: 15,
						offset: 0,
					},
				},
			}).catch((e) => {});

			setOptions({
				shipment_id: "#" + shipment?.id,
				shipment_store: shipment?.merchant?.name,
				shipment_city: shipment?.hub?.city,
				shipment_knight:
					shipment?.courier?.firstName ||
					"-" + " " + shipment?.courier?.lastName ||
					"-",
				shipment_warehouse:
					shipment?.hub?.code + " " + shipment?.hub?.id,
			});

			setModalOpen(false);
		} catch (error) {
			setError(error?.message + "");
		} finally {
			setShipmentLoading(false);
		}
	}, [trackingNo]);

	const onScanShipment = React.useCallback(
		(id, isRemove) => {
			let data = [...rows];
			const index = data.findIndex((item) => item.trackingNo === id);

			if (index === -1) {
				data.push({
					trackingNo: id,
					status: "wrong_order",
				});
			}

			if (
				data[index]?.status &&
				data[index]?.status === "not_scanned" &&
				!isRemove
			) {
				data[index].status = "scanned";
			} else if (isRemove) {
				console.log(data[index]);
				if (data[index]?.status !== "wrong_order") {
					data[index].status = "not_scanned";
				} else {
					data = data.filter(
						(i) => i.trackingNo !== data[index].trackingNo
					);
				}
			}
			console.log(data);
			setRows([...data]);
		},
		[rows]
	);

	return (
		<div>
			<InboundMenu options={options} />
			<InboundFilters
				onScanShipment={onScanShipment}
				rows={rows}
				shipmentId={trackingNo}
			/>
			<CustomModal
				autoOpen={modalOpen}
				hidden
				onDismiss={onDismiss}
				title={"Scan or type Shipment tracking number"}
			>
				<div className='w-full flex flex-col space-y-5 mb-12 mt-5'>
					<div className='flex justify-between items-start space-x-3'>
						<CustomSearch
							error={error}
							filterName='Barcode'
							onChange={onChangeBarCode}
							infoText='Scan or Type Shipment barcode. For example, AN258213115'
						/>
						<div className='w-[144px] flex'>
							<LoadingButton
								loading={shipmentLoading}
								variant='contained'
								color='success'
								loadingPosition='start'
								startIcon={<CheckIcon />}
								onClick={() => onSubmit()}
							>
								<p className='ml-4'>SUBMIT</p>
							</LoadingButton>
						</div>
					</div>
				</div>
			</CustomModal>
			<InboundTable onScanShipment={onScanShipment} data={rows} />
		</div>
	);
}
