import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/common/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import SplitButton from "../../Sortation/components/SortationSelect";
import MuiFilterButton from "../../../components/common/FilterButton/MuiFilterButton";
import NewSearch from '../../../components/common/Search/new-search';

export default function KnightFilters ({ search, setSearch, setFilterModalOpen, setCreateKnightModalOpen }: any) {
  const { t } = useTranslation("common");
  return (
    <div className="main-wrapper flex justify-between items-start space-x-3 bg-white">
      <div className="flex items-center space-x-3">
        <div className="w-60">
          <NewSearch
            hasValue={true}
            value={search}
            filterName="Search"
            infoText=""
            placeholder="Type here..."
            onChange={(e: any) => setSearch(e.target.value)}
            handleClearSearch={() => setSearch("")}
          /></div>
        <div
          className="flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg"
          onClick={() => setFilterModalOpen(true)}>
          <FilterListIcon color="warning" />
          <span className="text-orange-500 font-medium">FILTER BY</span>
        </div>
      </div>
      <div className="flex space-x-3"></div>
      <div className="flex">
        <CustomButton onClick={() => setCreateKnightModalOpen(true)} variant="contained" color="warning">Add Courier</CustomButton>
      </div>
    </div>
  );
}
