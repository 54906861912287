import React from "react";
import { useTranslation } from "react-i18next";

type MenuItemProps = {
	data: string;
	label: string;
};

const MenuItem = ({ data, label }: MenuItemProps) => {
	return (
		<div className='flex flex-col'>
			<span className='text-mediumGray text-md capitalize'>{label}</span>
			<span className='text-xl font-medium'>{data}</span>
		</div>
	);
};

export default function InboundMenu({ options }: { options: any }) {
	const { t } = useTranslation(["common", "shipment", "knight"]);

	return (
		<div className='main-wrapper bg-gray-50 flex justify-between items-start'>
			<MenuItem
				label={`${t("shipment")} #`}
				data={options?.shipment_id || "-"}
				key='1'
			/>
			<MenuItem
				label={`${t("shipment_store")}`}
				data={options?.shipment_store || "-"}
				key='2'
			/>
			<MenuItem
				label={`${t("city")}`}
				data={options?.shipment_city || "-"}
				key='3'
			/>
			<MenuItem
				label={`${t("knight")}`}
				data={options?.shipment_knight || "-"}
				key='4'
			/>
			<MenuItem
				label={`${t("warehouse")}`}
				data={options?.shipment_warehouse || "-"}
				key='5'
			/>
		</div>
	);
}
