import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import _ from "lodash";
import PurchaseOrderFilters from "./components/PurchaseOrderFilters";
import PurchaseOrderTable from "./components/PurchaseOrderTable";
import CustomSearch from "../../../components/common/Search";
import CustomSelect from "../../../components/common/Select";
import NormalSelect from "../../../components/common/Select/NormalSelect";
import CustomInput from "../../../components/common/Input";
import CustomButton from "../../../components/common/Button";
import Modal from "../../../components/common/Modal";
import { LoadingButton } from "@mui/lab";
import { logger } from "../../../helper/logger";
import SelectAsyncPaginate from "../../../components/common/Select/AsyncPaginateSelect";
import {
  GET_ALL_MERCHANTS_WMS,
  GET_ALL_PRODUCTS,
  GET_ALL_WAREHOUSES,
} from "../../../api/queries/WMSqueries";
import { wmsOptions } from "../../../constants/apollo";
import NewSearch from "../../../components/common/Search/new-search";
import CreatePuchaseOrderForm from "./CreatePurchaseOrderForm";

export default function NewPurchaseOrder({
  setNewPurchaseOrder,
  fetchPurchaseOrder,
}: {
  setNewPurchaseOrder: any;
  fetchPurchaseOrder: () => {};
}) {
  const [filterByBin, setFilterByBin] = React.useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCart, setSelectedCart] = useState(null);
  const [serialText, setSerialText] = useState("");
  const [serialList, setSerialList] = useState<string[]>([]);

  const [getAllMerchants] = useLazyQuery(GET_ALL_MERCHANTS_WMS, wmsOptions);
  const [getAllProducts] = useLazyQuery(GET_ALL_PRODUCTS, wmsOptions);
  const [getAllCarts] = useLazyQuery(GET_ALL_WAREHOUSES, wmsOptions);
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const handleCreatePurchaseOrder = () => {
    setNewPurchaseOrder(false);
    fetchPurchaseOrder();
  };

  return (
    <>
      <div>
        <CreatePuchaseOrderForm
          handleCreatePurchaseOrder={handleCreatePurchaseOrder}
          setPurchaseOrders={setPurchaseOrders}
        />
        <PurchaseOrderTable
          cols={[
            "Serial",
            "Product Name",
            "Product Type",
            "Weight",
            "Size",
            "Created at",
            "Status",
          ]}
          data={purchaseOrders}
          setData={setPurchaseOrders}
          onPressCheckbox={() => {}}
          selectedRows={selectedRows}
          loading={false}
          filterByBin={filterByBin}
        />
      </div>
    </>
  );
}
