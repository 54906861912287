export default function useCurrentInventory() {
    const getOrderObj = (sortName, sortOrder) => {
        if (sortName !== "" && sortOrder != "DEFAULT") {
            switch (sortName) {
                case "product.name":
                    return {
                        order: {
                            product: { name: sortOrder },
                        },
                    };
                case "location.referenceName":
                    return {
                        order: {
                            location: {
                                referenceName: sortOrder,
                                locationCode: sortOrder
                            },
                        },
                    };
                case "merchant.name":
                    return {
                        order: {
                            merchant: {
                                name: sortOrder,
                            },
                        },
                    };
                case "location.type":
                    return {
                        order: {
                            location: {
                                type: sortOrder,
                            },
                        },
                    };
                case "updatedAt":
                    return {
                        order: {
                            updatedAt: sortOrder,
                        },
                    };
            }
        }
        else {
            return {
                order: {
                    id: "ASC",
                },
            }
        }
    }


    const getFilterObj = (filters) => {
        let filterList = [
        ]

        if (filters?.productId != "all") {
            filterList.push({
                productId: {
                    eq: parseInt(filters.productId),
                },
            });
        }

        if (filters?.locationId != "all") {
            filterList.push({
                locationId: {
                    eq: parseInt(filters.locationId),
                },
            });
        }
        if (filters?.locationType != "all") {
            filterList.push({
                location: {
                    type:
                    {
                        eq: filters.locationType,
                    }
                }
            });
        }
        if (filters?.merchantId != "all") {
            filterList.push({
                merchantId: {
                    eq: parseInt(filters.merchantId),

                }
            });
        }
        return {
            where: {
                and: [...filterList,
                ],
            }
        }
    }

    const getSearchObj = (search) => {

        let searchObj = {}


        if (search.trim() != "") {
            searchObj = {
                query: search
            }
        }
        return searchObj
    }
    return {
        getOrderObj,
        getFilterObj,
        getSearchObj
    };
}