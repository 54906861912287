import { useMutation, useLazyQuery as apolloUseLazyQuery } from "@apollo/client";
import FiltersList from "../../../../components/FiltersList";
import useLazyQuery from "hooks/useLazyQuery";
import React, { useEffect, useState } from "react";
import {
	ADD_UPDATE_CART,
	DELETE_LOCATIONS,
} from "../../../../api/mutations/WMSMutations";
import {
	WMS_FILTER_MERCHANTS,
	WMS_FILTER_PRODUCTS,
} from "../../../../api/queries/WMSFilterQueries";
import { GET_ALL_INVENTORY_LOGS } from "../../../../api/queries/WMSqueries";
import Modal from "../../../../components/common/Modal";
import ModalFooter from "../../../../components/common/Modal/ModalFooter";
import PaginatedTable from "../../../../components/Table/paginatedTable";
import { wmsOptions } from "../../../../constants/apollo";
import { getSortationOrder } from "../../../../helper/GetSortOrder";
import { logger } from "../../../../helper/logger";
import { objFlatten } from "../../../../helper/objFlatten";
import { inventoryLogsCol } from "../../colData";
import CreateCartfForm from "../CreateCartForm";
import useCurrentInventory from "./useCurrentInventory";
import useInventoryLogs from "./useInventoryLogs";

export default function InventoryLogs({
	warehouse,
	debouncedSearchText,
	setFilterOpen,
	filterOpen,
}) {
	const [getMerchants] = apolloUseLazyQuery(WMS_FILTER_MERCHANTS, wmsOptions);
	const [getProducts] = apolloUseLazyQuery(WMS_FILTER_PRODUCTS, wmsOptions);

	const defaultFilter = {
		productId: "all",
		merchantId: "all",
		parentLocationId: +warehouse.id,
	};

	const [dataList, setDataList] = useState([]);
	const [productFilters, setProductFilters] = useState(defaultFilter);
	const [selectedRows, setSelectedRows] = useState([]);
	const [products, setProducts] = useState([]);
	const [merchants, setMerchants] = useState([]);
	const [itemsCount, setItemsCount] = useState(0);
	const [page, setPage] = useState(0);

	const [searchText, setSearchText] = useState("");
	const [sortCol, setSortCol] = useState({
		label: "id",
		value: 1,
	});

	const [getAllCurrentInventory] = useLazyQuery(
		GET_ALL_INVENTORY_LOGS,
		wmsOptions
	);
	const [updateLocations] = useMutation(ADD_UPDATE_CART, wmsOptions);
	const [deleteLocations] = useMutation(DELETE_LOCATIONS, wmsOptions);

	const { getOrderObj, getSearchObj, getFilterObj } = useInventoryLogs();

	useEffect(() => {
		logger("PROD FILTERS", productFilters);
	}, [productFilters]);

	useEffect(() => {
		getMerchants().then((res) => setMerchants(res?.data?.merchants?.items));
		getProducts().then((res) => setProducts(res?.data?.products?.items));
	}, []);

	const fetchAllCartsOfTheWarehouse = (
		skip = 0,
		search = debouncedSearchText,
		sortName = sortCol.label,
		sortOrder = getSortationOrder(sortCol.value),
		filters = productFilters
	) => {
		let orderObj = getOrderObj(sortName, sortOrder);
		let searchObj = getSearchObj(search);
		let filterObj = getFilterObj(filters);

		console.log(filterObj);

		getAllCurrentInventory({
			variables: {
				skip: skip,
				...filterObj,
				...searchObj,
				...orderObj,
			},
		}).then((res) => {
			const data = res.data.inventoryLogs.items;

			setItemsCount(res.data.items?.length || 0);

			const dataFlattened = data.map((item) => {
				let serialsCount = item?.serials?.length || 0;

				// let time = "";
				// if (item.updatedAt) {
				//     time = moment(item.updatedAt).format("D-M-YYYY / hh:mm");
				// }

				return {
					item,
					...objFlatten({
						...item,
						serialsCount: serialsCount,
					}),
				};
			});

			setDataList(dataFlattened);
		});
	};

	const fetch_merchants = ({ page = 1, searchQuery = "" } = {}) => {
		logger("fetch_merchants", page, searchQuery);

		const searchObj = getSearchObj(searchQuery);

		return getMerchants({
			variables: {
				where: {},
				skip: (page - 1) * 20,
				...searchObj,
			},
		}).then((res) => {
			setMerchants(res?.data?.merchants?.items);

			return {
				options: res?.data?.merchants?.items,
				hasMore: res?.data?.merchants?.totalCount >= page * 20,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		});
	};

	const fetch_merchant = ({ id = "0" } = {}) => {
		return getMerchants({
			variables: {
				where: { id: { eq: parseInt(id) } },
			},
		}).then((res) => {
			let data = res?.data?.merchants?.items[0];
			return data;
		});
	};

	const fetch_products = ({ page = 1, searchQuery = "" } = {}) => {
		logger("fetch_merchants", page, searchQuery);

		const searchObj = getSearchObj(searchQuery);

		return getProducts({
			variables: {
				where: {},
				skip: (page - 1) * 20,
				...searchObj,
			},
		}).then((res) => {
			setProducts(res?.data?.products?.items);

			return {
				options: res?.data?.products?.items,
				hasMore: res?.data?.products?.totalCount >= page * 20,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		});
	};

	const fetch_product = ({ id = "0" } = {}) => {
		return getProducts({
			variables: {
				where: { id: { eq: parseInt(id) } },
			},
		}).then((res) => {
			let data = res?.data?.products?.items[0];
			return data;
		});
	};

	function handleFilter() {
		fetchAllCartsOfTheWarehouse(
			0,
			searchText,
			sortCol.label,
			getSortationOrder(sortCol.value),
			productFilters
		);
	}

	function handleFilterReset() {
		setProductFilters(defaultFilter);
		fetchAllCartsOfTheWarehouse(
			0,
			searchText,
			sortCol.label,
			getSortationOrder(sortCol.value),
			defaultFilter
		);
		setFilterOpen(false);
	}

	useEffect(() => {
		fetchAllCartsOfTheWarehouse();
		setFilterOpen(false);
	}, [debouncedSearchText]);
	return (
		<>
			<PaginatedTable
				count={itemsCount}
				page={page}
				setPage={setPage}
				onPageChange={() => { }}
				cols={inventoryLogsCol}
				data={dataList}
				selectedRows={selectedRows}
				loading={false}
				onSortClick={(sortVal) => {
					// handleCurrentInventorySort(sortVal);
				}}
			/>
			<Modal
				onDismiss={() => {
					setFilterOpen(false);
				}}
				hidden
				autoOpen={filterOpen}
				footer={(handleClose) => (
					<ModalFooter
						handleClose={handleClose}
						handleSubmit={handleFilter}
						handleReset={handleFilterReset}
					/>
				)}
				onClose={() => { }}
				title={"Filter Products"}
			>
				<FiltersList
					selectedFilters={productFilters}
					filters={[
						{
							name: "Filter by Product",
							value: "productId",
							id: 1,
							isAsync: true,
							isSearchable: true,
							getSelectedVal: (id) => {
								if (id && id != "all") {
									let sel = fetch_product({ id: id });
									return sel;
								} else return { value: "all", label: "All" };
							},
							loadMore: (searchQuery, page) =>
								fetch_products({ page: page, searchQuery: searchQuery }),
						},
						{
							name: "Filter by Merchant",
							value: "merchantId",
							id: 2,
							isAsync: true,
							isSearchable: true,
							getSelectedVal: (id) => {
								if (id && id != "all") {
									let sel = fetch_merchant({ id: id });
									return sel;
								} else return { value: "all", label: "All" };
							},
							loadMore: (searchQuery, page) =>
								fetch_merchants({ page: page, searchQuery: searchQuery }),
						},
					]}
					handleChange={(filterVal, filterName) => {
						setProductFilters((old) => ({
							...old,
							[filterName]: filterVal,
						}));
					}}
				/>
			</Modal>
		</>
	);
}
