import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    StepIcon,
    TextField,
    Typography,
    Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useRef, useState } from "react";
import Modal from "../../../components/common/Modal";
import SelectAsyncPaginate from "../../../components/common/Select/AsyncPaginateSelect";

import CustomCheckBox from "../../../components/common/Checkbox";
import NewSearch from "../../../components/common/Search/new-search";
import CustomInput from "../../../components/common/Input";
import CustomButton from "../../../components/common/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DoneIcon from "@mui/icons-material/Done";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
    GET_CURR_INV_SERIALS,
    GET_INVENTORY_LOGS_WITH_MERCHANT_AND_PRODUCT_ID,
    GET_LOCATIONS,
    GET_PRODUCTS_AND_MERCHANTS,
} from "../../../api/queries/Queries";
import { wmsOptions } from "../../../constants/apollo";
import { CREATE_OR_UPDATE_INVENTORY_LOGS } from "../../../api/mutations/WMSMutations";
import { toast } from "react-toastify";
import _, { range } from "lodash";
import { CancelOutlined, CheckBox } from "@mui/icons-material";
import { logger } from "helper/logger";
import FiltersList from "../../../components/FiltersList";
import { WMS_FILTER_LOCATIONS, WMS_FILTER_MERCHANTS, WMS_FILTER_PRODUCTS } from "api/queries/WMSFilterQueries";
import NormalSelect from "../../../components/common/Select/NormalSelect";
import { removeAllDuplicateMatchingValuesInTwoArrays, removeAllMatchingValuesFromArray, removeAllMatchingValuesInTwoArays, removeFirstMatchingValueFromArray } from "helper/arrayMatch";

export default function MoveInventoryNew ({
    setModalOpen,
    modalOpen,
}) {

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [getLocations] = useLazyQuery(WMS_FILTER_LOCATIONS, wmsOptions);
    const [getMerchants] = useLazyQuery(WMS_FILTER_MERCHANTS, wmsOptions);
    const [getProducts] = useLazyQuery(WMS_FILTER_PRODUCTS, wmsOptions);
    const [getCurrSerials] = useLazyQuery(
        GET_CURR_INV_SERIALS, wmsOptions
    )
    const formik = useFormik({
        initialValues: {

            id: 0,
            destinationLocationId: 0,
            destinationLocationType: "",
            sourceLocationId: 0,
            sourceLocationType: "",
            merchantId: 0,
            productId: 0,
            serialList: [],
        },
        validationSchema: Yup.object({
            destinationLocationType: Yup.string().required("Required"),
            sourceLocationType: Yup.string().required("Required"),
            destinationLocationId: Yup.number().required("Required"),
            sourceLocationId: Yup.number().required("Required"),
            productId: Yup.number().required("Required"),
            merchantId: Yup.number().required("Required"),
            serialList: Yup.array().of(Yup.string()).required("Required"),
        }),
        onSubmit: (values) => {
            setIsSubmitting(true)
            if (values.sourceLocationId === values.destinationLocationId) {
                toast.error("Orders cannot be re-asssign to the same location")
                setIsSubmitting(false)
                return
            }
            createOrUpdateInventoryLogMutation({
                variables: {
                    input: [
                        {
                            id: 0,
                            destinationLocationId: parseInt(values.destinationLocationId),
                            // destinationLocation: {
                            //     id: parseInt(values.destinationLocationId),
                            //     type: values.destinationLocationType,
                            // },
                            // sourceLocation: {
                            //     id: parseInt(values.sourceLocationId),
                            //     type: values.sourceLocationType,
                            // },
                            sourceLocationId: parseInt(values.sourceLocationId),
                            merchantId: parseInt(values.merchantId),
                            productId: parseInt(values.productId),
                            serials: values.serialList,
                        },
                    ],
                },

            }).then((res) => {

                if (res.data?.createOrUpdateInventoryLogs[0]?.id) {
                    toast.success(
                        `Inventory log ${res?.data.createOrUpdateInventoryLogs[0].id} has been successfully created`
                    );
                    formik.resetForm()
                    setModalOpen(false)
                }
                setIsSubmitting(false)
            });
        },
    });

    const [scanError, setScanError] = useState('')
    const [loading, setLoading] = useState(false);
    const [serials, setSerials] = useState([]);
    const [serialText, setSerialText] = useState('')
    const [selectedSerials, setSelectedSerials] = useState([]);
    const [locationType, setLocationType] = useState("");
    const [asyncSelectKey, setAsyncSelectKey] = useState("key");
    const [asyncSelectSourceKey, setAsyncSelectSourceKey] = useState("source-key")
    const [merchantKey, setMerchantKey] = useState("merchant-key")
    const [productKey, setProductKey] = useState("product-key")
    const [desTypeKey, setDesTypeKey] = useState("des-key")

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isGenerationLoading, setIsGenerationLoading] = useState(false);
    const [serialStart, setSerialStart] = useState("");
    const [serialEnd, setSerialEnd] = useState("");
    const [includeDuplicate, setIncludeDuplicate] = useState(true);
    const [generationError, setGenerationError] = useState("");
    const [productFilters, setProductFilters] = useState({
        locationId: "",
        locationType: "",
    });

    const [data, setData] = useState();
    const merchantRef = useRef();
    const productRef = useRef();

    const [pullMerchantAndProducts] = useLazyQuery(GET_PRODUCTS_AND_MERCHANTS, {
        ...wmsOptions,
    });
    const { data: locations } = useQuery(GET_LOCATIONS, {
        ...wmsOptions,
    });
    const [getInventoryLogsWithMerchandAndProductID] = useLazyQuery(
        GET_INVENTORY_LOGS_WITH_MERCHANT_AND_PRODUCT_ID,
        wmsOptions
    );


    const [createOrUpdateInventoryLogMutation] = useMutation(
        CREATE_OR_UPDATE_INVENTORY_LOGS,
        wmsOptions
    );


    const handleScan = (serial = "") => {
        logger("SCANING", serial);
        let count = serial.split(/\r?\n/);
        if (count.length === 1) {
            if (serials.includes(serial) && !selectedSerials.includes(serial)) {
                // formik.setFieldValue("selectedSerials", [...formik.values.selectedSerials, serial]);
                setSelectedSerials([...selectedSerials, serial])
            }
        } else {
            const duplicates = _.intersection(serials, count);
            setSelectedSerials([...selectedSerials, ...duplicates]);
        }
    };

    // const 


    const getSearchObj = (search) => {
        let searchObj = { query: "" };

        if (search.trim() != "") {
            searchObj = {
                query: search,
            };
        }
        return searchObj;
    };

    const fetch_locations = ({ page = 1, searchQuery = "", locationType } = {}) => {

        const searchObj = getSearchObj(searchQuery);
        if (!locationType || locationType == "") return;

        return getLocations({
            variables: {
                skip: (page - 1) * 20,
                ...searchObj,
                where: {
                    type: {
                        eq: locationType,
                    },
                },
            },
        }).then((res) => {
            let locationList = res?.data?.locations?.items;
            let locationsModified = [];
            locationList.map((item) => {
                if (!item.label) {
                    locationsModified.push({
                        value: item.value,
                        label: item.locationCode,
                    });
                } else {
                    locationsModified.push(item);
                }
            });
            return {
                options: locationsModified,
                hasMore: res?.data?.locations?.totalCount >= page * 20,
                additional: {
                    page: searchQuery ? 2 : page + 1,
                },
            };
        });
    };

    const fetch_merchants = ({ page = 1, searchQuery = "" } = {}) => {

        const searchObj = getSearchObj(searchQuery);


        return getMerchants({
            variables: {
                where: {
                    currentInventory: {
                        some: {
                            locationId: {
                                eq: parseInt(formik.values.sourceLocationId)
                            }
                        }
                    }
                },

                skip: (page - 1) * 20,
                ...searchObj,
            },
        }).then((res) => {
            // setMerchants(res?.data?.merchants?.items);

            return {
                options: res?.data?.merchants?.items,
                hasMore: res?.data?.merchants?.totalCount >= page * 20,
                additional: {
                    page: searchQuery ? 2 : page + 1,
                },
            };
        });
    };
    const fetch_products = ({ page = 1, searchQuery = "" } = {}) => {
        const searchObj = getSearchObj(searchQuery);

        return getProducts({
            variables: {
                where: {
                    currentInventory: {
                        some: {
                            locationId: {
                                eq: parseInt(formik.values.sourceLocationId)
                            },
                            merchantId: {
                                eq: parseInt(formik.values.merchantId)
                            }
                        }
                    }
                },
                skip: (page - 1) * 20,
                ...searchObj,
            },
        }).then((res) => {
            return {
                options: res?.data?.products?.items,
                hasMore: res?.data?.products?.totalCount >= page * 20,
                additional: {
                    page: searchQuery ? 2 : page + 1,
                },
            };
        });
    };

    const fetch_serials = () => {
        getCurrSerials({
            variables: {
                where: {
                    locationId: {
                        eq: parseInt(formik.values.sourceLocationId)
                    },
                    merchantId: {
                        eq: parseInt(formik.values.merchantId)

                    },
                    productId: {
                        eq: parseInt(formik.values.productId)

                    }
                }
            }
        }).then(res => {
            logger("serials", res?.data?.currentInventory?.items[0]?.serials)
            setSerials(res?.data?.currentInventory?.items[0]?.serials || [])
        }
        )
    }

    const clearFormikTextFields = (listOfFields) => {
        listOfFields.map(field =>
            formik.setFieldValue(field, ""))
    }



    React.useEffect(() => {
        setAsyncSelectSourceKey((old) => old + "1");
        setMerchantKey((old) => old + "1")
        setProductKey((old) => old + "1")
        setSerials([])
        setSelectedSerials([]) // auto updates formikk.values.serialList
        setAsyncSelectSourceKey((old) => old + "1")
        setDesTypeKey((old) => old + "1")
        clearFormikTextFields(["sourceLocationId", "merchantId", "productId", "destinationLocationId", "destinationLocationType"])

    }, [formik.values.sourceLocationType]);

    React.useEffect(() => {
        setMerchantKey((old) => old + "1")
        setProductKey((old) => old + "1")
        setSerials([])
        setSelectedSerials([]) // auto updates formikk.values.serialList
        setAsyncSelectKey((old) => old + "1")
        setDesTypeKey((old) => old + "1")
        clearFormikTextFields(["merchantId", "productId", "destinationLocationId", "destinationLocationType"])

    }, [formik.values.sourceLocationId]);

    React.useEffect(() => {
        setProductKey((old) => old + "1")
        setSerials([])
        setSelectedSerials([]) // auto updates formikk.values.serialList
        setAsyncSelectKey((old) => old + "1") // des loc id
        setDesTypeKey((old) => old + "1") // des loc type
        clearFormikTextFields(["productId", "destinationLocationId", "destinationLocationType"])

    }, [formik.values.merchantId]);

    React.useEffect(() => {
        logger("FORMIK", formik.values)
        // refresh
        setSerials([])
        setSelectedSerials([]) // auto updates formikk.values.serialList
        setAsyncSelectKey((old) => old + "1") // des loc id
        setDesTypeKey((old) => old + "1") // des loc type
        clearFormikTextFields(["destinationLocationId", "destinationLocationType"])

        // execute
        if (!formik.values.productId || !formik.values.sourceLocationId || !formik.values.merchantId) return
        fetch_serials()
    }, [formik.values.productId]);

    React.useEffect(() => {
        setAsyncSelectKey((old) => old + "1") // des loc id
        clearFormikTextFields(["destinationLocationId"])
    }, [formik.values.destinationLocationType]);

    React.useEffect(() => {
        logger("SELECTED: ", selectedSerials)
        formik.setFieldValue("serialList", selectedSerials)
    }, [selectedSerials]);

    React.useEffect(() => {
        logger("Formik val ", formik.values, isSubmitting)
    }, [formik.values]);

    const handleScanAdvanced = () => {
        try {
            setGenerationError("");

            let start = parseInt(serialStart);
            let end = parseInt(serialEnd);
            let successfulCount = 0

            // TODO:: check for isNumeric and add this case here too
            if (serialStart?.toString()?.length > 15) {
                let newStart = parseInt(
                    serialStart.toString().substr(serialStart.length - 5)
                );
                let bigIntFirst = serialStart
                    .toString()
                    .substr(0, serialStart.length - 5);
                let newEnd = parseInt(
                    serialEnd.toString().substr(serialStart.length - 5)
                );

                let strLen = 5;

                // list of splitted serials
                let list = range(newEnd, newStart - 1, -1);
                // new list of selected serials

                let newList = [];
                list?.map((item) => {
                    let strNum = item.toString();

                    // fill in empty spaces with zeros: '9' > '0009'
                    if (strNum.length < strLen) {
                        for (let i = 0; i < strLen - strNum.length; i++) {
                            strNum = "0" + strNum;
                        }
                    }

                    newList.push(bigIntFirst + strNum)

                });

                let count = handleSerialListScan(newList, includeDuplicate)

                if (count) {
                    toast.success(`Successfully scanned ${count} serials`);
                } else {
                    throw Error(`Could not find these serials from the serial list`);

                }

            } else {
                let list = range(end, start - 1, -1);

                let count = handleSerialListScan(list, includeDuplicate)

                if (count) {
                    toast.success(`Successfully scanned ${count} serials`);
                } else {
                    throw Error(`Could not find these serials from the serial list`);
                }


            }


            setIsGenerationLoading(false);
            setSerialStart("");
            setSerialEnd("");
            setIsModalOpen(false);
        } catch (e) {
            console.log("ERRROR", e.message);
            // setIsModalOpen(false)
            setIncludeDuplicate(true);
            setGenerationError(e.message);
        }
    };

    const handleSerialListScan = (scanList, inCludeDuplicate = false) => {
        if (inCludeDuplicate) {
            const { arr, count, scanned } = removeAllDuplicateMatchingValuesInTwoArrays(serials, scanList)
            if (count > 0) {
                console.log("scan res: ", count, arr)
                setSerials(arr)
                setSelectedSerials((old) => [...scanned, ...old])
                return count
            } else {
                logger("NOT SCANNED", scanList)
                return 0
            }
        } else {
            const { arr, count, scanned } = removeAllMatchingValuesInTwoArays(serials, scanList)
            if (count > 0) {
                console.log("scan res: ", count, arr)
                setSerials(arr)
                setSelectedSerials((old) => [...scanned, ...old])
                return count
            } else {
                logger("NOT SCANNED", scanList)
                return 0
            }
        }
        return 0
    }
    const handleSerialScan = (serial, inCludeDuplicate = false) => {
        if (inCludeDuplicate) {
            const { arr, count } = removeAllMatchingValuesFromArray(serials, serial)
            if (count > 0) {
                console.log("scan res: ", count, arr)
                setSerials(arr)
                let newSelected = []
                for (let i = 0; i < count; i++) {
                    newSelected.push(serial)
                }
                setSelectedSerials((old) => [...newSelected, ...old])
                return count
            } else {
                logger("NOT SCANNED")
                return 0
            }
        } else {
            logger("Handle Scan Serial", serial)
            const { arr, isScanned } = removeFirstMatchingValueFromArray(serials, serial)
            if (isScanned) {
                setSerials(arr)
                setSelectedSerials((old) => [serial, ...old])
                return 1
            } else {
                logger("NOT SCANNED")
                return 0
            }
        }
    }
    const handleSerialUnScan = (serial) => {
        logger("Handle Unscan Serial")
        const { arr, isScanned } = removeFirstMatchingValueFromArray(selectedSerials, serial)
        if (isScanned) {
            setSelectedSerials(arr)
            setSerials((old) => [serial, ...old])
        } else {
            logger("NOT SCANNED")
        }
    }

    return (
        <>
            <Modal
                onClose={() => setModalOpen(false)}
                hidden={true}
                onDismiss={() => setModalOpen(false)}
                autoOpen={modalOpen}
                title="Move inventory"
            >
                {/* Source Location */}

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit()
                    }}
                    className='main-wrapper flex flex-col justify-between space-y-5'
                >
                    <div className="w-full flex">
                        <div
                            className={`mr-3`}
                        >
                            <NormalSelect
                                filter={{
                                    name: "Source Location Type",
                                    value: "sourceLocationType",
                                    id: 1,
                                    options: [
                                        { value: "WAREHOUSE", label: "Warehouse" },
                                        { value: "SHELF", label: "Shelf" },
                                        { value: "CART", label: "Cart" },
                                        { value: "DRIVER", label: "Driver" },
                                        { value: "ORDER", label: "Order" },
                                    ],
                                }}
                                defaultValue={[
                                    { value: "WAREHOUSE", label: "Warehouse" },
                                    { value: "SHELF", label: "Shelf" },
                                    { value: "CART", label: "Cart" },
                                    { value: "DRIVER", label: "Driver" },
                                    { value: "ORDER", label: "Order" },
                                ].find((option) => option?.value == formik.values.sourceLocationType)}
                                handleChange={(e) => formik.setFieldValue("sourceLocationType", e.value)}
                            />
                        </div>
                        <div
                            className={`w-1/2 ${(!formik.values.sourceLocationType || formik.values.sourceLocationType == "") &&
                                "disabledContent"
                                }`}
                        >
                            <SelectAsyncPaginate
                                defaultOptions={[]}
                                key={asyncSelectSourceKey}
                                value={""}
                                defaultValue={{}}
                                isSearchable={true}
                                getDefaultValue={() => { }}
                                onChange={(e) => formik.setFieldValue(
                                    "sourceLocationId",
                                    e.value
                                )}
                                loadMore={(searchQuery, page) =>
                                    fetch_locations({ page: page, searchQuery: searchQuery, locationType: formik.values.sourceLocationType })
                                }
                                filterName={"Source Location"}
                            />
                        </div>
                    </div>
                    {/* Merchant */}
                    <div
                        className={`w-full my-4 ${(!formik.values.sourceLocationId || formik.values.sourceLocationId == "") &&
                            "disabledContent"
                            }`}
                    >
                        <SelectAsyncPaginate
                            key={merchantKey}
                            defaultOptions={[]}
                            value={""}
                            defaultValue={{}}
                            isSearchable={true}
                            getDefaultValue={() => { }}
                            onChange={(e) => formik.setFieldValue(
                                "merchantId",
                                e.value
                            )}
                            loadMore={(searchQuery, page) =>
                                fetch_merchants({ page: page, searchQuery: searchQuery, locationType: formik.values.sourceLocationType })
                            }
                            filterName={"Merchant"}
                        />
                    </div>
                    {/* Product */}
                    <div
                        className={`w-full my-4 ${(!formik.values.merchantId || formik.values.merchantId == "") &&
                            "disabledContent"
                            }`}
                    >
                        <SelectAsyncPaginate
                            key={productKey}
                            defaultOptions={[]}
                            value={""}
                            defaultValue={{}}
                            isSearchable={true}
                            getDefaultValue={() => { }}
                            onChange={(e) => formik.setFieldValue(
                                "productId",
                                e.value
                            )}
                            loadMore={(searchQuery, page) =>
                                fetch_products({ page: page, searchQuery: searchQuery, locationType: formik.values.sourceLocationType })
                            }
                            filterName={"Product"}
                        />
                    </div>

                    {/* serial scan */}
                    <div className="w-full flex items-center">
                        <div
                            className={`w-full my-4 ${(!formik.values.productId || formik.values.productId == "") &&
                                "disabledContent"
                                }`}
                        >
                            <NewSearch
                                hasValue
                                value={serialText}
                                filterName='Serial Code'
                                placeholder='Scan each item'
                                onChange={(e) => {
                                    setSerialText(e.target.value)
                                }}
                                handleClearSearch={() => {
                                    setSerialText("")
                                }}
                                infoText='Press Enter to Submit'
                                onKeyPress={(ev) => {
                                    console.log(`Pressed keyCode ${ev.key}`);
                                    if ((ev.key == "Enter" || ev.keyCode === 13) && serialText.length) {
                                        // Do code here
                                        ev.preventDefault();

                                        handleSerialScan(serialText);

                                        setTimeout(() => {
                                            ev.target.value = ""
                                            setSerialText("")
                                        }, 200);
                                    }
                                }}
                                style={{ height: "38px" }}
                            />
                        </div>

                        <div className={`w-1/4 text-center ${(!formik.values.productId || formik.values.productId == "") ? "disabledContent text-gray-400" : ""}`}>
                            <span
                                className={`hover:underline cursor-pointer `}
                                onClick={() => {

                                    setIsModalOpen(true);
                                }}
                                style={{ width: "100%", display: "block" }}
                            >
                                Add advanced
                            </span>
                        </div>

                    </div>
                    {scanError !== "" && <span className="text-red-500 text-sm">{scanError}</span>}

                    {isModalOpen && (
                        <>
                            <Modal
                                onDismiss={() => {
                                    setIsModalOpen(false);
                                }}
                                hidden
                                footer={() => <></>}
                                autoOpen={isModalOpen}
                                onClose={() => { }}
                                title={"Advanced Scan of Serials"}
                            >
                                <div className="w-full flex space-x-4 mt-4 items-center">
                                    <div className="w-1/3">
                                        <Box>
                                            <NewSearch
                                                hasIcon={false}
                                                hasValue={true}
                                                value={serialStart}
                                                filterName="Serial Start"
                                                placeholder="Serial start"
                                                onChange={(e) => setSerialStart(e.target.value)}
                                                handleClearSearch={() => setSerialStart("")}
                                                style={{ height: "38px" }}
                                            />
                                            {/* {formik.errors.serials ? (
                                      <div className='error'>
                                          {formik.errors.serials}
                                      </div>
                                  ) : null} */}
                                        </Box>
                                    </div>
                                    <div className="w-1/3">
                                        <Box>
                                            <NewSearch
                                                hasIcon={false}
                                                // ref={serialCode}
                                                hasValue={true}
                                                value={serialEnd}
                                                filterName="Serial End"
                                                placeholder="Serial End"
                                                onChange={(e) => setSerialEnd(e.target.value)}
                                                handleClearSearch={() => setSerialEnd("")}
                                                style={{ height: "38px" }}
                                            />
                                            {/* {formik.errors.serials ? (
                                          <div className='error'>
                                              {formik.errors.serials}
                                          </div>
                                      ) : null} */}
                                        </Box>
                                    </div>
                                    <div className="flex w-1/3 items-center">
                                        <div className="w-1/2">
                                            <Box>
                                                <CustomCheckBox
                                                    label="Include duplicate"
                                                    checked={includeDuplicate}
                                                    setChecked={setIncludeDuplicate}
                                                />
                                            </Box>
                                        </div>

                                        <div className="w-1/2 pl-4">
                                            <CustomButton
                                                type="button"
                                                variant="contained"
                                                color="warning"
                                                onClick={() => {
                                                    handleScanAdvanced();
                                                }}
                                                style={{ width: "100%", height: "38px" }}
                                            >
                                                {isGenerationLoading ? "Loading..." : "Scan"}
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                                <span className="text-red-500">{generationError}</span>
                            </Modal>
                        </>
                    )}

                    <MoveInventoryTableNew
                        handleSelect={handleSerialScan}
                        handleUnSelect={handleSerialUnScan}
                        selectedSerials={selectedSerials}
                        serials={serials}
                    />
                    {/* Destination Location Select */}
                    <div>
                        <Typography
                            sx={{ padding: 1, marginTop: 1 }}
                            fontSize={20}
                            textAlign={"center"}
                        >
                            Select destination
                        </Typography>
                        <div className="w-full flex space-x-3 mt-3">
                            <div
                                className={`pr-3 ${selectedSerials.length === 0 && "disabledContent"
                                    }`}
                            >
                                <NormalSelect
                                    key={desTypeKey}

                                    filter={{
                                        name: "Destination Location Type",
                                        value: "locationType",
                                        id: 4,
                                        options: [
                                            { value: "WAREHOUSE", label: "Warehouse" },
                                            { value: "SHELF", label: "Shelf" },
                                            { value: "CART", label: "Cart" },
                                            { value: "DRIVER", label: "Driver" },
                                            { value: "ORDER", label: "Order" },
                                        ],
                                    }}
                                    defaultValue={[
                                        { value: "WAREHOUSE", label: "Warehouse" },
                                        { value: "SHELF", label: "Shelf" },
                                        { value: "CART", label: "Cart" },
                                        { value: "DRIVER", label: "Driver" },
                                        { value: "ORDER", label: "Order" },
                                    ].find((option) => option?.value == formik.values.destinationLocationType)}
                                    handleChange={(e) => formik.setFieldValue("destinationLocationType", e.value)}
                                // isDisabled={lockedFields?.indexOf(filter.value) >= 0}
                                />
                            </div>
                            <div
                                className={`w-1/2 ${(!formik.values.destinationLocationType || formik.values.destinationLocationType == "") &&
                                    "disabledContent"
                                    }`}
                            >
                                <SelectAsyncPaginate
                                    defaultOptions={[]}
                                    key={asyncSelectKey}
                                    value={""}
                                    defaultValue={{}}
                                    isSearchable={true}
                                    getDefaultValue={() => { }}
                                    onChange={(e) => formik.setFieldValue(
                                        "destinationLocationId",
                                        e.value
                                    )}
                                    loadMore={(searchQuery, page) =>
                                        fetch_locations({ page: page, searchQuery: searchQuery, locationType: formik.values.destinationLocationType })
                                    }
                                    filterName={"Destination Location"}
                                />
                            </div>
                        </div>

                    </div>
                    {/* Submit button */}
                    <div className="w-full mt-5 flex space-x-3 justify-end">
                        <div className="w-1/4">
                            <CustomButton
                                type='button'
                                variant='outlined'
                                onClick={() => {
                                    formik.resetForm()
                                    setModalOpen(false)
                                }}
                                style={{ width: "100%", height: "38px" }}
                            >
                                Cancel
                            </CustomButton>
                        </div>
                        <div className={`w-1/4 ${(formik.values.destinationLocationId == "" || isSubmitting) && "disabledContent"
                            }`}>

                            <CustomButton
                                type='submit'
                                variant='contained'
                                color='success'
                                onClick={() => { }}
                                style={{ width: "100%", height: "38px" }}
                            >
                                Move Inventory
                            </CustomButton>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
}

const MoveInventoryTableNew = ({
    selectedSerials,
    serials,
    handleSelect = () => { },
    handleUnSelect = () => { }
}) => {
    return (
        <>
            <span className="text-sm">Scanned : {selectedSerials.length}</span>
            <Box
                sx={{ marginTop: 0, maxHeight: 300, overflow: "scroll" }}
                component={Paper}
            >

                <Box sx={{ p: 1 }}>
                    {(!serials?.length && !selectedSerials?.length) && <p>No data</p>}
                    {selectedSerials?.map((serial, index) => {
                        return (
                            <Chip
                                key={serial + index}
                                label={serial}
                                sx={{ m: 0.5 }}
                                color="success"
                                onDelete={() => {
                                    handleUnSelect(serial)
                                }}
                            />
                        );
                    })}
                    {serials?.map((serial, index) => {

                        return (
                            <Chip
                                key={serial + index}
                                label={serial}
                                sx={{ m: 0.5 }}
                                color="default"
                                clickable
                                onClick={() => {
                                    // setSelectedSerials([...selectedSerials, serial]);
                                    handleSelect(serial)
                                }}
                            />
                        );


                    })}



                </Box>
            </Box>
        </>

    );
};

