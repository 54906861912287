import { Box, Container } from "@mui/material";
import OrderDetails from "./components/OrderDetails";
import Header from "./components/Header";
import Map from "./components/Map";
import React from "react";

export default function Tracking () {
	const [showMap, setShowMap] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState<any>();
	const id = Object.fromEntries(
		new URLSearchParams(window.location.search)
	)?.trackingId;

	const isProduction = process.env.REACT_APP_MODE === "production";

	const getData = async () => {
		setLoading(true);
		try {
			let request = await fetch(
				isProduction
					? "https://barqfleet.com/graphql/"
					: "https://staging.barqfleet.com/graphql/",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						query: `query OrderTracking($trackingNo: String!) {
				orderTracking(trackingNo: $trackingNo) {
						trackingNo
						origin
						destination
						currentLocation
						orderStatus
						merchantName
						merchantNameAr
						merchantLogoUrl
						merchantType
						merchantAllowsTrackingPickupLocation
					}
					publicFetchOrder(trackingNo: $trackingNo) {
    orderLogs{
      status
      statusHumanReadable
	  createdAt
    }
  }
				}`,
						variables: {
							trackingNo: id,
						},
					}),
				}
			);
			request = await request.json();
			return request;
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		(async () => {
			let data = await getData();
			setData(data);
		})();
	}, []);

	return (
		<Box
			className='min-h-screen'
			sx={{
				flexGrow: 1,
				backgroundColor: "#FAFAFA",
				position: "relative",
			}}
		>
			{data?.errors?.length ? (
				<div className='error-wrapper'>
					<p>We couldn't find any order with this tracking id.</p>
				</div>
			) :
				(
					<>
						<Header data={data} />

						<Container sx={{ marginTop: 5 }}>
							<div className='w-full flex flex-col align-center justify-center md:flex-row'>
								<OrderDetails
									data={data}
									showMap={showMap}
									setShowMap={setShowMap}
								/>
								{data?.data?.orderTracking?.merchantAllowsTrackingPickupLocation
									&& data?.data?.orderTracking?.orderStatus !== "delivery_attempt_failed" &&
									<Map
										data={data}
										showMap={showMap}
										setShowMap={setShowMap}
									/>
								}
							</div>
						</Container>
					</>
				)}

		</Box>
	);
}
