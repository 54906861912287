import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { logger } from "../../../helper/logger";

export default function useDriverList ({
  selectedDriverId,
  setSelectedDriverId,
  setSelectedDriver,
  cityIds,
}) {
  const { t } = useTranslation("common");
  const [filterAreaOpen, setFilterAreaOpen] = React.useState(false);
  const [pinnedFilterIds, setPinnedFilterIds] = React.useState([]);
  const selectedRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const filterOptions = [
    // { name: "Filter by City", value: "city", id: 1 },
    { name: t("filter_by_city"), value: "city", id: 1, options: cityIds },

    {
      name: t("filter_by_vehicle"),
      value: "vehicle",
      id: 2,
      options: [
        // {car: 0, motorbike: 1, van: 2, minivan: 3, pickup: 4, small_truck: 5, large_truck: 6}
        { value: 0, label: t("car") },
        { value: 1, label: t("motorbike") },
        { value: 2, label: t("van") },
        { value: 3, label: t("minivan") },
        { value: 4, label: t("pickup") },
        { value: 5, label: t("small_truck") },
        { value: 6, label: t("large_truck") },
      ],
    },
    // { name: "Filter by Nationality", value: "nationality", id: 3 },
    {
      name: t("filter_by_device"),
      value: "device",
      id: 4,
      options: [
        { value: "android", label: t("android") },
        { value: "ios", label: t("ios") },
      ],
    },
    // { name: "Filter by Partner", value: "partner", id: 5 },
    {
      name: t("filter_by_dress"),
      value: "dress",
      id: 6,
      options: [
        { value: 0, label: t("branded") },
        { value: 1, label: t("unbranded") },
      ],
    },
    {
      name: t("filter_by_status"),
      value: "status",
      id: 7,
      options: [
        { value: "free", label: t("Online available") },
        { value: "busy", label: t("Online busy") },
        { value: "offline", label: t("Offline") },
      ],
    },
  ];


  const handleClick = (driver) => {
    const id = driver.id;
    if (selectedDriverId === id) {
      setSelectedDriverId(null);
      setSelectedDriver(null);
    } else {
      setSelectedDriverId(id);
      setSelectedDriver(driver);
    }
  };

  const updateFilters = (id) => {
    const filter = filterOptions.find((item) => item.id === id);
    if (filter) {
      setPinnedFilterIds((prevState) => {
        if (prevState.includes(filter.id)) {
          return prevState?.filter((id) => id !== filter.id);
        } else {
          return [...prevState, filter.id];
        }
      });
    }
  };

  const updatePinnedFilters = (id) => {
    const filter = filterOptions.find((item) => item.id === id);
    if (filter) {
      setPinnedFilterIds((prevState) => {
        if (prevState.includes(filter.id)) {
          return prevState?.filter((id) => id !== filter.id);
        } else {
          return [...prevState, filter.id];
        }
      });
    }
  };

  return {
    t,
    filterAreaOpen,
    setFilterAreaOpen,
    pinnedFilterIds,
    setPinnedFilterIds,
    selectedRef,
    isLoading,
    setIsLoading,
    filterOptions,
    handleClick,
    updateFilters,
    updatePinnedFilters,
  };
}
