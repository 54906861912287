import { useMutation } from '@apollo/client';
import { stagingOptions } from 'constants/apollo';

import { ASSIGN_ORDER, UNASSIGN_ORDER } from 'api/mutations/AssignShipmentMutation';
import { useEffect } from 'react';
import { toast } from 'react-toastify';


export const useUnassignCourier = ({ clearUp = () => { }, selectedShipmentIds }) => {
    const [handleGqlUnassign, { data, loading, error }] =
        useMutation(UNASSIGN_ORDER, { ...stagingOptions, fetchPolicy: "network-only" });

    useEffect(() => {
        if (data) {
            if (data?.unassignOrdersFromShipment?.couriers) {
                toast.success("Order unassigned successfully");
                clearUp()
            } else {
                toast.error("Order can not be unassigned");
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            console.log("Error object:", error);
            toast.error(`Error:`);
        }
    }, [error])


    function handleUnassignCourier () {
        if (selectedShipmentIds.length > 0) {
            const confirmed = window.confirm("Are you sure you want to assign the selected shipments?");
            if (confirmed) {
                let orderIds = []
                selectedShipmentIds.forEach((shipmentId) => {
                    orderIds.push(Number(shipmentId))
                });

                handleGqlUnassign({
                    variables: {
                        input: {
                            orderIds
                        },
                    },
                })

            }
        } else {
            toast.error("Select at least one shipment and driver!");
        }
    }

    return { handleUnassignCourier }
}
