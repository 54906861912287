import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function PurchaseOrderTable({
	data,
	onPressCheckbox,
	selectedRows,
	loading,
	filterByBin,
	cols,
	setData,
}: PurchaseOrderTableProps) {
	const { t } = useTranslation("common");

	const TableItem = ({ row }: { row: any }) => {
		const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
			null
		);
		const open = Boolean(anchorEl);
		const handleClick = (event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = (id: string) => {
			onPressCheckbox(id);
			setAnchorEl(null);
		};

		const selected = !!selectedRows.find((item) => item === row.trackingNo);

		return (
			<TableRow
				sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			>
				<TableCell padding='checkbox'>
					<Checkbox
						color='primary'
						checked={selected}
						onChange={(event, checked) =>
							onPressCheckbox(row.trackingNo)
						}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{row.map((item: any, index: any) => (
					<TableCell component='th' scope='row' key={index}>
						<span
							className={`${
								index === 0 ? "text-warningColor" : ""
							}
            ${
				index === row.length - 1
					? "bg-[#4CAF5080] text-successColor py-1 px-2 rounded-xl uppercase text-[12px]"
					: ""
			}`}
						>
							{item || "-"}
						</span>
					</TableCell>
				))}

				<TableCell>
					<div>
						<IconButton
							aria-label='more'
							id='long-button'
							aria-controls={open ? "long-menu" : undefined}
							aria-expanded={open ? "true" : undefined}
							aria-haspopup='true'
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id='long-menu'
							MenuListProps={{
								"aria-labelledby": "long-button",
							}}
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
						>
							<MenuItem
								key={"remove"}
								onClick={() => {
									setData(
										data.filter((i: any) => i[0] != row[0])
									);
									handleClose(row.trackingNo);
								}}
							>
								Remove
							</MenuItem>
						</Menu>
					</div>
				</TableCell>
			</TableRow>
		);
	};

	const LoadingItem = ({ index }: { index: number }) => {
		return (
			<TableRow
				key={index}
				sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			>
				<TableCell width={95} height={15}>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
			</TableRow>
		);
	};

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label='simple table'>
				<TableHead>
					<TableRow>
						<TableCell padding='checkbox' key={"001"}>
							<Checkbox
								color='primary'
								checked={selectedRows.length === data.length}
								onChange={(event, checked) => onPressCheckbox()}
								inputProps={{
									"aria-label": "select all desserts",
								}}
							/>
						</TableCell>
						{cols?.map((colName, idx) => (
							<TableCell key={idx}>
								<p className='font-bold'>{colName}</p>
							</TableCell>
						))}

						<TableCell>
							<p className='font-bold'></p>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<>
						{data?.map((row, index) => (
							<TableItem key={index} row={row} />
						))}

						{loading &&
							Array.from(Array(10).keys()).map((item, index) => (
								<LoadingItem key={index} index={index} />
							))}
					</>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

type PurchaseOrderTableProps = {
	data: Array<any>;
	onPressCheckbox: (id?: string) => void;
	selectedRows: string[];
	loading: boolean;
	filterByBin?: string;
	cols: Array<string>;
	setData: any;
};
