import { Typography, Container, Button } from "@mui/material";
import React from "react";

export default function Playground() {
	const [loading, setLoading] = React.useState(false);
	const [url, setUrl] = React.useState("");
	const [query, setQuery] = React.useState("");
	const [response, setResponse] = React.useState("");
	const [error, setError] = React.useState("");

	const sendRequest = async () => {
		setLoading(true);
		try {
			let r = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					query: query,
				}),
			});

			r = await r.json();
			setResponse(JSON.stringify(r, undefined, 4) + "");
		} catch (error) {
			setError(error + "");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="p-4">
			<p className="font-semibold">Graphql test playground</p>
			<div className="mt-5 flex flex-col">
				<input
					className="border p-2"
					type="url"
					required
					placeholder="url"
					onChange={(e) => setUrl(e.target.value)}
				/>
				<textarea
					className="p-2 border mt-3 mb-2"
					name="textarea"
					placeholder="Query"
					onChange={(e) => setQuery(e.target.value)}
					rows={10}
				></textarea>
				<Button type="button" variant="contained" onClick={sendRequest}>
					Send request
				</Button>
				<div className="flex">
					<textarea
						className="p-2 border mt-3 mb-2 w-1/2"
						name="result"
						placeholder="Result"
						value={response}
						rows={20}
					></textarea>
					<textarea
						className="p-2 border mt-3 mb-2 w-1/2"
						name="error"
						placeholder="Error"
						value={error}
					></textarea>
				</div>
			</div>
		</div>
	);
}
