import axios from "axios";
import { parseCookies } from "nookies";
import { toast } from "react-toastify";
import { logger } from "../helper/logger";

// import { useHistory } from 'react-router-dom'
const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 42000,
  // withCredentials: true,
});


const errorHandler = (error, hooks) => {
  if (error.response) {
    const data = error.response.data;
    logger(error.response);
    const { token } = parseCookies();
    if (error.response.status === 403) {
      //   notification.error({
      //     message: 'Forbidden',
      //     description: data.message
      //   })
      logger("Forbidden");
      toast.error("Forbidden");
    }

    if (error.response.status === 401) {
      // //   notification.error({
      // //     message: 'Unauthorized',
      // //     description: 'Authorization verification failed'

      //   })
      //   localStorage.removeItem('token')
      //   localStorage.removeItem('persist:dashboard')
      //   window.location.href = '/'
      logger("Unauthorized");
      toast.error("Unauthorized");
    }
    toast.error(data.message);
  }
  return Promise.reject(error.response);
};

// request interceptor
request.interceptors.request.use((config) => {

  const { token } = parseCookies();
  //   config.headers["client-id"] = process.env.CLIENT_ID;
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  return response.data;
}, errorHandler);

export default request;
