import { gql } from "apollo-boost";

export const LOAD_BILLINGS = gql`
query FetchAllBillings(
  $offset: Int
  $paymentState: PaymentStateOptions
) {
    fetchAllBillings (
      offset: $offset
      paymentState : $paymentState
    ){
        items{
          id
          invoiceId
          merchant {
            name
          }
          numOfBilledOrders
          ordersCount
          totalCost
          totalCod
          totalDeliveryFee
          week
          issueDate
          dueDate
          paymentState
      
        }
        totalCount
      }
    
}
`;

export const LOAD_BILLING = gql`
query fetchBilling(
  $id: Int!
) {
    fetchBilling (
      id: $id
    ){
        id
        createdAt
        issueDate
        week
        dueDate

        discountMode
        discountValue

        merchant {
          name
          nameAr
        }

        discountComputedAmount
        totalCod
        totalCost
        totalDeliveryFee
        totalAdditionalProductsFee
        totalFeesWithVat
        totalReturnFee
        totalReverseFee

        orders {
          id
          trackingNo
          merchantOrderId
          neighborhood
          zone
          grandTotal
          codFee
          deliveryFee
          additionalProductsFee
          returnFee
          paymentStatus
          paymentType
          orderStatus
          customerDetails {
            firstName
            lastName
            mobile
          }
        }
      }
    
}
`;

export const LOAD_TRANSACTIONS = gql`
query FetchAllTransactions(
  $offset: Int
  $limit: Int
  $transactionType: Int
  $order: String
) {
    fetchAllTransactions (
      offset: $offset
      limit: $limit
      transactionType: $transactionType
      order: $order
    ){
      items{
        id
        wallet {
          id
          owner {
            ... on Merchant{
              name
            }
            ... on Courier{
              name: firstName
              lastName
            }
          }
        }
        amount
        prevBalance
        notes
        trackingNo
        createdAt
        merchant {
          name
        }
      }
      totalCount
      }
}
`;

export const LOAD_WALLETS = gql`
query fetchAllWallets(
  $offset: Int
  $query: String
) {
  fetchAllWallets (
      offset: $offset
      query: $query
    ){
        items{
          id
          balance
          creditLimit
          daysOverLimit
          lastDeliveredOn

          transactions {
            id
          }

          owner{
            type: __typename

            ... on Merchant{
              mobile
              name
              nameAr
           }
            ... on Courier {
              mobileNumber
              firstName
              lastName
           }
         }
        }
        totalCount
      }
    
}
`;

export const LOAD_TRANSACTIONS_BY_WALLET_ID= gql`
query fetchTransactionsByWalletId(
  $id: Int!
  $offset: Int
) {
  fetchTransactionsByWalletId (
      walletId: $id
      offset: $offset
    )
    {
      items{
        id
        status
        amount
        prevBalance
        afterBalance
        notes
        trackingNo
        createdAt

        wallet {
          balance
          creditLimit
          owner {
            type: __typename

            ... on Merchant{
              mobile
              name
              nameAr
           }
            ... on Courier {
              mobileNumber
              firstName
              lastName
           }
         }
        }
      }
      totalCount
    }
  }
`;

// Mutations go here
// export const APPLY_DISCOUNT_BILLING = gql`
// mutation billingApplyDiscount(){
//   billingApplyDiscount(){
    
//   }
// }
// `
// fill in the logic once applyDiscount API is updated