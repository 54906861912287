import { DriverCalendarIcon, DriverLastSeenIcon, DriverLoactionIcon, DriversIcon, FlagIcon, MapIcon, PhoneIcon, WalletIcon } from '../../../components/svg'
import React, { useEffect, useState } from 'react'
import InfoCard from '../../../components/common/InfoCard'
import moment from 'moment'
import PaginatedTable from '../../../components/Table/paginatedTable'
import { objFlatten } from 'helper/objFlatten'
import { logger } from 'helper/logger'
import CustomButton from '../../../components/common/Button'
import Modal from '../../../components/common/Modal'
import ModalFooter from '../../../components/common/Modal/ModalFooter'
import AddExternalId from './AddExternalId'
import { Update_DRIVER_EXTERNAL_ID } from 'api/mutations/Mutations'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import LastSeenMap from './LastSeenMap'
import { stagingOptions } from 'constants/apollo'


const cols = [
  { name: "Where", value: "name" },
  { name: "ID", value: "id" },
  { name: "Value", value: "value" },
  { name: "From Date", value: "startedAt", type: "date-simple" },
  { name: "To Date", value: "finishedAt", type: "date-simple" },
  { name: "Status", value: "status", type: "status", styles: "uppercase" },
];

export default function DriverUserCredentialsTab ({ driver, tableData, reload, openChangePasswordModal }) {
  const totalCount = tableData.length
  const [modalOpen, setModalOpen] = useState(false)
  const [mapModalOpen, setMapModalOpen] = useState(false)
  const [selected, setSelected] = useState(null)

  const [
    updateCourierExternalID,
    {
      data,
      loading,
      error
    },
  ] = useMutation(Update_DRIVER_EXTERNAL_ID, stagingOptions);

  const handleUpdateDriverExternalID = (id, attributes) => {
    if (Object.keys(attributes).length > 0)
      updateCourierExternalID({
        variables: {
          input: {
            id: parseInt(id),
            attributes: {
              ...attributes,
            }
          }
        }
      })
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (data?.updateCourierExternalId?.courierExternalId?.id) {
      toast.success(`Successfully updated External ID ${selected.id}`)
      setModalOpen(false)
      setSelected()
      reload()
    }
  }, [data])

  return (
    <>
      <div className='flex flex-col space-y-2 bg-gray-50'>
        <div className="flex w-full justify-between p-4 mt-1 bg-white space-x-5">
          <InfoCard
            key={0}
            title="Knight App OS"
            info={moment(driver?.joinedAt).format("D-M-YYYY / HH:mm")}
            icon={<DriversIcon />}
          />
          <InfoCard
            key={1}
            title="Knight App Version"
            info={moment(driver?.joinedAt).format("D-M-YYYY / HH:mm")}
            icon={<DriversIcon />}
          />
          <InfoCard
            key={3}
            title="Last Seen"
            info={moment(driver?.lastSeen).format("D-M-YYYY / HH:mm")}
            icon={<DriverLastSeenIcon />}
          />
          <InfoCard
            title="Last Seen Location"
            styles='cursor-pointer border-b border-orange-400 px-1 text-orange-400'
            // info={`${driver?.latitude} ${driver?.longitude}`}
            onClick={() => setMapModalOpen(true)}
            info="Open Map"
            icon={<MapIcon />}
          />
          <div>
            <div className='flex space-x-2 items-center'>
              <div className={`h-4 w-4  rounded-full ${driver?.isOnline ? 'bg-successColor' : 'bg-gray-300'}`}>
              </div>
              <span>Online</span>
            </div>
            <div className='flex space-x-2 items-center'>
              <div className={`h-4 w-4  rounded-full ${driver?.isBusy ? 'bg-blue-700' : 'bg-gray-300'}`}>
              </div>
              <span>Busy</span>
            </div>
          </div>
          <div className="w-1/7">
            <CustomButton
              type='button'
              variant='outlined'
              color="danger"
              onClick={openChangePasswordModal}
              style={{ width: "100%", height: "38px" }}
            >
              Reset Password
            </CustomButton>
          </div>
        </div>
        <div className="flex flex-col w-full justify-between mt-1 bg-white">

          <div className='flex w-ful justify-between p-4'>
            <span className="text-xl">External IDs</span>
            <div className="w-1/7">
              <CustomButton
                type='button'
                variant='contained'
                color="warning"
                onClick={() => { setSelected(null); setModalOpen(true) }}
                style={{ width: "100%", height: "38px" }}
              >
                Add
              </CustomButton>
            </div>
          </div>
          <PaginatedTable
            // hasCheckbox
            filterByBin={''}
            sortCol={{ label: "", value: 0 }}
            onSortClick={(sortVal) => {
              // handleSort(sortVal);
            }}
            count={totalCount || 0}
            page={0}
            setPage={() => { }}
            onPageChange={() => { }}
            cols={cols}
            data={tableData}
            selectedRows={[]}
            setSelectedRows={() => { }}
            loading={false}
            canBeEdited
            editMenuItems={[
              {
                actionName: "Edit",
                action: (data) => {
                  console.log("editing", data)
                  setSelected(data)
                  setModalOpen(true)
                },
                isDisabled: false,
              },
              {
                actionName: "Block",
                action: (data) => {
                  setSelected({ ...data, status: 'blocked' })
                  console.log("blocking", data)
                  handleUpdateDriverExternalID(
                    data.id,
                    {
                      status: 'blocked'
                    }
                  )
                },
                disableStatus: "blocked"
              },
              // {
              //   actionName: "Remove",
              //   action: (data) => {
              //     console.log("removing", data)
              //   },
              //   isDisabled: false,
              // },
            ]}

          />
        </div>
      </div>

      <Modal
        onDismiss={() => {
          setModalOpen(false);
        }}
        hidden
        autoOpen={modalOpen}
        onClose={() => { }}
        title={selected ? `Update External ID ${selected?.id}` : "Add New External ID"}
      >
        <AddExternalId handleUpdateDriverExternalID={handleUpdateDriverExternalID} selected={selected} reload={reload} handleClose={() => setModalOpen(false)} driverId={driver?.id} />
      </Modal>

      <Modal
        onDismiss={() => {
          setMapModalOpen(false);
        }}
        hidden
        autoOpen={mapModalOpen}
        onClose={() => { }}
        title={""}
      >
        <LastSeenMap driver={driver} />
      </Modal>
    </>
  )
}
