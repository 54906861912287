import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  StepIcon,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Modal from "../../../components/common/Modal";
import SelectAsyncPaginate from "../../../components/common/Select/AsyncPaginateSelect";

import CustomCheckBox from "../../../components/common/Checkbox";
import NewSearch from "../../../components/common/Search/new-search";
import CustomInput from "../../../components/common/Input";
import CustomButton from "../../../components/common/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DoneIcon from "@mui/icons-material/Done";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_INVENTORY_LOGS_WITH_MERCHANT_AND_PRODUCT_ID,
  GET_LOCATIONS,
  GET_PRODUCTS_AND_MERCHANTS,
} from "../../../api/queries/Queries";
import { wmsOptions } from "../../../constants/apollo";
import { CREATE_OR_UPDATE_INVENTORY_LOGS } from "../../../api/mutations/WMSMutations";
import { toast } from "react-toastify";
import _, { range } from "lodash";
import { CancelOutlined, CheckBox } from "@mui/icons-material";
import { logger } from "helper/logger";
import FiltersList from "../../../components/FiltersList";
import { WMS_FILTER_LOCATIONS } from "api/queries/WMSFilterQueries";
import NormalSelect from "../../../components/common/Select/NormalSelect";

export default function MoveInventory({
  setModalOpen,
  modalOpen,
  defMerchantId = "",
  defProductId = "",
  defSerials = [],
  defLocationId = "",
  defMerchantName = "",
  defProductName = "",
  defSourceName = "",
  autoFill = false,
  defLocationType = "",
  reloadFunction = () => {},
}: MoveInventoryProps) {
  const [merchantId, setMerchantId] = useState<string>(defMerchantId);
  const [productId, setProductId] = useState<string>(defProductId);
  const [serials, setSerials] = useState<string[]>(defSerials);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSerials, setSelectedSerials] = useState<string[]>([]);
  const [locationType, setLocationType] = useState<string>("");
  const [asyncSelectKey, setAsyncSelectKey] = useState("key");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [serialStart, setSerialStart] = useState("");
  const [serialEnd, setSerialEnd] = useState("");
  const [includeDuplicate, setIncludeDuplicate] = useState(true);
  const [generationError, setGenerationError] = useState("");
  const [productFilters, setProductFilters] = useState({
    locationId: "",
    locationType: "",
  });

  const [destinationLocationType, setDestinationLocationType] = useState("");
  const [destinationLocationId, setDestinationLocationId] = useState("");

  const [selectedLocationType, setSelectedLocationType] = useState<string>("");
  const [sourceLocation, setSourceLocation] = useState<string>(defLocationId);
  const [data, setData] = useState<any>();
  const merchantRef = useRef();
  const productRef = useRef();

  const [pullMerchantAndProducts] = useLazyQuery(GET_PRODUCTS_AND_MERCHANTS, {
    ...wmsOptions,
  });
  const { data: locations } = useQuery(GET_LOCATIONS, {
    ...wmsOptions,
  });
  const [getInventoryLogsWithMerchandAndProductID] = useLazyQuery(
    GET_INVENTORY_LOGS_WITH_MERCHANT_AND_PRODUCT_ID,
    wmsOptions
  );

  const [createOrUpdateInventoryLogMutation] = useMutation(
    CREATE_OR_UPDATE_INVENTORY_LOGS,
    wmsOptions
  );

  const [getLocations] = useLazyQuery(WMS_FILTER_LOCATIONS, wmsOptions);

  const handleScan = (serial = "") => {
    let count = serial.split(/\r?\n/);
    if (count.length === 1) {
      logger("SCANING", serial);

      if (serials.includes(serial) && !selectedSerials.includes(serial)) {
        setSelectedSerials([...selectedSerials, serial]);
      }
    } else {
      const duplicates = _.intersection(serials, count);
      setSelectedSerials([...selectedSerials, ...duplicates]);
    }
  };
  const handleListScan = (list: string[]) => {
    let selectedList: string[] = [];
    list.map((serial: string) => {
      let count = serial.split(/\r?\n/);
      if (count.length === 1) {
        if (serials.includes(serial) && !selectedSerials.includes(serial)) {
          //   selectedList;
          setSelectedSerials([...selectedSerials, serial]);
        }
      } else {
        const duplicates = _.intersection(serials, count);
        setSelectedSerials([...selectedSerials, ...duplicates]);
      }
    });
  };

  const getSearchObj = (search: string) => {
    let searchObj = { query: "" };

    if (search.trim() != "") {
      searchObj = {
        query: search,
      };
    }
    return searchObj;
  };

  const fetch_locations = ({ page = 1, searchQuery = "" } = {}) => {
    logger("fetch_merchants", page, searchQuery);

    const searchObj = getSearchObj(searchQuery);
    if (!destinationLocationType || destinationLocationType == "") return;

    return getLocations({
      variables: {
        skip: (page - 1) * 20,
        ...searchObj,
        where: {
          type: {
            eq: destinationLocationType,
          },
        },
      },
    }).then((res: any) => {
      let locationList = res?.data?.locations?.items;
      let locationsModified: any = [];
      locationList.map((item: any) => {
        if (!item.label) {
          locationsModified.push({
            value: item.value,
            label: item.locationCode,
          });
        } else {
          locationsModified.push(item);
        }
      });
      return {
        options: locationsModified,
        hasMore: res?.data?.locations?.totalCount >= page * 20,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    });
  };

  React.useEffect(() => {
    setAsyncSelectKey((old) => old + "1");
    setDestinationLocationId("");
  }, [destinationLocationType]);
  React.useEffect(() => {
    if (
      (sourceLocation &&
        sourceLocation !== "undefined" &&
        sourceLocation !== "null") ||
      defLocationId.length
    ) {
      setLoading(true);
      let filter: any[] = [
        {
          locationId: {
            eq: parseInt(sourceLocation || defLocationId),
          },
        },
      ];

      if (
        (merchantId !== "undefined" && merchantId.length) ||
        defMerchantId.length
      ) {
        filter.push({
          merchantId: {
            eq: parseInt(merchantId || defMerchantId),
          },
        });
      }

      if (
        (productId !== "undefined" && productId.length) ||
        defProductId.length
      )
        filter.push({
          productId: {
            eq: parseInt(productId || defProductId),
          },
        });

      getInventoryLogsWithMerchandAndProductID({
        variables: {
          input: {
            and: filter,
          },
        },
      })
        .then(({ data }) => {
          setData({
            items: data?.currentInventory?.items,
          });

          if (productId && merchantId) {
            if (data?.currentInventory?.items[0]) {
              setSerials(data?.currentInventory?.items[0].serials);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [merchantId, productId, sourceLocation]);

  React.useEffect(() => {
    if (defSerials.length) {
      setSerials(defSerials);
      if (defProductId) {
        setSelectedSerials(defSerials);
      }
    }
  }, [defSerials]);

  const handleScanAdvanced = () => {
    try {
      setGenerationError("");

      let start = parseInt(serialStart);
      let end = parseInt(serialEnd);

      if (includeDuplicate) {
        // TODO:: check for isNumeric and add this case here too
        if (serialStart?.toString()?.length > 15) {
          let newStart = parseInt(
            serialStart.toString().substr(serialStart.length - 5)
          );
          let bigIntFirst = serialStart
            .toString()
            .substr(0, serialStart.length - 5);
          let newEnd = parseInt(
            serialEnd.toString().substr(serialStart.length - 5)
          );
          let bigIntSecond = serialEnd
            .toString()
            .substr(0, serialStart.length - 5);

          let strLen = 5;

          let list = range(newEnd, newStart - 1, -1);
          let newList: string[] = [];
          list?.map((item) => {
            let strNum = item.toString();
            if (strNum.length < strLen) {
              for (let i = 0; i < strLen - strNum.length; i++) {
                strNum = "0" + strNum;
              }
            }
            newList.push(bigIntFirst + strNum);
            logger("Handle scan", bigIntFirst + strNum);
            handleScan(bigIntFirst + strNum);
          });
          setSelectedSerials([...selectedSerials, ...newList]);

          if (newList.length == 0) {
            throw Error("Cannot scan these serials");
          }
        } else {
          let list = range(end, start - 1, -1);

          list.map((item) => {
            handleScan(item.toString());
          });
        }
      } else {
        let list = Array(end - start + 1).fill(serialStart);
      }
      setIsGenerationLoading(false);
      setSerialStart("");
      setSerialEnd("");
      setIsModalOpen(false);
      toast.success("Successfully scanned the serials");
    } catch (e: any) {
      console.log("ERRROR", e.message);
      // setIsModalOpen(false)
      setIncludeDuplicate(true);
      setGenerationError(e.message);
    }
  };

  return (
    <>
      <Modal
        onClose={() => setModalOpen(false)}
        footer={() =>
          MoveInventoryFooter({
            destinationLocationId: destinationLocationId,
            destinationLocationType: destinationLocationType,
            selectedLocationType: locations?.locations?.items?.find(
              (item: any) => item.id == selectedLocationType
            ),
            setModalOpen: setModalOpen,
            createOrUpdateInventoryLogMutation:
              createOrUpdateInventoryLogMutation,
            selectedSerials: selectedSerials,
            merchantId: merchantId || defMerchantId,
            productId: productId || defProductId,
            sourceLocation: locations?.locations?.items?.find(
              (item: any) => item.id == sourceLocation
            ) || { id: defLocationId, type: defLocationType },
            data: data,
            reloadFunction: reloadFunction,
          })
        }
        hidden={true}
        onDismiss={() => setModalOpen(false)}
        autoOpen={modalOpen}
        title="Move inventory"
      >
        {!autoFill ? (
          <Autocomplete
            fullWidth
            disablePortal
            id="select-id"
            getOptionLabel={(option: any) =>
              `${option?.locationCode || ""} || ${option?.id} || ${
                option?.type
              }`
            }
            options={locations?.locations?.items}
            renderInput={(params) => (
              <TextField {...params} label="Source location" />
            )}
            onChange={(_, value) => {
              setSourceLocation(value?.id + "" || "");
            }}
            sx={{
              marginBottom: 2,
            }}
            disabled={selectedSerials.length > 0}
          />
        ) : (
          <Autocomplete
            fullWidth
            disablePortal
            id="select-id"
            options={[]}
            renderInput={(params) => (
              <TextField {...params} label="Source location" />
            )}
            sx={{
              marginBottom: 2,
            }}
            disabled={true}
            value={defSourceName}
          />
        )}
        {!autoFill ? (
          <Autocomplete
            disablePortal
            id="merchant-id"
            getOptionLabel={(option: any) => {
              return `${option?.merchant?.id} || ${option?.merchant?.name}`;
            }}
            ref={merchantRef}
            options={
              _.uniqBy(data?.items, (e: any) => {
                return e?.merchant?.id;
              }) || []
            }
            renderInput={(params) => (
              <TextField {...params} label="Merchant name" />
            )}
            onChange={(_, value) => {
              setMerchantId(value?.merchant?.id + "" || "");
              setProductId("");
            }}
            disabled={
              selectedSerials.length !== 0 ||
              sourceLocation.length === 0 ||
              loading
            }
          />
        ) : (
          <Autocomplete
            disablePortal
            id="merchant-id"
            renderInput={(params) => (
              <TextField {...params} label="Source location" />
            )}
            options={[]}
            value={defMerchantName}
            disabled={true}
          />
        )}
        {!autoFill ? (
          <Autocomplete
            disablePortal
            id="product-id"
            getOptionLabel={(option: any) =>
              `${option?.product?.name} || ${option?.product?.id}`
            }
            ref={productRef}
            options={data?.items || []}
            sx={{ marginTop: 2 }}
            renderInput={(params) => (
              <TextField {...params} label="Product name" />
            )}
            onChange={(_, value) => {
              setProductId(value?.product?.id + "" || "");
            }}
            disabled={
              selectedSerials.length !== 0 ||
              sourceLocation.length === 0 ||
              loading
            }
          />
        ) : (
          <Autocomplete
            disablePortal
            id="product-id"
            options={[]}
            sx={{ marginTop: 2 }}
            renderInput={(params) => (
              <TextField {...params} label="Product name" />
            )}
            value={defProductName}
            disabled={true}
          />
        )}
        <div className="w-full flex items-center">
          <TextField
            multiline
            fullWidth
            title="Scan serial"
            type="text"
            label="Scan serial"
            onKeyDown={(event: any) => {
              if (event.keyCode === 13 && event?.target?.value?.length) {
                handleScan(event.target.value);

                setTimeout(() => {
                  event.target.value = "";
                }, 200);
              }
            }}
            sx={{
              marginTop: 2,
            }}
            helperText={
              !productId?.length || !merchantId?.length
                ? `This field opens when you choose Product and Merchant name`
                : !serials.length
                ? `We can't find any serials with those options`
                : null
            }
            disabled={!serials.length}
          />
          <div className="w-1/4 text-center">
            <span
              className={`hover:underline cursor-pointer ${
                !serials.length ? "disabled text-gray-400" : ""
              }`}
              onClick={() => {
                logger("OPEINGNIG");
                setIsModalOpen(true);
              }}
              style={{ width: "100%", display: "block" }}
            >
              Add advanced
            </span>
          </div>
        </div>

        {isModalOpen && (
          <>
            <Modal
              onDismiss={() => {
                setIsModalOpen(false);
              }}
              hidden
              footer={() => <></>}
              autoOpen={isModalOpen}
              onClose={() => {}}
              title={"Advanced Scan of Serials"}
            >
              <div className="w-full flex space-x-4 mt-4 items-center">
                <div className="w-1/3">
                  <Box>
                    <NewSearch
                      hasIcon={false}
                      hasValue={true}
                      value={serialStart}
                      filterName="Serial Start"
                      placeholder="Serial start"
                      onChange={(e) => setSerialStart(e.target.value)}
                      handleClearSearch={() => setSerialStart("")}
                      style={{ height: "38px" }}
                    />
                    {/* {formik.errors.serials ? (
									<div className='error'>
										{formik.errors.serials}
									</div>
								) : null} */}
                  </Box>
                </div>
                <div className="w-1/3">
                  <Box>
                    <NewSearch
                      hasIcon={false}
                      // ref={serialCode}
                      hasValue={true}
                      value={serialEnd}
                      filterName="Serial End"
                      placeholder="Serial End"
                      onChange={(e) => setSerialEnd(e.target.value)}
                      handleClearSearch={() => setSerialEnd("")}
                      style={{ height: "38px" }}
                    />
                    {/* {formik.errors.serials ? (
										<div className='error'>
											{formik.errors.serials}
										</div>
									) : null} */}
                  </Box>
                </div>
                <div className="flex w-1/3 items-center">
                  <div className="w-1/2">
                    <Box>
                      <CustomCheckBox
                        label="Include duplicate"
                        checked={includeDuplicate}
                        setChecked={setIncludeDuplicate}
                      />
                    </Box>
                  </div>

                  <div className="w-1/2 pl-4">
                    <CustomButton
                      type="button"
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        handleScanAdvanced();
                      }}
                      style={{ width: "100%", height: "38px" }}
                    >
                      {isGenerationLoading ? "Loading..." : "Scan"}
                    </CustomButton>
                  </div>
                </div>
              </div>
              <span className="text-red-500">{generationError}</span>
            </Modal>
          </>
        )}

        <MoveInventoryTable
          selectedSerials={selectedSerials}
          setSelectedSerials={setSelectedSerials}
          serials={serials}
        />
        <div>
          <Typography
            sx={{ padding: 1, marginTop: 1 }}
            fontSize={20}
            textAlign={"center"}
          >
            Select destination
          </Typography>
          <div className="w-full flex">
            <div
              className={`w-1/2 ${
                selectedSerials.length === 0 && "disabledContent"
              }`}
            >
              <NormalSelect
                filter={{
                  name: "Filter by Location Type",
                  value: "locationType",
                  id: 4,
                  options: [
                    { value: "WAREHOUSE", label: "Warehouse" },
                    { value: "SHELF", label: "Shelf" },
                    { value: "CART", label: "Cart" },
                    { value: "DRIVER", label: "Driver" },
                    { value: "ORDER", label: "Order" },
                  ],
                }}
                defaultValue={[
                  { value: "WAREHOUSE", label: "Warehouse" },
                  { value: "SHELF", label: "Shelf" },
                  { value: "CART", label: "Cart" },
                  { value: "DRIVER", label: "Driver" },
                  { value: "ORDER", label: "Order" },
                ].find((option) => option?.value == destinationLocationType)}
                handleChange={(e: any) => setDestinationLocationType(e.value)}
                // isDisabled={lockedFields?.indexOf(filter.value) >= 0}
              />
            </div>
            <div
              className={`w-1/2 ${
                (!destinationLocationType || destinationLocationType == "") &&
                "disabledContent"
              }`}
            >
              <SelectAsyncPaginate
                key={asyncSelectKey}
                value={""}
                defaultValue={{}}
                isSearchable={true}
                getDefaultValue={() => {}}
                onChange={(e: any) => setDestinationLocationId(e.value)}
                loadMore={(searchQuery: any, page: any) =>
                  fetch_locations({ page: page, searchQuery: searchQuery })
                }
                filterName={"Location"}
              />
            </div>
          </div>
          {/* <FormControl
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <InputLabel id="select-location-type-label">Type</InputLabel>
            <Select
              disabled={selectedSerials.length === 0}
              labelId="select-location-type-label"
              id="select-location-type"
              label="Type"
              sx={{
                width: "30%",
                marginRight: 1,
              }}
              defaultValue={
                renderLocationTypes(locations?.locations?.items || [])[0]
              }
              onChange={(event: any) => {
                setLocationType(event.target.value);
              }}
            >
              {renderLocationTypes(locations?.locations?.items || []).map(
                (item: string) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                }
              )}
            </Select>

            <Autocomplete
              disablePortal
              id="select-id"
              getOptionLabel={(option: any) =>
                `${option?.locationCode || ""} || ${option?.id}`
              }
              options={
                locations?.locations?.items.filter(
                  (item: any) =>
                    item.type ===
                    (locationType ||
                      renderLocationTypes(locations?.locations?.items || [])[0])
                ) || []
              }
              renderInput={(params) => (
                <TextField {...params} label="Location" />
              )}
              onChange={(_, value) => {
                setSelectedLocationType(value?.id + "" || "");
              }}
              sx={{
                width: "70%",
              }}
              disabled={selectedSerials.length === 0}
            />
          </FormControl> */}
        </div>
      </Modal>
    </>
  );
}

const MoveInventoryTable = ({
  selectedSerials,
  setSelectedSerials,
  serials,
}: {
  selectedSerials: any;
  setSelectedSerials: any;
  serials: any;
}) => {
  return (
    <Box
      sx={{ marginTop: 2, maxHeight: 300, overflow: "scroll" }}
      component={Paper}
    >
      <Box sx={{ p: 1 }}>
        {!serials.length && <p>No data</p>}
        {serials.map((row: any, index: number) => {
          if (row?.length) {
            if (selectedSerials.find((i: any) => i === row)) {
              return (
                <Chip
                  key={row + index}
                  label={row}
                  sx={{ m: 0.5 }}
                  color="success"
                  onDelete={() => {
                    setSelectedSerials(
                      selectedSerials.filter((i: any) => i !== row)
                    );
                  }}
                />
              );
            } else {
              return (
                <Chip
                  key={row + index}
                  label={row}
                  sx={{ m: 0.5 }}
                  color="default"
                  clickable
                  onClick={() => {
                    setSelectedSerials([...selectedSerials, row]);
                  }}
                />
              );
            }
          }
        })}
      </Box>
    </Box>
  );
};

const MoveInventoryFooter = ({
  destinationLocationId,
  destinationLocationType,
  selectedLocationType,
  setModalOpen,
  createOrUpdateInventoryLogMutation,
  selectedSerials,
  merchantId,
  productId,
  sourceLocation,
  data,
  reloadFunction,
}: {
  destinationLocationId: string;
  destinationLocationType: string;
  selectedLocationType: any;
  setModalOpen: any;
  createOrUpdateInventoryLogMutation: any;
  selectedSerials: string[];
  merchantId: any;
  productId: any;
  sourceLocation: any;
  data: any;
  reloadFunction: any;
}) => {
  return (
    <div>
      <Button
        color="warning"
        variant="outlined"
        onClick={() => setModalOpen(false)}
      >
        Cancel
      </Button>
      <Button
        sx={{ marginLeft: 1 }}
        color="warning"
        variant="contained"
        disabled={destinationLocationId == ""}
        startIcon={<DoneIcon />}
        onClick={() => {
          createOrUpdateInventoryLogMutation({
            variables: {
              input: [
                {
                  id: 0,
                  destinationLocation: {
                    id: parseInt(destinationLocationId),
                    type: destinationLocationType,
                  },
                  sourceLocation: {
                    id: parseInt(sourceLocation?.id),
                    type: sourceLocation?.type,
                  },
                  merchantId: parseInt(merchantId),
                  productId: parseInt(productId),
                  serials: selectedSerials,
                },
              ],
            },
          }).then((data: any) => {
            toast.success(
              `Inventory log ${data?.data.createOrUpdateInventoryLogs[0].id} has been successfully created`
            );
            reloadFunction();
            setModalOpen(false);
          });
        }}
      >
        Move
      </Button>
    </div>
  );
};

type MoveInventoryProps = {
  setModalOpen: any;
  modalOpen: any;
  defMerchantId?: string;
  defProductId?: string;
  defSerials?: string[];
  defLocationId?: string;
  defMerchantName?: string;
  defProductName?: string;
  defSourceName?: string;
  autoFill?: boolean;
  defLocationType?: string;
  reloadFunction?: any;
};

function renderLocationTypes(array: any[]) {
  let values: any[] = [];
  array.forEach((item: any) => {
    if (!values.includes(item?.type)) {
      values.push(item?.type);
    }
  });

  return values;
}
