import {
  AcceptedStatusIcon,
  AlertIcon,
  AssignedStatusIcon,
  CancelledStatusIcon,
  CompletedStatusIcon,
  OnHoldStatusIcon,
  UnassignedStatusIcon,
} from "./svg";

const getIcon = (status) => {
  switch (status) {
    case "Pending":
      return <AlertIcon />;
    case "Accepted":
      return <AcceptedStatusIcon />;
    case "Assigned":
      return <AssignedStatusIcon />;
    case "Unassigned":
      return <UnassignedStatusIcon />;
    case "OnHold":
      return <OnHoldStatusIcon />;
    case "Completed":
      return <CompletedStatusIcon />;
    case "Cancelled":
      return <CancelledStatusIcon />;
  }
};
const getBgColor = (status) => {
  switch (status) {
    case "Pending":
      return "bg-red-500";
    case "Accepted":
      return "bg-green-500";
    case "Assigned":
      return "bg-indigo-500";
    case "Unassigned":
      return "bg-rose-400";
    case "OnHold":
      return "bg-amber-400";
    case "Completed":
      return "bg-emerald-500";
    case "Cancelled":
      return "bg-gray-500";
  }
};

export default function StatisticsCard({ item, key }) {
  return (
    <div
      key={key}
      className="w-[120px] h-[135px] p-[10px] pb-4 rounded-2xl shadow-[0px_0px_10px_rgba(0,0,0,0.15);] flex items-start flex-col text-left justify-between relative"
    >
      {item?.icon ? <div>{item.icon}</div> : <div>{getIcon(item?.status)}</div>}
      <div className="text-[13px] text-gray-500">
        <span className="block">{item?.title}</span>
        <span>{item?.time}</span>
      </div>
      <span className="text-md font-bold">{item?.count}</span>
      <div
        className={`absolute bottom-0 left-5 h-[5px] w-[80px] ${getBgColor(
          item?.status
        )} rounded-t-md`}
      ></div>
    </div>
  );
}
