import { gql } from "@apollo/client";

export const GET_ALL_WAREHOUSES = gql`
	query getAllWarehouses(
		$skip: Int
		$where: LocationFilterInput
		$order: [LocationSortInput!]
		$query: String
	) {
		locations(skip: $skip, where: $where, order: $order, query: $query) {
			items {
				id
				referenceName
				inventory {
					product {
						id
					}
					serials
					merchant {
						name
					}
				}
				purchaseOrders {
					status
				}
				shelves {
					id
				}
				carts {
					id
				}
				city
				isActive
				locationCode
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const GET_ALL_PRODUCTS = gql`
	query getAllProducts(
		$skip: Int
		$where: ProductFilterInput
		$order: [ProductSortInput!]
		$query: String
		$take: Int
	) {
		products(
			skip: $skip
			where: $where
			order: $order
			query: $query
			take: $take
		) {
			items {
				id
				name
				weightInGrams
				lengthInMm
				widthInMm
				heightInMm
				isActive
				categoryType
				sKU
				createdAt
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const GET_ALL_INVENTORY_LOGS = gql`
	query getAllInventoryLogs(
		$skip: Int
		$where: InventoryLogFilterInput
		$order: [InventoryLogSortInput!]
		$query: String
	) {
		inventoryLogs(
			skip: $skip
			where: $where
			order: $order
			query: $query
		) {
			items {
				id
				product {
					sKU
					name
				}
				merchant {
					id
					name
				}

				serials

				sourceLocation {
					referenceName
					locationCode
					type
				}

				destinationLocation {
					referenceName
					locationCode
					type
				}
				createdAt
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const GET_ALL_CURRENT_INVENTORY = gql`
	query getAllCurrentInventory(
		$skip: Int
		$where: CurrentInventoryFilterInput
		$order: [CurrentInventorySortInput!]
		$query: String
	) {
		currentInventory(
			skip: $skip
			where: $where
			order: $order
			query: $query
		) {
			items {
				product {
					id
					name
				}
				merchant {
					name
				}
				serials
				location {
					referenceName
					locationCode
					type
				}
				updatedAt
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const GET_ALL_PURCHASE_ORDER = gql`
	query getAllPurchaseOrders(
		$skip: Int
		$where: PurchaseOrderFilterInput
		$order: [PurchaseOrderSortInput!]
		$query: String
	) {
		purchaseOrders(
			skip: $skip
			where: $where
			order: $order
			query: $query
		) {
			items {
				id
				product {
					id
					name
					weightInGrams
				}
				merchant {
					id
					name
				}
				location {
					id
					locationCode
					type
				}
				status
				serials
				createdAt
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const GET_WAREHOUSE = gql`
	query getAllWarehouses($id: Int) {
		locations(
			where: { and: [{ id: { eq: $id } }, { type: { eq: WAREHOUSE } }] }
		) {
			items {
				id
				referenceName
				inventory {
					product {
						id
					}
					serials
					updatedAt
				}
				purchaseOrders {
					status
				}
				shelves {
					id
					locationCode
					isActive
					inventory {
						updatedAt
					}
				}
				carts {
					locationCode
					isActive
					id
				}
				city
				isActive
			}
		}
	}
`;

export const GET_ALL_MERCHANTS_WMS = gql`
	query fetchAllMerchants(
		$skip: Int
		$where: MerchantFilterInput
		$order: [MerchantSortInput!]
		$query: String
	) {
		merchants(skip: $skip, where: $where, order: $order, query: $query) {
			items {
				name
				id
			}
		}
	}
`;

export const GET_PICKERS = gql`
	query fetchAllPickers(
		$where: UserFilterInput
		$order: [UserSortInput!]
		$query: String
		$skip: Int
		$take: Int
	) {
		users(
			where: $where
			order: $order
			query: $query
			skip: $skip
			take: $take
		) {
			items {
				id
				name
				status
			}
		}
	}
`;
export const GET_PACKERS = gql`
	query fetchAllPackers {
		users(where: { role: { eq: PACKER } }) {
			items {
				id
				name
				status
			}
		}
	}
`;

export const GET_WMS_SHIPMENTS = gql`
	query fetchAllWmsShipments(
		$skip: Int
		$where: ShipmentFilterInput
		$order: [ShipmentSortInput!]
		$query: String
	) {
		shipments(skip: $skip, where: $where, order: $order, query: $query) {
			items {
				id
				trackingNo
				createdAt
				updatedAt
				status
				saleOrder {
					id
					packingJob {
						cart {
							locationCode
						}
					}
				}
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const GET_SALE_ORDERS = gql`
	query fetchAllSaleOrders(
		$skip: Int
		$where: SaleOrderFilterInput
		$order: [SaleOrderSortInput!]
		$query: String
	) {
		saleOrders(skip: $skip, where: $where, order: $order, query: $query) {
			items {
				id
				shipment {
					id
					trackingNo
				}
				merchant {
					id
					name
				}
				packingJob {
					cart {
						id
						referenceName
					}
				}
				saleOrderItems {
					id
					quantity
					product {
						id
						name
					}
				}

				amount
				status
				code
				updatedAt
				createdAt
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const GET_PACKING_JOBS = gql`
	query GetAllPackingItems(
		$order: [PackingJobSortInput!]
		$query: String
		$where: PackingJobFilterInput
		$skip: Int
		$take: Int
	) {
		packingJobs(
			order: $order
			query: $query
			where: $where
			skip: $skip
			take: $take
		) {
			totalCount
			items {
				id
				status
				code
				createdAt
				cart {
					id
					locationCode
					referenceName
				}
				saleOrderId
				saleOrder {
					id
					code
					amount
					saleOrderItems {
						quantity
						serials
						product {
							name
						}
					}
					merchant {
						name
						referenceName
					}
				}
				assignedTo {
					id
					name
				}
			}
		}
	}
`;

export const GET_PICKING_JOBS = gql`
	query GetAllPickingItems(
		$order: [PickingJobSortInput!]
		$query: String
		$where: PickingJobFilterInput
		$skip: Int
		$take: Int
	) {
		pickingJobs(
			order: $order
			query: $query
			where: $where
			skip: $skip
			take: $take
		) {
			totalCount
			items {
				id
				status
				code
				createdAt
				cart {
					id
					locationCode
					referenceName
				}
				assignedTo {
					id
					name
				}

				saleOrderItem {
					serials
					id
					quantity

					product {
						name
					}

					saleOrder {
						id
						merchant {
							name
							referenceName
						}
					}
				}

				location {
					locationCode
				}
				assignedTo {
					id
					name
				}

				updatedAt
			}
		}
	}
`;

export const GET_RESTOCKED_JOBS = gql`
	query GetRestockedJobs($skip: Int) {
		restockJobs(skip: $skip) {
			items {
				id
				status
				code
				assignedTo {
					id
					name
				}
				cart {
					id
					locationCode
					referenceName
				}

				location {
					id
					referenceName
				}
				saleOrderItem {
					id
					saleOrder {
						id
						merchant {
							id
							name
						}
						saleOrderItems {
							id
							quantity
							serials
						}
					}
					product {
						id
						name
					}
				}
				createdAt
			}
			totalCount
		}
	}
`;

export const GET_ALL_LOCATIONS = gql`
	query GET_LOCATIONS(
		$where: LocationFilterInput
		$skip: Int
		$order: [LocationSortInput!]
		$query: String
		$take: Int
	) {
		locations(
			where: $where
			skip: $skip
			order: $order
			query: $query
			take: $take
		) {
			items {
				id
				locationCode
				referenceName
			}
		}
	}
`;

export const WMS_EXPORT_INVENTORY_SUMMARY_PER_MERCHANT = gql`
	query fetchEXPORTInventorySummaryPerMerchant($merchantId: Int!) {
		exportInventorySummaryPerMerchant(merchantId : $merchantId) 
	}
`;
