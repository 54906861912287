import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	ApolloLink,
	concat,
	HttpLink,
	split,
	from,
} from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			toast(message + "", {
				type: "error",
			})
		);
	if (networkError)
		toast("Network error, please check internet connection", {
			type: "error",
		});
});

const mainUrl = Object.fromEntries(
	new URLSearchParams(window.location.search)
)?.mainUrl;

const httpLink = new HttpLink({
	uri: mainUrl
		? mainUrl
		: Object.fromEntries(new URLSearchParams(window.location.search))
				?.urlProduction
		? Object.fromEntries(new URLSearchParams(window.location.search))
				?.urlProduction
		: process.env.REACT_APP_MODE === "production"
		? "https://barqfleet.com/graphql/admins"
		: "http://localhost:8010/graphql/admins",
	credentials: "include",
});

const httpLinkForStaging = new HttpLink({
	uri: mainUrl
		? mainUrl
		: Object.fromEntries(new URLSearchParams(window.location.search))
				?.urlStaging
		? Object.fromEntries(new URLSearchParams(window.location.search))
				?.urlStaging
		: process.env.REACT_APP_MODE === "production"
		? "https://barqfleet.com/graphql/admins"
		: process.env.REACT_APP_MODE === "stage" ||
		  process.env.REACT_APP_MODE === "staging"
		? "https://staging.barqfleet.com/graphql/admins"
		: "http://localhost:8010/graphql/admins",
	credentials: "include",
});

const authMiddleware = new ApolloLink((operation, forward) => {
	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			APP_BUNDLE: "com.kaakati.barqcaptain",
			APP_NAME: "com.kaakati.barqcaptain",
			LANGUAGE: "en",
			authorization: `Bearer Bearer ${process.env.REACT_APP_GRAPHQL_TOKEN}`,
		},
	}));
	return forward(operation);
});

const wmsLink = new HttpLink({
	uri: Object.fromEntries(new URLSearchParams(window.location.search))?.urlWMS
		? Object.fromEntries(new URLSearchParams(window.location.search))
				?.urlWMS
		: process.env.REACT_APP_MODE === "production"
		? "https://api.inventory.barqfleet.com/graphql"
		: "https://api.inventory.stage.barqfleet.com/graphql",
	// uri: "https://localhost:44351/graphql",
});

function client({ loading, setLoading }: { loading: any; setLoading: any }) {
	return new ApolloClient({
		cache: new InMemoryCache(),
		link: from([
			errorLink,
			concat(
				authMiddleware,
				split(
					(operation) =>
						operation.getContext().clientName === "staging",
					httpLinkForStaging,
					split(
						(operation) =>
							operation.getContext().clientName === "wms",
						wmsLink,
						httpLink
					)
				)
			),
		]),
		credentials: "include",
		resolvers: {
			Query: {},
		},
		// defaultOptions: {
		// 	watchQuery: {
		// 		fetchPolicy: "no-cache",
		// 		nextFetchPolicy(lastFetchPolicy) {
		// 			console.log(lastFetchPolicy);
		// 			if (lastFetchPolicy == "no-cache") {
		// 				setLoading(false);
		// 			} else {
		// 				setLoading(true);
		// 			}
		// 			return "network-only";
		// 		},
		// 	},
		// },
	});
}
export { client as ApolloClientConfig };
