import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip, { ChipPropsColorOverrides } from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getStatusColor } from "helper/getStatusColor";
import { getBackgroundColors } from "@components/colors";
import { useTheme } from "@mui/styles";

export default function SortationTable({
	data,
	onPressCheckbox,
	selectedRows,
	loading,
	filterByBin,
	searchStr,
}: SortationTableProps) {
	const { t } = useTranslation("common");

	const TableItem = ({ row }: { row: any }) => {
		const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
			null
		);
		const theme = useTheme();

		const open = Boolean(anchorEl);
		const handleClick = (event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = (id: string) => {
			onPressCheckbox(id);
			setAnchorEl(null);
		};

		const selected = !!selectedRows.find((item) => item === row.trackingNo);

		return (
			<TableRow
				key={row.trackingNo}
				sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			>
				<TableCell padding='checkbox'>
					<Checkbox
						color='primary'
						checked={selected}
						onChange={(event, checked) =>
							onPressCheckbox(row.trackingNo)
						}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				<TableCell component='th' scope='row'>
					{row.id || "N/A"} | {row.trackingNo}
				</TableCell>
				<TableCell>
					{row.merchantTrackingNo || "N/A"} |{" "}
					{row.merchantOrderId || "N/A"}
				</TableCell>
				<TableCell>
					{row.merchant?.name || "N/A"} | {"N/A"}
				</TableCell>
				<TableCell>
					{row.customerDetails.city || "N/A"} |{" "}
					{row.neighborhood || "N/A"}
				</TableCell>
				<TableCell>{row.products?.length}</TableCell>
				{
					<TableCell className='text-center'>
						<Chip
							color={
								[
									"processing_at_warehouse",
									"in_warehouse",
									"processing",
									"picked_up",
									"intransit",
									"not_ready_for_pickup",
									"pickup_failed",
									"misrouted",
								].includes(row.orderStatus)
									? "default"
									: ["ready_for_delivery"].includes(
											row.orderStatus
									  )
									? "warning"
									: ["completed"].includes(row.orderStatus)
									? "success"
									: "error"
							}
							size='small'
							style={{
								color: [
									"completed",
									"ready_for_delivery",
								].includes(row.orderStatus)
									? "#ffffff"
									: undefined,
								opacity: 0.8,
							}}
							/* 
						// @ts-ignore */
							label={
								row.orderStatus
									?.replace(/_/g, " ")
									?.replace(/(^\w|\s\w)/g, (m: any) =>
										m.toUpperCase()
									) || "test"
							}
						/>
						{/* We can change color with color attribute */}
					</TableCell>
				}
				<TableCell>{row.sortingBin}</TableCell>
			</TableRow>
		);
	};

	const LoadingItem = ({ index }: { index: number }) => {
		return (
			<TableRow
				key={index}
				sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			>
				<TableCell width={95} height={15}>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
			</TableRow>
		);
	};

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label='simple table'>
				<TableHead>
					<TableRow>
						<TableCell padding='checkbox'>
							<Checkbox
								color='primary'
								checked={selectedRows.length === data.length}
								onChange={(event, checked) => onPressCheckbox()}
								inputProps={{
									"aria-label": "select all desserts",
								}}
							/>
						</TableCell>
						<TableCell>
							<p className='font-bold'>{`${t(
								"order_number"
							)}`}</p>
						</TableCell>
						<TableCell>
							<p className='font-bold'>{`${t(
								"merchant_number"
							)}`}</p>
						</TableCell>
						<TableCell>
							<p className='font-bold'>{`${t(
								"merchant_name"
							)}`}</p>
						</TableCell>
						<TableCell>
							<p className='font-bold'>{`${t(
								"city_neighborhood"
							)}`}</p>
						</TableCell>
						<TableCell>
							<p className='font-bold'>{`${t("products")}`}</p>
						</TableCell>
						<TableCell>
							<p className='font-bold'>{`${t(
								"order_status"
							)}`}</p>
						</TableCell>
						<TableCell>
							<p className='font-bold'>{`${t("bin")}`}</p>
						</TableCell>
						<TableCell>
							<p className='font-bold'></p>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<>
						{filterByBin?.length || searchStr?.length
							? data
									?.filter((i: any) => {
										return (
											i.sortingBin == filterByBin ||
											searchStr.includes(i.trackingNo) ||
											searchStr.includes(
												i.merchantOrderId
											)
										);
									})
									.map((row, index) => (
										<TableItem key={index} row={row} />
									))
							: data?.map((row, index) => (
									<TableItem key={index} row={row} />
							  ))}
						{loading &&
							Array.from(Array(10).keys()).map((item, index) => (
								<LoadingItem key={index} index={index} />
							))}
					</>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

type SortationTableProps = {
	data: InboundRow[];
	onPressCheckbox: (id?: string) => void;
	selectedRows: string[];
	loading: boolean;
	filterByBin?: string;
	searchStr: any;
};
