import React, { MemoExoticComponent } from "react";
import WarehouseIcon from "../assets/icons/WarehouseIcon";
import DashboardPage from "../pages/Dashboard";
import Inbound from "../pages/Inbound";
import Knights from "../pages/Knights";
import Orders from "../pages/Orders";
import OrderDetails from "../pages/Orders/order-details";
import Playground from "../pages/Playground";
import Sortation from "../pages/Sortation";
import CurrentInventory from "../pages/WMS/CurrentInventory";
import InventorySummary from "../pages/WMS/InventorySummary";
import InventoryLog from "../pages/WMS/InventoryLog";
import Products from "../pages/WMS/Products";
import PurchaseOrder from "../pages/WMS/PurchaseOrder";
import Warehouses from "../pages/WMS/Warehouses";
import WarehouseDetails from "../pages/WMS/Warehouses/warehouse";
import {
	Dashboard,
	TimeToLeave,
	HouseSiding,
	ShoppingBasket,
	Input,
	Map,
	Speed,
} from "@mui/icons-material";
import { SvgIconProps, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import OutboundPage from "pages/WMS/Outbound";
import Proceed from "pages/WMS/Outbound/Proceed";
import Picking from "pages/WMS/Outbound/Picking";
import BillingInfo from "pages/Financials/Billings/BillingInfoPage.tsx";
import Wallets from "pages/Financials/Wallets";
import KnightDetails from "pages/Knights/pages/driver-details";
import DensityMap from 'pages/DensityMap';
import Billings from 'pages/Financials/Billings';
import Transactions from 'pages/Financials/Transactions';
import OrdersMap from 'pages/OrdersMap';
import WalletInfo from 'pages/Financials/Wallets/WalletInfoPage';

const routes: CategoryType[] = [
	{
		name: "Main",
		accordions: [
			{
				name: "dashboard",
				icon: Dashboard,
				routes: [
					{
						name: "dashboard",
						component: DashboardPage,
						path: "/",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
				],
			},
		],
	},
	{
		name: "finances",
		accordions: [
			{
				name: "Financials",
				icon: HouseSiding,
				path: "/financials/billings",
				routes: [
					{
						name: "Billings",
						component: Billings,
						path: "/financials/billings",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "Billings",
						component: BillingInfo,
						path: "/financials/billings/:billing",
						options: {
							headerShown: true,
						},
						hidden: true,
					},
					{
						name: "Wallets",
						component: Wallets,
						path: "/financials/wallets",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "Wallet Transaction Info",
						component: WalletInfo,
						path: "/financials/wallets/:wallet",
						options: {
							headerShown: true,
						},
						hidden: true,
					},
					{
						name: "Transactions",
						component: Transactions,
						path: "/financials/transactions",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
				],
			},
		],
	},
	{
		name: "assets",
		accordions: [
			{
				name: "knights",
				icon: TimeToLeave,
				routes: [
					{
						name: "knights",
						component: Knights,
						path: "/knights",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "Knight Details",
						component: KnightDetails,
						path: "/knights/:knightId",
						options: {
							headerShown: true,
							canBack: true,
						},
						hidden: true,
					},
				],
			},
			{
				name: "Warehouses",
				icon: HouseSiding,
				path: "/wms",
				routes: [
					{
						name: "Warehouses",
						component: Warehouses,
						path: "/wms/warehouses",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "Warehouse Details",
						component: WarehouseDetails,
						path: "/wms/warehouses/:order",
						options: {
							headerShown: true,
						},
						hidden: true,
					},
					{
						name: "Products",
						// @ts-ignore
						component: Products,
						path: "/wms/products",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "Purchase Order",
						component: PurchaseOrder,
						path: "/wms/purchase-order",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					// {
					//   name: "Receive",
					//   component: Sortation,
					//   path: "/wms/receive",
					//   options: {
					//     headerShown: true,
					//   },
					//   hidden: false,
					// },
					// {
					//   name: "Putaway",
					//   component: Sortation,
					//   path: "/wms/products",
					//   options: {
					//     headerShown: true,
					//   },
					//   hidden: false,
					// },
					{
						name: "Current Inventory",
						component: CurrentInventory,
						path: "/wms/current-inventory",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "Inventory Summary",
						component: InventorySummary,
						path: "/wms/inventory-summary",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "Inventory Log",
						component: InventoryLog,
						path: "/wms/inventory-log",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "Outbound",
						component: OutboundPage,
						path: "/wms/outbound/:pages",
						options: {
							headerShown: true,
						},
						hidden: true,
					},
					{
						name: "Outbound",
						component: OutboundPage,
						path: "/wms/outbound",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "Picking",
						component: Proceed,
						path: "/wms/outbound/proceed/picking",
						options: {
							headerShown: true,
							canBack: true,
						},
						hidden: true,
					},
					{
						name: "Packing",
						component: Proceed,
						path: "/wms/outbound/proceed/packing",
						options: {
							headerShown: true,
							canBack: true,
						},
						hidden: true,
					},
					{
						name: "Complete Restocking",
						component: Proceed,
						path: "/wms/outbound/proceed/restocking",
						options: {
							headerShown: true,
							canBack: true,
						},
						hidden: true,
					},
				],
			},
			{
				name: "orders",
				icon: ShoppingBasket,
				routes: [
					{
						name: "orders",
						component: Orders,
						path: "/orders",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "order_details",
						component: OrderDetails,
						path: "/orders/:order",
						options: {
							headerShown: true,
						},
						hidden: true,
					},
				],
			},
			{
				name: "Inbound & Sortation",
				icon: Input,
				path: "/assets",
				routes: [
					{
						name: "inbound",
						component: Inbound,
						path: "/assets/inbound",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
					{
						name: "sortation",
						component: Sortation,
						path: "/assets/sortation",
						options: {
							headerShown: true,
						},
						hidden: false,
					},
				],
			},
		],
	},
	{
		name: "processes",
		accordions: [
			{
				name: "map",
				icon: Map,
				routes: [
					{
						name: "map",
						component: OrdersMap,
						path: "/map",
						options: {
							headerShown: false,
						},
						hidden: false,
					},
					{
						name: "Density Map",
						component: DensityMap,
						path: "/density-map",
						options: {
							headerShown: false,
						},
						hidden: false,
					},
				],
			},
		],
	},
	{
		name: "Playground",
		accordions: [
			{
				name: "playground",
				icon: Speed,
				routes: [
					{
						name: "Playground",
						component: Playground,
						path: "/playground",
						options: {
							headerShown: false,
						},
						hidden: false,
					},
				],
			},
		],
	},
];

export default routes;
