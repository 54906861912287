import React from "react";
import cn from "classnames";
import DriverCard from "../../../components/DriverCard";
import { useTranslation } from "react-i18next";

function SelectedDriverList({ selectedDriver, selectedRef, handleClick }) {
  const { t } = useTranslation("drivers");
  return (
    <div className={cn({ "border-b border-gray-500": selectedDriver != null })}>
      <h3 className={cn({ hidden: !selectedDriver?.id })}>Selected Drivers</h3>

      {selectedDriver?.id && (
        <DriverCard
          selectedRef={selectedRef}
          key={selectedDriver?.id}
          data={selectedDriver}
          onClick={handleClick}
          selectedDriverId={selectedDriver.id}
          inSelectedCards={true}
        />
      )}
    </div>
  );
}

export default React.memo(SelectedDriverList);
