import React, { useEffect, useState } from "react";
import _ from "lodash";
import CustomButton from "../../../components/common/Button";
import { GridFilterListIcon } from "@mui/x-data-grid";
import Modal from "../../../components/common/Modal";
import { CartIcon } from "../../../components/svg";
import { wmsOptions } from "../../../constants/apollo";
import { logger } from "../../../helper/logger";
import { GET_ALL_PRODUCTS } from "../../../api/queries/WMSqueries";
import { objFlatten } from "../../../helper/objFlatten";
import PaginatedTable from "../../../components/Table/paginatedTable";
import { useDebounce } from "../../../hooks/useDebounce";
import NewSearch from "../../../components/common/Search/new-search";
import CreateProductForm from "./CreateProductFrom";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import useWMSProducts from "./useProducts";
import { useSearchParams } from "react-router-dom";
import useLazyQuery from "hooks/useLazyQuery";
import moment from "moment";
import FiltersList from "../../../components/FiltersList";

const cols = [
  { name: "Product SKU", value: "sKU", canBeSorted: true },
  {
    name: "Product Name",
    value: "name",
    canBeSorted: true,
  },
  {
    name: "Product Type",
    type: "capitalize",
    value: "categoryType",
    canBeSorted: true,
    styles: "capitalize",
  },
  { name: "Weight", value: "weightInGrams", canBeSorted: true },
  { name: "Size", value: "size" },
  { name: "Created At", value: "createdAt", canBeSorted: true },
  {
    name: "Status",
    value: "status",
    type: "status",
    styles: "uppercase",
    // Cannot be easily sorted: "status" is not a field in db, "isActive" is
    canBeSorted: true,
  },
];

const filters = {
  category: "all",
  status: "all",
};

function Products() {
  const localStorageFilters = JSON.parse(
    window?.localStorage?.getItem("wmsProductFilters") || "{}"
  );
  const [searchParams, setSearchParams] = useSearchParams(
    localStorageFilters || {}
  );

  const [searchText, setSearchText] = useState(
    localStorageFilters?.searchText ||
      Object.fromEntries([...searchParams]).searchText ||
      ""
  );
  const debouncedSearchText = useDebounce(searchText, 500);

  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterByBin, setFilterByBin] = useState("");
  const [page, setPage] = useState(0);

  const [newPurchaseOrder, setNewPurchaseOrder] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [getProducts] = useLazyQuery(GET_ALL_PRODUCTS, wmsOptions);
  // const { data } = useQuery(GET_ALL_PRODUCTS, wmsOptions);
  const [products, setProducts] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
  });

  const [sortCol, setSortCol] = useState({
    label: "id",
    value: 2,
  });

  const [productFilters, setProductFilters] = useState({
    ...filters,
    ...Object.fromEntries([...searchParams]),
  });

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const { getOrderObj, getSortOrder, getFilterObj, getSearchObj } =
    useWMSProducts();

  const fetchProducts = (
    skip = 0,
    search = searchText,
    sortName = sortCol.label,
    sortOrder = getSortOrder(sortCol.value),
    filters = productFilters
  ): any => {
    let orderObj = getOrderObj(sortName, sortOrder);
    let searchObj = getSearchObj(search);
    let filterObj = getFilterObj(filters);

    getProducts({
      variables: {
        skip: skip,
        take: 10,
        ...searchObj,
        ...filterObj,
        ...orderObj,
      },
    }).then((res) => {
      logger("PRODUCTS FETCHED", res.data.products);

      setPaginationInfo((old) => ({
        ...old,
        hasNextPage: res.data.products.pageInfo.hasNextPage,
        hasPreviousPage: res.data.products.pageInfo.hasPreviousPage,
        totalCount: res.data.products.totalCount,
      }));
      const productList = res.data.products.items;

      let productListFlattened = productList.map((item: any) => {
        let size = "";
        if (item.widthInMm && item.heightInMm && item.lengthInMm) {
          size = `${item.widthInMm} x ${item.heightInMm} x ${item.lengthInMm}`;
        }
        let status;
        if (item.isActive) {
          status = "active";
        } else {
          status = "inactive";
        }

        let updateTime = "";
        if (item.createdAt) {
          updateTime = moment(item.createdAt).format("D-M-YYYY / HH:mm");
        }

        return objFlatten({
          ...item,
          status: status,
          size: size,
          createdAt: updateTime,
        });
      });

      setProducts(productListFlattened);
    });
  };
  const handleQueryChange = (obj: any) => {
    // updare router query based on implemented filters
    let mergedObj: any = { ...Object.fromEntries([...searchParams]), ...obj };
    let modified: any = {};

    Object.keys(mergedObj).map((key) => {
      if (mergedObj[key] != "all" && mergedObj[key] != "") {
        if (key in obj) {
          modified[key] = obj[key];
        } else {
          modified[key] = mergedObj[key];
        }
      }
    });

    setSearchParams(modified);
    localStorage.setItem("wmsProductFilters", JSON.stringify({ ...modified }));
  };

  useEffect(() => {
    handleSearch(searchText);
    handleQueryChange({ searchText: searchText });
  }, [debouncedSearchText]);

  function handleFilter() {
    handleQueryChange(productFilters);
    fetchProducts(
      0,
      searchText,
      sortCol.label,
      getSortOrder(sortCol.value),
      productFilters
    );
  }

  function handleFilterReset() {
    setProductFilters(filters);
    handleQueryChange(filters);
    fetchProducts(
      0,
      searchText,
      sortCol.label,
      getSortOrder(sortCol.value),
      filters
    );
    setFilterOpen(false);
  }

  function handleSearch(search: any) {
    fetchProducts(0, search, sortCol.label, getSortOrder(sortCol.value));
    setPage(0);
  }
  const handleSort = (sortVal = "") => {
    if (sortCol.label === sortVal) {
      // sort Desc
      const sortOrder = getSortOrder(sortCol.value + 1);
      sortCol.value += 1;

      fetchProducts(0, searchText, sortVal, sortOrder);
    } else {
      setSortCol((old: any) => ({
        ...old,
        label: sortVal,
        value: 0,
      }));
      fetchProducts(0, searchText, sortVal, "ASC");
    }

    setPage(0);
  };

  return (
    <>
      <div>
        <div className="main-wrapper flex justify-between space-x-3">
          <div className="w-[450px] flex space-x-3">
            <NewSearch
              hasValue={true}
              filterName="Search"
              infoText=""
              placeholder="Type here..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              handleClearSearch={() => setSearchText("")}
            />
            <div
              onClick={() => setFilterOpen(true)}
              className="flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg"
            >
              <GridFilterListIcon color="warning" />
              <span className="text-orange-500 font-medium uppercase ml-2">{`Filters By`}</span>
            </div>
          </div>

          <div className="flex space-x-3">
            <CustomButton
              variant="outlined"
              color="warning"
              onClick={() => setModalOpen(true)}
              style={{ border: "none" }}
            >
              <div className="flex items-center justify-center space-x-2">
                <CartIcon /> <span>Add A New Product</span>
              </div>
            </CustomButton>
          </div>
        </div>
        <PaginatedTable
          sortCol={sortCol}
          onSortClick={(sortVal) => {
            handleSort(sortVal);
          }}
          count={paginationInfo?.totalCount || 0}
          page={page}
          setPage={setPage}
          onPageChange={fetchProducts}
          hasCheckbox
          cols={cols}
          data={products}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          loading={false}
          filterByBin={filterByBin}
        />
      </div>
      <Modal
        onDismiss={() => {
          setModalOpen(false);
        }}
        hidden
        autoOpen={modalOpen}
        footer={null}
        onClose={() => {}}
        title={"Create a new product"}
      >
        <CreateProductForm
          handleClose={() => {
            setModalOpen(false);
            fetchProducts();
          }}
        />
      </Modal>

      <Modal
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        footer={(handleClose: any) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={handleFilter}
            handleReset={handleFilterReset}
          />
        )}
        onClose={() => {}}
        title={"Filter Products"}
      >
        <FiltersList
          selectedFilters={productFilters}
          filters={[
            {
              name: "Filter by Type",
              value: "category",
              id: 1,
              options: [
                { value: "all", label: "All" },
                { value: "ELECTRONICS", label: "Electronics" },
                { value: "CLOTHES", label: "Clothes" },
                { value: "GROCERIES", label: "Groceries" },
                { value: "PERFUME", label: "Perfume" },
                { value: "SIM_CARDS", label: "Sim Cards" },
              ],
            },
            {
              name: "Filter by Status",
              value: "status",
              id: 2,
              options: [
                { value: "all", label: "All" },
                { value: "true", label: "Active" },
                { value: "false", label: "Inactive" },
              ],
            },
          ]}
          handleChange={(filterVal: any, filterName: string) => {
            setProductFilters((old) => ({
              ...old,
              [filterName]: filterVal,
            }));
          }}
        />
      </Modal>
    </>
  );
}

export default Products;
