import { Avatar, Chip, Skeleton } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import _ from "lodash";
import React from "react";

export default function SortationMenu({
  loading,
  rows,
  bins,
  setFilterByBin,
  filterByBin,
  setSelectedRows,
}: {
  loading: boolean;
  rows: any[];
  bins: any;
  setFilterByBin: any;
  filterByBin: string;
  setSelectedRows: any;
}) {
  return (
    <div className="main-wrapper bg-gray-50 flex flex-wrap space-x-3">
      {loading ? (
        Array.from(Array(12).keys()).map((item, index) => (
          <Skeleton key={index} width={97} height={33} />
        ))
      ) : (
        <>
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: filterByBin.length ? "grey" : "#FF9800" }}>
                {rows.length > 100 ? "99+" : rows.length}
              </Avatar>
            }
            label="All"
            color={filterByBin.length ? "default" : "warning"}
            variant="outlined"
            onClick={() => {
              setFilterByBin("");
              setSelectedRows([])
            }}
          />
          {Object.keys(bins).map((item, index) => {
            if (item !== "null") {
              return (
                <Chip
                  key={index}
                  avatar={
                    <Avatar
                      sx={{ bgcolor: filterByBin == item ? "#FF9800" : "grey" }}
                      color={filterByBin == item ? "warning" : "default"}
                    >
                      {bins[item].length}
                    </Avatar>
                  }
                  label={item}
                  variant="outlined"
                  color={filterByBin == item ? "warning" : "default"}
                  onClick={() => {
                    setFilterByBin(item);
                    setSelectedRows(
                      rows
                        .filter((i) => i.sortingBin == item)
                        .map((i) => i.trackingNo)
                    );
                    
                  }}
                />
              );
            }
          })}
        </>
      )}
    </div>
  );
}
