import PaginatedTable from "../../../../components/Table/paginatedTable";
import React, { useEffect, useState } from "react";
import Modal from "../../../../components/common/Modal";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  ListItem,
  Chip,
  Grid,
  Alert,
} from "@mui/material";
import { SearchIcon } from "../../../../components/svg";
import { logger } from "helper/logger";
import { useLazyQuery, useMutation } from "@apollo/client";
import { wmsOptions } from "constants/apollo";
import { GET_SALE_ORDERS, GET_WMS_SHIPMENTS } from "api/queries/WMSqueries";
import useShipping from "./useShipping";
import { getSortationOrder } from "helper/GetSortOrder";
import { useDebounce } from "hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { objFlatten } from "helper/objFlatten";
import {
  CREATE_OR_UPDATE_SALEORDERS,
  CREATE_OR_UPDATE_WMS_SHIPMENT_STATUS,
} from "api/mutations/WMSMutations";

const cols = [
  {
    name: "Shipment ID",
    value: "id",
    canBeSorted: true,
  },
  {
    name: "Sale Order ID",
    value: "saleOrder.id",
    canBeSorted: true,
  },
  {
    name: "Tracking #",
    value: "trackingNo",
  },
  { name: "Created At", value: "createdAt", canBeSorted: true },

  {
    name: "Updated At",
    value: "updatedAt",
    canBeSorted: true,
  },
  { name: "Cart", value: "saleOrder.packingJob.cart.locationCode" },
  {
    name: "Status",
    value: "status",
    type: "status",
    styles: "uppercase",
    canBeSorted: true,
  },
];

export default function OnShipping ({ searchText, isFilterApplied, setIsFilterApplied, productFilters }: any) {
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);
  const [sortCol, setSortCol] = useState({
    label: "updatedAt",
    value: 1,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [refetchShippingOrders, { data, error }] = useLazyQuery(
    GET_WMS_SHIPMENTS,
    wmsOptions
  );

  const [updateShipmentStatusMutation] = useMutation(
    CREATE_OR_UPDATE_WMS_SHIPMENT_STATUS,
    wmsOptions
  );

  const { getOrderObj, getSearchObj, getFilterObj } = useShipping();

  const refetch = (
    skip = 0,
    search = searchText,
    sortName = sortCol.label,
    sortOrder = getSortationOrder(sortCol.value),
    filters = productFilters
  ): any => {
    let orderObj = getOrderObj(sortName, sortOrder);
    let searchObj = getSearchObj(search);
    let filterObj = getFilterObj(filters);

    refetchShippingOrders({
      variables: {
        skip: skip,
        ...searchObj,
        ...filterObj,
        ...orderObj,
      },
    });
  };

  const updateShipmentStatus = (shipmentId: any, newStatus: any, saleOrderStatus: any) => {
    updateShipmentStatusMutation({
      variables: {
        id: parseInt(shipmentId),
        shipmentStatus: newStatus,
        saleOrderStatus: saleOrderStatus
      },
    }).then(() => refetch())
  };

  function handleSearch (search: any) {
    refetch(0, search, sortCol.label, getSortationOrder(sortCol.value));
    setPage(0);
  }
  const handleSort = (sortVal = "") => {
    if (sortCol.label === sortVal) {
      // sort Desc
      const sortOrder = getSortationOrder(sortCol.value + 1);
      sortCol.value += 1;

      refetch(0, searchText, sortVal, sortOrder);
    } else {
      setSortCol((old: any) => ({
        ...old,
        label: sortVal,
        value: 0,
      }));
      refetch(0, searchText, sortVal, "ASC");
    }

    setPage(0);
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data) {
      setItemsCount(data?.shipments?.totalCount);
      logger("DATA HERE", data);

      const dataList = data?.shipments?.items;
      const dataListFlattened = dataList?.map((item: any) => {
        let time = "";
        if (item.updatedAt) {
          time = moment(item.updatedAt).format("D-M-YYYY / HH:mm");
        }
        let createTime = "";
        if (item.createdAt) {
          createTime = moment(item.createdAt).format(
            "D-M-YYYY / HH:mm"
          );
        }

        return objFlatten({
          ...item,
          updatedAt: time,
          createdAt: createTime,
        });
      });

      setTableData(dataListFlattened);
    }
  }, [data]);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    if (isFilterApplied) {
      refetch(
        0,
        searchText,
        sortCol.label,
        getSortationOrder(sortCol.value),
        productFilters
      );
      setIsFilterApplied(false)
    } else {
      refetch();
    }
    // return () => setIsFilterApplied(false)

  }, [isFilterApplied, productFilters]);

  return (
    <>
      <div>
        <PaginatedTable
          // hasCheckbox
          sortCol={sortCol}
          count={itemsCount}
          page={page}
          setPage={setPage}
          onPageChange={refetch}
          cols={cols}
          data={
            tableData || [
              {
                id: "SA-3650",
                tracking: "DK-505728848-970",
                date: "08/30/2022 18:45",
                cart: "CRT-10001",
                status: "pending",
              },
            ]
          }
          filterByBin={() => { }}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          loading={false}
          onSortClick={(sortVal) => {
            handleSort(sortVal);
          }}
          canBeEdited

          editMenuItems={[
            {
              actionName: "Shipping",
              action: (item: any) => {
                updateShipmentStatus(item.id, "SHIPPING", "ON_SHIPPING");
              },
              isDisabled: false,
              type: "complexStatus",
              chechIsDisabled: (item: any) => {
                return item.status !== "READY_FOR_SHIPPING"
              },
            },
            {
              actionName: "Shipped",
              action: (item: any) => {
                updateShipmentStatus(item.id, "SHIPPED", "SHIPPED");
              },
              isDisabled: false,
              chechIsDisabled: (item: any) => {
                return item.status !== "SHIPPING"
              },
              type: "complexStatus",
            },
            {
              actionName: "Cancel",
              action: (item: any) => {
                updateShipmentStatus(item.id, "CANCELLED", "CANCELLATION");
                refetch();
              },
              isDisabled: false,
              chechIsDisabled: (item: any) => {
                return item.status == "CANCELLED"
              },
              type: "complexStatus",
            },
            // {
            //   actionName: "Re-Stock",
            //   action: (item: any) => {
            //     updateShipmentStatus(item.id, "PENDING");
            //     refetch();
            //   },
            //   isDisabled: false,
            //   type: "purchaseOrder",
            // },
          ]}
        />
      </div>

      <Modal
        footer={() => { }}
        onClose={() => {
          setModalOpen(false);
        }}
        hidden
        onDismiss={() => {
          setModalOpen(false);
        }}
        color="primary"
        autoOpen={modalOpen}
        maxWidth="xs"
      >
        <ModalContent />
      </Modal>
    </>
  );
}

function ModalFooter ({ close, assign }: any) {
  return (
    <Box sx={{ width: "100%" }}>
      <Divider />

      <div
        style={{
          marginTop: 20,
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Button
          onClick={() => close()}
          variant='outlined'
          color='primary'
        >
          Cancel
        </Button>
        <Button
          onClick={() => assign()}
          sx={{ marginLeft: 2 }}
          variant='contained'
          color='primary'
        >
          <span className='text-white'>Assign</span>
        </Button>
      </div>
    </Box>
  );
}

function ModalContent () {
  const [isActionComplete, setIsActionComplete] = useState(false);

  const [checked, setChecked] = React.useState([0]);
  const [selected, setSelected] = useState("available");

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <TextField
        fullWidth
        title='Search employee'
        placeholder='Type here...'
        variant='outlined'
        label='Scan or search cart barcodes'
        size='small'
        sx={{ marginY: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={2}>
        <Grid item>
          <Chip
            id={"0"}
            color={selected === "all" ? "primary" : "default"}
            label='All'
            variant='filled'
            onClick={() => setSelected("all")}
          />
        </Grid>
        <Grid item>
          <Chip
            id={"0"}
            color={selected !== "all" ? "primary" : "default"}
            label='Available'
            onClick={() => setSelected("available")}
          />
        </Grid>
      </Grid>

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        {[0, 1, 2, 3].map((value, index) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <>
              {index !== 0 && <Divider />}

              <ListItem
                sx={{
                  width: "100%",
                  bgcolor:
                    checked.indexOf(value) !== -1
                      ? "#FF980014"
                      : "background.paper",
                }}
                key={value}
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value)}
                  dense
                  sx={{
                    width: "100%",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={
                        checked.indexOf(value) !== -1
                      }
                      color='warning'
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`Line item ${value + 1}`}
                  />
                  <Chip
                    sx={{
                      textAlign: "right",
                      backgroundColor: "#4CAF5014",
                      border: "none",
                      color: "#4CAF50",
                    }}
                    id={labelId}
                    color='success'
                    label='Available'
                    variant='outlined'
                    size='small'
                  />
                </ListItemButton>
              </ListItem>
            </>
          );
        })}
      </List>

      <Alert variant='standard' severity='warning'>
        <span>
          You have more than <BoldText>30</BoldText> items. You need{" "}
          <BoldText>1 more</BoldText> employee
        </span>
      </Alert>
      <ModalFooter
        assign={() => {
          setIsActionComplete(true);
        }}
        close={() => { }}
      />
    </>
  );
}
function FeedbackModal () {
  const [checked, setChecked] = React.useState([0]);

  return <>Assigning 5 picklists</>;
}

function BoldText ({ children }: any) {
  return <span style={{ fontWeight: "bold" }}>{children}</span>;
}
