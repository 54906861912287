export default function usePicking() {
	const getOrderObj = (sortName, sortOrder) => {
		if (sortName !== "" && sortOrder != "DEFAULT") {
			switch (sortName) {
				case "id":
					return {
						order: {
							id: sortOrder,
						},
					};

				case "createdAt":
					return {
						order: {
							createdAt: sortOrder,
						},
					};
				case "updatedAt":
					return {
						order: {
							updatedAt: sortOrder,
						},
					};
				case "saleOrderItem.id":
					return {
						order: {
							saleOrderItemId: sortOrder,
						},
					};
				case "cart.referenceName":
					return {
						order: {
							cart: {
								referenceName: sortOrder,
							},
						},
					};
				case "saleOrderItem.saleOrder.merchant.name":
					return {
						order: {
							saleOrder: {
								merchant: {
									referenceName: sortOrder,
								},
							},
						},
					};
				case "saleOrderItem.saleOrder.id":
					return {
						order: {
							saleOrderItem: {
								saleOrder: {
									id: sortOrder,
								},
							},
						},
					};
				case "saleOrderItem.product.name":
					return {
						order: {
							saleOrderItem: {
								product: {
									name: sortOrder,
								},
							},
						},
					};
				case "serialsLength":
					return {
						order: {
							saleOrderItem: {
								quantity: sortOrder,
							},
						},
					};
				case "status": {
					return {
						order: {
							status: sortOrder,
						},
					};
				}
			}
		} else {
			return {};
		}
	};

	const getFilterObj = (filters, status) => {
		let filterList = [];

		// if (filters?.productId != "all") {
		//     filterList.push({
		//         saleOrderItems: {
		//             product: {
		//                 id: {
		//                     eq: parseInt(filters.productId),
		//                 }
		//             }
		//         },
		//     });
		// }

		if (filters?.merchantId && filters?.merchantId != "all") {
			filterList.push({
				saleOrderItem: {
					saleOrder: {
						merchantId: {
							eq: parseInt(filters.merchantId),
						},
					},
				},
			});
		}

		// if (filters?.locationId != "all") {
		//     filterList.push({
		//         locationId: {
		//             eq: parseInt(filters.locationId),
		//         },
		//     });
		// }
		// if (filters?.locationType != "all") {
		//     filterList.push({
		//         location: {
		//             type:
		//             {
		//                 eq: filters.locationType,
		//             }
		//         }
		//     });
		// }

		if (filters?.status != "all" && filters?.status) {
			filterList.push({
				status: {
					eq: filters.status,
				},
			});
		}

		return {
			where: {
				and: [...filterList],
			},
		};
	};

	const getSearchObj = (search = "") => {
		let searchRes = {};
		searchRes.query = search;
		return searchRes;
	};
	return {
		getOrderObj,
		getFilterObj,
		getSearchObj,
	};
}
