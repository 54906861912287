import { useLazyQuery, useQuery } from "@apollo/client";
import React from "react";
import {
	FETCH_WAREHOUSES,
	FETCH_WAREHOUSES_SHIPMENTS,
} from "../../api/queries/Queries";
import SortationFilters from "./components/SortationFilters";
import SortationMenu from "./components/SortationMenu";
import SortationTable from "./components/SortationTable";
import _ from "lodash";
import { toast } from "react-toastify";

export default function Sortation() {
	const [barcode, setBarcode] = React.useState();
	const [rows, setRows] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState(true);
	const [filterByBin, setFilterByBin] = React.useState("");
	const [searchStr, setSearchStr] = React.useState<any[]>([]);

	const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
	const [options, setOptions] = React.useState({});
	const { data: warehouses, error: warehousesError } = useQuery(
		FETCH_WAREHOUSES,
		{
			context: { clientName: "staging" },
			errorPolicy: "all",
		}
	);
	const [reload, setReload] = React.useState(0);
	const [fetchWarehouseShipments] = useLazyQuery(FETCH_WAREHOUSES_SHIPMENTS, {
		context: { clientName: "staging" },
	});

	React.useEffect(() => {
		fetchShipments();
	}, [warehouses, reload]);

	const bins = React.useMemo(() => {
		return _.groupBy(rows, (e) => e.sortingBin);
	}, [rows]);

	const fetchShipments = async () => {
		let shipments = await fetchWarehouseShipments().finally(() => {
			setLoading(false);
		});
		if (shipments.data.fetchWarehouseShipments.length) {
			setRows(shipments.data.fetchWarehouseShipments);
		}
	};

	const onPressCheckbox = (id?: string) => {
		if (!id) {
			if (selectedRows.length === rows.length) {
				setSelectedRows([]);
				return;
			} else {
				setSelectedRows(rows.map((item) => item.trackingNo));
				return;
			}
		}

		const item = selectedRows?.find((item) => item === id);

		if (item) {
			setSelectedRows(selectedRows.filter((i) => i !== item));
		} else {
			selectedRows.push(id);
			setSelectedRows([...selectedRows]);
		}
	};

	const onScanShipment = (id?: string) => {
		if (!id) return;
		id = id.trim();
		let array = id?.split(",").map((i) => i.trim());
		const items = rows.filter((item) => array.includes(item.trackingNo));
		console.log(items);
		if (!items.length) return;

		items.forEach((item) => onPressCheckbox(item?.trackingNo));
	};

	return (
		<div>
			<SortationMenu
				loading={loading}
				rows={rows}
				bins={bins}
				filterByBin={filterByBin}
				setFilterByBin={setFilterByBin}
				setSelectedRows={setSelectedRows}
			/>
			<SortationFilters
				selectedRows={selectedRows}
				bins={bins}
				onScanShipment={onScanShipment}
				reload={setReload}
				setSearchStr={setSearchStr}
			/>
			<SortationTable
				data={rows}
				onPressCheckbox={onPressCheckbox}
				selectedRows={selectedRows}
				loading={loading}
				filterByBin={filterByBin}
				searchStr={searchStr}
			/>
		</div>
	);
}
