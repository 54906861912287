import { Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import "leaflet/dist/leaflet.css";
import classNames from "classnames";
import { LeafletDriverIcon, LeafletShipmentLocationIcon } from "../../../components/common/leaflet-icons";
import { useMapEvents } from "react-leaflet";
import { CustomTooltip } from "../../../components/common/Tooltip";
import polyline from "@mapbox/polyline";

import L from "../../../helper/CustomLeaflet";

import Route from "../../../services/Route";
import { routeColors } from "../../../components/colors";
import { logger } from "../../../helper/logger";

export default function OrderMap ({ data }) {
	// const {
	// 	map,
	// 	MapControls,
	// } = useOrderMap();
	const [map, setMap] = useState(null);



	const showMarkers = () => {
		map?.setView(
			[
				data?.origin?.latitude,
				data?.origin?.longitude,
			],
			11
		);
		let driverData = {}
		if (data?.shipment?.courier?.latitude) {

			driverData = {
				id: Number(1),
				currentLocation: {
					latitude: data?.shipment?.courier?.latitude,
					longitude: data?.shipment?.courier?.longitude,
				},
			};
			let driver = data.shipment.courier
			L.marker([data.shipment.courier.latitude, data.shipment.courier.longitude], {
				icon: LeafletDriverIcon,
			})
				.addTo(map)


		} else {
			driverData = {
				id: Number(1),
				currentLocation: {
					latitude: data?.destination?.latitude,
					longitude: data?.destination?.longitude,
				},
			};
		}

		const newShipmentObj = [
			{
				id: Number(1),
				latitude: data?.origin?.latitude,
				longitude: data?.origin?.longitude,
				promise: 0, // idk what it is
				dropoffs: [
					{
						latitude: data?.destination?.latitude,
						longitude: data?.destination?.longitude,
					},
				],
			},
		];

		let datas = {
			driver: driverData,
			shipments: newShipmentObj,
			options: {
				g: true,
			},
		};


		Route.optimizeRoute(datas).then((res) => {

			if (res?.length > 0) {
				let bounds = L.latLngBounds();
				res?.forEach((route, index) => {
					if (route.type !== "end" && route.type !== "start") {
						// add route's markers to the map
						addRouteMarker(
							route,
							routeColors[0],
							route.type === "completed"
						); // adds start/pickup/delivery markers
					}
					if (
						route.type == "start" &&
						driverData.currentLocation.latitude !== newShipmentObj[0].dropoffs[0].latitude &&
						driverData.currentLocation.longitude !== newShipmentObj[0].dropoffs[0].longitude
					) {
						addRouteMarker(
							route,
							routeColors[0],
							route.type === "completed"
						); // adds start/pickup/delivery markers
					}
					let polylineRes = route?.geometry;

					let lineOpacity = "1";

					if (
						polylineRes &&
						index !== res.length - 2 &&
						route.type !== "end"
					) {
						// shipment not started but is assigned: add driver->pickup dash route && opacity: 0.5

						let newRoute = L.polyline(
							polyline.decode(polylineRes)
						).addTo(map);
						bounds.extend(newRoute.getBounds());

						// Add dashed route if not completed
						if (route.type != "completed") {
							let dashRoute = L.polyline(
								polyline.decode(polylineRes)
							).addTo(map);
							dashRoute.setStyle({
								color: "white",
								weight: 1,
								dashArray: "6, 6",
								dashOffset: "2",
							});

							// dashRoute.id = shipmentList[0].id;
						}
						// Make opacity less if not completed route
						if (route.type != "completed") {
							lineOpacity = "0.5";
						}
						newRoute.setStyle({
							color: routeColors[0],
							opacity: lineOpacity,
							weight: 9,
						});
					}
				});
				map.fitBounds(bounds);
			}

		});
	};

	const addRouteMarker = (marker, markerouteColor, isCompleted) => {
		let pickupMarker = window.L.marker(
			[marker.latitude, marker.longitude],
			{
				icon: LeafletShipmentLocationIcon,
			}
		).addTo(map);

		// bind label to marker
		pickupMarker.bindTooltip(
			CustomTooltip(
				marker?.type,
				marker?.sequence,
				isCompleted ? "finished" : "notFinished",
				markerouteColor
			),
			{
				permanent: true,
				direction: "center",
				offset: [13, -18],
				class: "rounded-full",
				style: "background: transparent",
			}
		);
		// assign id to differentiate from other markers
		pickupMarker.id = marker.id;
	};

	useEffect(() => {
		if (map) {
			L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
				maxZoom: 20,
				subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
			}).addTo(map);
		}

		if (data?.origin && data?.destination && map) {
			showMarkers();
		}
	}, [data]);

	return (
		<div
			className={classNames(
				"w-full h-full bg-white border border-transparent overflow-hidden rounded-md",
				// {
				// 	"hidden md:block": showMap,
				// }
			)}
		> <MapContainer
			center={[24.719127, 46.669663]} // Riyadh
			zoom={10}
			zoomControl={true}
			style={{ height: "100%", position: "relative" }}
			tap={true}
			attributionControl={true}
			dragging={true}
		>
				{/* <div className="absolute top-3 left-1 bg-white p-2 rounded-md z-[400]">{data?.neighborhood}</div> */}
				{/* <MapControls /> */}
				<Checker setMap={setMap} />

			</MapContainer>
		</div>
	);
}
const Checker = ({ setMap }) => {
	const map = useMapEvents({});
	useEffect(() => {

		setMap(map);
	}, [])

	return <></>;
};