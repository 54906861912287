import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { logger } from "../../helper/logger";
import { DefaultWarehouseIcon } from "../svg";
import { bgcolor } from "@mui/system";
import { backgroundColors, lighBackgroundColors, textColors } from "../colors";

let exampleData = [
  [
    {
      name: "josh",
      age: 12,
    },
  ],
];
export default function CustomTable({
  hasCheckbox = false,
  canBeEdited = false,
  data,
  onPressCheckbox,
  selectedRows,
  loading,
  filterByBin,
  cols,
}) {
  const { t } = useTranslation("common");

  const TableItem = ({ row, cols }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (id) => {
      onPressCheckbox(id);
      setAnchorEl(null);
    };

    const selected = !!selectedRows?.find((item) => item === row.trackingNo);

    return (
      <>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          {hasCheckbox && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={selected}
                onChange={(event, checked) => onPressCheckbox(row.trackingNo)}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </TableCell>
          )}
          {cols.map((col, idx) =>
            <CustomTableCell key={idx} item={row} col={col} />
          )}

          {canBeEdited && (
            <TableCell>
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    key={"remove"}
                    onClick={() => { }}
                    disabled={!selected}
                  >
                    Remove
                  </MenuItem>
                </Menu>
              </div>
            </TableCell>
          )}
        </TableRow>
      </>
    );
  };

  const LoadingItem = ({ index }) => {
    return (
      <TableRow
        key={index}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell width={95} height={15}>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  };

  const CustomTableCell = ({ item, col }) => {

    if (col?.type == "money") return (
      <TableCell component="th" scope="row">
        <span className={`text[14px] ${col?.styles}`}>
          {item[col?.value]?.toFixed(2) || 0} {' SAR'}
        </span>
      </TableCell>
    )
    if (col?.type == "status") {
      let color = 'default'
      if (item[col?.value] == 'active')
        color = "success"

      return (
        <TableCell component="th" scope="row">
          <span className={`${textColors[color]} ${lighBackgroundColors[color]} p-1 px-2 rounded-full ${col?.styles}`}>
            {item[col?.value]}
          </span>
        </TableCell>
      )
    }

    if (col?.type == "distance") return (
      <TableCell component="th" scope="row">
        <span className={`text[14px] ${col?.styles}`}>
          {item[col.value] || 0} {' km'}
        </span>
      </TableCell>
    )

    if (col?.type == "warehouse") return (
      <TableCell component="th" scope="row">
        <span className={`text[14px] flex items-center ${col?.styles}`}>
          <DefaultWarehouseIcon />{item[col.value] || "N/A"}
        </span>
      </TableCell>
    )
    if (col?.type == "orders") return (
      <TableCell component="th" scope="row">
        <div className={`text[14px] flex items-center space-x-1 ${col?.styles}`}>
          <span className="text-warningColor">{item[col.value][0] || 0}</span>
          <span>/</span>
          <span className="text-successColor">{item[col.value][1] || 0}</span>
          <span>/</span>
          <span className="">{item[col.value][2] || 0}</span>
        </div>
      </TableCell>
    )
    if (col?.type == "name") {
      logger('col', item, col, "item", item[col.value])
      let name = item[col.value]?.courier?.firstName
      if (name) name = name[0].toUpperCase() + name.substring(1).toLowerCase();
      const lastName = item[col.value]?.courier?.lastName
      if (name && lastName) name += ` ${lastName[0].toUpperCase()}`

      return (
        <TableCell component="th" scope="row">
          <span className={`text[14px] ${col?.styles}`}>
            {name || 'N/A'}
          </span>
        </TableCell>
      )
    }

    return (
      <TableCell component="th" scope="row">
        <span className={`text[14px] ${col?.styles}`}>
          {item[col?.value] || "N/A"}
        </span>
      </TableCell>
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow >
            {hasCheckbox && (
              <TableCell padding="checkbox" key={"001"}>
                <Checkbox
                  color="primary"
                  checked={selectedRows.length === data.length}
                  onChange={(event, checked) => onPressCheckbox()}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
            )}
            {cols.map((col) => (
              <TableCell key={col.value}>
                <p className="font-bold">{col.name}</p>
              </TableCell>
            ))}

            {canBeEdited && (
              <TableCell>
                <p className="font-bold"></p>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {data?.map((row, index) => (
              <TableItem key={index} row={row} cols={cols} />
            ))}

            {loading &&
              Array.from(Array(10).keys()).map((item, index) => (
                <LoadingItem key={index} index={index} />
              ))}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

