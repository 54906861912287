import { Box } from "@mui/material";
import OrderStarted from "../../../assets/icons/OrderStarted";
import classNames from "classnames";
import HistoryIcon from "@mui/icons-material/History";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MapIcon from "@mui/icons-material/Map";
import cn from "classnames";
import moment from "moment";
import "./OrderDetails.scss";
import React from "react";

export default function OrderDetails({
	showMap,
	setShowMap,
	data,
}: {
	showMap: any;
	setShowMap: any;
	data: any;
}) {
	return (
		<section className='md:w-2/5'>
			<Box className='bg-white m-2 px-8 py-4 rounded'>
				<strong>Order details</strong>
				<div className='mt-10'>
					<Stepper status={data?.data?.orderTracking.orderStatus} />
					<div className='text-sm mt-10'>
						<p>
							<span className='text-gray-500'>Merchant:</span>
							<span>
								{" "}
								{data?.data?.orderTracking.merchantName}
							</span>
						</p>
						<p>
							<span className='text-gray-500'>Tracking No.:</span>
							<span>
								{" "}
								{data?.data?.orderTracking.trackingNo}{" "}
							</span>
						</p>
						<p>
							<span className='text-gray-500'>Status:</span>
							<span className='capitalize'>
								{" "}
								{data?.data?.orderTracking.orderStatus
									? data?.data?.orderTracking.orderStatus
											.split("_")
											.join(" ")
									: ""}
							</span>
						</p>
						<button
							onClick={() => setShowMap(!showMap)}
							className='md:hidden py-2 flex w-full'
						>
							{!showMap ? (
								<HistoryIcon
									fontSize='small'
									className='mr-2'
								/>
							) : (
								<MapIcon fontSize='small' className='mr-2' />
							)}
							<p className='mr-auto'>
								{!showMap ? "Order history" : "On Map"}
							</p>
							<ArrowDropDownIcon />
						</button>
					</div>
				</div>
			</Box>
			<Box
				className={classNames("bg-white m-2 px-8 py-4 rounded", {
					"hidden md:block": !showMap,
				})}
			>
				<strong>Order history</strong>
				<OrderHistory data={data} />
			</Box>
		</section>
	);
}

const OrderHistory = (data: any) => {
	return (
		<div className='mt-5'>
			<ol className='vertical-stepper'>
				{data?.data?.data?.publicFetchOrder?.orderLogs?.map(
					(
						{
							statusHumanReadable: text,
							status,
							createdAt: time,
						}: {
							statusHumanReadable: string;
							status: string;
							createdAt: Date;
						},
						index: number
					) => {
						return (
							<li
								key={index}
								className={cn(
									"vertical-stepper-item",
									{
										completed: status === "completed",
										in_progress: status === "in_progress",
										not_started: status === "not_started",
									},
									status
								)}
							>
								<div className='vertical-stepper-item-styles'>
									<span className='vertical-stepper-items-line'></span>
									<span className='vertical-stepper-items-circle'></span>
									<span className='vertical-stepper-items-line'></span>
								</div>
								<div className='vertical-stepper-content'>
									<time>
										{moment(time).format(
											"MMMM Do YYYY, h:mm a"
										)}
									</time>
									<p>{text}</p>
								</div>
							</li>
						);
					}
				)}
			</ol>
		</div>
	);
};

const Stepper = ({ status }: { status: string }) => {
	console.log(status);

	const steps = [
		{
			title: "Merchant is",
			text: "processing your order",
			icon: <OrderStarted />,
			animatedIcon: (
				<img src={require("../../../assets/gifs/OrderStarted.gif")} />
			),
			status: [
				"new_order",
				"processing",
				"pickup_failed",
				"processing_at_warehouse",
				"in_warehouse",
				"misrouted",
			].includes(status)
				? "in_progress"
				: "completed",
		},
		{
			title: "Our knight is",
			text: "on his way to pick up",
			icon: (
				<img
					src={require("../../../assets/icons/OrderInProgress.png")}
				/>
			),
			animatedIcon: (
				<img
					src={require("../../../assets/gifs/OrderInProgress.gif")}
				/>
			),
			status: [
				"new_order",
				"processing",
				"pickup_failed",
				"processing_at_warehouse",
				"in_warehouse",
				"misrouted",
			].includes(status)
				? "not_started"
				: ["ready_for_delivery"].includes(status)
				? "in_progress"
				: "completed",
		},
		{
			title: "Our Knight is",
			text: "on his way for delivery",
			icon: <img src={require("../../../assets/icons/OrderInWay.png")} />,
			animatedIcon: (
				<img src={require("../../../assets/gifs/OrderInWay.gif")} />
			),
			status: ["new_order", "processing", "ready_for_delivery"].includes(
				status
			)
				? "not_started"
				: ["picked_up"].includes(status)
				? "in_progress"
				: "completed",
		},
		{
			title: "Delivered",
			text: "Successfully",
			icon: (
				<img
					src={require("../../../assets/icons/OrderCompleted.png")}
				/>
			),
			animatedIcon: (
				<img src={require("../../../assets/gifs/OrderCompleted.gif")} />
			),
			status: [
				"new_order",
				"processing",
				"ready_for_delivery",
				"picked_up",
			].includes(status)
				? "not_started"
				: ["completed"].includes(status)
				? "in_progress"
				: "completed",
		},
	];

	console.log(steps);

	return (
		<div>
			<ol className='stepper-list'>
				{steps.map(
					({ title, text, icon, status, animatedIcon }, index) => {
						return (
							<React.Fragment key={index}>
								{index !== 0 && (
									<li
										className={cn("line", {
											completed: status === "completed",
											in_progress:
												status === "in_progress",
											not_started:
												status === "not_started",
										})}
									></li>
								)}

								<li
									className={cn("stepper-item", {
										completed: status === "completed",
										in_progress: status === "in_progress",
										not_started: status === "not_started",
									})}
								>
									<div className='stepper-image-wrapper'>
										{status === "in_progress"
											? animatedIcon
											: icon}
									</div>
									<div className='stepper-content'>
										<h3 className='stepper-title'>
											{title}
										</h3>
										<p className='stepper-text'>{text}</p>
									</div>
								</li>
							</React.Fragment>
						);
					}
				)}
			</ol>
		</div>
	);
};
