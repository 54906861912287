export const removeFirstMatchingValueFromArray = (arr, val) => {

    let newArr = [...arr]

    for (let i = 0; i < newArr.length; i++) {
        console.log("i is", i)
        if (newArr[i] == val) {
            console.log('lll', newArr[i], newArr[newArr.length - 1])
            newArr[i] = newArr[newArr.length - 1]
            i -= 1
            newArr.pop()
            return { arr: newArr, isScanned: true }
        }
    }
    return { arr: newArr, isScanned: false }

}

export const removeAllMatchingValuesFromArray = (arr, val) => {

    let newArr = [...arr]
    let occurance = 0
    console.log("Scanning", newArr.length, newArr)
    for (let i = 0; i < newArr.length; i++) {
        console.log("i is", i)
        if (newArr[i] == val) {
            console.log('lll', newArr[i], newArr[newArr.length - 1])
            newArr[i] = newArr[newArr.length - 1]
            i -= 1
            newArr.pop()
            occurance += 1
        }
    }
    return { arr: newArr, count: occurance }

}

export const removeAllMatchingValuesInTwoArays = (arr, arr2) => {

    let newArr = [...arr]
    let newArr2 = [...arr2]
    let scannedList = []
    let occurance = 0
    console.log("Scanning", newArr.length, newArr)
    for (let i = 0; i < newArr.length; i++) {
        console.log("i is", i, newArr[i], newArr2.indexOf(parseInt(newArr[i])), newArr2.indexOf(newArr[i]))
        if (newArr2.indexOf(parseInt(newArr[i])) >= 0 || newArr2.indexOf(newArr[i]) >= 0 || newArr2.indexOf(newArr[i].toString()) >= 0) {
            let idx2 = newArr2.indexOf(newArr[i])
            console.log('lll', newArr[i], newArr[newArr.length - 1])
            scannedList.push(newArr[i])
            newArr[i] = newArr[newArr.length - 1]
            i -= 1
            newArr.pop()

            newArr2[idx2] = newArr2[newArr2.length - 1]
            newArr2.pop()

            occurance += 1
        }
    }
    return { arr: newArr, count: occurance, scanned: scannedList }

}

export const removeAllDuplicateMatchingValuesInTwoArrays = (arr, arr2) => {

    let newArr = [...arr]
    let newArr2 = [...arr2]
    let occurance = 0
    let scannedList = []
    console.log("Scanning", newArr.length, newArr)
    for (let i = 0; i < newArr.length; i++) {
        console.log("i is", i, newArr[i], newArr2, newArr2.indexOf(parseInt(newArr[i])), newArr2.indexOf(newArr[i]))

        if (newArr2.indexOf(parseInt(newArr[i])) >= 0 || newArr2.indexOf(newArr[i]) >= 0 || newArr2.indexOf(newArr[i].toString()) >= 0) {
            scannedList.push(newArr[i])
            console.log('lll', newArr[i], newArr[newArr.length - 1])
            newArr[i] = newArr[newArr.length - 1]
            i -= 1
            newArr.pop()
            occurance += 1
        }
    }
    return { arr: newArr, count: occurance, scanned: scannedList }

}