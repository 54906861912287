import { GridFilterListIcon } from "@mui/x-data-grid";
import { useDebounce } from "hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { deepOrange, orange } from "@mui/material/colors";

import {
	useMutation,
	useLazyQuery as apolloUseLazyQuery,
} from "@apollo/client";

import NewSearch from "../../../components/common/Search/new-search";
import Modal from "../../../components/common/Modal";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import ProductFilters from "../Products/ProductFilters";
import SaleOrders from "./SaleOrders";
import MuiFilterButton from "../../../components/common/FilterButton/MuiFilterButton";
import OnShipping from "./OnShipping";
import Shipped from "./Shipped";
import Picking from "./Picking";
import Packing from "./Packing";
import Cancellation from "./Cancellation";
import { logger } from "helper/logger";
import {
	WMS_FILTER_LOCATIONS,
	WMS_FILTER_MERCHANTS,
	WMS_FILTER_PRODUCTS,
} from "api/queries/WMSFilterQueries";
import { wmsOptions } from "constants/apollo";
import FiltersList from "../../../components/FiltersList";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel (props: TabPanelProps) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps (index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const filters = {
	0: {
		merchantId: "all",
		status: "all",
	},
	1: {
		merchantId: "all",
		status: "all",
	},
	2: {
		merchantId: "all",
		status: "all",
	},
	3: {
		status: "all",
	},
	4: {},
	5: {},
};

export default function OutboundPage () {
	const color = orange[500];

	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const [searchText, setSearchText] = useState(
		Object.fromEntries([...searchParams]).searchText || ""
	);
	const debouncedSearchText = useDebounce(searchText, 500);

	const [filterOpen, setFilterOpen] = useState(false);
	const params = useParams();
	const pages = [
		"sale_orders",
		"on_picking",
		"on_packing",
		"on_shipping",
		"cancellation",
		"restocking",
	];

	const [getProducts] = apolloUseLazyQuery(WMS_FILTER_PRODUCTS, wmsOptions);
	const [getLocations] = apolloUseLazyQuery(WMS_FILTER_LOCATIONS, wmsOptions);
	const [getMerchants] = apolloUseLazyQuery(WMS_FILTER_MERCHANTS, wmsOptions);

	const [tab, setTab] = useState(0);
	const [isFilterApplied, setIsFilterApplied] = useState(true);
	const [autoAssign, setAutoAssign] = useState(false);
	const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
	const [assignModalOpen, setAssignModalOpen] = useState(false);
	const [manualAssign, setManualAssign] = useState(false);

	const [productFilters, setProductFilters] = useState({
		...filters[tab as keyof typeof filterObjects],
		...Object.fromEntries([...searchParams]),
	});
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	React.useEffect(() => {
		if (params.pages) {
			let index = pages.findIndex((item) => item == params.pages);
			if (index != -1) {
				setTab(index);
			}
		}
	}, []);

	const handleQueryChange = (obj: any) => {
		// update router query based on implemented filters
		let mergedObj = { ...Object.fromEntries([...searchParams]), ...obj };
		let modified: any = {};

		Object.keys(mergedObj).map((key) => {
			if (mergedObj[key] != "all" && mergedObj[key] != "") {
				if (key in obj) {
					modified[key] = obj[key];
				} else {
					modified[key] = mergedObj[key];
				}
			}
		});

		setSearchParams(modified);
	};

	const fetch_merchants = ({ page = 1, searchQuery = "" } = {}) => {
		logger("fetch_merchants", page, searchQuery);

		let searchObj: any = { query: "" };

		if (searchQuery.trim() != "") {
			searchObj = {
				query: searchQuery,
			};
		}

		return getMerchants({
			variables: {
				where: {},
				skip: (page - 1) * 20,
				...searchObj,
			},
		}).then((res) => {
			return {
				options: res?.data?.merchants?.items,
				hasMore: res?.data?.merchants?.totalCount >= page * 20,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		});
	};

	const fetch_merchant = ({ id = "0" } = {}) => {
		logger("fetc_merchant only ", id);
		return getMerchants({
			variables: {
				where: { id: { eq: parseInt(id) } },
			},
		}).then((res) => {
			let data = res?.data?.merchants?.items[0];
			return data;
		});
	};

	const filterObjects = {
		0: [
			{
				name: "Filter by Merchant",
				value: "merchantId",
				id: 1,
				isAsync: true,
				isSearchable: true,
				getSelectedVal: (id: any) => {
					if (id && id != "all") {
						let sel = fetch_merchant({ id: id });
						return sel;
					} else return { value: "all", label: "All" };
				},
				loadMore: (searchQuery: any, page: any) =>
					fetch_merchants({ page: page, searchQuery: searchQuery }),
			},

			{
				name: "Filter by Status",
				value: "status",
				id: 2,
				options: [
					{ value: "all", label: "All" },
					{ value: "PENDING", label: "Pending" },
					{ value: "ON_PICKING", label: "On Picking" },
					{ value: "ON_PACKING", label: "On Packing" },
					{ value: "ON_SHIPPING", label: "On Shipping" },
					{ value: "SHIPPED", label: "Shipped" },
					{ value: "CANCELLATION", label: "Cancelled" },
				],
			},
		],
		1: [
			{
				name: "Filter by Merchant",
				value: "merchantId",
				id: 1,
				isAsync: true,
				isSearchable: true,
				getSelectedVal: (id: any) => {
					if (id && id != "all") {
						let sel = fetch_merchant({ id: id });
						return sel;
					} else return { value: "all", label: "All" };
				},
				loadMore: (searchQuery: any, page: any) =>
					fetch_merchants({ page: page, searchQuery: searchQuery }),
			},

			{
				name: "Filter by Status",
				value: "status",
				id: 2,
				options: [
					{ value: "all", label: "All" },
					{ value: "PENDING", label: "Pending" },
					{ value: "PICKING", label: "Picking" },
					{ value: "READY_FOR_PACKING", label: "Ready for packing" },
					{ value: "READY_FOR_PICKING", label: "Ready for picking" },
					{ value: "ASSIGNED", label: "Assigned" },
				],
			},
		],
		2: [
			{
				name: "Filter by Merchant",
				value: "merchantId",
				id: 1,
				isAsync: true,
				isSearchable: true,
				getSelectedVal: (id: any) => {
					if (id && id != "all") {
						let sel = fetch_merchant({ id: id });
						return sel;
					} else return { value: "all", label: "All" };
				},
				loadMore: (searchQuery: any, page: any) =>
					fetch_merchants({ page: page, searchQuery: searchQuery }),
			},

			{
				name: "Filter by Status",
				value: "status",
				id: 2,
				options: [
					{ value: "all", label: "All" },
					{ value: "PENDING", label: "Pending" },
					{ value: "PICKING", label: "Picking" },
					{ value: "PACKING", label: "Packing" },
					{
						value: "READY_FOR_SHIPPING",
						label: "Ready for shipping",
					},
					{ value: "ASSIGNED", label: "Assigned" },
				],
			},
		],
		3: [
			{
				name: "Filter by Status",
				value: "status",
				id: 2,
				options: [
					{ value: "all", label: "All" },
					{ value: "PENDING", label: "Pending" },
					{
						value: "READY_FOR_SHIPPING",
						label: "Ready for shipping",
					},
					{ value: "SHIPPING", label: "Shipping" },
					{ value: "SHIPPED", label: "Shipped" },
					{ value: "CANCELLED", label: "Cancelled" },
				],
			},
		],
		4: [],
	};
	const [elCount, setElCount] = useState({
		0: 0,
		1: 0,
		2: 0,
		3: 0,
	});

	function handleFilter () {
		handleQueryChange(productFilters);
		setIsFilterApplied(true);
	}
	function handleFilterReset () {
		handleQueryChange(filters[tab as keyof typeof filters]);
		setIsFilterApplied(true);
		setProductFilters(filters[tab as keyof typeof filters]);
		setFilterOpen(false);
	}

	useEffect(() => {
		handleQueryChange({ searchText: searchText });
	}, [debouncedSearchText]);

	useEffect(() => {
		setProductFilters(filters[tab as keyof typeof filters]);
		handleFilterReset()
		setSearchText("");
	}, [tab]);

	return (
		<>
			<div className='main-wrapper flex justify-between space-x-3 border-t-2 border-gray-50 border-b-2'>
				<div className='w-[450px] flex space-x-3'>
					<NewSearch
						hasValue={true}
						filterName='Search'
						infoText=''
						placeholder='Type here...'
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						handleClearSearch={() => setSearchText("")}
					/>
					{filterObjects[tab as keyof typeof filterObjects]?.length >
						0 ? (
						<div
							onClick={() => setFilterOpen(true)}
							className='flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg'
						>
							<GridFilterListIcon color='primary' />
							<span className='text-[#FF9800] font-medium uppercase ml-2 min-w-[100px]'>{`Filters By`}</span>
						</div>
					) : (
						<div className='min-w-[156px]'></div>
					)}
				</div>

				{tab === 1 && (
					<div className='flex space-x-3'>
						<MuiFilterButton
							onClick={(index) => {
								if (index == 1) setAutoAssign(true);
								else if (index == 0) setManualAssign(true);
							}}
							options={[
								"Assign Pickers Manually",
								"Auto Assign Pickers",
							]}
						/>
					</div>
				)}
			</div>

			<Box sx={{ width: "100%" }}>
				<Box
					sx={{
						borderBottom: 2,
						borderColor: "rgb(249 250 251)",
					}}
				>
					<Tabs
						value={tab}
						onChange={handleChange}
						aria-label='outbound tabs'
						variant='fullWidth'
					>
						<Tab
							onClick={() =>
								navigate("/wms/outbound/sale_orders")
							}
							label={
								<div>
									<span className='mr-4'>SALE ORDERS</span>
									{/* <Chip
										label='0'
										color='primary'
										sx={{
											"& .MuiChip-label": {
												color: "white",
											},
										}}
									/> */}
								</div>
							}
							{...a11yProps(0)}
						/>
						<Tab
							onClick={() => navigate("/wms/outbound/on_picking")}
							label={
								<div>
									<span className='mr-4'>ON PICKING</span>{" "}
									{/* <Chip
										label='0'
										color='primary'
										sx={{
											"& .MuiChip-label": {
												color: "white",
											},
										}}
									/> */}
								</div>
							}
							{...a11yProps(1)}
						/>
						<Tab
							onClick={() => navigate("/wms/outbound/on_packing")}
							label={
								<div>
									<span className='mr-4'>ON PACKING</span>{" "}
									{/* <Chip
										label='0'
										color='primary'
										sx={{
											"& .MuiChip-label": {
												color: "white",
											},
										}}
									/> */}
								</div>
							}
							{...a11yProps(2)}
						/>
						<Tab
							onClick={() =>
								navigate("/wms/outbound/on_shipping")
							}
							label={
								<div>
									<span className='mr-4'>SHIPMENTS</span>{" "}
									{/* <Chip
										label='0'
										color='primary'
										sx={{
											"& .MuiChip-label": {
												color: "white",
											},
										}}
									/> */}
								</div>
							}
							{...a11yProps(3)}
						/>
						<Tab
							onClick={() =>
								navigate("/wms/outbound/cancellation")
							}
							label={
								<div>
									<span className='mr-4'>CANCELLATION</span>{" "}
									{/* <Chip
										label='0'
										color='primary'
										sx={{
											"& .MuiChip-label": {
												color: "white",
											},
										}}
									/> */}
								</div>
							}
							{...a11yProps(4)}
						/>
						<Tab
							onClick={() => navigate("/wms/outbound/restocking")}
							label={
								<div>
									<span className='mr-4'>Restocking</span>{" "}
									{/* <Chip
										label='0'
										color='primary'
										sx={{
											"& .MuiChip-label": {
												color: "white",
											},
										}}
									/> */}
								</div>
							}
							{...a11yProps(5)}
						/>
					</Tabs>
				</Box>
				<TabPanel value={tab} index={0}>
					<SaleOrders
						isFilterApplied={isFilterApplied}
						setIsFilterApplied={setIsFilterApplied}
						searchText={debouncedSearchText}
						productFilters={productFilters}
					/>
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<Picking
						isFilterApplied={isFilterApplied}
						setIsFilterApplied={setIsFilterApplied}
						searchText={debouncedSearchText}
						autoAssign={autoAssign}
						setAutoAssign={setAutoAssign}
						setFeedbackModalOpen={setFeedbackModalOpen}
						feedbackModalOpen={feedbackModalOpen}
						manualAssign={manualAssign}
						setManualAssign={setManualAssign}
						setAssignModalOpen={setAssignModalOpen}
						assignModalOpen={assignModalOpen}
						setActiveTab={setTab}
						filters={filters[0]}
						productFilters={productFilters}
					/>
				</TabPanel>
				<TabPanel value={tab} index={2}>
					<Packing
						productFilters={productFilters}
						searchText={debouncedSearchText}
						setAssignModalOpen={setAssignModalOpen}
						assignModalOpen={assignModalOpen}
					/>
				</TabPanel>
				<TabPanel value={tab} index={3}>
					<OnShipping
						searchText={debouncedSearchText}
						isFilterApplied={isFilterApplied}
						setIsFilterApplied={setIsFilterApplied}
						filters={filters[0]}
						productFilters={productFilters}
					/>
				</TabPanel>
				<TabPanel value={tab} index={4}>
					<Cancellation />
				</TabPanel>
				<TabPanel value={tab} index={5}>
					<Shipped searchText={debouncedSearchText} />
				</TabPanel>
			</Box>

			<Modal
				onDismiss={() => {
					setFilterOpen(false);
				}}
				hidden
				autoOpen={filterOpen}
				footer={(handleClose: any) => (
					<ModalFooter
						handleClose={handleClose}
						handleSubmit={() => handleFilter()}
						handleReset={handleFilterReset}
						cancelButtonColor='warning'
						confirmButtonColor='warning'
						resetButtonColor='default'
					/>
				)}
				onClose={() => { }}
				title={"Filter Items"}
			>
				<FiltersList
					selectedFilters={productFilters}
					filters={filterObjects[tab as keyof typeof filterObjects]}
					handleChange={(filterVal: any, filterName: string) => {
						setProductFilters((old: any) => ({
							...old,
							[filterName]: filterVal,
						}));
					}}
				/>
			</Modal>
		</>
	);

	// return (
	// 	<>
	// 		<div className='main-wrapper flex justify-between space-x-3 border-t-2 border-gray-50 border-b-2'>
	// 			<div className='w-[450px] flex space-x-3'>
	// 				<NewSearch
	// 					hasValue={true}
	// 					filterName='Search'
	// 					infoText=''
	// 					placeholder='Type here...'
	// 					value={searchText}
	// 					onChange={(e) => setSearchText(e.target.value)}
	// 					handleClearSearch={() => setSearchText("")}
	// 				/>
	// 				<div
	// 					onClick={() => setFilterOpen(true)}
	// 					className='flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg'
	// 				>
	// 					<GridFilterListIcon color='primary' />
	// 					<span className='text-[#FF9800] font-medium uppercase ml-2 min-w-[100px]'>{`Filters By`}</span>
	// 				</div>
	// 			</div>

	// 			{tab === 0 && (
	// 				<div className='flex space-x-3'>
	// 					<MuiFilterButton
	// 						onClick={(index) => {
	// 							logger(index);
	// 							if (index == 1) setAutoAssign(true);
	// 							else if (index == 0) setManualAssign(true);
	// 						}}
	// 						options={[
	// 							"Assign Pickers Manually",
	// 							"Auto Assign Pickers",
	// 						]}
	// 					/>
	// 				</div>
	// 			)}
	// 		</div>

	// 		<Box sx={{ width: "100%" }}>
	// 			<Box
	// 				sx={{
	// 					borderBottom: 2,
	// 					borderColor: "rgb(249 250 251)",
	// 				}}
	// 			>
	// 				<Tabs
	// 					value={tab}
	// 					onChange={handleChange}
	// 					aria-label='outbound tabs'
	// 					variant='fullWidth'
	// 				>
	// 					<Tab
	// 						onClick={() =>
	// 							navigate("/wms/outbound/sale_orders")
	// 						}
	// 						label={
	// 							<div>
	// 								<span className='mr-4'>SALE ORDERS</span>
	// 								<Chip
	// 									label='0'
	// 									color='primary'
	// 									sx={{
	// 										"& .MuiChip-label": {
	// 											color: "white",
	// 										},
	// 									}}
	// 								/>
	// 							</div>
	// 						}
	// 						{...a11yProps(0)}
	// 					/>
	// 					<Tab
	// 						onClick={() => navigate("/wms/outbound/on_picking")}
	// 						label={
	// 							<div>
	// 								<span className='mr-4'>ON PICKING</span>{" "}
	// 								<Chip
	// 									label='0'
	// 									color='primary'
	// 									sx={{
	// 										"& .MuiChip-label": {
	// 											color: "white",
	// 										},
	// 									}}
	// 								/>
	// 							</div>
	// 						}
	// 						{...a11yProps(1)}
	// 					/>
	// 					<Tab
	// 						onClick={() => navigate("/wms/outbound/on_packing")}
	// 						label={
	// 							<div>
	// 								<span className='mr-4'>ON PACKING</span>{" "}
	// 								<Chip
	// 									label='0'
	// 									color='primary'
	// 									sx={{
	// 										"& .MuiChip-label": {
	// 											color: "white",
	// 										},
	// 									}}
	// 								/>
	// 							</div>
	// 						}
	// 						{...a11yProps(2)}
	// 					/>
	// 					<Tab
	// 						onClick={() =>
	// 							navigate("/wms/outbound/on_shipping")
	// 						}
	// 						label={
	// 							<div>
	// 								<span className='mr-4'>ON SHIPPING</span>{" "}
	// 								<Chip
	// 									label='0'
	// 									color='primary'
	// 									sx={{
	// 										"& .MuiChip-label": {
	// 											color: "white",
	// 										},
	// 									}}
	// 								/>
	// 							</div>
	// 						}
	// 						{...a11yProps(3)}
	// 					/>
	// 					<Tab
	// 						onClick={() => navigate("/wms/outbound/shipped")}
	// 						label={
	// 							<div>
	// 								<span className='mr-4'>SHIPPED</span>{" "}
	// 								<Chip
	// 									label='0'
	// 									color='primary'
	// 									sx={{
	// 										"& .MuiChip-label": {
	// 											color: "white",
	// 										},
	// 									}}
	// 								/>
	// 							</div>
	// 						}
	// 						{...a11yProps(4)}
	// 					/>
	// 					<Tab
	// 						onClick={() =>
	// 							navigate("/wms/outbound/cancellation")
	// 						}
	// 						label={
	// 							<div>
	// 								<span className='mr-4'>CANCELLATION</span>{" "}
	// 								<Chip
	// 									label='0'
	// 									color='primary'
	// 									sx={{
	// 										"& .MuiChip-label": {
	// 											color: "white",
	// 										},
	// 									}}
	// 								/>
	// 							</div>
	// 						}
	// 						{...a11yProps(5)}
	// 					/>
	// 				</Tabs>
	// 			</Box>
	// 			<TabPanel value={tab} index={0}>
	// 				<SaleOrders
	// 					setFeedbackModalOpen={setAutoAssign}
	// 					feedbackModalOpen={autoAssign}
	// 					modalOpen={manualAssign}
	// 					setModalOpen={setManualAssign}
	// 					setActiveTab={setTab}
	// 				/>
	// 			</TabPanel>
	// 			<TabPanel value={tab} index={1}>
	// 				<Picking />
	// 			</TabPanel>
	// 			<TabPanel value={tab} index={2}>
	// 				<Packing />
	// 			</TabPanel>
	// 			<TabPanel value={tab} index={3}>
	// 				<OnShipping />
	// 			</TabPanel>
	// 			<TabPanel value={tab} index={4}>
	// 				<Shipped />
	// 			</TabPanel>
	// 			<TabPanel value={tab} index={5}>
	// 				<Cancellation />
	// 			</TabPanel>
	// 		</Box>

	// 		<Modal
	// 			onDismiss={() => {
	// 				setFilterOpen(false);
	// 			}}
	// 			hidden
	// 			autoOpen={filterOpen}
	// 			footer={(handleClose: any) => (
	// 				<ModalFooter
	// 					handleClose={handleClose}
	// 					// handleSubmit={handleFilter}
	// 					// handleReset={handleFilterReset}
	// 					cancelButtonColor='warning'
	// 					confirmButtonColor='warning'
	// 					resetButtonColor='default'
	// 				/>
	// 			)}
	// 			onClose={() => {}}
	// 			title={"Filter Products"}
	// 		>
	// 			<FilterList
	// 				selectedFilters={{}}
	// 				filters={[
	// 					{
	// 						name: "Product name",
	// 						value: "productId",
	// 						id: 1,
	// 						options: [{ value: "SIM Card", label: "SIM Card" }],
	// 					},

	// 					{
	// 						name: "Date",
	// 						value: "merchantId",
	// 						id: 4,
	// 						options: [
	// 							{
	// 								value: "11-12-2022 – ..",
	// 								label: "11-12-2022 – ..",
	// 							},
	// 						],
	// 					},

	// 					{
	// 						name: "Status",
	// 						value: "locationId",
	// 						id: 3,
	// 						options: [
	// 							{
	// 								value: "Ready for packing",
	// 								label: "Ready for packing",
	// 							},
	// 						],
	// 					},
	// 					{
	// 						name: "Picker",
	// 						value: "locationType",
	// 						id: 4,
	// 						options: [
	// 							{
	// 								value: "Toby Schmeler",
	// 								label: "Toby Schmeler",
	// 							},
	// 						],
	// 					},
	// 				]}
	// 				handleChange={(filterVal: any, filterName: string) => {}}
	// 			/>
	// 		</Modal>
	// 	</>
	// );
}
