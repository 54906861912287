import React from "react";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import CustomModal from "../../../../components/common/Modal";
import CustomSearch from "../../../../components/common/Search";
import CustomButton from "../../../../components/common/Button";
import { options } from "../../../../constants/sortation";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { CartIcon } from "../../../../components/svg";
import MuiFilterButton from "../../../../components/common/FilterButton/MuiFilterButton";
import NewSearch from "../../../../components/common/Search/new-search";

export default function PurchaseOrderFilters({
  setFilterOpen,
  selectedRows,
  bins,
  reload,
  setNewPurchaseOrder,
  setSearch,
  search,
}: PurchaseOrderFiltersProps) {
  return (
    <>
      <div className="main-wrapper flex justify-between space-x-3">
        <div className="w-[450px] flex space-x-3">
          <NewSearch
            hasValue={true}
            value={search}
            filterName="Search"
            infoText=""
            placeholder="Type here..."
            onChange={(e: any) => setSearch(e.target.value)}
            handleClearSearch={() => setSearch("")}
          />
          <div
            onClick={() => setFilterOpen(true)}
            className="flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg"
          >
            <FilterListIcon color="warning" />
            <span className="text-orange-500 font-medium uppercase ml-2">{`Filters By`}</span>
          </div>
        </div>
        <div className="flex space-x-3">
          <CustomButton
            variant="outlined"
            color="warning"
            onClick={() => setNewPurchaseOrder(true)}
            style={{ display: "flex" }}
          >
            <CartIcon />{" "}
            <span className="text-orange-500 font-medium uppercase ml-2">
              new purchase order
            </span>
          </CustomButton>
        </div>
      </div>
    </>
  );
}

type PurchaseOrderFiltersProps = {
  selectedRows: any[];
  bins: any;
  reload: (n: number) => void;
  setNewPurchaseOrder: (arg0: any) => void;
  setSearch: (e: any) => void;
  setFilterOpen: (e: any) => void;
  search: string
};
