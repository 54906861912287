import React from "react";
import cn from "classnames";
import { SearchIcon } from "../../svg";

type SearchProps = {
  onChange: (value?: any) => void;
  filterName: string;
  infoText?: string;
  placeholder?: string;
  error?: string;
  beforeText?: string;
  defaultValue?: string;
};

export default function CustomSearch({
  filterName,
  onChange = () => {},
  infoText = "",
  error,
  placeholder = "",
  beforeText = "",
  defaultValue = "",
}: SearchProps) {
  return (
    <div className="flex-1 flex flex-col">
      <div
        className={cn(
          "relative  flex items-center justify-between min-w-64 border  rounded-lg pl-3 py-2 min-w-36",
          {
            "border-red-500": error?.length,
          }
        )}
      >
        <span className="text-gray-400 text-sm mr-3">{beforeText}</span>
        <input
          className="flex flex-1 ouline-none focus:outline-none"
          onChange={onChange}
          onKeyDown={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
        <div
          className={cn(
            "absolute w-auto h-4 bg-white -top-[11px] px-1 left-3 text-sm cursor-default select-none",
            [error?.length ? "text-red-500" : "text-gray-500"]
          )}
        >
          {filterName}
        </div>
        <span className="cursor-pointer mr-3">
          <SearchIcon />
        </span>
      </div>
      <span
        className={cn("text-[12px] ", [
          error?.length ? "text-red-500" : "text-mediumGray",
        ])}
      >
        {error?.length ? error : infoText}
      </span>
    </div>
  );
}
