import React, { useEffect, useState, useContext } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Table from "@mui/material/Table";
import { TablePagination } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CardGreenIcon, CardRedIcon, DefaultWarehouseIcon, SortIcon } from "../svg";
import { lighBackgroundColors, textColors } from "../colors";
import { logger } from "../../helper/logger";
import { getStatusColor } from "helper/getStatusColor";
import moment from "moment";

export default function PaginatedTable ({
	showPagination = true,
	hasCheckbox = false,
	canBeEdited = false,
	editMenuItems = [],
	data,
	selectedRows = [],
	setSelectedRows = (e) => { },
	loading: oldLoading,
	filterByBin,
	cols,
	count,
	page = 0,
	setPage = (e) => { },
	onPageChange,
	onSortClick = (e) => { },
	sortCol = { label: "", value: 0 },
	bgColor = "",
	tableHeadStyles = "",
}) {
	const [allSelected, setAllSelected] = useState(false);
	const handleAllSelect = (checked) => {
		if (checked) {
			// get ids of the current row elements
			let ids = [];
			data.map((item) => ids.push(parseInt(item.id)));
			const newSelected = [-10]; // -10 means all selected
			setAllSelected(true);
			setSelectedRows(ids);
			return;
		}
		setSelectedRows([]);
		setAllSelected(false);
	};

	function onPressCheckbox (productId) {
		const selectedIndex = selectedRows.indexOf(parseInt(productId));
		let newSelected = [...selectedRows];

		if (selectedIndex === -1) {
			newSelected = [...newSelected, parseInt(productId)];
		} else if (selectedIndex > 0) {
			newSelected = newSelected.splice(selectedIndex, 1);
		}

		setSelectedRows(newSelected);
	}

	const TableItem = ({ row, cols }) => {
		const [anchorEl, setAnchorEl] = React.useState(null);
		const open = Boolean(anchorEl);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = (id) => {
			// onPressCheckbox(id);
			setAnchorEl(null);
		};

		const selected = !!selectedRows?.find(
			(item) => item.toString() === row?.id
		);

		return (
			<>
				<TableRow
					sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
				>
					{hasCheckbox && (
						<TableCell padding='checkbox'>
							<Checkbox
								color='primary'
								checked={selected || allSelected}
								onChange={(event, checked) =>
									onPressCheckbox(row.id)
								}
								inputProps={{
									"aria-label": "select all desserts",
								}}
							/>
						</TableCell>
					)}
					{cols.map((col, idx) => (
						<CustomTableCell key={idx} item={row} col={col} />
					))}

					{canBeEdited && (
						<div>
							<TableCell>
								<div>
									<IconButton
										aria-label='more'
										id='long-button'
										aria-controls={
											open ? "long-menu" : undefined
										}
										aria-expanded={
											open ? "true" : undefined
										}
										aria-haspopup='true'
										onClick={handleClick}
									>
										<MoreVertIcon />
									</IconButton>
									<Menu
										id='long-menu'
										MenuListProps={{
											"aria-labelledby": "long-button",
										}}
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
									>
										{editMenuItems.map((item) => {
											if (item.type === "status") {
												return (
													<MenuItem
														key={item.actionName}
														onClick={() =>
															item.action(
																row.id,
																row.isActive
															)
														}
														disabled={
															item.isDisabled
														}
													>
														{row.isActive
															? "Set Inactive"
															: "Set Active"}
													</MenuItem>
												);
											}

											if (
												item.type === "purchaseOrder" &&
												(row.status === "CANCELLED" ||
													row.status === "PUT_AWAY")
											) {
												return (
													<MenuItem
														key={item.actionName}
														onClick={() =>
															item.action(row)
														}
														sx={item.sx || {}}
														disabled={true}
													>
														{item.actionName}
													</MenuItem>
												);
											}
											if (
												item.type === "complexStatus"

											) {
												return (
													<MenuItem
														key={item.actionName}
														onClick={() =>
															item.action(row)
														}
														sx={item.sx || {}}
														disabled={item.chechIsDisabled(row)}
													>
														{item.actionName}
													</MenuItem>
												);
											}


											return (
												<MenuItem
													key={item.actionName}
													onClick={() =>
														item.action(row)
													}
													sx={item.sx || {}}
													disabled={item.isDisabled || row?.status === item?.disableStatus}
												>
													{item.actionName}
												</MenuItem>
											);
										})}
									</Menu>
								</div>
							</TableCell>
						</div>
					)}
				</TableRow>
			</>
		);
	};

	const LoadingItem = ({ index }) => {
		return (
			<TableRow
				className='loading-item'
				key={index}
				sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			>
				<TableCell width={95} height={15}>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
			</TableRow>
		);
	};

	const CustomTableCell = ({ item, col }) => {
		if (col?.type == "defaultVal")
			return (
				<TableCell component='th' scope='row'>
					<span className={`text[14px] ${col?.styles}`}>
						{col?.defaultVal || "-"}
					</span>
				</TableCell>
			);
		if (col?.type == "number")
			return (
				<TableCell component='th' scope='row'>
					<span className={`text[14px] ${col?.styles}`}>
						{item[col?.value] || "0"}
					</span>
				</TableCell>
			);
		if (col?.type == "status") {
			let color = getStatusColor(item[col?.value]);

			return (
				<TableCell component='th' scope='row'>
					<span
						className={`${textColors[color]} ${lighBackgroundColors[color]} p-1 px-2 rounded-full ${col?.styles}`}
					>
						{item[col?.value]?.replaceAll("_", " ")}
					</span>
				</TableCell>
			);
		}
		if (col?.type == "price") {

			let color = item[col?.value] <= 0 && "text-red-500";
			let icon = item[col?.value] <= 0 ? <CardRedIcon /> : <CardGreenIcon />
			return (
				<TableCell component='th' scope='row'>
					<div
						className={`${color} flex items-center space-x-2`}
					>
						<span>{icon}</span>
						<span>{"SR " + `${item[col?.value]}`}</span>
					</div>
				</TableCell>
			);
		}
		if (col?.type == "customHTML") {

			return (
				<TableCell component='th' scope='row'>
					{/* <span
						className={`p-1 px-2 rounded-full ${col?.styles}`}
					> */}
					<div className={`${col?.styles}`} dangerouslySetInnerHTML={{ __html: item[col?.value] }} />
					{/* </span> */}
				</TableCell>
			);
		}
		if (col?.type == "locationType") {
			// 	let serials = "";
			// 	if (item[col?.value] > 0) {
			// 		const len = item[col?.value];
			// 		Array.from(Array(len).keys()).map(
			// 			(num) =>
			// 			(serials +=
			// 				item[`${col?.serialsList}` + "." + `${num}`] + ", ")
			// 		);
			// 		serials = serials.slice(0, -2);
			// 	}
			// 	return (
			// 		<TableCell component='th' scope='row'>
			// 			<Tooltip content={serials} placement='bottom'>
			// 				<span className={`text[14px] ${col?.styles}`}>
			// 					{item[col?.value] || "-"}
			// 				</span>
			// 			</Tooltip>
			// 		</TableCell>
			// 	);
			let color = "default";
			if (item[col?.value]) color = "success";

			return (
				<TableCell component='th' scope='row'>
					<span
						className={`${textColors[color]} ${lighBackgroundColors[color]} p-1 px-2 rounded-full ${col?.styles}`}
					>
						{item[col?.value] ? "ACTIVE" : "INACTIVE"}
					</span>
				</TableCell>
			);
		}

		if (col?.type == "distance")
			return (
				<TableCell component='th' scope='row'>
					<span className={`text[14px] ${col?.styles}`}>
						{item[col.value] || 0} {" km"}
					</span>
				</TableCell>
			);
		if (
			col?.type == "date-simple"
		) {
			return (
				<TableCell component='th' scope='row'>
					<span className={`text[14px] ${col?.styles}`}>
						{item[col?.value] ? moment(`${item[col?.value]}`).format("YYYY-MM-DD") : "-"}
					</span>
				</TableCell>
			);
		}
		if (col?.type == "warehouse") {
			if (typeof col?.render == "function") {
				return (
					<TableCell component='th' scope='row'>
						<span
							className={`text[14px] flex items-center ${col?.styles}`}
						>
							<DefaultWarehouseIcon />
							{col?.render(item)}
						</span>
					</TableCell>
				);
			}

			return (
				<TableCell component='th' scope='row'>
					<span
						className={`text[14px] flex items-center ${col?.styles}`}
					>
						<DefaultWarehouseIcon />
						{item[col?.value] || item[col?.secondVal] || "-"}
					</span>
				</TableCell>
			);
		}
		if (col?.type == "directLink")
			return (
				<TableCell component='th' scope='row'>
					<span
						className={`text[14px] flex items-center underline text-yellow-600 hover:text-blue-800 visited:text-purple-600 ${col?.styles}`}
					>
						<Link to={`${col.path}${item[col.link]}`}>
							{item[col.value] || "-"}
						</Link>
					</span>
				</TableCell>
			);


		if (col?.type == "link")
			return (
				<TableCell component='th' scope='row'>
					<span
						className={`text[14px] flex items-center underline text-yellow-600 hover:text-blue-800 visited:text-purple-600 ${col?.styles}`}
					>
						{!col?.href ?
							<Link to={`./${item[col.link]}`}>
								{item[col.value] || "-"}
							</Link>
							:
							<a
								target="_blank"
								href={`${col.href}${item[col.link]}`}
							>
								{item[col.value] || "-"}
							</a>
						}
					</span>
				</TableCell>
			);

		if (col?.type == "button")
			return (
				<TableCell component='th' scope='row'>
					<span
						className={`text[14px] flex items-center underline text-yellow-600 hover:text-blue-800 visited:text-purple-600 ${item[col.value] ? col?.styles : ""
							}`}
					>
						<button onClick={(e) => col?.onClick(e, item)}>
							{item[col.value] || "N/A"}
						</button>
					</span>
				</TableCell>
			);
		if (col?.type == "orders")
			return (
				<TableCell component='th' scope='row'>
					<div
						className={`text[14px] flex items-center space-x-1 ${col?.styles}`}
					>
						<span className='text-warningColor'>
							{item[col.value][0] || 0}
						</span>
						<span>/</span>
						<span className='text-successColor'>
							{item[col.value][1] || 0}
						</span>
						<span>/</span>
						<span className=''>{item[col.value][2] || 0}</span>
					</div>
				</TableCell>
			);
		if (col?.type == "name") {
			let name = item[col.value]?.courier?.firstName;
			if (name)
				name = name[0].toUpperCase() + name.substring(1).toLowerCase();
			const lastName = item[col.value]?.courier?.lastName;
			if (name && lastName) name += ` ${lastName[0].toUpperCase()}`;

			return (
				<TableCell component='th' scope='row'>
					<span className={`text[14px] ${col?.styles}`}>
						{name || "-"}
					</span>
				</TableCell>
			);
		}

		if (col?.type == "capitalize") {
			let val = item[col.value];
			if (val) {
				val = val.replaceAll("_", " ");
				val = val[0].toUpperCase() + val.substring(1).toLowerCase();
			}

			return (
				<TableCell component='th' scope='row'>
					<span className={`text[14px] ${col?.styles}`}>
						{val || "-"}
					</span>
				</TableCell>
			);
		}

		if (col?.type == "serial") {
			let serials = "";

			if (item[col?.serialsString]) {
				serials = item[col?.serialsString]
			}
			else if (item[col?.value] > 0) {
				const len = item[col?.value];
				Array.from(Array(len).keys()).map(
					(num) =>
					(serials +=
						item[`${col?.serialsList}` + "." + `${num}`] +
						", " +
						(num % 3 === 0 ? "<br /> " : ""))
				);
				serials = serials.slice(0, -2);
			}



			let id = Math.random()
			return (
				<TableCell component='th' scope='row'>
					<Tooltip id={id} />
					<a
						data-tooltip-id={id}
						variant='info'
						data-tooltip-html={serials}
						placement='bottom'
					>
						<span className={`text[14px] ${col?.styles}`}>
							{item[col?.value] || "-"}
						</span>
					</a>
				</TableCell>
			);
		}

		if (typeof col?.render == "function") {
			return (
				<TableCell component='th' scope='row'>
					<span
						className={`text[14px] flex items-center ${col?.styles}`}
					>
						{col?.render(item)}
					</span>
				</TableCell>
			);
		}

		return (
			<TableCell component='th' scope='row'>
				<span className={`text[14px] ${col?.styles}`}>
					{item[col?.value] || item[col?.secondVal] || "-"}
				</span>
			</TableCell>
		);
	};

	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	// Avoid a layout jump when reaching the last page with empty rows.
	// const emptyRows =
	//     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		onPageChange(newPage * rowsPerPage);
	};

	const [sort, setSort] = useState({
		id: "ASC",
	});

	const handleSort = (sortVal) => {
		onSortClick(sortVal);
	};

	return (
		<TableContainer component={Paper}>
			<Table
				className={`${bgColor}`} sx={{ minWidth: 650 }} aria-label='simple table'>
				<TableHead className={tableHeadStyles}>
					<TableRow>
						{hasCheckbox && (
							<TableCell padding='checkbox' key={"300"}>
								<Checkbox
									color='primary'
									indeterminate={
										selectedRows.length > 0 && !allSelected
									}
									checked={
										allSelected || selectedRows.length > 0
									}
									onChange={(event, checked) =>
										handleAllSelect(checked)
									}
									inputProps={{
										"aria-label": "select all desserts",
									}}
								/>
							</TableCell>
						)}
						{cols.map((col) => (
							<TableCell key={col.value}>
								<div className='flex items-center'>
									<p className='font-bold'>{col.name}</p>
									{col.canBeSorted && (
										<div
											onClick={() =>
												handleSort(col.value)
											}
											className='mx-1 rounded-full w-7 h-7 flex items-center justify-center hover:bg-gray-200 cursor-pointer'
										>
											{/* DESC */}
											{sortCol?.label == col.value && (
												<SortIcon
													sortId={sortCol?.value}
												/>
											)}
											{sortCol?.label != col.value && (
												<SortIcon sortId={-1} />
											)}
										</div>
									)}
								</div>
							</TableCell>
						))}
						{canBeEdited && (
							<TableCell padding='checkbox' key={"001"}>
								{" "}
							</TableCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody className={bgColor}  >
					{Array.from(Array(10).keys()).map((item, index) => (
						<LoadingItem key={index} index={index} />
					))}
					{data?.map((row, index) => (
						<TableItem key={index} row={row} cols={cols} />
					))}
				</TableBody>

				{data.length > 0 && showPagination && (
					<TableFooter className={bgColor}  >
						<TableRow>
							<TablePagination
								count={count}
								// onPageChange={onPageChange}
								rowsPerPage={rowsPerPage}
								page={page}
								rowsPerPageOptions={[rowsPerPage]}
								// SelectProps={{
								//     inputProps: {
								//         'aria-label': 'rows per page',
								//     },
								//     native: true,
								// }}
								onPageChange={handleChangePage}
							// onRowsPerPageChange={handleChangeRowsPerPage}
							// ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				)}
			</Table>
			{data.length == 0 && (
				<div className={`p-4 flex items-center text-gray-400 min-w-40 no-data-found w-full ${bgColor}`}>
					<span> No data found</span>
				</div>
			)}
		</TableContainer>
	);
}

// export default React.memo(PaginatedTable)
