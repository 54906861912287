import PaginatedTable from "../../../../components/Table/paginatedTable";
import React from "react";
import Modal from "../../../../components/common/Modal";
import {
	Alert,
	Button,
	Checkbox,
	Chip,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TextField,
} from "@mui/material";
import {
	useLazyQuery as useApolloLazyQuery,
	useMutation,
} from "@apollo/client";
import { CheckBox } from "@mui/icons-material";
import { Box } from "@mui/system";
import { SearchIcon } from "../../../../components/svg";
import { useNavigate } from "react-router-dom";
import useLazyQuery from "hooks/useLazyQuery";
import { GET_PACKING_JOBS, GET_PICKERS } from "api/queries/WMSqueries";
import { wmsOptions } from "constants/apollo";
import { objFlatten } from "helper/objFlatten";
import moment from "moment";
import { useDebounce } from "hooks/useDebounce";
import usePacking from "./usePacking";
import { getSortationOrder } from "helper/GetSortOrder";
import NewSearch from "../../../../components/common/Search/new-search";
import { ASSIGN_PACKER_TO_PACKING_JOBS } from "api/mutations/WMSMutations";

const cols = [
	{
		name: "Packing Job ID",
		value: "id",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Sale Order Id",
		value: "saleOrder.id",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Merchant",
		value: "saleOrder.merchant.referenceName",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Product",
		value: "saleOrder.saleOrderItems.0.product.name",
		styles: "text-warningColor",
	},
	{
		name: "Location",
		value: "location.locationCode",
		styles: "text-warningColor",
	},
	{ name: "Created at", value: "createdAt", canBeSorted: true },
	{ name: "Updated at", value: "updatedAt", canBeSorted: true },
	{
		name: "Packer",
		value: "assignedTo.name",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{ name: "Status", value: "status", type: "status", canBeSorted: true },
];

export default function Packing({
	searchText,
	productFilters,
	assignModalOpen,
	setAssignModalOpen,
}: any) {
	const [itemsCount, setItemsCount] = React.useState(0);
	const [items, setItems] = React.useState([]);
	const [sortCol, setSortCol] = React.useState<any>([]);
	const [page, setPage] = React.useState(0);
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [filterByBin, setFilterByBin] = React.useState("");
	const [modalOpen, setModalOpen] = React.useState(false);
	const [selectedOrderIDs, setSelectedOrderIDs] = React.useState<number[]>(
		[]
	);
	const [reload, setReload] = React.useState(0);

	const [selectedPickerIDs, setSelectedPickerIDs] = React.useState<number[]>(
		[]
	);
	const [packingJobs] = useLazyQuery(GET_PACKING_JOBS, {
		...wmsOptions,
	});

	const [assignPacker] = useMutation(ASSIGN_PACKER_TO_PACKING_JOBS, {
		...wmsOptions,
	});

	const navigate = useNavigate();

	const debouncedSearchText = useDebounce(searchText, 500);

	const { getOrderObj, getSearchObj, getFilterObj } = usePacking();

	React.useEffect(() => {
		fetchData();
	}, [debouncedSearchText, productFilters, reload]);

	const fetchData = (
		skip = 0,
		search = searchText,
		sortName = sortCol.label,
		sortOrder = getSortationOrder(sortCol.value),
		filters = productFilters
	) => {
		let orderObj = getOrderObj(sortName, sortOrder);
		let searchObj = getSearchObj(search);
		let filterObj = getFilterObj(filters);

		packingJobs({
			variables: {
				skip,
				...orderObj,
				...searchObj,
				...filterObj,
			},
		}).then((data) => {
			if (data?.data?.packingJobs?.items?.length) {
				let flattenedData = data?.data?.packingJobs?.items?.map(
					(item: any) => {
						let time = "";
						if (item.createdAt) {
							time = moment(item.createdAt).format(
								"D-M-YYYY / HH:mm"
							);
						}
						let serialsLength = item?.saleOrderItem?.quantity || 0;

						return {
							...objFlatten({
								...item,
								createdAt: time,
								serialsLength: `${serialsLength}`,
							}),
							saleOrderItems: item?.saleOrder?.saleOrderItems,
						};
					}
				);
				setItemsCount(data?.data?.packingJobs?.totalCount);
				setItems(flattenedData);
			} else {
				setItems([]);
				setItemsCount(0);
			}
		});
	};

	const handleSort = (sortVal = "") => {
		if (sortCol.label === sortVal) {
			// sort Desc
			const sortOrder = getSortationOrder(sortCol.value + 1);
			sortCol.value += 1;

			fetchData(0, searchText, sortVal, sortOrder);
		} else {
			setSortCol((old: any) => ({
				...old,
				label: sortVal,
				value: 0,
			}));
			fetchData(0, searchText, sortVal, "ASC");
		}

		setPage(0);
	};

	return (
		<>
			<PaginatedTable
				// @ts-ignore
				onSortClick={(sortVal: any) => handleSort(sortVal)}
				count={itemsCount}
				page={page}
				cols={cols}
				sortCol={sortCol}
				setPage={setPage}
				onPageChange={(number: any) => {
					fetchData(number);
				}}
				hasCheckbox
				data={items}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				loading={false}
				filterByBin={filterByBin}
				canBeEdited
				editMenuItems={[
					{
						actionName: "Start packing",
						action: (data: any) => {
							let serials: string[] = [];

							data.saleOrderItems.forEach((item: any) => {
								if (item?.serials?.length) {
									serials = [...serials, ...item.serials];
								}
							});

							serials = serials.filter(
								(item: string) => item.length
							);

							return navigate("/wms/outbound/proceed/packing", {
								state: {
									id: 2,
									cartCode: data["cart.locationCode"],
									merchantName:
										data[
											"saleOrder.merchant.referenceName"
										],
									productName:
										data[
											"saleOrder.saleOrderItems.0.product.name"
										],
									status: data["status"],
									saleOrderItems: data.saleOrderItems,
									saleOrderItemId: data["saleOrderItem.id"],
									saleOrderItemQuantity: serials.length,
									serials: serials,
									type: "packing",
									packingJobId: data["id"],
								},
							});
						},
						isDisabled: false,
						sx: {
							background: "#EFF7EE",
							color: "#4CAF50",
						},
					},
					{
						actionName: "Assign packer",
						action: (data: any) => {
							setAssignModalOpen(true);
							setSelectedPickerIDs([]);
							setSelectedOrderIDs([parseInt(data["id"])]);
						},
						isDisabled: false,
					},
				]}
			/>
			<Modal
				footer={() => <ModalFooter close={() => setModalOpen(false)} />}
				onClose={() => {
					setModalOpen(false);
				}}
				hidden
				onDismiss={() => {
					setModalOpen(false);
				}}
				autoOpen={modalOpen}
				maxWidth='xs'
				title='Assign a cart'
			>
				<ModalContent />
			</Modal>
			<Modal
				title='Assign Packer'
				footer={() => (
					<AssignPickerModalFooter
						count={selectedPickerIDs?.length || 0}
						assign={() => {
							assignPacker({
								variables: {
									packingJobId: selectedOrderIDs[0],
									packerId: selectedPickerIDs[0],
								},
							}).then((data) => {
								if (data.data.assignPackerToPackingJob) {
									setReload(Math.random());
									setAssignModalOpen(false);
								}
							});

							// setSelectedRows([])
						}}
						close={() => setAssignModalOpen(false)}
					/>
				)}
				onClose={() => {
					setAssignModalOpen(false);
				}}
				hidden
				onDismiss={() => {
					setAssignModalOpen(false);
				}}
				color='primary'
				autoOpen={assignModalOpen}
				maxWidth='xs'
			>
				<AssignPickerModalContent
					checked={selectedPickerIDs}
					setChecked={setSelectedPickerIDs}
				/>
			</Modal>
		</>
	);
}

function ModalFooter({ close }: { close: any }) {
	return (
		<Box sx={{ width: "100%" }}>
			<Divider />

			<div
				style={{
					marginTop: 20,
					justifyContent: "flex-end",
					display: "flex",
				}}
			>
				<Button
					onClick={() => close()}
					variant='outlined'
					color='warning'
				>
					Cancel
				</Button>
				<Button
					sx={{ marginLeft: 2 }}
					variant='contained'
					color='warning'
				>
					Assign
				</Button>
			</div>
		</Box>
	);
}

function ModalContent() {
	const [checked, setChecked] = React.useState([0]);

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	return (
		<>
			<TextField
				fullWidth
				title='Scan or search cart barcodes'
				placeholder='Scan or type...'
				variant='outlined'
				label='Scan or search cart barcodes'
				size='small'
				sx={{ marginY: 1 }}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
				}}
			>
				{[0, 1, 2, 3].map((value, index) => {
					const labelId = `checkbox-list-label-${value}`;

					return (
						<>
							{index !== 0 && <Divider />}

							<ListItem
								sx={{
									width: "100%",
									bgcolor:
										checked.indexOf(value) !== -1
											? "#FF980014"
											: "background.paper",
								}}
								key={value}
								disablePadding
							>
								<ListItemButton
									role={undefined}
									onClick={handleToggle(value)}
									dense
									sx={{
										width: "100%",
									}}
								>
									<ListItemIcon>
										<Checkbox
											edge='start'
											checked={
												checked.indexOf(value) !== -1
											}
											color='warning'
											tabIndex={-1}
											disableRipple
											inputProps={{
												"aria-labelledby": labelId,
											}}
										/>
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={`Line item ${value + 1}`}
									/>
									<Chip
										sx={{
											textAlign: "right",
											backgroundColor: "#4CAF5014",
											border: "none",
											color: "#4CAF50",
										}}
										id={labelId}
										color='success'
										label='Scanned'
										variant='outlined'
										size='small'
									/>
								</ListItemButton>
							</ListItem>
						</>
					);
				})}
			</List>
		</>
	);
}

function AssignPickerModalFooter({ close, assign, count }: any) {
	return (
		<Box sx={{ width: "100%" }}>
			<Divider />

			<div
				style={{
					marginTop: 20,
					justifyContent: "flex-end",
					display: "flex",
				}}
			>
				<Button
					onClick={() => close()}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={() => assign()}
					sx={{ marginLeft: 2 }}
					variant='contained'
					color='primary'
					disabled={count < 1}
				>
					<span className='text-white'>Assign</span>
				</Button>
			</div>
		</Box>
	);
}

function AssignPickerModalContent({ checked, setChecked }: any) {
	const [isActionComplete, setIsActionComplete] = React.useState(false);

	const [selected, setSelected] = React.useState("available");
	const [searchText, setSearchText] = React.useState("");

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(+value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const [refetchFunction, { data, error }] = useApolloLazyQuery(
		GET_PICKERS,
		wmsOptions
	);
	const [pickerList, setPickerList] = React.useState([]);
	const newSearchQuery = useDebounce(searchText, 500);

	const fetchPickers = (query = newSearchQuery, status = selected) => {
		setChecked([]);
		let filterObj: any = {
			where: { role: { eq: "PACKER" } },
		};

		if (status == "available") {
			filterObj = {
				where: { role: { eq: "PACKER" }, status: { eq: "AVAILABLE" } },
			};
		}
		let queryObj = { query: "" };
		if (newSearchQuery?.trim() != "") {
			queryObj = {
				query: newSearchQuery,
			};
		}
		refetchFunction({
			variables: {
				...filterObj,
				...queryObj,
			},
		});
	};
	React.useEffect(() => {
		if (data) setPickerList(data?.users?.items);
	}, [data]);

	React.useEffect(() => {
		fetchPickers();
	}, []);

	React.useEffect(() => {
		fetchPickers();
	}, [newSearchQuery, selected]);

	return (
		<>
			{/* <TextField
        fullWidth
        title="Search employee"
        placeholder="Type here..."
        variant="outlined"
        label="Scan or search pickers"
        size="small"
        sx={{ marginY: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      /> */}

			<NewSearch
				hasValue={true}
				value={searchText}
				filterName='Search'
				infoText=''
				placeholder='Type here...'
				onChange={(e: any) => setSearchText(e.target.value)}
				handleClearSearch={() => setSearchText("")}
			/>
			<div className='m-5'></div>

			<Grid container spacing={2}>
				<Grid item>
					<Chip
						id={"0"}
						color={selected === "all" ? "primary" : "default"}
						label='All'
						variant='filled'
						onClick={() => setSelected("all")}
					/>
				</Grid>
				<Grid item>
					<Chip
						id={"0"}
						color={selected !== "all" ? "primary" : "default"}
						label='Available'
						onClick={() => setSelected("available")}
					/>
				</Grid>
			</Grid>

			{pickerList.length > 0 ? (
				<List
					sx={{
						width: "100%",
						bgcolor: "background.paper",
					}}
				>
					{pickerList?.map((item: any, index: any) => {
						const labelId = `checkbox-list-label-${item?.id}`;

						return (
							<>
								{index !== 0 && <Divider />}

								<ListItem
									sx={{
										width: "100%",
										bgcolor:
											checked.indexOf(item?.id) !== -1
												? "#FF980014"
												: "background.paper",
									}}
									key={item?.id}
									disablePadding
								>
									<ListItemButton
										role={undefined}
										onClick={handleToggle(item?.id)}
										dense
										sx={{
											width: "100%",
										}}
									>
										<ListItemIcon>
											<Checkbox
												edge='start'
												checked={
													checked.indexOf(
														+item?.id
													) !== -1
												}
												color='warning'
												tabIndex={-1}
												disableRipple
												inputProps={{
													"aria-labelledby": labelId,
												}}
											/>
										</ListItemIcon>
										<ListItemText
											id={labelId}
											primary={item?.name}
										/>
										<Chip
											sx={{
												textAlign: "right",
												backgroundColor: "#4CAF5014",
												border: "none",
												color: "#4CAF50",
											}}
											id={labelId}
											color='success'
											label={item?.status || "Available"}
											variant='outlined'
											size='small'
										/>
									</ListItemButton>
								</ListItem>
							</>
						);
					})}
				</List>
			) : (
				<Box mt={2}>
					<Alert variant='standard' severity='warning'>
						<span>No Pickers found!</span>
					</Alert>
				</Box>
			)}
			{/* <Alert variant="standard" severity="warning">
        <span>
          You have more than <BoldText>30</BoldText> items. You need{" "}
          <BoldText>1 more</BoldText> employee
        </span>
      </Alert> */}
		</>
	);
}
