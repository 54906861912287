import React from "react";
import { backgroundColors, borderColors, textColors } from "../../colors";

export default function CustomButton ({
  children,
  variant,
  color = "default",
  onClick,
  disabled = false,
  bgColor = "",
  additionalStyles = "",
  ...rest
}) {
  if (variant === "contained") {
    return (
      <button
        onClick={onClick}
        className={`${backgroundColors[color]
          } uppercase flex-1 opacity-90 hover:opacity-100 text-white font-bold py-2 px-3 rounded min-w-[150px] focus:outline-nones outline-none hover:outline-none text-sm ${disabled && "opacity-70 hover:scale-100 hover:shadow-none"
          } ${additionalStyles}`}
        {...rest}
      >
        {children}
      </button>
    );
  }

  if (variant === "outlined") {
    return (
      <button
        onClick={onClick}
        className={`${textColors[color]} ${borderColors[color]
          }  uppercase flex-1 opacity-90 hover:bg-gray-50 hover:opacity-100  border font-bold py-2 px-3 rounded min-w-[150px] text-sm ${disabled && "opacity-70 hover:scale-100 hover:shadow-none"
          } ${bgColor}  ${additionalStyles}`}
        {...rest}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`${textColors[color]
        } uppercase flex-1 opacity-90 hover:opacity-100 hover:bg-gray-100 bg-transparent font-bold py-2 px-3 rounded min-w-[180px] text-sm ${disabled && "opacity-70 hover:scale-100 hover:shadow-none"
        }  ${additionalStyles}`}
      {...rest}
    >
      {children}
    </button>
  );
}
