export const vehicleTypeOptions = [
    { value: "car", label: "Car" },
    { value: "motorbike", label: "Motorbike" },
    { value: "van", label: "Van" },
    { value: "minivan", label: "Minivan" },
    { value: "pickup", label: "Pickup" },
    { value: "small_truck", label: "Small truck" },
    { value: "large_truck", label: "Large truck" },
]

export const bonusTypeOptions = [
    { value: "bonus", label: "Bonus" },
    { value: "deduction", label: "Deduction" }
]

export const orderStatusTypes = [
    { value: "all", label: "All" },
    { value: "new_order", label: "New Order" },
    { value: "processing", label: "Processing" },
    { value: "ready_for_delivery", label: "Ready for Delivery" },
    { value: "picked_up", label: "Picked Up" },
    { value: "intransit", label: "In Transit" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
    { value: "exception", label: "Exception" },
    { value: "returned", label: "Returned" },
    { value: "damaged_or_lost", label: "Damaged or Lost" },
    { value: "not_ready_for_pickup", label: "Not Ready for Pickup" },
    { value: "pickup_failed", label: "Pickup Failed" },
    { value: "processing_at_warehouse", label: "Processing at Warehouse" },
    { value: "in_warehouse", label: "In Warehouse" },
    { value: "misrouted", label: "Misrouted" },
    { value: "in_transit_to_warehouse", label: "In Transit to Warehouse" },
    { value: "delivery_attempt_failed", label: "Delivery Attempt Failed" }
]

export const shipmentTypes = [
    { value: "all", label: "All" },
    { value: "barq", label: "Barq" },
    { value: "bullet", label: "Bullet" },
    { value: "fast", label: "Fast" },
    { value: "intercity", label: "Intercity" },
    { value: "instant_delivery", label: "Instant Delivery" },
    { value: "same_day", label: "Same Day" },
    { value: "next_day", label: "Next Day" },
    { value: "pick_up", label: "Pick Up" },
    { value: "cold", label: "Cold" },
    { value: "food", label: "Food" }
]