import {
  useMutation,
  useLazyQuery as apolloUseLazyQuery,
} from "@apollo/client";
import useLazyQuery from "hooks/useLazyQuery";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import PurchaseOrderFilters from "./components/PurchaseOrderFilters";
import PurchaseOrderTable from "./components/PurchaseOrderTable";
import NewPurchaseOrder from "./new-purchase";
import PaginatedTable from "../../../components/Table/paginatedTable";
import { wmsOptions } from "../../../constants/apollo";
import { useDebounce } from "../../../hooks/useDebounce";
import { GET_ALL_PURCHASE_ORDER } from "../../../api/queries/WMSqueries";
import usePurchaseOrder from "./usePurchaseOrder";
import { logger } from "../../../helper/logger";
import { objFlatten } from "../../../helper/objFlatten";
import { GET_ALL_MERCHANTS } from "../../../api/queries/Queries";
import Modal from "../../../components/common/Modal";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import FiltersList from "../../../components/FiltersList";
import MoveInventory from "../CurrentInventory/moveInventory";
import {
  WMS_FILTER_LOCATIONS,
  WMS_FILTER_MERCHANTS,
  WMS_FILTER_PRODUCTS,
} from "../../../api/queries/WMSFilterQueries";
import { getSortationOrder } from "../../../helper/GetSortOrder";
import moment from "moment";
import { ADD_UPDATE_PURCHASE_ORDER } from "../../../api/mutations/WMSMutations";
import {
  useSearchParams,
  useParams,
  useInRouterContext,
  useRoutes,
} from "react-router-dom";
import { toast } from "react-toastify";

const filters = {
  productId: "all",
  locationId: "all",
  merchantId: "all",
  locationType: "all",
  status: "all",
};

export default function PurchaseOrder () {
  const localStorageFilters = JSON.parse(
    window?.localStorage?.getItem("purchaseOrFilters") || "{}"
  );
  const [searchParams, setSearchParams] = useSearchParams(
    localStorageFilters || {}
  );

  const [searchText, setSearchText] = useState(
    localStorageFilters?.searchText ||
    Object.fromEntries([...searchParams]).searchText ||
    ""
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState({
    productId: "",
    merchantId: "",
    serials: [],
    locationId: "",
    locationName: "",
    merchantName: "",
    productName: "",
    cardType: "",
  });

  const lockedFields = searchParams?.get("locked")?.split("-");

  logger("lock", lockedFields);

  const [filterOpen, setFilterOpen] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 500);
  const [productFilters, setProductFilters] = useState({
    ...filters,
    ...Object.fromEntries([...searchParams]),
  });
  const [page, setPage] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);

  const [filterByBin, setFilterByBin] = useState("");

  const [merchants, setMerchants] = useState([]);
  const [products, setProducts] = useState([]);
  const [locations, setLocations] = useState([]);

  const [getPurchaseOrders] = useLazyQuery(GET_ALL_PURCHASE_ORDER, wmsOptions);

  const [createAndUpdatePurchaseOrder] = useMutation(
    ADD_UPDATE_PURCHASE_ORDER,
    wmsOptions
  );
  const [getMerchants] = apolloUseLazyQuery(WMS_FILTER_MERCHANTS, wmsOptions);
  const [getProducts] = apolloUseLazyQuery(WMS_FILTER_PRODUCTS, wmsOptions);
  const [getLocations] = apolloUseLazyQuery(WMS_FILTER_LOCATIONS, wmsOptions);

  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [sortCol, setSortCol] = useState({
    label: "createdAt",
    value: 1,
  });

  const cols = [
    {
      name: "Purchase ID",
      value: "id",
      canBeSorted: true,
    },
    {
      name: "Product Name",
      value: "product.name",
      canBeSorted: true,
    },
    { name: "Merchant", value: "merchant.name", canBeSorted: true },
    {
      name: "Serials",
      value: "serialsCount",
      type: "serial",
      serialsString: "serialsString"
    },
    { name: "Location", value: "location.locationCode", canBeSorted: true },
    { name: "Location Type", value: "location.type", canBeSorted: true },
    {
      name: "Total Weight",
      value: "product.weightInGrams",
      canBeSorted: true,
    },
    { name: "Created at", value: "createdAt", canBeSorted: true },
    { name: "Status", value: "status", type: "status", canBeSorted: true },
  ];

  const { getOrderObj, getSearchObj, getFilterObj } = usePurchaseOrder();

  const handleQueryChange = (obj: any) => {
    // updare router query based on implemented filters
    let mergedObj: any = {
      ...Object.fromEntries([...searchParams]),
      ...obj,
    };
    let modified: any = {};

    Object.keys(mergedObj).map((key) => {
      if (mergedObj[key] != "all" && mergedObj[key] != "") {
        if (key in obj) {
          modified[key] = obj[key];
        } else {
          modified[key] = mergedObj[key];
        }
      }
    });

    setSearchParams(modified);
    localStorage.setItem("purchaseOrFilters", JSON.stringify({ ...modified }));
  };
  useEffect(() => {
    handleSearch(searchText);
    handleQueryChange({ searchText: searchText });
  }, [debouncedSearchText]);

  const fetchPurchaseOrder = (
    skip = 0,
    search = debouncedSearchText,
    sortName = sortCol.label,
    sortOrder = getSortationOrder(sortCol.value),
    filters = productFilters
  ): any => {
    let orderObj = getOrderObj(sortName, sortOrder);
    let searchObj = getSearchObj(search);
    let filterObj = getFilterObj(filters);

    getPurchaseOrders({
      variables: {
        skip: skip,
        ...filterObj,
        ...searchObj,
        ...orderObj,
      },
    }).then((res) => {
      logger(res.data.purchaseOrders);
      setItemsCount(res.data.purchaseOrders.totalCount);

      const purchaseOrderList = res.data.purchaseOrders.items;

      const purchaseOrderListFlattened = purchaseOrderList.map((item: any) => {
        let status;
        if (item.isActive) {
          status = "active";
        } else {
          status = "inactive";
        }
        let serialsCount = item?.serials?.length || 0;
        let time = "";
        if (item.createdAt) {
          time = moment(item.createdAt).format("D-M-YYYY / hh:mm");
        }

        let purchaseOrdersCount = item?.purchaseOrders?.length || 0;

        let serStr = ''
        let serList: any[] = []
        if (serialsCount > 0) {
          item?.serials?.map((el: any) =>
            serList.push(el)
          )
        }
        serStr = serList.join(', ')

        return {
          ...objFlatten({
            ...item,
            createdAt: time,
            serialsCount: serialsCount,
            serialsString: serStr
          }),

          data: item,
        };
      });

      setPurchaseOrderList(purchaseOrderListFlattened);
    });
  };

  const fetch_merchants = ({ page = 1, searchQuery = "" } = {}) => {
    logger("fetch_merchants", page, searchQuery);

    const searchObj = getSearchObj(searchQuery);

    return getMerchants({
      variables: {
        where: {},
        skip: (page - 1) * 20,
        ...searchObj,
      },
    }).then((res) => {
      setMerchants(res?.data?.merchants?.items);

      return {
        options: res?.data?.merchants?.items,
        hasMore: res?.data?.merchants?.totalCount >= page * 20,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    });
  };

  const fetch_merchant = ({ id = "0" } = {}) => {
    return getMerchants({
      variables: {
        where: { id: { eq: parseInt(id) } },
      },
    }).then((res) => {
      let data = res?.data?.merchants?.items[0];
      return data;
    });
  };

  const fetch_products = ({ page = 1, searchQuery = "" } = {}) => {
    logger("fetch_merchants", page, searchQuery);

    const searchObj = getSearchObj(searchQuery);

    return getProducts({
      variables: {
        where: {},
        skip: (page - 1) * 20,
        ...searchObj,
      },
    }).then((res) => {
      setProducts(res?.data?.products?.items);

      return {
        options: res?.data?.products?.items,
        hasMore: res?.data?.products?.totalCount >= page * 20,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    });
  };

  const fetch_product = ({ id = "0" } = {}) => {
    return getProducts({
      variables: {
        where: { id: { eq: parseInt(id) } },
      },
    }).then((res) => {
      let data = res?.data?.products?.items[0];
      return data;
    });
  };

  const fetch_locations = ({ page = 1, searchQuery = "" } = {}) => {
    logger("fetch_merchants", page, searchQuery);

    const searchObj = getSearchObj(searchQuery);

    return getLocations({
      variables: {
        where: {},
        skip: (page - 1) * 20,
        ...searchObj,
      },
    }).then((res) => {
      let locationList = res?.data?.locations?.items;
      let locationsModified: any = [];
      locationList.map((item: any) => {
        if (!item.label) {
          locationsModified.push({
            value: item.value,
            label: item.locationCode,
          });
        } else {
          locationsModified.push(item);
        }
        setLocations(locationsModified);
      });
      return {
        options: locationsModified,
        hasMore: res?.data?.locations?.totalCount >= page * 20,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    });
  };

  const fetch_location = ({ id = "0" } = {}) => {
    return getLocations({
      variables: {
        where: { id: { eq: parseInt(id) } },
      },
    }).then((res) => {
      let data = res?.data?.locations?.items[0];
      return data;
    });
  };

  const handleSort = (sortVal = "") => {
    if (sortCol.label === sortVal) {
      // sort Desc
      const sortOrder = getSortationOrder(sortCol.value + 1);
      sortCol.value += 1;

      fetchPurchaseOrder(0, searchText, sortVal, sortOrder);
    } else {
      setSortCol((old: any) => ({
        ...old,
        label: sortVal,
        value: 0,
      }));
      fetchPurchaseOrder(0, searchText, sortVal, "ASC");
    }

    setPage(0);
  };
  function handleSearch (search: any) {
    fetchPurchaseOrder(
      0,
      search,
      sortCol.label,
      getSortationOrder(sortCol.value)
    );
    setPage(0);
  }

  function handleFilter () {
    handleQueryChange(productFilters);
    fetchPurchaseOrder(
      0,
      debouncedSearchText,
      sortCol.label,
      getSortationOrder(sortCol.value),
      productFilters
    );
  }

  function handleFilterReset () {
    setProductFilters(filters);
    handleQueryChange(filters);
    fetchPurchaseOrder(
      0,
      debouncedSearchText,
      sortCol.label,
      getSortationOrder(sortCol.value),
      filters
    );
    setFilterOpen(false);
  }

  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [options, setOptions] = React.useState({});
  const [newPurchaseOrder, setNewPurchaseOrder] = React.useState(false);

  return (
    <>
      {newPurchaseOrder ? (
        <div>
          <NewPurchaseOrder fetchPurchaseOrder={fetchPurchaseOrder} setNewPurchaseOrder={setNewPurchaseOrder} />
        </div>
      ) : (
        <div>
          <PurchaseOrderFilters
            setFilterOpen={setFilterOpen}
            selectedRows={selectedRows}
            bins={[]}
            reload={() => { }}
            search={searchText}
            setSearch={setSearchText}
            setNewPurchaseOrder={setNewPurchaseOrder}
          />
          <PaginatedTable
            sortCol={sortCol}
            onSortClick={(sortVal) => {
              handleSort(sortVal);
            }}
            count={itemsCount || 0}
            page={page}
            setPage={setPage}
            onPageChange={fetchPurchaseOrder}
            hasCheckbox
            cols={cols}
            data={purchaseOrderList}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            loading={false}
            filterByBin={filterByBin}
            canBeEdited
            editMenuItems={[
              {
                actionName: "Put away",
                action: (data: any) => {
                  setModalOptions({
                    productId: data.data.product.id,
                    merchantId: data.data.merchant.id,
                    serials: data.data.serials,
                    locationId: data.data.location.id,
                    locationName: data.data.location.locationCode,
                    merchantName: data.data.merchant.name,
                    productName: data.data.product.name,
                    cardType: data.data.location.type,
                  });
                  setModalOpen(true);
                },
                isDisabled: false,
                type: "purchaseOrder",
              },
              {
                actionName: "Cancel",
                sx: {
                  background: "#F4433614",
                  color: "#F44336",
                },
                action: (data: any) => {
                  createAndUpdatePurchaseOrder({
                    variables: {
                      input: [
                        {
                          id: parseInt(data?.id),
                          productId: parseInt(data.data.product.id),
                          merchantId: parseInt(data.data.merchant.id),
                          serials: data.data.serials,
                          locationId: parseInt(data.data.location.id),
                          itemPriceInSAR: 0,
                          status: "CANCELLED",
                        },
                      ],
                    },
                  }).then((i) => {
                    console.log(i);
                  });
                },
                isDisabled: false,
                type: "purchaseOrder",
              },
            ]}
          />
          <MoveInventory
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            defMerchantId={modalOptions?.merchantId}
            defProductId={modalOptions?.productId}
            defSerials={modalOptions?.serials}
            defLocationId={modalOptions.locationId}
            defMerchantName={modalOptions.merchantName}
            defProductName={modalOptions.productName}
            defSourceName={modalOptions.locationName}
            defLocationType={modalOptions.cardType}
            autoFill
          />
        </div>
      )}

      <Modal
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        footer={(handleClose: any) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={handleFilter}
            handleReset={handleFilterReset}
          />
        )}
        onClose={() => { }}
        title={"Filter Products"}
      >
        <FiltersList
          selectedFilters={productFilters}
          lockedFields={lockedFields}
          filters={[
            {
              name: "Filter by Product",
              value: "productId",
              id: 1,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  let sel = fetch_product({ id: id });
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: any, page: any) =>
                fetch_products({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Merchant",
              value: "merchantId",
              id: 2,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  let sel = fetch_merchant({ id: id });
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: any, page: any) =>
                fetch_merchants({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Location",
              value: "locationId",
              id: 3,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  let sel = fetch_location({ id: id });
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: any, page: any) =>
                fetch_locations({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Location Type",
              value: "locationType",
              id: 4,
              options: [
                { value: "all", label: "All" },
                { value: "WAREHOUSE", label: "Warehouse" },
                { value: "SHELF", label: "Shelf" },
                { value: "CART", label: "Cart" },
                { value: "DRIVER", label: "Driver" },
                { value: "ORDER", label: "Order" },
              ],
            },
            {
              name: "Filter by Status",
              value: "status",
              id: 5,
              options: [
                { value: "all", label: "All" },
                { value: "IN_CART", label: "In Cart" },
                { value: "NEW", label: "New" },
                { value: "PUT_AWAY", label: "Put Away" },
                { value: "CANCELLED", label: "Cancelled" },
              ],
            },
          ]}
          handleChange={(filterVal: any, filterName: string) => {
            setProductFilters((old) => ({
              ...old,
              [filterName]: filterVal,
            }));
          }}
        />
      </Modal>
    </>
  );
}
