import { gql } from "@apollo/client";

export const ASSIGN_SHIPMENT = gql`
  mutation AssignShipment($input: AssignShipmentToCourierInput!) {
    assignShipmentToCourier(input: $input) {
      assigned
      clientMutationId
    }
  }
`;

export const UNASSIGN_SHIPMENT = gql`
  mutation UnassignShipment($input: UnassignShipmentFromCourierInput!) {
    unassignShipmentFromCourier(input: $input) {
      unassigned
      clientMutationId
    }
  }
`;

export const MERGE_TWO_SHIPMENTS = gql`
  mutation mergeShipments($input: MergeShipmentsInput!) {
    mergeShipments(input: $input) {
      shipment{
        id
      }
      clientMutationId
    }
  }
`;


export const ASSIGN_ORDER = gql`
  mutation assignOrdersToCourierThroughIds($input: AssignOrdersToCourierThroughIdsInput!) {
    assignOrdersToCourierThroughIds(input: $input) {
      clientMutationId
    }
  }
`;

export const UNASSIGN_ORDER = gql`
  mutation unassignOrdersFromShipment($input: UnassignOrdersFromShipmentInput!) {
    unassignOrdersFromShipment(input: $input) {
      orders{
        id
      }
      couriers{id}
      clientMutationId
    }
  }
`;
