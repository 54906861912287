import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../Button";
import { Button } from "@mui/material";
import { ResetIcon, TickIcon } from "../../svg";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
// 	"& .MuiDialogContent-root": {
// 		padding: theme.spacing(4),
// 	},
// }));

function CustomModal({
	children,
	btnContent = "open modal",
	title = "Title",
	onClose,
	footer,
	hidden,
	autoOpen = false,
	onDismiss,
	maxWidth = "md",
	fullScreen = false,
	fullWidth = true,
	...props
}) {
	const [open, setOpen] = React.useState(false || autoOpen);

	React.useEffect(() => {
		setOpen(autoOpen);
	}, [autoOpen]);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		if (!onDismiss) {
			setOpen(false);
		} else {
			console.log(onDismiss);
			onDismiss();
		}
	};

	return (
		<div
			style={{
				width: "100%",
			}}
		>
			{!hidden && (
				<Button
					padding={0}
					variant='text'
					onClick={handleClickOpen}
					className='uppercase w-full'
				>
					{btnContent}
				</Button>
			)}

			<Dialog
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}
				maxWidth={maxWidth}
				fullScreen={fullScreen}
				fullWidth={fullWidth}
				repositionOnUpdate={false}
			>
				<DialogContent
					style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
					dividers
					sx={{
						padding: 3,
					}}
				>
					<div>
						<div className='flex justify-between capitalize text-xl mb-4'>
							<span>{title}</span>
							<span
								className='text-gray-500 cursor-pointer p-1 pr-0 pt-0'
								onClick={handleClose}
							>
								<CloseIcon />
							</span>
						</div>
						{children}
					</div>
					<div className='flex justify-end items-end mt-5 text-primaryColor space-x-3 p-3'>
						{footer && footer(handleClose)}
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default React.memo(CustomModal);
