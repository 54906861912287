export default function useCurrentInventory() {
	const getOrderObj = (sortName, sortOrder) => {
		if (sortName !== "" && sortOrder != "DEFAULT") {
			switch (sortName) {
				case "status":
					return {
						order: {
							isActive: sortOrder,
						},
					};

				case "locationCode":
					return {
						order: {
							locationCode: sortOrder,
						},
					};
			}
		} else {
			return {
				order: {
					id: "ASC",
				},
			};
		}
	};

	const getFilterObj = (filters) => {
		let filterList = [];

		if (filters?.parentLocationId != "all") {
			filterList.push({
				locationId: {
					eq: parseInt(filters.parentLocationId),
				},
			});
		}

		if (filters?.productId != "all") {
			filterList.push({
				productId: {
					eq: parseInt(filters.productId),
				},
			});
		}

		if (filters?.merchantId != "all") {
			filterList.push({
				merchantId: {
					eq: parseInt(filters.merchantId),
				},
			});
		}

		return {
			where: {
				and: [...filterList],
			},
		};
	};

	const getSearchObj = (search) => {
		let searchObj = { query: "" };

		if (search.trim() != "") {
			searchObj = {
				query: search,
			};
		}
		return searchObj;
	};
	return {
		getOrderObj,
		getFilterObj,
		getSearchObj,
	};
}
