export const currentInventoryCols = [
	{ name: "Product ID", value: "product.id", canBeSorted: true },
	{
		name: "Serials",
		value: "serialsCount",
		styles: "text-warningColor",
		type: "serial",
		serialList: "serials",
	},
	{
		name: "Current Location",
		value: "location.referenceName",
		styles: "text-warningColor",
		canBeSorted: true,
	},
	{
		name: "Merchant name",
		value: "merchant.name",
		styles: "text-warningColor",
		canBeSorted: true,
	},
	{ name: "Location Type", value: "location.type", canBeSorted: true },
	{ name: "Updated At", value: "updatedAt", canBeSorted: true },
];

export const inventoryLogsCol = [
	{ name: "Product sKU", value: "product.sKU", canBeSorted: true },

	{
		name: "Current Location",
		value: "sourceLocation.referenceName",
		styles: "text-warningColor",
		canBeSorted: true,
	},
	{ name: "Current Type", value: "sourceLocation.type", canBeSorted: true },
	{
		name: "Destination Location",
		value: "destinationLocation.referenceName",
		styles: "text-warningColor",
		canBeSorted: true,
	},
	{
		name: "Destination Type",
		value: "destinationLocation.type",
		canBeSorted: true,
	},

	{
		name: "Serials",
		value: "serialsCount",
		styles: "text-warningColor",
		type: "serial",
		serialList: "serials",
	},
	{ name: "Created At", value: "createdAt", canBeSorted: true },
];

export const shelvesCols = (defaultVal) => {
	return [
		{ name: "Shelf Name", value: "locationCode", canBeSorted: true },
		{
			name: "Warehouse Label",
			value: "serialsLength",
			styles: "text-warningColor",
			type: "defaultVal",
			defaultVal: defaultVal,
		},
		{ name: "Updated At", value: "inventory.updatedAt", canBeSorted: true },
		{
			name: "Status",
			value: "isActive",
			type: "locationType",
			styles: "upercase",
			canBeSorted: true,
		},
	];
};

export const cartsCols = (defaultVal) => {
	return [
		{ name: "Cart Name", value: "locationCode", canBeSorted: true },
		{
			name: "Warehouse Name",
			value: "serialsLength",
			styles: "text-warningColor",
			type: "defaultVal",
			defaultVal: defaultVal,
		},
		{
			name: "Updated At",
			value: "inventory.updatedAt",
			canBeSorted: true,
			canBeSorted: true,
		},
		{
			name: "Status",
			value: "isActive",
			type: "locationType",
			styles: "upercase",
			canBeSorted: true,
		},
	];
};
