import { useEffect, useState } from 'react'
import { MapContainer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'

import useDensityMap from '../hooks/useDensityMap'
import FloatingInfoMenu from '../../../components/common/FloatingMenu'
import MapFilters from 'pages/OrdersMap/common/MapFilterButtons'

export default function DensityLeafletMap ({
  shipments,
  drivers,
  filters,
  setFilters,
  setMapBounds,
}) {
  const {
    map,
    MapControls,
    showShipmentMarkers,
    showDriverMarkers,
  } = useDensityMap({
    filters,
    drivers,
    shipments
  })

  // set tile: Google maps
  useEffect(() => {
    window.L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    })
  }, [])

  useEffect(() => {
    if (map) {
      map.on(
        'moveend zoomend',
        (function () {
          let timer
          return function () {
            clearTimeout(timer)
            timer = setTimeout(function () {
              setMapBounds({
                ne: `${map.getBounds().getNorthEast().lat.toString()}, ${map
                  .getBounds()
                  .getNorthEast()
                  .lng.toString()}`,
                sw: `${map.getBounds().getSouthWest().lat.toString()}, ${map
                  .getBounds()
                  .getSouthWest()
                  .lng.toString()}`
              })
              map.invalidateSize()

            }, 500)
          }
        })()
      )

      return null
    }
  }, [map])

  // add shipment markers to the map
  useEffect(() => {
    showShipmentMarkers()
  }, [shipments, map, filters.shipment.showOnMap])

  useEffect(() => {
    if (filters.shipment.apply == true)
      showShipmentMarkers()
  }, [filters.shipment.apply])

  // add driver markers to the map
  useEffect(() => {
    showDriverMarkers()
  }, [drivers, map, filters.driver.showOnMap])


  useEffect(() => {
    if (filters.driver.apply == true)
      showDriverMarkers()
  }, [filters.driver.apply])

  return (
    <div className='max-h-screen w-full flex-1 z-0'>
      <MapContainer
        center={[24.719127, 46.669663]} // Riyadh
        zoom={12}
        zoomControl={true}
        style={{ height: '100vh', position: 'relative' }}
        tap={false}
        attributionControl={false}
      >
        <FloatingInfoMenu />
        <MapFilters filters={filters} setFilters={setFilters} />
        <ReactLeafletGoogleLayer
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          type={'roadmap'}
        />
        <MapControls />
      </MapContainer>
    </div>
  )
}
