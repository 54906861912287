import { useLazyQuery } from '@apollo/client';
import PaginatedTable from '../../../../components/Table/paginatedTable';
import React, { useState, useEffect } from 'react'
import { stagingOptions } from 'constants/apollo';

import { LOAD_WALLETS } from 'api/queries/FinanceQueries';
import { toast } from 'react-toastify';
import moment from 'moment';
import { objFlatten } from 'helper/objFlatten';

const cols = [
    { name: "ID", value: "id", type: "link", link: "id", },
    {
        name: "Type",
        value: "owner.type",

    },
    {
        name: "Owner Name",
        value: "ownerName",
    },
    {
        name: "Balance",
        value: "balance",
        type: "number"

    },
    {
        name: "Last Delivered on",
        value: "lastDeliveredOn",
        type: "number"
    },
    { name: "Credit Limit", value: "creditLimit", },
    { name: "# Days Over Limit", value: "daysOverLimit" },
    { name: "Mobile Number", value: "phone" },
    {
        name: "Transaction #", value: "transactionsCount",
        type: "number"
    },
];

export default function WalletsTable ({
    value = "1",
    searchText = "",
    fetchFilters = {}
}) {

    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(0);
    const [itemsCount, setItemsCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const [selectedRows, setSelectedRows] = useState([]);
    const [sortCol, setSortCol] = useState({
        label: "updatedAt",
        value: 1,
    });

    const [fetchData, { data, error }] = useLazyQuery(LOAD_WALLETS, { ...stagingOptions, fetchPolicy: "network-only" });
    const fetch = (
        skip = 0,
        search = searchText,
        filters = fetchFilters
    ) => {
        try {

            fetchData(
                {
                    variables: {
                        offset: skip,
                        query: search,
                    }
                }
            )
            setLoading(true)
        } catch (e) {
            toast.error("An error occured");
            setLoading(false)
        }
    };

    useEffect(() => {
        if (data) {
            setLoading(false)
            setItemsCount(data?.fetchAllWallets?.totalCount);

            const dataList = data?.fetchAllWallets?.items;
            const dataListFlattened = dataList?.map((item: any) => {
                let transactionsCount = 0
                let ownerName = ''
                let phone = ''
                if (item.owner?.type?.toLowerCase() === "merchant") {
                    ownerName = item.owner.name
                    phone = item.owner.mobile
                } else {
                    // it is a courier
                    ownerName = item.owner.firstName + ' ' + item.owner.lastName
                    phone = item.owner.mobileNumber
                }
                transactionsCount = item.transactions?.length

                return objFlatten({
                    ...item,
                    ownerName,
                    transactionsCount,
                    phone,
                });
            });

            setTableData(dataListFlattened);
        }
    }, [data]);

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        fetch()
    }, [searchText])

    return (
        <div>
            <PaginatedTable
                hasCheckbox
                sortCol={sortCol}
                count={itemsCount}
                page={page}
                setPage={setPage}
                onPageChange={(skip: any) => fetch(skip)}
                cols={cols}
                data={tableData}
                selectedRows={selectedRows}
                loading={false}
                filterByBin={""}
                onSortClick={(sortVal) => {
                    // handleSort(sortVal);
                    { }
                }}
            /></div>
    )
}
