import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import ShipmentCard from "../../../components/ShipmentCard";
import OrderCard from "./OrderCard";
import { Button } from "@mui/material";

function SelectedShipmentList ({ data, selectedRef, handleShipmentClick, selectedShipmentIds, handleDeselectAllItems }) {
  const { t } = useTranslation("common");
  return (
    <div className={cn({ "border-b border-gray-500": data.length > 0 })}>
      <div className={`flex items-center justify-between ${cn({ hidden: data?.length == 0 })}`}>
        <h3>
          Selected Orders
        </h3>
        <Button onClick={handleDeselectAllItems}>Deselect all</Button>
      </div>
      {data?.map((shipment, idx) => (
        <OrderCard
          selectedRef={selectedRef}
          key={shipment?.id}
          data={shipment}
          onClick={(shipment) => handleShipmentClick(shipment)}
          selectedShipmentIds={selectedShipmentIds}
          inSelectedCards={true}
        />
      ))}
    </div>
  );
}

export default React.memo(SelectedShipmentList);
