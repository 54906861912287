import cn from "classnames";
import { useTranslation } from "react-i18next";
import CustomButton from "../common/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export default function Header ({ title, user, canBack }: HeaderProps) {
	const { t, i18n } = useTranslation("common");
	const navigate = useNavigate();

	return (
		<div className={cn("bg-white", "flex", "justify-between", "p-4")}>
			<div className={cn("bg-white", "flex", "items-center")}>
				{canBack && (
					<button onClick={() => navigate(-1)} className='mr-3'>
						<ArrowBackIcon
							color='warning'
							style={{ color: "black" }}
						/>
					</button>
				)}
				<h2 className='text-2xl'>{`${t(`${title}`)}` || ""}</h2>
			</div>
			{/* <div className='flex space-x-3'>
				<div className='cursor-pointer text-warningColor'>
					<span
						className={`cursor-pointer p-2 hover:bg-gray-100 rounded-md text-warningColor ${
							i18n?.language == "ar" ? "underline" : ""
						}`}
						onClick={() => i18n.changeLanguage("ar")}
						style={{
							minWidth: "30px",
							width: "30px",
							padding: "5px",
						}}
					>
						Ar
					</span>
					{"/"}
					<span
						className={`cursor-pointer p-2 hover:bg-gray-100 rounded-md text-warningColor ${
							i18n?.language == "en" ? "underline" : ""
						}`}
						onClick={() => i18n.changeLanguage("en")}
						style={{
							minWidth: "30px",
							width: "30px",
							padding: "5px",
						}}
					>
						En
					</span>
				</div>
				<div className='flex flex-col'>
					<span className='font-medium'>
						{user?.firstName} {user?.lastName}
					</span>
					<span className='text-gray-500 text-sm'>
						{user?.role} / Last login: {user?.lastLogin} ago
					</span>
				</div>
				<div className='relative w-10'>
					<img
						className='rounded-full'
						width='40'
						height='40'
						src={user?.avatarUrl || "https://picsum.photos/200"}
						alt="Current user's profile photo"
					></img>
					<div className='absolute w-3 h-3 border border-white bg-successColor rounded-full bottom-2 right-0'></div>
				</div>
			</div> */}
		</div>
	);
}

type HeaderProps = {
	title: string;
	user: any;
	canBack?: boolean;
};
