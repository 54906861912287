import React from "react";
import { useTranslation } from "react-i18next";
import { ResetIcon, TickIcon } from "../../svg";
import CustomButton from "../Button";

function ModalFooter({
	handleClose = () => {},
	handleReset = () => {},
	handleSubmit = () => {},
	resetButtonColor = "primary",
	cancelButtonColor = "primary",
	confirmButtonColor = "primary",
}) {
	const { t } = useTranslation("common");

	return (
		<>
			<CustomButton
				color={resetButtonColor}
				onClick={() => {
					handleReset();
				}}
			>
				<div className='flex space-x-2 items-center justify-center'>
					<ResetIcon />
					<span>{t("reset_filters")}</span>
				</div>
			</CustomButton>
			<CustomButton
				color={cancelButtonColor}
				onClick={handleClose}
				variant='outlined'
			>
				<div className='flex space-x-2 items-center justify-center'>
					<span>{t("cancel")}</span>
				</div>
			</CustomButton>
			<CustomButton
				color={confirmButtonColor}
				autoFocus
				onClick={() => {
					handleSubmit();
					handleClose();
				}}
				variant='contained'
			>
				<div className='flex space-x-2 items-center justify-center'>
					<TickIcon />
					<span>{t("confirm")}</span>
				</div>
			</CustomButton>
		</>
	);
}

export default React.memo(ModalFooter);
