import React from 'react'

export default function InfoCard ({ title = '', icon = <></>, info = '', additionalInfo = '', styles = '', onClick = () => { }, additionalStyles = '' }) {
    return (
        <div className={`flex flex-col items-start ${additionalStyles}`} onClick={onClick}>

            <span className={`text-sm text-gray-500 flex items-center justify-center space-x-1`}>
                {icon} <span>{title}</span>
            </span>
            <span className={`capitalize ${styles}`}>{info}</span>
            {
                additionalInfo &&
                <span className="text-sm text-gray-400 flex items-center justify-center space-x-1">
                    {additionalInfo}
                </span>
            }
        </div>
    )
}
