export default function useCarts() {
    const getOrderObj = (sortName, sortOrder) => {
        if (sortName !== "" && sortOrder != "DEFAULT") {
            switch (sortName) {
                case "status":
                    return {
                        order: {
                            isActive: sortOrder,
                        }
                    }

                case "locationCode":
                    return {
                        order: {
                            locationCode: sortOrder,
                        }
                    }

            }
        }
        else {
            return {
                order: {
                    id: "ASC",
                },
            }
        }
    }


    const getFilterObj = (filters) => {
        let filterList = [
            {
                type: { eq: "CART" }
            }
        ]

        if (filters?.parentLocationId != "all") {
            filterList.push({
                parentLocationId: {
                    eq: parseInt(filters.parentLocationId),
                },
            });
        }
        if (filters?.status != "all") {
            filterList.push({
                isActive: {
                    eq: filters.status,
                },
            });
        }


        return {
            where: {
                and: [...filterList],
            }
        }
    }

    const getSearchObj = (search) => {

        let searchObj = { query: "" }


        if (search.trim() != "") {
            searchObj = {
                query: search
            }
        }
        return searchObj
    }
    return {
        getOrderObj,
        getFilterObj,
        getSearchObj
    };
}