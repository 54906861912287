import React, { useContext, useEffect, useState } from "react";
import SearchBar from "../../components/common/SearchBar";
import { logger } from "../../helper/logger";
import { CircularProgress } from "@mui/material";
import { LOAD_DRIVERS } from "../../api/queries/Queries";
import { useLazyQuery } from "@apollo/client";
import CustomSelect from "../../components/common/Select";
import MapFilterMenu from "./common/MapFilterMenu";
import CustomButton from "../../components/common/Button";
import useDriverList from "./hooks/useDriverList";
import ActiveDriversList from "./components/ActiveDriversList";
import AllDriversList from "./components/AllDriversList";
import { stagingOptions } from "../../constants/apollo";
import Modal from "../../components/common/Modal";
import ModalFooter from "../../components/common/Modal/ModalFooter";
import FiltersList from "../../components/FiltersList";
import { defaultDriverMapFilters } from "../../constants/filters";
import SelectedDriversList from "./components/SelectedDriversList";
import DiscreteSliderLabel from "./components/SliderFilter";
import { DRIVER_LAST_SEEN_MAX_MARK, DRIVER_LAST_SEEN_MIN_MARK, driverDeviceTypes, driverLastSeenMarks, driverStatusTypes, driverUniformTypes, driverVehicleTypes } from "constants/constants";
import MapIsTypingContext from "context/MapIsTypingContext";

const days = {
  1: 1,
  2: 2,
  3: 3,
  4: 7,
  5: 14,
  6: 21,
  7: 30
}
const daysReverse = {
  1: 1,
  2: 2,
  3: 3,
  7: 4,
  14: 5,
  21: 6,
  30: 7
}

function DriverList ({
  handleQueryChange,
  setDriverSkip,
  fetch_drivers,
  fetch_city,
  fetch_cities,
  setDriverFilters,
  driverFilters,
  activeDrivers,
  setSelectedDriverId,
  setSelectedDriver,
  selectedDriver,
  drivers,
  cityIds,
  setDrivers,
  selectedDriverId,
  searchText,
  setSearchText,
  loading,
  mapBounds,
  totalDriverCount,
  driverListLoading
}) {
  const {
    t,
    updatePinnedFilters,
    filterAreaOpen,
    setFilterAreaOpen,
    pinnedFilterIds,
    setPinnedFilterIds,
    selectedRef,
    isLoading,
    setIsLoading,
    filterOptions,
    handleClick,
    updateFilters,
  } = useDriverList({
    selectedDriverId,
    setSelectedDriverId,
    setSelectedDriver,
    cityIds,
  });

  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const { setIsInputFocused } = useContext(MapIsTypingContext);

  function handleFilter () {
    handleQueryChange(driverFilters);
    fetch_drivers({
      filters: driverFilters,
    })
  }

  async function handleFilterReset () {
    handleQueryChange(defaultDriverMapFilters);
    setDriverFilters((old) => ({ ...old, ...defaultDriverMapFilters }));

    await fetch_drivers({
      filters: defaultDriverMapFilters,
    });

    setFilterOpen(false);
  }

  useEffect(() => {
    let myInterval = setInterval(
      () => fetch_drivers({ load: false, filters: driverFilters }),
      15000
    );

    return () => clearInterval(myInterval);
  }, [driverFilters, mapBounds]);

  useEffect(() => {
    fetch_drivers({ load: false, filters: driverFilters })
  }, [driverFilters, mapBounds]);

  useEffect(() => {
    setIsInputFocused(filterOpen)
  }, [filterOpen])

  return (
    <>
      <div>
        <div className="mb-4 flex justify-between items-center space-x-3 relative">
          <div className="flex space-x-3">
            <h2 className="pageTitle">{t("knights")}</h2>
            {driverListLoading && <CircularProgress size={28} />}
          </div>

          <div className="flex justify-between">
            <MapFilterMenu open={filterAreaOpen} setOpen={setFilterAreaOpen} />
            <SearchBar
              placeholder={t("search_drivers")}
              onChange={setSearchText}
              value={searchText}
            />
          </div>
        </div>
        {filterAreaOpen && (
          <>
            <div className="pt-2 rounded-md flex flex-col space-x-1">
              <div className="flex flex-col">
                {filterOptions?.map((filter) => {
                  if (pinnedFilterIds.includes(filter.id)) {
                    return (
                      <div className="flex p-3">
                        <CustomSelect
                          pinnedFilterIds={pinnedFilterIds}
                          key={filter.id}
                          filter={filter}
                          defaultValue={driverFilters.driver[filter.value]}
                          hasPin={false}
                          onPinClick={() => {
                            updatePinnedFilters(filter.id);
                          }}
                          handleChange={(selected) => {
                            setDriverFilters((old) => ({
                              ...old,
                              [filter.value]: selected,
                            }));
                          }}
                        />
                      </div>
                    );
                  }
                })}
              </div>


              {pinnedFilterIds.length > 0 && (
                <div className="flex px-3">
                  <CustomButton
                    className="flex-1"
                    onClick={() => setPinnedFilterIds([])}
                  >
                    {t("remove_filters")}
                  </CustomButton>
                </div>
              )}

            </div>
            <div
              onClick={() => setFilterOpen(true)}
              className="cursor-pointer uppercase text-blue-400 font-bold text-center bg-white py-3 rounded-md hover:bg-blue-100 text-sm"
            >
              Open All Filters
            </div>
          </>

        )}
        <div
          className={`flex flex-col space-y-3 h-[calc(100vh-100px)] ${filterAreaOpen && "h-[calc(100vh-150px)] "
            } overflow-y-scroll removeScroll`}
        >
          <SelectedDriversList
            selectedDriver={selectedDriver}
            handleClick={(id) => handleClick(id)}
            selectedRef={selectedRef}
          />
          <ActiveDriversList
            drivers={activeDrivers}
            selectedDriver={selectedDriver}
            handleClick={(id) => handleClick(id)}
            selectedRef={selectedRef}
          />
          {!loading && (
            <AllDriversList
              drivers={drivers}
              selectedDriverId={selectedDriverId}
              handleClick={(id) => handleClick(id)}
              selectedRef={selectedRef}
            >
              {drivers?.length > 0 && (
                <div className="w-full flex items-center justify-center">
                  <CustomButton
                    onClick={() => {
                      setFetchMoreLoading(true);
                      const skip = drivers.length || 0;
                      setDriverSkip(skip);
                      fetch_drivers({ skip: skip, load: false });
                    }}
                  >
                    {totalDriverCount > drivers.length
                      ? fetchMoreLoading
                        ? "Loading..."
                        : "Load More"
                      : ""}
                  </CustomButton>
                </div>
              )}
            </AllDriversList>
          )}

          {loading && (
            <div className="mt-10 w-full flex items-center justify-center">
              <CircularProgress />
            </div>
          )}

          {!loading && drivers?.length == 0 && (
            <div className="mt-10 w-full flex items-center justify-center">
              <span>{t("no_drivers")}</span>
            </div>
          )}
          {/* {error && (
          <div className="mt-10 w-full flex items-center justify-center text-red-500">
            {t("error_occured")} {error?.message}
          </div>
        )} */}
        </div>
      </div>
      <Modal
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        footer={(handleClose) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={handleFilter}
            handleReset={handleFilterReset}
          />
        )}
        onClose={() => { }}
        title={"Filter Drivers"}
      >
        <div className="p-4">
          Driver Last Seen
          <div>
            <DiscreteSliderLabel
              min={DRIVER_LAST_SEEN_MIN_MARK}
              max={DRIVER_LAST_SEEN_MAX_MARK}
              marks={driverLastSeenMarks}
              defaultVal={daysReverse[+driverFilters['driverLastSeen']]}
              handleChange={(day) => {
                setDriverFilters((old) => ({
                  ...old,
                  driverLastSeen: days[day],
                }));
              }}
            />
          </div>
        </div>
        <FiltersList
          selectedFilters={driverFilters}
          filters={[
            {
              name: "Filter by City",
              value: "driverCity",
              id: 1,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id) => {
                if (id && id != "all") {
                  let sel = fetch_city({ id: +id });
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery, page) =>
                fetch_cities({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Vehicle",
              value: "driverVehicle",
              id: 2,
              options: driverVehicleTypes,
            },
            {
              name: t("filter_by_device"),
              value: "driverDevice",
              id: 4,
              options: driverDeviceTypes,
            },
            {
              name: "Filter by Uniform",
              value: "driverUniform",
              id: 6,
              options: driverUniformTypes,
            },
            {
              name: t("filter_by_status"),
              value: "driverStatus",
              id: 7,
              options: driverStatusTypes,
            },
          ]}
          handleChange={(filterVal, filterName) => {
            setDriverFilters((old) => ({
              ...old,
              [filterName]: filterVal,
            }));
          }}
        />
      </Modal>
    </>
  );
}

export default React.memo(DriverList);
