import React, { useState } from "react";
import CustomTable from "../../components/Table";
import DashboardFilters from "./DashboardFilters";
import DashboardMenu from "./DashboardMenu";


const columnNames = [
  { name: "Tracking #", value: "tracking_no" },
  { name: "Shipment Store", value: "shipment_store" },
  {
    name: "# Orders",
    value: "orders_no",
  },
  {
    name: "Driving Time",
    value: "driving_time",
    styles: "text-[9px]"
  },
  {
    name: "Orders Distance",
    value: "orders_distance",
    styles: "text-[9px]"
  },
  { name: "Shipment Reward", value: "shipment_reward" },
  { name: "City", value: "city" },
  { name: "Knight", value: "knight" },
  { name: "Shipment Status", value: "shipment_status", styles: "text-red-500 capitalize" },
]

const data = [
  [
    {
      tracking_no: `DK-6JKXPXMP-524\n
      Shipment ID: 8184`,
      shipment_store: "Omniful, 2",
      orders_no: "3",
      driving_time: `Required 00:00:00 HH:MM:SS\n
      Given 00:40:00 HH:MM:SS`,
      orders_distance: `Required 00:00:00 HH:MM:SS\n
      Given 00:40:00 HH:MM:SS`,
      shipment_reward: "54.0 SAR",
      city: "Jeddah",
      knight: "Not Assigned",
      shipment_status: "Cancelled",
    },
  ],
]

export default function DashboardPage () {
  const [filterByBin, setFilterByBin] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  return (
    <div className="flex flex-col space-y-2">
      <div className="bg-gray-50 main-wrapper">
        <DashboardMenu />
      </div>
      <div className="main-wrapper">
        <DashboardFilters />
      </div>
      <h1>Dashboard</h1>
      <CustomTable
        hasCheckbox={true}
        cols={columnNames}
        data={data}
        onPressCheckbox={() => { }}
        selectedRows={selectedRows}
        loading={false}
        filterByBin={filterByBin}
      />
    </div>
  );
}
