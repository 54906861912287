import { CircularProgress } from '@mui/material'
import React from 'react'

export default function FallBackComponent() {
  return (
    <div className='w-full h-[100vh] flex items-center justify-center'>
        <CircularProgress/>
    </div>
  )
}
