import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/common/Button";
import CustomSearch from "../../components/common/Search";
import SearchBar from "../../components/common/SearchBar";
import CustomSelect from "../../components/common/Select";
import NormalSelect from "../../components/common/Select/NormalSelect";
import { ResetIcon, SearchIcon } from "../../components/svg";
import { logger } from "../../helper/logger";

const filters = [
  {
    name: "Filter by City",
    option: [
      { value: 1, label: "Riyadh" },
      { value: 2, label: "Any" },
    ],
  },
  {
    name: "Filter by Status",
    option: [
      { value: 1, label: "Riyadh" },
      { value: 2, label: "Any" },
    ],
  },
  {
    name: "Filter by Merchant",
    option: [
      { value: 1, label: "Riyadh" },
      { value: 2, label: "Any" },
    ],
  },
];
export default function DashboardFilters() {
  const { t } = useTranslation("common");
  return (
    <div className="flex justify-between">
      <div className="flex space-x-5 w-full">
        <h2 className="text-2xl">{t("filters")}</h2>
        <div className="flex space-x-3 w-3/4">
          {filters.map((filter) => (
            <NormalSelect key={filter.name} filter={filter} />
          ))}
          <CustomButton
            color="primary"
            onClick={() => logger("dashboard reset filters clicked")}
          >
            <div className="flex space-x-2 items-center justify-center uppercase">
              <ResetIcon color="rgb(63, 81, 181)" />
              <span>{t("reset_filters")}</span>
            </div>
          </CustomButton>
        </div>
      </div>
      <CustomSearch
        filterName="Search"
        infoText=""
        placeholder="Type here..."
        onChange={() => {}}
      />
    </div>
  );
}
