import React from "react";
import Select from "react-select";
import { CheckedPinIcon, PinIcon } from "../../svg";

const options = [

];

export default function NormalSelect ({
    defaultValue,
    filter,
    handleChange,
    styles = {},
    ...rest
}) {

    return (
        <div className="relative w-[402px]" style={styles}>
            <Select
                isMulti={filter?.isMulti || false}
                defaultValue={defaultValue || null}
                maxMenuHeight={200}
                options={filter?.options || options}
                styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 999, backgroundColor: "white" }),
                    valueContainer: (base) => ({ ...base, marginLeft: 0 }),
                }}
                format="YYYY/MM/DD"
                onChange={(e) => {
                    if (filter?.isMulti) {
                        e.value = e.map(e => e.value)
                    }
                    handleChange(e)
                }}
                {...rest}
            />
            <div className="absolute w-auto h-4 bg-white -top-[11px] px-1 left-3 text-sm text-gray-500 cursor-default select-none">
                {filter?.name}
            </div>
        </div>
    );
}
