import NormalSelect from '../../../components/common/Select/NormalSelect';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import CustomInput from "../../../components/common/Input";
import moment from 'moment';
import CustomButton from '../../../components/common/Button';
import PaginatedTable from '../../../components/Table/paginatedTable';
import { vehicleTypeOptions } from '../../../constants/enums';
import Modal from '../../../components/common/Modal'
import ChangeVehicle from './ChangeVehicle';
import * as Yup from 'yup'
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ALL_VEHICLES } from 'api/queries/Queries';
import { stagingOptions } from 'constants/apollo';
import { logger } from 'helper/logger';
import { CREATE_VEHICLE_ASSIGNMENT } from 'api/mutations/Mutations';
import { toast } from 'react-toastify';

const cols = [
    { name: "Vehicle", value: "vehicle.plateNo" },
    { name: "From Date", value: "from", type: "date-simple" },
    { name: "To Date", value: "to", type: "date-simple" },
    { name: "Reason", value: "reason" },
];
export default function DriverAllowancesTab ({ driver, reload, vehiclesTableData }) {
    const [totalCount, setTotalCount] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)
    const [fetch_all_vehicles, { data, error }] = useLazyQuery(GET_ALL_VEHICLES, stagingOptions);
    const [type, setType] = useState([])
    const [typeOptions, setTypeOptions] = useState([])
    const [model, setModel] = useState([])
    const [modelOptions, setModelOptions] = useState([])
    const [allVehicles, setAllVehiceles] = useState([])

    const [
        createCourierVehicleAssignment,
        {
            data: assignRes,
            loading,
            error: assignError
        },
    ] = useMutation(CREATE_VEHICLE_ASSIGNMENT, stagingOptions);

    const formik = useFormik({
        initialValues: {
            vehicleType: "",
            vehicleModel: "",
            plateNumber: "",
            imei: "",
            reason: ""
        },
        validationSchema: Yup.object({
            vehicleType: Yup.string().required("Required"),
            vehicleModel: Yup.string().required("Required"),
            plateNumber: Yup.string().required("Required"),
            imei: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            const selectedVehicle = allVehicles.find((el) => {
                if (el.plateNo === formik.values.plateNumber
                    && el.imei === formik.values.imei
                    && el.model === formik.values.vehicleModel
                    && el.vehicleType === formik.values.vehicleType)
                    return el
                else return null
            })

            if (!selectedVehicle) {
                return toast.error("No matching vehicle found")
            }
            createCourierVehicleAssignment({
                variables: {
                    input: {
                        attributes: {
                            courierId: parseInt(driver.id),
                            vehicleId: parseInt(selectedVehicle.id),
                            reason: formik.values.reason,
                            from: moment().format("YYYY-MM-DD")
                        }
                    }
                }
            })
            // setIsSubmitting(true)
            alert(JSON.stringify(values, null, 2));
        },
    });
    useEffect(() => {
        fetch_all_vehicles()
    }, [])

    useEffect(() => {
        if (data?.fetchAllVehicles?.items?.length > 0) {
            const vehicles = data.fetchAllVehicles.items
            setAllVehiceles(vehicles)
            vehicles.map((item) => {
                if (!type.includes(item.vehicleType)) {
                    setType((old) => [...old, item.vehicleType])
                }
                if (!model.includes(item.model)) {
                    setModel((old) => [...old, item.model])
                }
            }
            )
        }
    }, [data])

    useEffect(() => {
        let newList = []
        vehicleTypeOptions.map(option => {
            if (type.includes(option.value) && !newList.includes(option.value)) {
                newList.push(option)
            }
        })
        setTypeOptions(newList)

    }, [type])

    useEffect(() => {
        if (formik?.values?.vehicleType) {
            let newList = []
            allVehicles.map(el => {
                if (el.vehicleType == formik.values.vehicleType) {
                    newList.push({ value: el.model, label: el.model })
                }
            })
            setModelOptions(newList)
        }

    }, [model, formik.values.vehicleType])

    useEffect(() => {
        if (assignRes?.createVehicleAssignment) {
            toast.success("Successfully added!")
            formik.resetForm()
            reload()
        }
    }, [assignRes])

    useEffect(() => {

        if (assignError)
            toast.error(assignError)
    }, [assignError])

    return (
        <>
            <div className='flex flex-col space-y-2 bg-gray-50'>
                <div className="flex flex-col w-full justify-between mt-1 bg-white">
                    <div className='flex w-ful justify-between p-4'>
                        <span className="text-xl">Vehicle</span>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.handleSubmit()
                        }}
                        className='main-wrapper flex flex-col justify-between space-y-5'
                    >
                        <div
                            className="flex space-x-2 py-4"
                        >
                            <div className="w-1/4 flex flex-col">
                                {/* <CustomInput
                                id="vehicleType"
                                name="vehicleType"
                                type="text"
                                inputName="Vehicle Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.vehicleType}
                            /> */}
                                <NormalSelect
                                    styles={{ width: "100%" }}
                                    key={2}
                                    filter={
                                        {
                                            name: "Vehicle Type",
                                            value: "vehicleType",
                                            id: 1,
                                            options: typeOptions

                                        }
                                    }
                                    defaultValue={vehicleTypeOptions.find(option => option.value == formik.values.vehicleType
                                    )}
                                    handleChange={(e) => formik.setFieldValue("vehicleType", e.value)}
                                />

                                {formik.touched.vehicleType && formik.errors.vehicleType &&
                                    <div className="error" key={0}>{formik.errors.vehicleType}</div>
                                }
                            </div>
                            <div className="w-1/4 flex flex-col">
                                <NormalSelect
                                    styles={{ width: "100%" }}
                                    key={3}
                                    filter={
                                        {
                                            name: "Vehicle Model",
                                            value: "vehicleModel",
                                            id: 1,
                                            options: modelOptions,
                                        }
                                    }
                                    defaultValue={[].find(option => option.value == formik.values.vehicleModel
                                    )}
                                    handleChange={(e) => formik.setFieldValue("vehicleModel", e.value)}
                                />
                                {formik.touched.vehicleModel && formik.errors.vehicleModel &&
                                    <div className="error" key={0}>{formik.errors.vehicleModel}</div>
                                }
                            </div>
                            <div className="w-1/4 flex flex-col">
                                <CustomInput
                                    id="plateNumber"
                                    name="plateNumber"
                                    type="text"
                                    inputName="Plate Number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.plateNumber}
                                />
                                {formik.touched.plateNumber && formik.errors.plateNumber &&
                                    <div className="error" key={0}>{formik.errors.plateNumber}</div>
                                }
                            </div>
                            <div className="w-1/4 flex flex-col">
                                <CustomInput
                                    id="imei"
                                    name="imei"
                                    type="text"
                                    inputName="Tracking SIM - IMEI"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.imei}
                                />
                                {formik.touched.imei && formik.errors.imei &&
                                    <div className="error" key={0}>{formik.errors.imei}</div>
                                }
                            </div>
                            <div className="w-1/4 flex flex-col">
                                <CustomInput
                                    id="reason"
                                    name="reason"
                                    type="text"
                                    inputName="Reason"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.reason}
                                />
                                {formik.touched.reason && formik.errors.reason &&
                                    <div className="error" key={0}>{formik.errors.reason}</div>
                                }
                            </div>
                            <div className="w-1/7">
                                <CustomButton
                                    type='submit'
                                    variant='contained'
                                    color="warning"
                                    onClick={() => { }}
                                    style={{ width: "100%", height: "38px" }}
                                >
                                    Change
                                </CustomButton>
                            </div>
                        </div>

                    </form>
                </div>
                <div className="flex flex-col w-full justify-between mt-1 bg-white">
                    <span className="text-xl p-4">Vehicle History</span>
                    <PaginatedTable
                        // hasCheckbox
                        filterByBin={''}
                        sortCol={{ label: "", value: 0 }}
                        onSortClick={(sortVal) => {
                            // handleSort(sortVal);
                        }}
                        count={totalCount || 0}
                        page={0}
                        setPage={() => { }}
                        onPageChange={() => { }}
                        cols={cols}
                        data={vehiclesTableData}
                        selectedRows={[]}
                        setSelectedRows={() => { }}
                        loading={false}
                    />
                </div>
            </div>
            <Modal
                onDismiss={() => {
                    setModalOpen(false);
                }}
                hidden
                autoOpen={modalOpen}
                onClose={() => { }}
                title={"Change Vehicle"}
            >
                <ChangeVehicle handleUpdateDriverExternalID={() => { }} reload={() => { }} handleClose={() => setModalOpen(false)} driverId={driver?.id} />
            </Modal>
        </>
    )
}
