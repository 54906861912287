import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import {
  FETCH_ALL_ORDERS,
  GET_ALL_CITIES,
  GET_ALL_MERCHANTS,
} from "../../api/queries/Queries";
import SortationMenu from "./components/SortationMenu";
import OrdersTable from "./components/OrdersTable";
import SortationFilters from "./components/SortationFilters";
import CustomTable from "../../components/Table";
import { logger } from "../../helper/logger";
import PaginatedTable from "../../components/Table/paginatedTable";
import { objFlatten } from "../../helper/objFlatten";
import NewSearch from "../../components/common/Search/new-search";
import { GridFilterListIcon } from "@mui/x-data-grid";
import { useDebounce } from "../../hooks/useDebounce";
import Modal from "../../components/common/Modal";
import ModalFooter from "../../components/common/Modal/ModalFooter";
import OrderFilters from "./components/OrderFilters";
import MuiFilterButton from "../../components/common/FilterButton/MuiFilterButton";
import { stagingOptions } from "../../constants/apollo";
import { getSortationOrder } from "../../helper/GetSortOrder";
import { toast } from "react-toastify";

const cols = [
  { name: "Tracking #", value: "trackingNo", type: "link", link: "trackingNo" },
  { name: "Shipment Store", value: "store.name" },

  {
    name: "Driving Time",
    value: "driving_time",
    // styles: "text-[9px] line-clamp-2 w-32",
  },
  {
    name: "Orders Distance",
    value: "orders_distance",
    type: "distance",
  },
  { name: "Shipment Reward", value: "shipment.potentialReward", type: "money" },
  { name: "City", value: "hub.city" },
  { name: "Knight", value: "shipment.courier.firstName" },
  { name: "Attempts", value: "attempts" },
  {
    name: "Status",
    value: "orderStatus",
    styles: "capitalize",
    type: "status",
  },
];
const defaultFilters = {
  orderStatus: "all",
  includeThirdParty: false,
  shipmentType: "all",
  merchantId: "all",
  cityId: "all",
};
export default function Orders() {
  const [fetchAllOrders] = useLazyQuery(FETCH_ALL_ORDERS, stagingOptions);
  const [fetchAllMerchants] = useLazyQuery(GET_ALL_MERCHANTS, stagingOptions);
  const [fetchAllCities] = useLazyQuery(GET_ALL_CITIES, stagingOptions);

  const [merchants, setMerchants] = useState([]);
  const [cities, setCities] = useState([]);
  const [orders, setOrders] = useState([[]]);
  const [filterOpen, setFilterOpen] = useState(false);

  const [barcode, setBarcode] = React.useState();
  const [rows, setRows] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterByBin, setFilterByBin] = React.useState("");

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const [reload, setReload] = React.useState(0);

  const [sortCol, setSortCol] = useState({
    label: "id",
    value: 1,
  });
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);

  const [orderFilters, setOrderFilters] = useState(defaultFilters);

  const fetchOrders = (
    skip = 0,
    search = searchText,
    sortName = sortCol.label,
    sortOrder = getSortationOrder(sortCol.value),
    filters = orderFilters
  ): any => {
    try {
      // FILTERATION
      let filterObj: any = {};

      if (filters?.orderStatus != "all") {
        filterObj.orderStatus = filters.orderStatus;
      }
      filterObj.includeThirdParty = filters.includeThirdParty;

      if (filters?.shipmentType != "all") {
        filterObj.shipmentType = filters.shipmentType;
      }
      if (filters?.merchantId != "all") {
        filterObj.merchantId = Number(filters.merchantId);
      }
      if (filters?.cityId != "all") {
        filterObj.cityId = Number(filters.cityId);
      }

      logger("FILTERING", filterObj);

      fetchAllOrders({
        variables: {
          offset: skip,
          query: searchText,
          ...filterObj,
        },
      }).then((res) => {
        if (res?.data?.fetchAllOrders?.items) {
          setTotalCount(res.data.fetchAllOrders.totalCount || 0);
          const orderList = res.data.fetchAllOrders.items;

          let orderListFlattened = orderList.map((item: any) => {
            let size = "";
            if (item.widthInMm && item.heightInMm && item.lengthInMm) {
              size = `${item.widthInMm} x ${item.heightInMm} x ${item.lengthInMm}`;
            }
            let status;
            if (item.isActive) {
              status = "active";
            } else {
              status = "inactive";
            }

            return objFlatten({ ...item, status: status, size: size });
          });

          setOrders(orderListFlattened);
          
        } else {
          logger("PRODUCTS FETCHED", res);
          toast.error(res?.error?.message || "An error occured");
        }
      });
    } catch (e) {
      toast.error("An error occured");
    }
  };

  function handleFilter() {
    fetchOrders(
      0,
      searchText,
      sortCol.label,
      getSortationOrder(sortCol.value),
      orderFilters
    );
  }
  const handleSort = (sortVal = "") => {
    if (sortCol.label === sortVal) {
      // sort Desc
      const sortOrder = getSortationOrder(sortCol.value + 1);
      sortCol.value += 1;

      fetchOrders(0, searchText, sortVal, sortOrder);
    } else {
      setSortCol((old: any) => ({
        ...old,
        label: sortVal,
        value: 0,
      }));
      fetchOrders(0, searchText, sortVal, "ASC");
    }

    setPage(0);
  };

  async function handleFilterReset() {
    setOrderFilters(defaultFilters);

    // TO AVIOD GQL CACHE BUG, FETCHING TWICE
    await fetchOrders(
      0,
      searchText,
      sortCol.label,
      getSortationOrder(sortCol.value),
      defaultFilters
    );
    fetchOrders(
      0,
      searchText,
      sortCol.label,
      getSortationOrder(sortCol.value),
      defaultFilters
    );

    setFilterOpen(false);
  }

  useEffect(() => {
    fetchAllMerchants({ variables: { limit: null } }).then((res) =>
      setMerchants(res?.data?.fetchAllMerchants || [])
    );
    fetchAllCities({ variables: { limit: null } }).then((res) =>
      setCities(res?.data?.fetchAllCities || [])
    );
  }, []);

  useEffect(() => {
    fetchOrders(0, searchText);
  }, [debouncedSearchText]);

  return (
    <>
      <div>
        <div className="main-wrapper flex justify-between space-x-3 border-lightBgColor border-t-2 ">
          <div className="w-[450px] flex space-x-3">
            <NewSearch
              filterName="Search"
              infoText=""
              placeholder="Type here..."
              onChange={(e: any) => setSearchText(e.target.value)}
              handleClearSearch={() => setSearchText("")}
            />
            <div
              onClick={() => setFilterOpen(true)}
              className="flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg"
            >
              <GridFilterListIcon style={{ color: "#FF9800" }} />
              <span className="text-warningColor font-medium uppercase ml-2">{`Filters By`}</span>
            </div>
          </div>
          <div className="flex space-x-3">
            <MuiFilterButton
              variant="outlined"
              options={[
                "Assign selected orders to 3PL carriers",
                "Operations",
                "Download AWB for selected orders",
              ]}
            />
            <MuiFilterButton
              options={["Export List", "Export List", "Rebase and merge"]}
            />
          </div>
        </div>
        <PaginatedTable
          sortCol={sortCol}
          onSortClick={(sortVal) => {
            handleSort(sortVal);
          }}
          count={totalCount || 0}
          page={page}
          setPage={setPage}
          onPageChange={fetchOrders}
          hasCheckbox
          cols={cols}
          data={orders}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          loading={false}
          filterByBin={filterByBin}
        />
      </div>
      <Modal
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        footer={(handleClose: any) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={handleFilter}
            handleReset={handleFilterReset}
          />
        )}
        onClose={() => {}}
        title={"Filter Orders"}
      >
        <OrderFilters
          selectedFilters={orderFilters}
          filters={[
            {
              name: "Filter by Status",
              value: "orderStatus",
              id: 1,
              options: [
                { value: "all", label: "All" },
                { value: "new_order", label: "New order" },
                { value: "processing", label: "Processing" },
                { value: "ready_for_delivery", label: "Ready for delivery" },
                { value: "picked_up", label: "Picked up" },
                { value: "intransit", label: "In transit" },
                { value: "completed", label: "Completed" },
                { value: "cancelled", label: "Cancelled" },
                { value: "exception", label: "Exception" },
                { value: "returned", label: "Returned" },
                { value: "damaged_or_lost", label: "Damaged or lost" },
                {
                  value: "not_ready_for_pickup",
                  label: "Not ready for pickup",
                },
                { value: "pickup_failed", label: "Pickup failed" },
                {
                  value: "processing_at_warehouse",
                  label: "Processing at warehouse",
                },
                { value: "in_warehouse", label: "In warehouse" },
                { value: "misrouted", label: "Misrouted" },
                {
                  value: "in_transit_to_warehouse",
                  label: "In transit to warehouse",
                },
              ],
            },
            {
              name: "Filter by Shipment Type",
              value: "shipmentType",
              id: 2,
              options: [
                { value: "all", label: "All" },
                { value: "instant_delivery", label: "Instant delivery" },
                { value: "same_day", label: "Same day" },
                { value: "next_day", label: "Next day" },
                { value: "pick_up", label: "Pick up" },
              ],
            },
            {
              name: "Include 3rd party",
              value: "includeThirdParty",
              id: 3,
              options: [
                { value: false, label: "No" },
                { value: true, label: "Yes" },
              ],
            },
            {
              name: "Filter by Merchant",
              value: "merchantId",
              id: 4,
              options: [{ value: "all", label: "All" }, ...merchants],
            },
            {
              name: "Filter by City",
              value: "cityId",
              id: 5,
              options: [{ value: "all", label: "All" }, ...cities],
            },
          ]}
          handleChange={(filterVal: any, filterName: string) => {
            setOrderFilters((old) => ({
              ...old,
              [filterName]: filterVal,
            }));
          }}
        />
      </Modal>
    </>
  );
}
