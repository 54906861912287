import moment from "moment";

export const getShipmentFetchFilters = (filters) => {
  const fetchFilters = {};

  if (filters.shipmentCity != "all") {
    fetchFilters.cityId = +filters.shipmentCity;
  }

  if (filters.shipmentStatus != "all") {
    fetchFilters.shipmentStatuses = [+filters.shipmentStatus];
  }

  if (filters.shipmentMerchant != "all") {
    fetchFilters.merchantId = +filters.shipmentMerchant;
  }

  if (filters.shipmentType != "all") {
    fetchFilters.shipmentType = filters.shipmentType;
  }
  if (filters.shipmentHubOpen != "all") {
    fetchFilters.hubOpen = filters.shipmentHubOpen === "true"
  }

  if (filters.shipmentReadySince) {
    fetchFilters.readySince = moment().subtract(+filters.shipmentReadySince, "days").format("YYYY-MM-DD")
  }

  return fetchFilters

}


export const getOrderFetchFilters = (filters) => {
  const fetchFilters = {};

  if (filters.shipmentOrderStatus && filters.shipmentOrderStatus != "all") {
    fetchFilters.orderStatuses = filters.shipmentOrderStatus;
  }

  if (filters.shipmentMerchant && filters.shipmentMerchant != "all") {
    fetchFilters.merchantIds = [+filters.shipmentMerchant];
  }

  if (filters.shipmentType && filters.shipmentType != "all") {
    fetchFilters.shipmentTypes = filters.shipmentType;
  }

  if (filters.shipmentHubOpen != "all") {
    fetchFilters.isHubActive = filters.shipmentHubOpen === "true"
  }

  if (filters.shipmentReadySince) {
    fetchFilters.startDate = moment().subtract(+filters.shipmentReadySince, "days").format("YYYY-MM-DD")
  }

  return fetchFilters

}

export const getDriverFetchFilters = (filters) => {
  const fetchFilters = {};

  if (filters.driverCity != "all") {
    fetchFilters.cityId = +filters.driverCity;
  }

  if (filters.driverVehicle != "all") {
    fetchFilters.vehicleType = +filters.driverVehicle;
  }

  if (filters.driverNationality != "all") {
    fetchFilters.nationality = filters.driverNationality;
  }


  if (filters.driverVehicleModel != "all") {
    fetchFilters.vehicleModel = filters.driverVehicleModel;
  }

  if (filters.driverUniform != "all") {
    fetchFilters.dress = +filters.driverUniform;
  }

  if (filters.driverDevice != "all") {
    fetchFilters.deviceType = filters.driverDevice;
  }

  if (filters.driverPartner != "all") {
    fetchFilters.partnerId = +filters.driverPartner;
  }

  if (filters.driverLastSeen) {
    fetchFilters.lastSeen = moment().subtract(+filters.driverLastSeen, "days").format("YYYY-MM-DD")
  }

  if (filters.driverStatus != "all") {
    switch (filters?.driverStatus) {
      case "free":
        fetchFilters.isOnline = true
        fetchFilters.isBusy = false
        break
      case "busy":
        fetchFilters.isOnline = true
        fetchFilters.isBusy = true
        break
      case "offline":
        fetchFilters.isOnline = false
        break
    }

  }

  return fetchFilters

}

export const getBillingFetchFilters = (filters) => {
  const fetchFilters = {};

  if (filters.merchantName != "all") {
    fetchFilters.merchantName = filters.merchantName;
  }

  if (filters.startPeriod != "all") {
    fetchFilters.startPeriod = filters.startPeriod;
  }

  if (filters.endPeriod != "all") {
    fetchFilters.endPeriod = filters.endPeriod;
  }


  if (filters.paymentState != "all") {
    fetchFilters.paymentState = filters.paymentState;
  }

  return fetchFilters

}