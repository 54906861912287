import React from "react";
import FloatingActionMenu from "../../../components/FloatingActionMenu";

export default function MapFilters({ filters, setFilters }) {
  return (
    <div
      className={`absolute bg-white rounded-xl px-3 py-2 top-2 left-2 z-[400] flex items-center space-x-4`}
    >
      <div
        className={`hover:cursor-pointer font-bold p-1 ${
          filters.shipment.showOnMap && filters.driver.showOnMap
            ? "text-primaryColor "
            : "text-gray-400"
        }`}
        onClick={() => {
          setFilters((prevState) => ({
            ...prevState,
            shipment: {
              ...prevState.shipment,
              showOnMap: true,
            },
            driver: {
              ...prevState.driver,
              showOnMap: true,
            },
          }));
        }}
      >
        <span>ALL</span>
      </div>
      <div
        className="hover:cursor-pointer p-1"
        onClick={() => {
          setFilters((prevState) => ({
            ...prevState,
            shipment: {
              ...prevState.shipment,
              showOnMap: true,
            },
            driver: {
              ...prevState.driver,
              showOnMap: false,
            },
          }));
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 6H17C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6H5C3.9 6 3.01 6.9 3.01 8L3 20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V8C21 6.9 20.1 6 19 6ZM12 3C13.66 3 15 4.34 15 6H9C9 4.34 10.34 3 12 3ZM12 13C9.24 13 7 10.76 7 8H9C9 9.66 10.34 11 12 11C13.66 11 15 9.66 15 8H17C17 10.76 14.76 13 12 13Z"
            fill={`${
              filters.shipment.showOnMap && !filters.driver.showOnMap
                ? "#3F51B5"
                : "#BDBDBD"
            }`}
          />
        </svg>
      </div>
      <div
        onClick={() => {
          setFilters((prevState) => ({
            ...prevState,
            shipment: {
              ...prevState.shipment,
              showOnMap: false,
            },
            driver: {
              ...prevState.driver,
              showOnMap: true,
            },
          }));
        }}
        className="hover:cursor-pointer p-1"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z"
            fill={`${
              !filters.shipment.showOnMap && filters.driver.showOnMap
                ? "#3F51B5"
                : "#BDBDBD"
            }`}
          />
        </svg>
      </div>
    </div>
  );
}
