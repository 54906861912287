export const textColors = {
  default: "text-lightColor",
  primary: "text-primaryColor",
  success: "text-successColor",
  danger: "text-dangerColor",
  warning: "text-warningColor",
};

export const borderColors = {
  default: "border-lightColor",
  primary: "border-primaryColor",
  success: "border-successColor",
  danger: "border-dangerColor",
  warning: "border-warningColor",
};

export const backgroundColors = {
  default: "bg-lightColor",
  primary: "bg-primaryColor",
  success: "bg-successColor",
  danger: "bg-dangerColor",
  warning: "bg-warningColor",
  onlineBusy: "bg-[#F44336]",
  onlineFree: "bg-[#CDDC39]",
  offline: "bg-[#607D8B]",
  intransit: "bg-primaryColor",
  cancelled: "bg-dangerColor",
  transparent: "bg-transparent"
};

export const lighBackgroundColors = {
  default: "bg-gray-300",
  primary: "bg-primaryColor",
  success: "bg-green-200",
  danger: "bg-red-200",
  warning: "bg-orange-200",
  onlineBusy: "bg-[#F44336]",
  onlineFree: "bg-[#CDDC39]",
  offline: "bg-[#607D8B]"
};

export const routeColors = [
  "#303F9F",
  "#c2185b",
  "#fbc02d",
  "#d32f2f",
  "#1976d2",
  "#795548",
  "#689f38",
  "#00796b",
  "#ffa000",
  "#4a148c",
  "#0277bd",
  "#00838f",
  "#69f0ae",
  "#607d8b"
];

export const routeBgColors = [
  "bg-[#303F9F]",
  "bg-[#c2185b]",
  "bg-[#fbc02d]",
  "bg-[#d32f2f]",
  "bg-[#1976d2]",
  "bg-[#795548]",
  "bg-[#689f38]",
  "bg-[#00796b]",
  "bg-[#ffa000]",
  "bg-[#4a148c]",
  "bg-[#0277bd]",
  "bg-[#00838f]",
  "bg-[#69f0ae]",
  "bg-[#607d8b]"
];

export const getBackgroundColors = (color) => {
  switch (color) {
    case "intransit": return "bg-primaryColor"
    case "cancelled": return "bg-dangerColor"
    case "ready_for_delivery": return "bg-successColor"
    case "completed": return "bg-successColor"
    default: return "bg-lightColor"
  }
}
