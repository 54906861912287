import { useMutation } from "@apollo/client";
import useLazyQuery from "hooks/useLazyQuery";
import { GridFilterListIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	ADD_UPDATE_CART,
	ADD_UPDATE_SHELF,
	CREATE_OR_UPDATE_LOCATIONS,
	DELETE_LOCATIONS,
} from "../../../api/mutations/WMSMutations";
import { GET_WAREHOUSE } from "../../../api/queries/WMSqueries";
import WarehouseIcon from "../../../assets/icons/WarehouseIcon";
import CustomButton from "../../../components/common/Button";
import MuiFilterButton from "../../../components/common/FilterButton/MuiFilterButton";
import Modal from "../../../components/common/Modal";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import CustomSearch from "../../../components/common/Search";
import NewSearch from "../../../components/common/Search/new-search";
import FiltersList from "../../../components/FiltersList";
import { CartIcon, PenIcon } from "../../../components/svg";
import CustomTable from "../../../components/Table";
import PaginatedTable from "../../../components/Table/paginatedTable";
import { wmsOptions } from "../../../constants/apollo";
import { logger } from "../../../helper/logger";
import { useDebounce } from "../../../hooks/useDebounce";
import { cartsCols, currentInventoryCols, shelvesCols } from "../colData";
import useCurrentInventory from "../CurrentInventory/useCurrentInventory";
import CreateCartForm from "./CreateCartForm";
import CreateShelfForm from "./CreateShelfForm";
import Carts from "./WarehouseDetails/Carts";
import CurrentInventory from "./WarehouseDetails/CurrentInventory";
import InventoryLogs from "./WarehouseDetails/InventoryLogs";
import Shelves from "./WarehouseDetails/Shelves";

const columnNames = [
	{ name: "Tracking #", value: "tracking_no" },
	{ name: "Shipment Store", value: "shipment_store" },
	{
		name: "# Orders",
		value: "orders_no",
	},
	{
		name: "Driving Time",
		value: "driving_time",
		styles: "text-[9px]",
	},
	{
		name: "Orders Distance",
		value: "orders_distance",
		styles: "text-[9px]",
	},
	{ name: "Shipment Reward", value: "shipment_reward" },
	{ name: "City", value: "city" },
	{ name: "Knight", value: "knight" },
	{
		name: "Shipment Status",
		value: "shipment_status",
		styles: "text-red-500 capitalize",
	},
];

const data = [
	[
		{
			tracking_no: `DK-6JKXPXMP-524\n
        Shipment ID: 8184`,
			shipment_store: "Omniful, 2",
			orders_no: "3",
			driving_time: `Required 00:00:00 HH:MM:SS\n
        Given 00:40:00 HH:MM:SS`,
			orders_distance: `Required 00:00:00 HH:MM:SS\n
        Given 00:40:00 HH:MM:SS`,
			shipment_reward: "54.0 SAR",
			city: "Jeddah",
			knight: "Not Assigned",
			shipment_status: "Cancelled",
		},
	],
];

const menuItems = [
	{ id: 0, label: "details" },
	{ id: 1, label: "orders" },
	{ id: 2, label: "current inventory" },
	{ id: 3, label: "inventory log" },
	{ id: 4, label: "shelves" },
	{ id: 5, label: "carts" },
];

export default function WarehouseDetails() {
	const [activeTab, setActiveTab] = useState(0); // o is for "details"

	const [cartModalOpen, setCartModalOpen] = useState(false);
	const [shelfModalOpen, setShelfModalOpen] = useState(false);

	const [filterByBin, setFilterByBin] = useState("");
	const [filterOpen, setFilterOpen] = useState(false);

	const [selectedRows, setSelectedRows] = useState([]);
	const [page, setPage] = useState(0);

	const [warehouse, setWarehouse] = useState<any>();
	const [currentInventory, setCurrentInventory] = useState<any>();
	const [searchText, setSearchText] = useState("");

	const debouncedSearchText = useDebounce(searchText, 500);

	const [getWarehouse] = useLazyQuery(GET_WAREHOUSE, wmsOptions);
	const [updateLocations] = useMutation(ADD_UPDATE_CART, wmsOptions);
	const [deleteLocations] = useMutation(DELETE_LOCATIONS, wmsOptions);

	const { order: id } = useParams();

	const defaultFilters = {
		0: {},
		1: {},
		2: {},
		3: {},
		4: {},
		5: {
			status: "all",
			parentLocationId: Number(id),
		},
	};

	function fetchWarehouse(WID: number = Number(id)) {
		getWarehouse({
			variables: {
				id: WID,
			},
		}).then((res) => {
			const warehouseData = res.data.locations.items[0];
			setWarehouse(warehouseData);
			setCurrentInventory(warehouseData.inventory);
		});
	}

	function updateCartOrShelfStatus(
		id: number,
		type = "CART",
		isActive = true
	) {
		updateLocations({
			variables: {
				locations: [
					{
						id: +id,
						type: type,
						isActive: !isActive,
					},
				],
			},
		}).then((res) => fetchWarehouse());
		// mutation {
		//   createOrUpdateLocations(
		//     locations: [
		//       {
		//         id: 156
		//         type:SHELF
		//         isActive: false

		//       }
		//     ]
		//   ){
		//     id
		//     isActive
		//   }
		// }
	}

	function deleteCartOrShelf(id: number, type = "CART") {
		deleteLocations({
			variables: {
				locations: [
					{
						id: +id,
						type: type,
					},
				],
			},
		}).then((res) => fetchWarehouse());
	}

	useEffect(() => {
		fetchWarehouse();
		logger("IDi", id);
	}, []);

	useEffect(() => {
		logger(warehouse);
	}, [warehouse]);

	useEffect(() => {}, [debouncedSearchText]);

	return (
		<>
			<div className='flex w-full justify-between space-x-3 py-2 px-4 border-y-2 border-lightBgColor'>
				{activeTab === 0 && <div></div>}
				{activeTab != 0 && (
					<div className='w-[450px] flex space-x-3 py-3'>
						<NewSearch
							filterName='Search'
							infoText=''
							placeholder='Type here...'
							onChange={(e: any) => setSearchText(e.target.value)}
							handleClearSearch={() => setSearchText("")}
						/>
						<div
							onClick={() => setFilterOpen(true)}
							className='flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg'
						>
							<GridFilterListIcon style={{ color: "#FF9800" }} />
							<span className='text-warningColor font-medium uppercase ml-2'>{`Filters By`}</span>
						</div>
					</div>
				)}

				<div className='flex space-x-3 w-1/2'>
					<CustomButton
						variant='default'
						onClick={() => setShelfModalOpen(true)}
					>
						<div className='flex space-x-2 items-center justify-center uppercase'>
							<WarehouseIcon />
							<span className='uppercase text-warningColor'>
								Add new shelves
							</span>
						</div>
					</CustomButton>
					<CustomButton
						variant='default'
						onClick={() => setCartModalOpen(true)}
					>
						<div className='flex space-x-2 items-center justify-center uppercase'>
							<CartIcon />
							<span className='uppercase text-warningColor'>
								Add a new cart
							</span>
						</div>
					</CustomButton>
				</div>
			</div>
			<div className='text-center flex w-full'>
				{menuItems.map((item) => (
					<div
						key={item.id}
						className={`
            p-4 border-b-2 text-gray-400 font-medium  uppercase cursor-pointer hover:bg-lightBgColor ${
				activeTab == item.id
					? "border-warningColor"
					: "border-transparent"
			}`}
						onClick={() => setActiveTab(item.id)}
					>
						{item.label}
					</div>
				))}
			</div>
			{/* <OrderDeta/> */}

			{activeTab == 0 && warehouse?.id && (
				<div className=' w-full p-4 bg-gray-50 h-full flex flex-col space-y-4'>
					<div className='flex w-full space-x-4'>
						<div className=' w-3/4 bg-white p-4 rounded-md'>
							<div className='flex justify-between'>
								<div className='flex space-x-2 items-center'>
									<span className='text-xl'>
										Warehouse Details
									</span>
									<div className='bg-successColor h-6 px-2 text-center rounded-md w-auto text-white'>
										{warehouse?.isActive
											? "Active"
											: "Inactive"}
									</div>
								</div>
								<div className='flex space-x-3'>
									<CustomButton
										variant='default'
										onClick={() => {}}
									>
										<div className='flex space-x-2 items-center justify-center uppercase'>
											<PenIcon />
											<span className='uppercase text-warningColor'>
												Edit warehouse details
											</span>
										</div>
									</CustomButton>
								</div>
							</div>
							<div className='flex w-full mt-5'>
								<div className='w-1/3 flex flex-col space-y-6'>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Warehouse ID
										</span>
										<span className=''>
											{warehouse?.id}
										</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Email
										</span>
										<span className=''>
											r.wagner@barqapp.com
										</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Phone number
										</span>
										<span className=''>
											+40 123 993 88 99
										</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Phone number
										</span>
										<span className=''>
											+40 123 993 88 99
										</span>
									</div>
								</div>
								<div className='w-1/3 flex flex-col space-y-4'>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Shipment Id
										</span>
										<span className='text-warningColor'>
											3444
										</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Delivery OTP
										</span>
										<span className=''>342342</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											COD Fee
										</span>
										<span className=''>0 SAR</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											COD Fee
										</span>
										<span className=''>0 SAR</span>
									</div>
								</div>
								<div className='w-1/3 flex flex-col space-y-4'>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Neighborhood
										</span>
										<span className='text-warningColor'>
											Al Olaya
										</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Pickup OTP
										</span>
										<span className='r'>
											YU157717468- 486090-4
										</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Payment Status
										</span>
										<span className=''>
											Not an on-demand order
										</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-lightColor text-sm'>
											Payment Status
										</span>
										<span className=''>
											Not an on-demand order
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className='bg-white w-1/4 rounded-md p-[10px]'>
							<div className='w-full h-[300px] bg-gray-200'>
								Map
							</div>

							<div className='flex flex-col'>
								<span className='text-lightColor text-sm'>
									Address
								</span>
								<span className=' text-sm'>
									Prince Mutaib bin Abdulaziz Rd, Al-Safa,
									Jeddah 23451, Saudi Arabia
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
			{activeTab == 2 && warehouse?.inventory && (
				<CurrentInventory
					warehouse={warehouse}
					debouncedSearchText={debouncedSearchText}
					setFilterOpen={setFilterOpen}
					filterOpen={filterOpen}
				/>
			)}
			{activeTab == 3 && warehouse?.inventory && (
				<InventoryLogs
					warehouse={warehouse}
					debouncedSearchText={debouncedSearchText}
					setFilterOpen={setFilterOpen}
					filterOpen={filterOpen}
				/>
			)}
			{activeTab == 4 && warehouse?.shelves && (
				<Shelves
					warehouse={warehouse}
					debouncedSearchText={debouncedSearchText}
					filterOpen={filterOpen}
					setFilterOpen={setFilterOpen}
					shelfModalOpen={shelfModalOpen}
					setShelfModalOpen={setShelfModalOpen}
				/>
			)}

			{activeTab == 5 && warehouse?.carts && (
				<Carts
					warehouse={warehouse}
					debouncedSearchText={debouncedSearchText}
					filterOpen={filterOpen}
					setFilterOpen={setFilterOpen}
					cartModalOpen={cartModalOpen}
					setCartModalOpen={setCartModalOpen}
				/>
			)}

			<Modal
				onDismiss={() => {
					setCartModalOpen(false);
				}}
				hidden
				autoOpen={cartModalOpen}
				footer={null}
				onClose={() => {}}
				title={"Create a new cart"}
			>
				<CreateCartForm
					locationId={warehouse?.id}
					handleClose={() => {
						setCartModalOpen(false);
						fetchWarehouse();
						setActiveTab(5);
					}}
				/>
			</Modal>
			<Modal
				onDismiss={() => {
					setShelfModalOpen(false);
				}}
				hidden
				autoOpen={shelfModalOpen}
				footer={null}
				onClose={() => {}}
				title={"Create a new shelf"}
			>
				<CreateCartForm
					locationId={warehouse?.id}
					type='SHELF'
					handleClose={() => {
						setShelfModalOpen(false);
						fetchWarehouse();
						setActiveTab(4);
					}}
				/>
			</Modal>
			{/* Modals */}
		</>
	);
}
