import React, { useEffect, useState } from "react";
import { GridFilterListIcon } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSearchParams } from "react-router-dom";

import NewSearch from "../../../components/common/Search/new-search";
import Modal from "../../../components/common/Modal";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import { useDebounce } from "hooks/useDebounce";
import TransactionsTable from './components/TransactionsTable';
import FilterList from '../../../components/common/Filter/FilterList';

export default function Transactions () {
  const [value, setValue] = useState("1");
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchText, setSearchText] = useState(
    Object.fromEntries([...searchParams]).searchText || ""
  );
  const debouncedSearchText = useDebounce(searchText, 500);

  const [filterOpen, setFilterOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleQueryChange = (obj) => {
    // update router query based on implemented filters
    let mergedObj = { ...Object.fromEntries([...searchParams]), ...obj };
    let modified= {};

    Object.keys(mergedObj).map((key) => {
      if (mergedObj[key] != "all" && mergedObj[key] != "") {
        if (key in obj) {
          modified[key] = obj[key];
        } else {
          modified[key] = mergedObj[key];
        }
      }
    });

    setSearchParams(modified);
  };

  useEffect(() => {
    handleQueryChange({ searchText: searchText });
  }, [debouncedSearchText]);

  return (
    <>
      <div className='main-wrapper flex flex-col space-y-3 bg-gray-50'>
        <div className='flex'>
          <div className='flex flex-col w-1/4'>
            <span className="text-sm uppercase">Total Compensations</span>
            <span className="text-warningColor text-[34px]">51,075.89</span>
          </div>
          <div className='flex flex-col w-1/4'>
            <span className="text-sm uppercase">total knights cashouts</span>
            <span className="text-warningColor text-[34px]">-18,132.22</span>
          </div>
          <div className='flex flex-col w-1/4'>
            <span className="text-sm uppercase">total cash-ins</span>
            <span className="text-warningColor text-[34px]">5,033.0</span>
          </div>
          <div className='flex flex-col w-1/4'>
            <span className="text-sm uppercase">Total others</span>
            <span className="text-warningColor text-[34px]">0.0</span>
          </div>
        </div>
        <div className='flex'>
          <div className='flex flex-col w-1/4'>
            <span className="text-sm uppercase">knights cashouts</span>
            <span className="flex items-center">
              <span className='text-warningColor text-[34px]'>0.0</span>
              <span className='text-warningColor text-sm ml-2 bg-orange-100 rounded-md px-1'>Today</span>
            </span>
          </div>
          <div className='flex flex-col w-1/4'>
            <span className="text-sm uppercase">return rewards</span>
            <span className="flex items-center">
              <span className='text-warningColor text-[34px]'>0.0</span>
              <span className='text-warningColor text-sm ml-2 bg-orange-100 rounded-md px-1'>Today</span>
            </span>
          </div>
          <div className='flex flex-col w-1/4'>
            <span className="text-sm uppercase">cash collected</span>
            <span className="flex items-center">
              <span className='text-warningColor text-[34px]'>0.0</span>
              <span className='text-warningColor text-sm ml-2 bg-orange-100 rounded-md px-1'>Today</span>
            </span>
          </div>
          <div className='flex flex-col w-1/4'>
            <span className="text-sm uppercase">Delivery rewards</span>
            <span className="flex items-center">
              <span className='text-warningColor text-[34px]'>0.0</span>
              <span className='text-warningColor text-sm ml-2 bg-orange-100 rounded-md px-1'>Today</span>
            </span>
          </div>
        </div>
      </div>

      <div className="main-wrapper flex justify-between space-x-3 border-t-2 border-gray-50 border-b-2">
        <div className="w-[450px] flex space-x-3">
          <NewSearch
            hasValue={true}
            filterName="Search"
            infoText=""
            placeholder="Type here..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            handleClearSearch={() => setSearchText("")}
          />
          <div
            onClick={() => setFilterOpen(true)}
            className="flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg"
          >
            <GridFilterListIcon color="primary" />
            <span className="text-[#FF9800] font-medium uppercase ml-2 min-w-[100px]">{`Filters By`}</span>
          </div>
        </div>
      </div>

      <Box sx={{ width: "100%" }}>
        <TransactionsTable
          value="1"
          fetchFilters={{}}
          searchText={debouncedSearchText} 
        />
      </Box>
      <Modal
        footer={(handleClose) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={() => { }
              // handleFilter
            }
            handleReset={() => { }
              // handleFilterReset
            }
          />
        )}
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        onClose={() => { }}
        title={"Filter Transactions"}
      >
        <FilterList
          selectedFilters={{}}
          handleChange={(filterVal, filterName) => { }
            //   setDriverFilters((old) => ({
            //     ...old,
            //     [filterName]: filterVal,
            //   }));
            // }
          }
          filters={[
            // {
            //   name: "Filter by City",
            //   value: "driverCity",
            //   id: 1,
            //   isAsync: true,
            //   isSearchable: true,
            //   getSelectedVal: (id) => {
            //     if (id && id != "all") {
            //       let sel = fetch_city(id);
            //       return sel;
            //     } else return { value: "all", label: "All" };
            //   },
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_cities({ page: page, searchQuery: searchQuery }),
            // },
            // {
            //   name: "Filter by Vehicle Model",
            //   value: "driverVehicleModel",
            //   id: 122,
            //   isAsync: true,
            //   isSearchable: false,
            //   getSelectedVal: (id: any) => {
            //     if (id && id != "all") {
            //       return { value: id, label: id };
            //     } else return { value: "all", label: "All" };
            //   },
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_vehicleModels({ page: page, searchQuery: searchQuery }),
            // },
            // {
            //   name: "Filter by Nationality",
            //   value: "driverNationality",
            //   id: 12,
            //   isAsync: true,
            //   isSearchable: false,
            //   getSelectedVal: (id: any) => {
            //     if (id && id != "all") {
            //       return { value: id, label: id };
            //     } else return { value: "all", label: "All" };
            //   },
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_nationalities({ page: page, searchQuery: searchQuery }),
            // },
            // {
            //   name: "Filter by Partner",
            //   value: "driverPartner",
            //   id: 11,
            //   isAsync: true,
            //   isSearchable: true,
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_driver_partners({ page: page, searchQuery: searchQuery }),
            // },

            {
              name: "Filter by Merchant",
              value: "driverDevice",
              id: 4,
              options: [
                { value: "all", label: "All" },
                { value: "android", label: "Android" },
                { value: "ios", label: "Ios" },
              ],
            },
          ]}
        />
      </Modal>
    </>
  );
}
