import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/common/Button";
import SearchBar from "../../components/common/SearchBar";
import CustomSelect from "../../components/common/Select";
import StatisticsCard from "../../components/StatisticsCard";
import { CloseHubIcon, OpenHubIcon, ResetIcon } from "../../components/svg";
import { logger } from "../../helper/logger";

let fakeData = [
  {
    status: "Pending",
    count: "+234",
    title: "Pending Accept",
    time: "58+ mins",
  },
  {
    status: "Accepted",
    count: "+8",
    title: "Accepted",
    time: "20+ mins",
  },
  {
    status: "Assigned",
    count: "+24",
    title: "Assigned & Not Complete",
  },
  {
    status: "Unassigned",
    count: "+34",
    title: "Unassigned & Not Complete",
  },
  {
    status: "OnHold",
    count: "+234",
    title: "On Hold / Not Ready",
  },
  {
    status: "Completed",
    count: "234",
    title: "Completed",
  },
  {
    status: "Cancelled",
    count: "14",
    title: "Cancelled",
  },
];
export default function DashboardMenu() {
  const { t } = useTranslation("common");
  return (
   
      <div className="flex justify-between items-center">
        <div className="flex space-x-3">
          {fakeData.map((el, index) => {
            return <StatisticsCard key={index} item={el} />;
          })}
        </div>
        <div className="flex flex-col items-stretch justify-center space-y-3">
          <CustomButton variant="outlined" bgColor="bg-gray-200">
            <div className="flex flex-1 space-x-2 uppercase">
              <OpenHubIcon />
              <span>{t("open_all_hubs")}</span>
            </div>
          </CustomButton>
          <CustomButton variant="outlined" color="danger" bgColor="bg-red-100">
            <div className="flex flex-1 space-x-2 uppercase">
              <CloseHubIcon />
              <span>{t("close_all_hubs")}</span>
            </div>
          </CustomButton>
        </div>
      </div>
    
   
  );
}
