import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { logger } from "helper/logger";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress color="success" variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel({
  totalProgressCount = 5,
  onFinish = () => {},
}) {
  const [progress, setProgress] = React.useState(0);
  const [progressCount, setProgressCount] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgressCount((prev) =>
        prev >= totalProgressCount ? totalProgressCount : prev + 1
      );
      setProgress((prev) =>
        prev >= 100 ? 100 : prev + 100 / totalProgressCount
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (progressCount >= totalProgressCount) {
      onFinish();
    }
  }, [progress]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
      <Box sx={{ color: "text.disabled" }}>
        {Math.floor(progressCount)} picklist out of {totalProgressCount}
      </Box>
    </Box>
  );
}
