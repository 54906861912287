import { gql } from "apollo-boost";

export const UPDATE_DRIVER = gql`
  mutation UpdateCourier($input: UpdateCourierInput!) {
    updateCourier(input: $input) {
      courier {
          id
      }
    }
  }
`;


export const CREATE_DRIVER_BONUS = gql`
  mutation createCourierBonus(
    $input: CreateCourierBonusInput!
    ){
      createCourierBonus(input: $input) {
        clientMutationId
        courierBonus {
          amount
      }
    }
  }
`;


export const UPLOAD_DRIVER_IMAGE = gql`
  mutation uploadImageCourier(
    $input: CourierUploadImageInput!
    ){
      uploadImageCourier(input: $input) {
        clientMutationId
        status
    }
  }
`;

export const CREATE_DRIVER_EXTERNAL_ID = gql`
  mutation createCourierExternalId(
    $input: CreateCourierExternalIdInput!
    ){
      createCourierExternalId(input: $input) {
        clientMutationId
        courierExternalId {
          id
      }
    }
  }
`;

export const CREATE_VEHICLE_ASSIGNMENT = gql`
  mutation createCourierVehicleAssignMent(
    $input: CreateVehicleAssignmentInput!
    ){
      createVehicleAssignment(input: $input) {
        clientMutationId
        vehicleAssignment {
          id
          courier{id}
      }
    }
  }
`;

export const Update_DRIVER_EXTERNAL_ID = gql`
  mutation updateCourierExternalId(
    $input: UpdateCourierExternalIdInput!
    ){
      updateCourierExternalId(input: $input) {
        clientMutationId
        courierExternalId {
          id
      }
    }
  }
`;


export const CREATE_NEW_KNIGHT = gql`
  mutation createCourier(
    $input: CreateCourierInput!
    ){
      createCourier(input: $input) {
        clientMutationId
        courier {
          id
      }
    }
  }
`;