import React, { useEffect } from 'react'
import MUIDatePicker from '../../../components/common/DatePicker';
import { useFormik } from 'formik';
import CustomInput from "../../../components/common/Input";
import moment from 'moment';
import NormalSelect from '../../../components/common/Select/NormalSelect';
import { green, red } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';
import { FormControlLabel, Switch } from '@mui/material';
import CustomButton from '../../../components/common/Button';
import { SaveIcon } from '../../../components/svg';
import { CREATE_DRIVER_EXTERNAL_ID } from 'api/mutations/Mutations';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { stagingOptions } from 'constants/apollo';

export default function ChangeVehicle ({ handleClose, driverId, reload }) {
    const formik = useFormik({
        initialValues: {
            name: "",
            value: "",
            startedAt: moment(),
            finishedAt: moment().add(10, 'd'),
            status: 'active'
        },
        // validationSchema: Yup.object({
        //     destinationLocationType: Yup.string().required("Required"),
        //     sourceLocationType: Yup.string().required("Required"),
        //     destinationLocationId: Yup.number().required("Required"),
        //     sourceLocationId: Yup.number().required("Required"),
        //     productId: Yup.number().required("Required"),
        //     merchantId: Yup.number().required("Required"),
        //     serialList: Yup.array().of(Yup.string()).required("Required"),
        // }),
        onSubmit: (values) => {

            handleChangeVehicle(
                {
                    ...values,
                    startedAt: moment(values.startedAt).format("YYYY-MM-DD"),
                    finishedAt: moment(values.finishedAt).format("YYYY-MM-DD")
                })

        },
    });

    const [
        createCourierExternalID,
        {
            data,
            loading,
            error
        },
    ] = useMutation(CREATE_DRIVER_EXTERNAL_ID, stagingOptions);

    const handleChangeVehicle = (attributes) => {
        if (Object.keys(attributes).length > 0)
            createCourierExternalID({
                variables: {
                    input: {
                        attributes: {
                            courierId: parseInt(driverId),
                            ...attributes,
                            // forDate: moment(attributes.forDate).format("YYYY-MM-DD")
                        }
                    }
                }
            })
    }

    useEffect(() => {
        if (data?.createCourierExternalId?.courierExternalId?.id) {
            toast.success("Successfully created a new External ID")
            reload()
            handleClose()
        }
    }, [data])

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    // formik.handleSubmit()
                }}
            >
                <h3 className='mb-3'>Personal Information</h3>
                <div className='flex space-x-3'>
                    <div className="w-1/2 flex flex-col">
                        <NormalSelect
                            styles={{ width: "100%" }}
                            key={44}
                            filter={
                                {
                                    name: "Where",
                                    value: "name",
                                    id: 1,
                                    options: [
                                        { value: "hunger_station", label: "Hunger Station" },
                                        { value: "marsool", label: "Marsool" },
                                        { value: "jahez", label: "Jahez" },
                                    ],
                                }
                            }
                            defaultValue={[
                                { value: "hunger_station", label: "Hunger Station" },
                                { value: "marsool", label: "Marsool" },
                                { value: "jahez", label: "Jahez" },
                            ].find(option => option.value == formik.values.name
                            )}
                            handleChange={(e) => formik.setFieldValue("name", e.value)}
                        />
                        {formik.touched.name && formik.errors.name &&
                            <div className="error" key={0}>{formik.errors.name}</div>
                        }
                    </div>
                    <div className="w-1/2 flex flex-col">
                        <CustomInput
                            id="value"
                            name="value"
                            type="text"
                            inputName="Value"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.value}
                        />
                        {formik.touched.value && formik.errors.value &&
                            <div className="error" key={0}>{formik.errors.value}</div>
                        }
                    </div>
                </div>
                <div className='flex space-x-3 mt-5'>
                    <div className="w-1/2 flex flex-col">
                        <MUIDatePicker
                            id="startedAt"
                            name="startedAt"
                            label="From Date"
                            value={moment(formik.values.startedAt, "YYYY MM DD")}
                            onChange={(date) => {
                                formik.setFieldValue("startedAt", moment(date).format("YYYY-MM-DD"))
                            }}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.startedAt && formik.errors.startedAt &&
                            <div className="error" key={0}>{formik.errors.startedAt}</div>
                        }
                    </div>
                    <div className="w-1/2 flex flex-col">
                        <MUIDatePicker
                            id="finishedAt"
                            name="finishedAt"
                            label="To Date"
                            value={moment(formik.values.finishedAt, "YYYY MM DD")}
                            onChange={(date) => {
                                formik.setFieldValue("finishedAt", moment(date).format("YYYY-MM-DD"))
                            }}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.finishedAt && formik.errors.finishedAt &&
                            <div className="error" key={0}>{formik.errors.finishedAt}</div>
                        }
                    </div>
                </div>
 
                <div className='w-full flex justify-end space-x-3 mt-5'>
                    <div className='w-1/8'>
                        <CustomButton
                            type='button'
                            onClick={handleClose}
                            variant='outlined'
                            color='warning'
                        >
                            cancel
                        </CustomButton>
                    </div>
                    <div className='w-1/8'>

                        <CustomButton
                            type='submit'
                            variant='contained'
                            color='warning'
                        >
                            <div className='flex space-x-2 w-full justify-center'>
                                <SaveIcon />
                                <span>save</span>
                            </div>
                        </CustomButton>
                    </div>
                </div>
            </form>
        </div>
    )
}
