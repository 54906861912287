export const getStatusColor = (status) => {
	if (status?.length > 0) {
		status = status.toLowerCase();
		switch (status) {
			case "active":
				return "success";
			case "shipping":
				return "warning";
			case "ready_for_shipping":
				return "success";
			case "shipped":
				return "success";
			case "cancelled":
				return "danger";
			case "assigned":
				return "warning";
			case "ready_for_delivery":
				return "warning";
			case "completed":
				return "success";
			case "in_cart":
				return "warning";
			case "put_away":
				return "success";
			case "pending":
				return "default";
			case "cancellation":
				return "danger";
			case "on_packing":
				return "warning";
			case "banned":
				return "danger";
			case "active":
				return "success";
			case "paid":
				return "success";
			case "unpaid":
				return "danger";
			default:
				return "default";
		}
	}
};
