import React, { useEffect, useState } from 'react'
import MUIDatePicker from '../../../components/common/DatePicker';
import { useFormik } from 'formik';
import CustomInput from "../../../components/common/Input";
import SelectAsyncPaginate from "../../../components/common/Select/AsyncPaginateSelect";
import moment from 'moment';
import NormalSelect from '../../../components/common/Select/NormalSelect';
import { green, red } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material';
import CustomButton from '../../../components/common/Button';
import { SaveIcon } from '../../../components/svg';
import { CREATE_NEW_KNIGHT } from 'api/mutations/Mutations';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { stagingOptions } from 'constants/apollo';
import * as Yup from 'yup'
import useAsyncFilterFns from 'helper/asyncFilterFns';
import { LoadingButton } from '@mui/lab';


export default function CreateKnight ({ handleClose, reload }) {
    const [bankIbanConfirmation, setBankIbanConfirmation] = useState("")
    const [acceptCash, setAcceptCash] = useState(true)
    const [acceptCard, setAcceptCard] = useState(true)
    const [minOrdersPerDay, setMinOrdersPerDay] = useState(50)

    const { fetch_cities,
        fetch_merchants,
        fetch_hubs,
        fetch_neighborhoods,
    } = useAsyncFilterFns()

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            mobileNumber: "",
            idNumber: "",
            nationality: "",
            email: "",
            passportNo: "",
            passportCountry: "",
            dateOfBirth: moment().subtract(20, 'y'),
            bankIbanNo: "",
            bankCountry: "",
            bankSwift: "",
            geideaTsrmId: "",

            isGeideaEnabled: false,
            onlyDedicatedHub: false,
            onlyDedicatedMerchant: false,
            onlyDedicatedNeighborhood: false,

            hubIds: [],
            merchantIds: [],
            neighborhoodIds: [],
            cityId: "",
            // minOrdersPerDay: 50,
            isBusy: false,
            isOnline: false,
            orderPaymentType: [],
            password: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            nationality: Yup.string().required("Required"),
            mobileNumber: Yup.string().required("Required"),
            password: Yup.string().required("Required"),
            email: Yup.string().email().matches(/@[^.]*\./).required("Required"),
            idNumber: Yup.number()
                .required("Required")
                .test('len', 'Must be no more than 12 characters', (val) => val?.toString().length <= 12)
                .test('len', 'Must be no less than 10 characters', (val) => val?.toString().length >= 10)
            ,
            bankIbanNo: Yup.string().required("Required"),
            bankSwift: Yup.string().required("Required"),
            bankCountry: Yup.string().required("Required"),
            cityId: Yup.number().required("Required"),

        }),
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2));
            console.log(acceptCard, acceptCash, values)
            if (values.bankIbanNo !== bankIbanConfirmation)
                return formik.setFieldError("bankIbanNo", "Please make sure that you filled in IBAN number  and IBAN number confirmation arre the same")

            let orderType = "accepts_all_orders"
            if (acceptCard && acceptCash)
                orderType = "accepts_all_orders"
            else if (acceptCard)
                orderType = "accepts_credit_card_orders_only"
            else if (acceptCash)
                orderType = "accepts_cash_on_delivery_orders_only"
            else
                return formik.setFieldError("orderPaymentType", "Please choose at least 1 payment method")

            handleCreateNewKnight(
                {
                    ...values,
                    orderPaymentType: orderType
                    // startedAt: moment(values.startedAt).format("YYYY-MM-DD"),
                    // finishedAt: moment(values.finishedAt).format("YYYY-MM-DD")
                })


        },
    });

    const [
        createKnight,
        {
            data,
            loading,
            error
        },
    ] = useMutation(CREATE_NEW_KNIGHT, stagingOptions);

    const handleCreateNewKnight = (attributes) => {
        if (Object.keys(attributes).length > 0)
            createKnight({
                variables: {
                    input: {
                        attributes: {
                            ...attributes,
                            // forDate: moment(attributes.forDate).format("YYYY-MM-DD")
                        }
                    }
                }
            })
    }

    useEffect(() => {
        if (data?.createCourier?.courier?.id) {
            toast.success(`Successfully created a new Courier ${data?.createCourier?.courier?.id}`)
            reload()
            handleClose()
        }
    }, [data])

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit()
                }}
            >
                <h3 className='mb-3'>Personal Information</h3>
                <div className='flex space-x-3'>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="firstName"
                            name="firstName"
                            type="text"
                            inputName="First Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                        />
                        {formik.touched.firstName && formik.errors.firstName &&
                            <div className="error" key={0}>{formik.errors.firstName}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="lastName"
                            name="lastName"
                            type="text"
                            inputName="Last Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                        />
                        {formik.touched.lastName && formik.errors.lastName &&
                            <div className="error" key={0}>{formik.errors.lastName}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="mobileNumber"
                            name="mobileNumber"
                            type="text"
                            inputName="Phone Number *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobileNumber}
                        />
                        {formik.touched.mobileNumber && formik.errors.mobileNumber &&
                            <div className="error" key={0}>{formik.errors.mobileNumber}</div>
                        }
                    </div>
                </div>
                <div className='flex space-x-3 mt-5'>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="idNumber"
                            name="idNumber"
                            type="text"
                            inputName="ID Number *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.idNumber}
                        />
                        {formik.touched.idNumber && formik.errors.idNumber &&
                            <div className="error" key={0}>{formik.errors.idNumber}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="nationality"
                            name="nationality"
                            type="text"
                            inputName="Nationality *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.nationality}
                        />
                        {formik.touched.nationality && formik.errors.nationality &&
                            <div className="error" key={0}>{formik.errors.nationality}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="email"
                            name="email"
                            type="text"
                            inputName="Email *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email &&
                            <div className="error" key={0}>{formik.errors.email}</div>
                        }
                    </div>
                </div>
                <div className='flex space-x-3 mt-5'>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="passportNo"
                            name="passportNo"
                            type="text"
                            inputName="Passport Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.passportNo}
                        />
                        {formik.touched.passportNo && formik.errors.passportNo &&
                            <div className="error" key={0}>{formik.errors.passportNo}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="passportCountry"
                            name="passportCountry"
                            type="text"
                            inputName="Passport Country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.passportCountry}
                        />
                        {formik.touched.passportCountry && formik.errors.passportCountry &&
                            <div className="error" key={0}>{formik.errors.passportCountry}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <MUIDatePicker
                            label="Date Of Birth *"
                            value={moment(formik.values.dateOfBirth, "YYYY MM DD")}
                            onChange={(date) => {
                                formik.setFieldValue("dateOfBirth", moment(date).format("YYYY-MM-DD"))
                            }}
                        />
                        {formik.touched.dateOfBirth && formik.errors.dateOfBirth &&
                            <div className="error" key={0}>{`${formik.errors.dateOfBirth}`}</div>
                        }
                    </div>
                </div>

                <h3 className='mb-3 mt-5'>Bank Info</h3>

                <div className='flex space-x-3'>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="bankIbanNo"
                            name="bankIbanNo"
                            type="text"
                            inputName="IBAN Number *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bankIbanNo}
                        />
                        {formik.touched.bankIbanNo && formik.errors.bankIbanNo &&
                            <div className="error" key={0}>{formik.errors.bankIbanNo}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="bankIbanConfirmation"
                            name="bankIbanConfirmation"
                            type="text"
                            inputName="IBAN Number confirmation *"
                            onChange={(e) => setBankIbanConfirmation(e.target.value)}
                            value={bankIbanConfirmation}
                        />
                        {formik.touched.bankIbanNo && formik.errors.bankIbanNo &&
                            <div className="error" key={0}>{formik.errors.bankIbanNo}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="bankCountry"
                            name="bankCountry"
                            type="text"
                            inputName="Bank Country *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bankCountry}
                        />
                        {formik.touched.bankCountry && formik.errors.bankCountry &&
                            <div className="error" key={0}>{formik.errors.bankCountry}</div>
                        }
                    </div>
                </div>
                <div className='flex space-x-3 mt-5'>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="bankSwift"
                            name="bankSwift"
                            type="text"
                            inputName="Bank Swift *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bankSwift}
                        />
                        {formik.touched.bankSwift && formik.errors.bankSwift &&
                            <div className="error" key={0}>{formik.errors.bankSwift}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <CustomInput
                            id="geideaTsrmId"
                            name="geideaTsrmId"
                            type="text"
                            inputName="Geidea TSRM (Terminal)"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.geideaTsrmId}
                        />
                        {formik.touched.geideaTsrmId && formik.errors.geideaTsrmId &&
                            <div className="error" key={0}>{formik.errors.geideaTsrmId}</div>
                        }
                    </div>
                    <div className="w-1/3 flex flex-col">
                    </div>
                </div>

                <h3 className='mb-3 mt-5'>Extra Info</h3>

                <div className='flex space-x-3'>
                    <div className='w-1/2'>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onChange={(e) => formik.setFieldValue("isGeideaEnabled", e?.target?.checked)} />} label="Enable Geidea" />
                        </FormGroup>
                    </div>
                    <div className='w-1/2'>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onChange={(e) => formik.setFieldValue("onlyDedicatedHub", e?.target?.checked)} />} label="Only Dedicated Hubs Orders" />
                        </FormGroup>
                    </div>
                </div>
                <div className='flex space-x-3'>
                    <div className='w-1/2'>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onChange={(e) => formik.setFieldValue("onlyDedicatedMerchant", e?.target?.checked)} />} label="Only Dedicated Merchants Orders" />
                        </FormGroup>
                    </div>
                    <div className='w-1/2'>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onChange={(e) => formik.setFieldValue("onlyDedicatedNeighborhood", e?.target?.checked)} />} label="Only Dedicated Neighborhood Orders" />
                        </FormGroup>
                    </div>
                </div>

                <div className='flex space-x-3 mt-5'>
                    <div className="w-1/2 flex flex-col pr-2">
                        <SelectAsyncPaginate
                            isSearchable={false}
                            key={23}
                            onChange={(e) => {
                                formik.setFieldValue("hubIds", [parseInt(e.value)])
                            }}
                            getDefaultValue={() => { }}
                            loadMore={(searchQuery, page) =>
                                fetch_hubs({ page: page, searchQuery: searchQuery })}
                            filterName={"Associated with Hub"}
                            defaultOptions={[]}
                        />

                        {
                            formik.touched.hubIds && formik.errors.hubIds &&
                            <div className="error" key={0}>{formik.errors.hubIds}</div>
                        }
                    </div>
                    <div className="w-1/2 flex flex-col pr-2">
                        <SelectAsyncPaginate
                            isSearchable={true}
                            key={23}
                            onChange={(e) => {
                                formik.setFieldValue("merchantIds", [parseInt(e.value)])
                            }}
                            loadMore={(searchQuery, page) =>
                                fetch_merchants({ page: page, searchQuery: searchQuery })}
                            filterName={"Associated with Merchant"}
                            defaultOptions={[]}
                        />

                        {
                            formik.touched.merchantIds && formik.errors.merchantIds &&
                            <div className="error" key={0}>{formik.errors.merchantIds}</div>
                        }
                    </div>
                </div>
                <div className='flex space-x-3 mt-5'>
                    <div className="w-1/2 flex flex-col pr-2">
                        <SelectAsyncPaginate
                            isSearchable={true}
                            key={23}
                            onChange={(e) => {
                                formik.setFieldValue("neighborhoodIds", [parseInt(e.value)])
                            }}
                            loadMore={(searchQuery, page) =>
                                fetch_neighborhoods({ page: page, searchQuery: searchQuery })}
                            filterName={"Associated with Neighborhood"}
                            defaultOptions={[]}
                        />

                        {
                            formik.touched.neighborhoodIds && formik.errors.neighborhoodIds &&
                            <div className="error" key={0}>{formik.errors.neighborhoodIds}</div>
                        }
                    </div>
                    <div className="w-1/2 flex flex-col pr-2">
                        <SelectAsyncPaginate
                            isSearchable={true}
                            key={23}
                            onChange={(e) => {
                                formik.setFieldValue("cityId", parseInt(e.value))
                            }}
                            loadMore={(searchQuery, page) =>
                                fetch_cities({ page: page, searchQuery: searchQuery })}
                            filterName={"City Availability *"}
                            defaultOptions={[]}
                        />

                        {
                            formik.touched.cityId && formik.errors.cityId &&
                            <div className="error" key={0}>{formik.errors.cityId}</div>
                        }
                    </div>
                </div>
                <div className='flex space-x-3 mt-5'>
                    <div className="w-1/2 flex flex-col pr-2">
                        {/* TODO: add formik field */}
                        <CustomInput
                            id="minOrdersPerDay"
                            name="minOrdersPerDay"
                            type="text"
                            inputName="Minimum Orders per Day"
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            onChange={(e) => setMinOrdersPerDay(e.target.val)}
                            value={minOrdersPerDay}
                        />
                        {formik.touched.minOrdersPerDay && formik.errors.minOrdersPerDay &&
                            <div className="error" key={0}>{formik.errors.minOrdersPerDay}</div>
                        }
                    </div>
                    <div className="w-1/2 flex">
                        <div className='w-1/2 flex justify-center'>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox onChange={(e) => formik.setFieldValue("isBusy", e?.target?.checked)} />} label="Is Busy" />
                            </FormGroup>
                        </div>
                        <div className='w-1/2 flex justify-center'>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox onChange={(e) => formik.setFieldValue("isOnline", e?.target?.checked)} />} label="Is Online" />
                            </FormGroup>
                        </div>
                    </div>
                </div>

                <h3 className='mb-3 mt-5'>Allowed Payment Types</h3>
                <div className="flex space-x-3">
                    <div className='w-1/2'>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => setAcceptCard(e.target.checked)} />} label="Credit Card Orders Only" />
                        </FormGroup>
                    </div>
                    <div className='w-1/2'>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => setAcceptCash(e.target.checked)} />} label="Cash On Delivery Orders Only" />
                        </FormGroup>
                    </div>
                </div>
                {formik.touched.orderPaymentType && formik.errors.orderPaymentType &&
                    <div className="error" key={0}>{formik.errors.orderPaymentType}</div>
                }

                <h3 className='mb-3 mt-5'>Account Settings</h3>

                <div className='flex space-x-3 mt-5'>
                    <div className="w-1/2 flex flex-col pr-2">
                        {/* TODO: add formik field */}
                        <CustomInput
                            id="password"
                            name="password"
                            type="text"
                            inputName="Password *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password &&
                            <div className="error" key={0}>{formik.errors.password}</div>
                        }
                    </div>
                </div>

                <div className='w-full flex justify-end space-x-3 mt-10'>
                    <div className='w-1/8 mr-10'>
                        <CustomButton
                            type='button'
                            onClick={handleClose}
                            variant='outlined'
                            color='warning'
                        >
                            cancel
                        </CustomButton>
                    </div>
                    <div className='w-1/8 pr-4'>
                        <LoadingButton
                            loading={loading}
                            variant='contained'
                            type="submit"
                            loadingPosition='start'
                            startIcon={<SaveIcon />}
                        >
                            <p className='pr-4 pl-2'>SUBMIT</p>
                        </LoadingButton>
                    </div>
                </div>



            </form>
        </div>
    )
}
