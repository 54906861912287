import PaginatedTable from '../../../../components/Table/paginatedTable';
import React, { useEffect, useState } from 'react'
import { LOAD_TRANSACTIONS } from 'api/queries/FinanceQueries';
import { stagingOptions } from 'constants/apollo';
import { useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import moment from 'moment';
import { objFlatten } from 'helper/objFlatten';

const cols = [
    {
        name: "ID",
        value: "id",
    },
    {
        name: "Wallet ID",
        value: "wallet.id",
        link: "wallet.id",
        path: "../financials/wallets/",
        type: "directLink",
    },
    {
        name: "Wallet Owner Name",
        value: "wallet.owner.name",

    },
    {
        name: "Balance",
        value: "amount",
        type: "price"

    },
    { name: "Prev. Palance", value: "prevBalance" },
    { name: "Note", value: "notes" },
    { name: "Tracking No.", value: "trackingNo", styles: "text-warningColor" },
    { name: "Created At", value: "createdAt" },
    { name: "Merchant", value: "merchant.name" },
];

export default function TransactionsTable ({
    value = "1",
    searchText = "",
    fetchFilters
}) {
    const [page, setPage] = useState(0);
    const [itemsCount, setItemsCount] = useState(0);
    const [tableData, setTableData] = useState([])
    const [barcode, setBarcode] = useState();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterByBin, setFilterByBin] = useState("");
    const [moveInventoryNewModalOpen, setMoveInventoryNewModalOpen] =
        useState(false);
    const [moveInventoryModalOpen, setMoveInventoryModalOpen] =
        useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [newPurchaseOrder, setNewPurchaseOrder] = useState(false);
    const [sortCol, setSortCol] = useState({
        label: "updatedAt",
        value: 1,
    });
    const [fetchData, { data, error }] = useLazyQuery(LOAD_TRANSACTIONS, { ...stagingOptions, fetchPolicy: "network-only" });
    const fetch = (
        skip = 0,
        search = searchText,
        filters = fetchFilters
    ) => {
        try {
            fetchData(
                {
                    variables: {
                        offset: skip,
                        query: search,
                        //         showBanned: true,
                        //         // ...filterObj,
                    }
                }
            )
            setLoading(true)
        } catch (e) {
            toast.error("An error occured");
            setLoading(false)
        }
    };

    useEffect(() => {
        if (data) {
            setLoading(false)
            setItemsCount(data?.fetchAllTransactions?.totalCount);

            const dataList = data?.fetchAllTransactions?.items;
            const dataListFlattened = dataList?.map((item) => {
                let createdAt = "";
                if (item.createdAt) {
                    createdAt = moment(item.createdAt).format("D-M-YYYY / HH:mm")
                }
                let prevBalance = "SR " + item.prevBalance
                let totalCod = "SR " + item.totalCod
                let totalDeliveryFee = "SR " + item.totalDeliveryFee


                return objFlatten({
                    ...item,
                    createdAt,
                    prevBalance
                });
            });

            setTableData(dataListFlattened);
        }
    }, [data]);

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        fetch()
    }, [searchText])

    return (
        <div>
            <PaginatedTable
                sortCol={sortCol}
                count={itemsCount}
                page={page}
                setPage={setPage}
                onPageChange={fetch}
                cols={cols}
                data={tableData}
                selectedRows={selectedRows}
                loading={false}
                filterByBin={""}
                onSortClick={(sortVal) => {
                    // handleSort(sortVal);
                    { }
                }}
            /></div>
    )
}
