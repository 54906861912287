import NormalSelect from '../../../components/common/Select/NormalSelect';
import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import CustomInput from "../../../components/common/Input";
import SelectAsyncPaginate from "../../../components/common/Select/AsyncPaginateSelect";
import moment from 'moment';
import CustomButton from '../../../components/common/Button';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_COURIER_PARTNERS } from 'api/queries/Queries';
import { Divider } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function DriverOperationOptions ({ driver, handleUpdateDriver }) {

    const [fetchDriverPartners, { data }] = useLazyQuery(GET_ALL_COURIER_PARTNERS)
    const formik = useFormik({
        initialValues: {
            firstName: driver?.firstName || "",
            lastName: driver?.lastName || "",
            mobileNumber: driver?.mobileNumber || "",
            email: driver?.email || "",
            // joinedAt: moment(driver?.joinedAt).format("DD-MM-yy") || "",
            idNumber: driver?.idNumber || "",
            // dateOfBirth: driver?.dateOfBirth || "",
            nationality: driver?.nationality || "",
            passportNo: driver?.passportNo || "",
            passportCountry: driver?.passportCountry || "",
            courierType: driver?.courierType || "",
            partnerId: driver?.partner?.id || "",
        },
        // validationSchema: Yup.object({
        //     destinationLocationType: Yup.string().required("Required"),
        //     sourceLocationType: Yup.string().required("Required"),
        //     destinationLocationId: Yup.number().required("Required"),
        //     sourceLocationId: Yup.number().required("Required"),
        //     productId: Yup.number().required("Required"),
        //     merchantId: Yup.number().required("Required"),
        //     serialList: Yup.array().of(Yup.string()).required("Required"),
        // }),
        onSubmit: (values) => {
            handleUpdateDriver({
                ...values,
                partnerId: parseInt(values.partnerId)
            })
            // setIsSubmitting(true)
            // alert(JSON.stringify(values, null, 2));
        },
    });

    const fetch_partners = ({ page = 1, searchQuery = "" } = {}) => {
        const searchObj = {}
        // const searchObj = getSearchObj(searchQuery);

        return fetchDriverPartners({
            variables: {
                skip: (page - 1) * 20,
                ...searchObj,
            },
        }).then((res) => {
            // setMerchants(res?.data?.fetchAllCourierPartners)
            return {
                options: res?.data?.fetchAllCourierPartners,
                hasMore: res?.data?.fetchAllCourierPartners?.length >= 1,
                additional: {
                    page: searchQuery ? 2 : page + 1,
                }
            }
        });
    };

    useEffect(() => {
        if (data) {
            console.log(data)
        }
    }, [data])

    return (
        <div className='flex flex-col section-wrapper'>
            <span className='text-xl mb-2'>Operation Options</span>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit()
                }}
                className='flex flex-col justify-between space-y-5'
            >
                <div className='flex py-4'>

                    <div className="w-1/5 flex flex-col pr-2">

                        <SelectAsyncPaginate
                            isSearchable={true}
                            key={23}
                            onChange={(e) => {
                                formik.setFieldValue("partnerId", e.value)
                            }}
                            defaultValue={{ value: driver?.partner?.id, label: driver?.partner?.name }}
                            // value={() => filter?.getSelectedVal(selectedFilters[filter?.value])}
                            // getDefaultValue={{ value: driver?.partner?.id, label: driver?.partner?.name }}
                            loadMore={(searchQuery, page) =>
                                fetch_partners({ page: page, searchQuery: searchQuery })}
                            filterName={"City Availability"}
                        // getOptionLabel={(option) => option.name}
                        // getOptionValue={(option) => option.id}
                        // isSearchable={true}
                        />



                        {/* <CustomInput
                            id="partner"
                            name="partner"
                            type="text"
                            inputName="Contractor"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.partner}
                        /> */}
                        {
                            formik.touched.partner && formik.errors.partner &&
                            <div className="error" key={0}>{formik.errors.partner}</div>
                        }
                    </div>
                    <div className="w-1/5 flex flex-col pr-2">
                        <NormalSelect
                            styles={{ width: "100%" }}
                            key={44}
                            filter={
                                {
                                    name: "Available order types",
                                    value: "courierType",
                                    id: 1,
                                    options: [
                                        { value: "paid", label: "Paid" },
                                        { value: "freelance", label: "Freelance" },
                                    ],
                                }
                            }
                            defaultValue={[
                                { value: "paid", label: "Paid" },
                                { value: "freelance", label: "Freelance" },
                            ].find(option => option.value == formik.values.courierType
                            )}
                            handleChange={(e) => formik.setFieldValue("courierType", e.value)}
                        />
                        {formik.touched.courierType && formik.errors.courierType &&
                            <div className="error" key={0}>{formik.errors.courierType}</div>
                        }
                    </div>
                </div>
                <div className='flex pt-6 border-t border-gray-100'>
                    <div className="w-1/5 flex flex-col pr-2">
                        <NormalSelect
                            styles={{ width: "100%" }}
                            key={44}
                            filter={
                                {
                                    name: "Associated with Merchant",
                                    value: "courierType",
                                    id: 1,
                                    options: [
                                        { value: "paid", label: "Paid" },
                                        { value: "freelance", label: "Freelance" },
                                    ],
                                }
                            }
                            defaultValue={[
                                { value: "paid", label: "Paid" },
                                { value: "freelance", label: "Freelance" },
                            ].find(option => option.value == formik.values.courierType
                            )}
                            handleChange={(e) => formik.setFieldValue("courierType", e.value)}
                        />
                        {formik.touched.courierType && formik.errors.courierType &&
                            <div className="error" key={0}>{formik.errors.courierType}</div>
                        }
                    </div>
                    <div className="w-2/5 flex flex-col pr-2">
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Only Dedicated Merchants Orders?" />
                        </FormGroup>
                    </div>
                    <div className="w-1/5 flex flex-col pr-2">
                        {/* {
            
                        loadMore: (searchQuery, page) =>
                        fetch_cities({page: page, searchQuery: searchQuery }),
                                } */}
                        <SelectAsyncPaginate
                            isSearchable={true}
                            key={23}
                            onChange={(e) => {
                                formik.setFieldValue("partnerId", e.value)
                            }}
                            defaultValue={{ value: driver?.partner?.id, label: driver?.partner?.name }}
                            // value={() => filter?.getSelectedVal(selectedFilters[filter?.value])}
                            // getDefaultValue={{ value: driver?.partner?.id, label: driver?.partner?.name }}
                            loadMore={(searchQuery, page) =>
                                fetch_partners({ page: page, searchQuery: searchQuery })}
                            filterName={"Courier Dedicated To"}
                        // getOptionLabel={(option) => option.name}
                        // getOptionValue={(option) => option.id}
                        // isSearchable={true}
                        />



                        {/* <CustomInput
                            id="partner"
                            name="partner"
                            type="text"
                            inputName="Contractor"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.partner}
                        /> */}
                        {
                            formik.touched.partner && formik.errors.partner &&
                            <div className="error" key={0}>{formik.errors.partner}</div>
                        }
                    </div>

                </div>
                <div className='flex'>
                    <div className="w-1/5 flex flex-col pr-2">
                        <NormalSelect
                            styles={{ width: "100%" }}
                            key={44}
                            filter={
                                {
                                    name: "Associated with Hubs",
                                    value: "courierType",
                                    id: 1,
                                    options: [
                                        { value: "paid", label: "Paid" },
                                        { value: "freelance", label: "Freelance" },
                                    ],
                                }
                            }
                            defaultValue={[
                                { value: "paid", label: "Paid" },
                                { value: "freelance", label: "Freelance" },
                            ].find(option => option.value == formik.values.courierType
                            )}
                            handleChange={(e) => formik.setFieldValue("courierType", e.value)}
                        />
                        {formik.touched.courierType && formik.errors.courierType &&
                            <div className="error" key={0}>{formik.errors.courierType}</div>
                        }
                    </div>
                    <div className="w-2/5 flex flex-col pr-2">
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Only Dedicated Hub Orders?" />
                        </FormGroup>
                    </div>
                </div>
                <div className='flex pb-4'>
                    <div className="w-1/5 flex flex-col pr-2">
                        <NormalSelect
                            styles={{ width: "100%" }}
                            key={44}
                            filter={
                                {
                                    name: "Areas",
                                    value: "courierType",
                                    id: 1,
                                    options: [
                                        { value: "paid", label: "Paid" },
                                        { value: "freelance", label: "Freelance" },
                                    ],
                                }
                            }
                            defaultValue={[
                                { value: "paid", label: "Paid" },
                                { value: "freelance", label: "Freelance" },
                            ].find(option => option.value == formik.values.courierType
                            )}
                            handleChange={(e) => formik.setFieldValue("courierType", e.value)}
                        />
                        {formik.touched.courierType && formik.errors.courierType &&
                            <div className="error" key={0}>{formik.errors.courierType}</div>
                        }
                    </div>
                    <div className="w-2/5 flex flex-col pr-2">
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Only Dedicated Neighbourhood Orders?" />
                        </FormGroup>
                    </div>
                </div>
                <div className='flex py-6 border-t border-gray-100'>
                    <div className="w-1/5 flex flex-col pr-2">
                        <NormalSelect
                            styles={{ width: "100%" }}
                            key={44}
                            filter={
                                {
                                    name: "Allowed Payment Types",
                                    value: "courierType",
                                    id: 1,
                                    options: [
                                        { value: "paid", label: "Paid" },
                                        { value: "freelance", label: "Freelance" },
                                    ],
                                }
                            }
                            defaultValue={[
                                { value: "paid", label: "Paid" },
                                { value: "freelance", label: "Freelance" },
                            ].find(option => option.value == formik.values.courierType
                            )}
                            handleChange={(e) => formik.setFieldValue("courierType", e.value)}
                        />
                        {formik.touched.courierType && formik.errors.courierType &&
                            <div className="error" key={0}>{formik.errors.courierType}</div>
                        }
                    </div>
                    <div className="w-2/5 flex flex-col pr-2">
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Enable Geidea?" />
                        </FormGroup>
                    </div>
                    <div className="w-1/5 flex flex-col pr-2">
                        {/* {
            
                        loadMore: (searchQuery, page) =>
                        fetch_cities({page: page, searchQuery: searchQuery }),
                                } */}
                        <SelectAsyncPaginate
                            isSearchable={true}
                            key={23}
                            onChange={(e) => {
                                formik.setFieldValue("partnerId", e.value)
                            }}
                            defaultValue={{ value: driver?.partner?.id, label: driver?.partner?.name }}
                            // value={() => filter?.getSelectedVal(selectedFilters[filter?.value])}
                            // getDefaultValue={{ value: driver?.partner?.id, label: driver?.partner?.name }}
                            loadMore={(searchQuery, page) =>
                                fetch_partners({ page: page, searchQuery: searchQuery })}
                            filterName={"Geidea TSRM(Terminal) ID"}
                        // getOptionLabel={(option) => option.name}
                        // getOptionValue={(option) => option.id}
                        // isSearchable={true}
                        />



                        {/* <CustomInput
                            id="partner"
                            name="partner"
                            type="text"
                            inputName="Contractor"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.partner}
                        /> */}
                        {
                            formik.touched.partner && formik.errors.partner &&
                            <div className="error" key={0}>{formik.errors.partner}</div>
                        }
                    </div>

                </div>
                <div className='flex w-full justify-end space-x-6'>
                    <div className="w-1/8">
                        <CustomButton
                            type='submit'
                            variant='outlined'
                            color="warning"
                            onClick={() => {
                                // formik.resetForm()
                            }}
                            style={{ width: "100%", height: "38px" }}
                        >
                            Cancel
                        </CustomButton>
                    </div>
                    <div className="w-1/7">
                        <CustomButton
                            type='submit'
                            variant='contained'
                            color="warning"
                            onClick={() => { }}
                            style={{ width: "100%", height: "38px" }}
                        >
                            Update
                        </CustomButton>
                    </div>
                </div>
            </form>
        </div>
    )
}
