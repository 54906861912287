import { useMutation } from "@apollo/client";
import useLazyQuery from "hooks/useLazyQuery";
import { GridFilterListIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
	ADD_UPDATE_CART,
	DELETE_LOCATIONS,
} from "../../../../api/mutations/WMSMutations";
import { GET_ALL_WAREHOUSES } from "../../../../api/queries/WMSqueries";
import CustomButton from "../../../../components/common/Button";
import Modal from "../../../../components/common/Modal";
import ModalFooter from "../../../../components/common/Modal/ModalFooter";
import NewSearch from "../../../../components/common/Search/new-search";
import FiltersList from "../../../../components/FiltersList";
import { CartIcon } from "../../../../components/svg";
import PaginatedTable from "../../../../components/Table/paginatedTable";
import { wmsOptions } from "../../../../constants/apollo";
import { getSortationOrder } from "../../../../helper/GetSortOrder";
import { logger } from "../../../../helper/logger";
import { objFlatten } from "../../../../helper/objFlatten";
import { useDebounce } from "../../../../hooks/useDebounce";
import CreateCartfForm from "../CreateCartForm";
import useCarts from "./useCarts";
import useShelves from "./useShelves";

export const cartsCols = (defaultVal) => {
	return [
		{ name: "Shelf Name", value: "locationCode", canBeSorted: true },
		{
			name: "Warehouse Label",
			value: "serialsLength",
			styles: "text-warningColor",
			type: "defaultVal",
			defaultVal: defaultVal,
		},
		{
			name: "Serials",
			value: "serialsCount",
			type: "serial",
			serialsList: "inventory.serials",
		},
		{ name: "Updated At", value: "inventory.updatedAt", canBeSorted: true },
		{
			name: "Status",
			value: "isActive",
			type: "locationType",
			styles: "upercase",
			canBeSorted: true,
		},
	];
};
export default function Shelves({
	warehouse,
	debouncedSearchText,
	filterOpen,
	setFilterOpen,
	setShelfModalOpen,
	shelfModalOpen,
}) {
	const defaultFilter = {
		status: "all",
		parentLocationId: Number(warehouse.id),
	};
	const [modalOpen, setModalOpen] = useState(false);

	const [dataList, setDataList] = useState([]);
	const [productFilters, setProductFilters] = useState(defaultFilter);
	const [selectedRows, setSelectedRows] = useState([]);
	const [itemsCount, setItemsCount] = useState(0);
	const [page, setPage] = useState(0);

	const [searchText, setSearchText] = useState("");
	const [sortCol, setSortCol] = useState({
		label: "id",
		value: 1,
	});

	const [getAllCarts] = useLazyQuery(GET_ALL_WAREHOUSES, wmsOptions);
	const [updateLocations] = useMutation(ADD_UPDATE_CART, wmsOptions);
	const [deleteLocations] = useMutation(DELETE_LOCATIONS, wmsOptions);

	const { getOrderObj, getSearchObj, getFilterObj } = useShelves();

	useEffect(() => {
		logger("PROD FILTERS", productFilters);
	}, [productFilters]);

	const fetchAllCartsOfTheWarehouse = (
		skip = 0,
		search = debouncedSearchText,
		sortName = sortCol.label,
		sortOrder = getSortationOrder(sortCol.value),
		filters = productFilters
	) => {
		let orderObj = getOrderObj(sortName, sortOrder);
		let searchObj = getSearchObj(search);
		let filterObj = getFilterObj(filters);

		logger("FILTER", filterObj, filters);
		getAllCarts({
			variables: {
				skip: skip,
				...filterObj,
				...searchObj,
				...orderObj,
			},
		}).then((res) => {
			const data = res.data.locations.items;

			setItemsCount(res.data.locations.totalCount);

			const dataFlattened = data.map((item) => {
				let serialsCount = item?.inventory?.serials?.length || 0;

				// let time = "";
				// if (item.updatedAt) {
				//     time = moment(item.updatedAt).format("D-M-YYYY / hh:mm");
				// }

				return objFlatten({
					...item,
					serialsCount: serialsCount,
				});
			});

			setDataList(dataFlattened);
		});
	};

	const handleSort = (sortVal = "") => {
		if (sortCol.label === sortVal) {
			// sort Desc
			const sortOrder = getSortationOrder(sortCol.value + 1);
			sortCol.value += 1;

			fetchAllCartsOfTheWarehouse(0, searchText, sortVal, sortOrder);
		} else {
			setSortCol((old) => ({
				...old,
				label: sortVal,
				value: 0,
			}));
			fetchAllCartsOfTheWarehouse(0, searchText, sortVal, "ASC");
		}

		setPage(0);
	};

	function handleFilter() {
		fetchAllCartsOfTheWarehouse(
			0,
			searchText,
			sortCol.label,
			getSortationOrder(sortCol.value),
			productFilters
		);
	}

	function handleFilterReset() {
		setProductFilters(defaultFilter);
		fetchAllCartsOfTheWarehouse(
			0,
			searchText,
			sortCol.label,
			getSortationOrder(sortCol.value),
			defaultFilter
		);
		setFilterOpen(false);
	}

	function updateCartOrShelfStatus(id, type = "CART", isActive = true) {
		updateLocations({
			variables: {
				locations: [
					{
						id: +id,
						type: type,
						isActive: !isActive,
					},
				],
			},
		}).then((res) => fetchAllCartsOfTheWarehouse());
	}

	function deleteCartOrShelf(id, type = "CART") {
		deleteLocations({
			variables: {
				locations: [
					{
						id: +id,
						type: type,
					},
				],
			},
		}).then((res) => fetchAllCartsOfTheWarehouse());
	}

	useEffect(() => {
		fetchAllCartsOfTheWarehouse();
		setFilterOpen(false);
	}, [debouncedSearchText]);
	return (
		<>
			<PaginatedTable
				hasCheckbox
				canBeEdited
				sortCol={sortCol}
				count={itemsCount}
				page={page}
				setPage={setPage}
				onPageChange={fetchAllCartsOfTheWarehouse}
				cols={cartsCols(warehouse?.referenceName)}
				data={dataList}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				loading={false}
				editMenuItems={[
					{
						actionName: "Delete",
						action: (item) =>
							deleteCartOrShelf(Number(item.id), "CART"),
						isDisabled: false,
						type: "default",
					},
					{
						type: "status",
						actionName: "Set active/inactive",
						action: (id, isActive) =>
							updateCartOrShelfStatus(id, "CART", isActive),
						isDisabled: false,
					},
				]}
				onSortClick={(sortVal) => {
					handleSort(sortVal);
				}}
			/>
			<Modal
				onDismiss={() => {
					setFilterOpen(false);
				}}
				hidden
				autoOpen={filterOpen}
				footer={(handleClose) => (
					<ModalFooter
						handleClose={handleClose}
						handleSubmit={handleFilter}
						handleReset={handleFilterReset}
					/>
				)}
				onClose={() => {}}
				title={"Filter Products"}
			>
				<FiltersList
					selectedFilters={productFilters}
					filters={[
						{
							name: "Filter by Status",
							value: "status",
							id: 1,
							options: [
								{ value: "all", label: "All" },
								{ value: true, label: "Active" },
								{ value: false, label: "Inactive" },
							],
						},
					]}
					handleChange={(filterVal, filterName) => {
						setProductFilters((old) => ({
							...old,
							[filterName]: filterVal,
						}));
					}}
				/>
			</Modal>
		</>
	);
}
