import { logger } from "../helper/logger";
import request from "../utils/axios";

// change base url to navigation server
// request.baseURL = process.env.OSRM_SERVER_URL;
request.baseURL = "https://157.175.167.175:5000/";

const req = {
  getRoute: (coordinates) =>
    request({
      method: "get",
      url: `/route/v1/driving/${coordinates}?geometries=polyline`,
      baseURL: "http://157.175.167.175:5000/",
      // baseURL: "http://router.project-osrm.org/"
    }),
  optimizeRoute: (data) =>
    request({
      method: "post",
      url: "api/routing/optimize",
      baseURL: "https://route.barqfleet.com/",
      data,
    }),
  getRouteHistory: (data) =>
    request({
      method: 'get',
      url: `development/locations?courier_id=${data.driverId}&from=${data.from}&to=${data.to}`, //.toISOString(
      baseURL: "https://4453vg7zbi.execute-api.me-south-1.amazonaws.com/"
    })

  //   {
  //     "driver": {
  //         "id": 38,
  //         "currentLocation": {
  //             "latitude": 24.610717365125215,
  //             "longitude": 46.85167445830534
  //         }
  //     },
  //     "shipments": [
  //         {
  //             "id": 8075,
  //             "latitude": 24.655276292763432,
  //             "longitude": 46.82665247750839,
  //             "promise": 0,
  //             "dropoffs": [
  //                 {
  //                     "id": 20335,
  //                     "latitude": 24.7035517,
  //                     "longitude": 46.7052957,
  //                     "promise": 0
  //                 },
  //                 {
  //                     "id": 20336,
  //                     "latitude": 24.7035517,
  //                     "longitude": 46.7052957,
  //                     "promise": 0
  //                 }
  //             ]
  //         }
  //     ],
  //     "options": {
  //         "g": true
  //     }
  // }

  //   getKnight: (id) => request({ method: "get", url: `/knights/${id}` }),
  //   assignShipment: (id, data) => request({ method: "post", url: `/knights/${id}/assign`, data }),
  // postAnswers:  (id, data) => request({ method: 'post', url: `/handbook/${id}/answers`, data }),
  // updateHandbookAnswer: (handbook_id, data) => request({ method: 'put', url: `handbook/${handbook_id}/answer/${data.id}`, data }),
  // deleteHandbookAnswer: (handbook_id, data) => request({ method: 'delete', url: `/handbook/${handbook_id}/answer`, data }),
};
export default req;
