import PaginatedTable from "../../../../components/Table/paginatedTable";
import React, { useEffect, useState } from "react";
import Modal from "../../../../components/common/Modal";
import {
	Box,
	Button,
	Checkbox,
	Divider,
	InputAdornment,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TextField,
	ListItem,
	Chip,
	Grid,
	Alert,
} from "@mui/material";
import { SearchIcon } from "../../../../components/svg";
import { logger } from "helper/logger";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import { useLazyQuery, useMutation } from "@apollo/client";
import useShipped from "./useShipped";
import { wmsOptions } from "constants/apollo";
import { GET_RESTOCKED_JOBS, GET_SALE_ORDERS } from "api/queries/WMSqueries";
import { getSortationOrder } from "helper/GetSortOrder";
import moment from "moment";
import { objFlatten } from "helper/objFlatten";
import {
	COMPLETE_RESTOCK_JOBS,
	CREATE_OR_UPDATE_SALEORDERS,
} from "api/mutations/WMSMutations";
import { toast } from "react-toastify";

const cols = [
	{
		name: "Sale Order ID",
		value: "id",
		canBeSorted: true,
	},
	{
		name: "Code",
		value: "cart.locationCode",
		canBeSorted: true,
	},
	{
		name: "Product",
		value: "saleOrderItem.product.name",
	},
	// { name: "Client", value: "client.name", },
	{ name: "Created At", value: "createdAt", canBeSorted: true },
	{
		name: "Updated At",
		value: "updatedAt",
		canBeSorted: true,
	},
	{ name: "Status", value: "status", type: "status", styles: "uppercase" },
];

const filters = {
	productId: "all",
	merchantId: "all",
	destinationLocationId: "all",
	destinationLocationType: "all",
	sourceLocationId: "all",
	sourceLocationType: "all",
};

export default function Shipped({ searchText }: any) {
	const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
	const [page, setPage] = useState(0);
	const [itemsCount, setItemsCount] = useState(0);
	const navigate = useNavigate();

	const [sortCol, setSortCol] = useState({
		label: "updatedAt",
		value: 1,
	});

	const [tableData, setTableData] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();

	const [filterOpen, setFilterOpen] = useState(false);

	const debouncedSearchText = useDebounce(searchText, 500);
	const [productFilters, setProductFilters] = useState({
		...filters,
		...Object.fromEntries([...searchParams]),
	});

	const [modalOpen, setModalOpen] = useState(false);
	const [reload, setReload] = useState(0);

	const [isModalLoading, setIsModalLoading] = useState(false);

	const [refetchShippingOrders] = useLazyQuery(
		GET_RESTOCKED_JOBS,
		wmsOptions
	);

	const [completeRestockingMutation] = useMutation(COMPLETE_RESTOCK_JOBS, {
		...wmsOptions,
	});

	const { getOrderObj, getSearchObj, getFilterObj } = useShipped();

	const refetch = (
		skip = 0,
		search = searchText,
		sortName = sortCol.label,
		sortOrder = getSortationOrder(sortCol.value),
		filters = productFilters
	): any => {
		let orderObj = getOrderObj(sortName, sortOrder);
		let searchObj = getSearchObj(search);

		refetchShippingOrders({
			variables: {
				skip: skip,
				...searchObj,
				...orderObj,
			},
		}).then((data) => {
			if (data) {
				setItemsCount(data?.data?.restockJobs?.totalCount);

				const dataList = data?.data?.restockJobs?.items || [];
				const dataListFlattened = dataList.map((item: any) => {
					let time = "";
					if (item.updatedAt) {
						time = moment(item.updatedAt).format(
							"D-M-YYYY / HH:mm"
						);
					}
					let createTime = "";
					if (item.createdAt) {
						createTime = moment(item.createdAt).format(
							"D-M-YYYY / HH:mm"
						);
					}

					let amount = 0;
					if (item?.saleOrderItems) {
						item?.saleOrderItems.map(
							(item: any) => (amount += +item.quantity)
						);
					}

					let productList: any[] = [];
					let productCount = 0;
					if (item?.saleOrderItems) {
						productCount = item.saleOrderItems.length;
						item?.saleOrderItems?.map((el: any) =>
							productList.push(el?.product?.name)
						);
					}
					let list = productList.join(" ");
					return objFlatten({
						...item,
						updatedAt: time,
						createdAt: createTime,
						amount: amount,
						productList: list,
						productCount: productCount,
					});
				});
				logger("DADAS, d", dataListFlattened);

				setTableData(dataListFlattened);
			}
		});
	};

	const handleSort = (sortVal = "") => {
		if (sortCol.label === sortVal) {
			// sort Desc
			const sortOrder = getSortationOrder(sortCol.value + 1);
			sortCol.value += 1;

			refetch(0, searchText, sortVal, sortOrder);
		} else {
			setSortCol((old: any) => ({
				...old,
				label: sortVal,
				value: 0,
			}));
			refetch(0, searchText, sortVal, "ASC");
		}

		setPage(0);
	};
	// function handleSearch(search: any) {
	//   fetch_data(0, search, sortCol.label, getSortationOrder(sortCol.value));
	//   setPage(0);
	// }
	function handleSearch(search: any) {
		refetch(0, search, sortCol.label, getSortationOrder(sortCol.value));
		setPage(0);
	}

	useEffect(() => {
		refetch();
	}, [reload]);

	useEffect(() => {
		handleSearch(searchText);
	}, [searchText]);
	return (
		<>
			<div>
				<PaginatedTable
					// hasCheckbox
					sortCol={sortCol}
					count={itemsCount}
					page={page}
					setPage={setPage}
					onPageChange={(n: number) => {
						refetch(n);
					}}
					cols={cols}
					data={
						tableData || [
							{
								id: "SA-3650",
								shipment: "DK-505728848-970",
								product: "Product list",
								list: "2, 2, 3, 4",
								merchant: "Marshall Douglas",
								date: "08/30/2022 18:45",
								amount: "103 SAR",
								status: "pending",
							},
						]
					}
					filterByBin={() => {}}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
					loading={false}
					onSortClick={(sortVal) => {
						handleSort(sortVal);
					}}
					canBeEdited
					editMenuItems={[
						{
							actionName: "Complete restocking",
							action: (data: any) => {
								let serials: string[] = [];

								data.saleOrderItem?.saleOrder?.saleOrderItems?.forEach(
									(item: any) => {
										if (item?.serials?.length) {
											serials = [
												...serials,
												...item.serials,
											];
										}
									}
								);

								serials = serials?.filter(
									(item: string) => item.length
								);

								completeRestockingMutation({
									variables: {
										serials,
										restockJob: data.id - 0,
									},
								}).then((x) => {
									setReload(Math.random());
									toast.success(`Item is moved successfully`);
								});

								// return navigate(
								// 	"/wms/outbound/proceed/restocking",
								// 	{
								// 		state: {
								// 			id: 2,
								// 			cartCode: data["cart.locationCode"],
								// 			merchantName:
								// 				data[
								// 					"saleOrderItem.saleOrder.merchant.name"
								// 				],
								// 			productName:
								// 				data[
								// 					"saleOrderItem.product.name"
								// 				],
								// 			status: data["status"],
								// 			saleOrderItems: data.saleOrderItems,
								// 			saleOrderItemId:
								// 				data["saleOrderItem.id"],
								// 			saleOrderItemQuantity:
								// 				serials.length,
								// 			serials: serials,
								// 			type: "restocking",
								// 			restockJobId: data["id"],
								// 		},
								// 	}
								// );
							},
							isDisabled: false,
							type: "purchaseOrder",
						},
						// {
						//   actionName: "Re-Stock",
						//   action: (item: any) => {
						//     updateSaleOrderStatus(item.id, "PENDING");
						//     refetch();
						//   },
						//   isDisabled: false,
						//   type: "purchaseOrder",
						// },
					]}
				/>
			</div>

			<Modal
				footer={() => {}}
				onClose={() => {
					setModalOpen(false);
				}}
				hidden
				onDismiss={() => {
					setModalOpen(false);
				}}
				color='primary'
				autoOpen={modalOpen}
				maxWidth='xs'
			>
				<ModalContent />
			</Modal>
		</>
	);
}

function ModalFooter({ close, assign }: any) {
	return (
		<Box sx={{ width: "100%" }}>
			<Divider />

			<div
				style={{
					marginTop: 20,
					justifyContent: "flex-end",
					display: "flex",
				}}
			>
				<Button
					onClick={() => close()}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={() => assign()}
					sx={{ marginLeft: 2 }}
					variant='contained'
					color='primary'
				>
					<span className='text-white'>Assign</span>
				</Button>
			</div>
		</Box>
	);
}

function ModalContent() {
	const [isActionComplete, setIsActionComplete] = useState(false);

	const [checked, setChecked] = React.useState([0]);
	const [selected, setSelected] = useState("available");

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	return (
		<>
			<TextField
				fullWidth
				title='Search employee'
				placeholder='Type here...'
				variant='outlined'
				label='Scan or search cart barcodes'
				size='small'
				sx={{ marginY: 1 }}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
			<Grid container spacing={2}>
				<Grid item>
					<Chip
						id={"0"}
						color={selected === "all" ? "primary" : "default"}
						label='All'
						variant='filled'
						onClick={() => setSelected("all")}
					/>
				</Grid>
				<Grid item>
					<Chip
						id={"0"}
						color={selected !== "all" ? "primary" : "default"}
						label='Available'
						onClick={() => setSelected("available")}
					/>
				</Grid>
			</Grid>

			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
				}}
			>
				{[0, 1, 2, 3].map((value, index) => {
					const labelId = `checkbox-list-label-${value}`;

					return (
						<>
							{index !== 0 && <Divider />}

							<ListItem
								sx={{
									width: "100%",
									bgcolor:
										checked.indexOf(value) !== -1
											? "#FF980014"
											: "background.paper",
								}}
								key={value}
								disablePadding
							>
								<ListItemButton
									role={undefined}
									onClick={handleToggle(value)}
									dense
									sx={{
										width: "100%",
									}}
								>
									<ListItemIcon>
										<Checkbox
											edge='start'
											checked={
												checked.indexOf(value) !== -1
											}
											color='warning'
											tabIndex={-1}
											disableRipple
											inputProps={{
												"aria-labelledby": labelId,
											}}
										/>
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={`Line item ${value + 1}`}
									/>
									<Chip
										sx={{
											textAlign: "right",
											backgroundColor: "#4CAF5014",
											border: "none",
											color: "#4CAF50",
										}}
										id={labelId}
										color='success'
										label='Available'
										variant='outlined'
										size='small'
									/>
								</ListItemButton>
							</ListItem>
						</>
					);
				})}
			</List>

			<Alert variant='standard' severity='warning'>
				<span>
					You have more than <BoldText>30</BoldText> items. You need{" "}
					<BoldText>1 more</BoldText> employee
				</span>
			</Alert>
			<ModalFooter
				assign={() => {
					setIsActionComplete(true);
				}}
				close={() => {}}
			/>
		</>
	);
}
function FeedbackModal() {
	const [checked, setChecked] = React.useState([0]);

	return <>Assigning 5 picklists</>;
}

function BoldText({ children }: any) {
	return <span style={{ fontWeight: "bold" }}>{children}</span>;
}
