import {
    LeafletDriverOfflineIconNew,
    LeafletDriverOnlineFreeIconNew,
    LeafletDriverOnlineBusyIconNew,
    LeafletDriverOnlineFreeIconNewFocused,
    LeafletDriverOnlineBusyIconNewFocused,
    LeafletDriverOfflineIconNewFocused,
    LeafletDriverAnalyticsFreeIconNew,
    LeafletDriverAnalyticsBusyIconNew,
    LeafletDriverAnalyticsFreeIconNewFocused,
    LeafletDriverAnalyticsBusyIconNewFocused
} from "../../../components/common/leaflet-icons"

export const getDriverIcon = (driver, selectedDriverId) => {
    let driverMarker = selectedDriverId === driver?.id
        ? LeafletDriverOfflineIconNewFocused
        : LeafletDriverOfflineIconNew

    if (driver.imei) {
        if (driver.isOnline && !driver.isBusy) {
            driverMarker = selectedDriverId === driver?.id
                ? LeafletDriverAnalyticsFreeIconNewFocused
                : LeafletDriverAnalyticsFreeIconNew
        } else if (driver.isOnline && driver.isBusy) {
            driverMarker = selectedDriverId === driver?.id
                ? LeafletDriverAnalyticsBusyIconNewFocused
                : LeafletDriverAnalyticsBusyIconNew
        }
    }
    else {

        if (driver.isOnline && !driver.isBusy) {
            // driver is online & free
            driverMarker = selectedDriverId === driver?.id
                ? LeafletDriverOnlineFreeIconNewFocused
                : LeafletDriverOnlineFreeIconNew
        } else if (driver.isOnline && driver.isBusy) {
            // driver is online & busy
            driverMarker = selectedDriverId === driver?.id
                ? LeafletDriverOnlineBusyIconNewFocused
                : LeafletDriverOnlineBusyIconNew
        }
    }

    // if selected
    if (parseInt(driver.id) === parseInt(selectedDriverId)) {
        driverMarker = LeafletDriverOfflineIconNewFocused
        if (driver.isOnline && !driver.isBusy) {
            // driver is online & free
            driverMarker = LeafletDriverOnlineFreeIconNewFocused
        } else if (driver.isOnline && driver.isBusy) {
            // driver is online & busy
            driverMarker = LeafletDriverOnlineBusyIconNewFocused
        }
    }

    return driverMarker
}