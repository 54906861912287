import cn from "classnames";
import { useState } from "react";

type FilterButtonProps = {
  filterName: string;
  count: string;
  color?: string;
};

export const FilterButton = ({
  filterName,
  count,
  color,
}: FilterButtonProps) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      onClick={() => setIsActive(!isActive)}
      className={cn(
        "p-1 rounded-full border flex cursor-pointer hover:bg-gray-100",
        {
          "border-dangerColor text-dangerColor": color === "error",
          "border-successColor text-successColor": color === "success",
          "border-gray-300 text-gray-800": color === "gray",
        }
      )}
    >
      <div
        className={cn(
          "w-6 h-6 rounded-full text-[12px] flex items-center justify-center",
          {
            "bg-dangerColor text-white": color === "error",
            "bg-successColor text-white": color === "success",
            "bg-gray-500 text-white": color === "gray",
          }
        )}
      >
        <span>{count}</span>
      </div>
      <div className="px-[6px]">
        <span className="text-sm capitalize">{filterName}</span>
      </div>
    </div>
  );
};
