import { gql } from "apollo-boost";

export const scanOrdersIntoWarehouseMutation = gql`
  mutation ScanOrdersIntoWarehouse($input: ScanOrdersIntoWarehouseInput!) {
    scanOrdersIntoWarehouse(input: $input) {
      orders {
        id
      }
      processId
    }
  }
`;

export const markShipmentProcessing = gql`
  mutation MarkShipmentProcessing($input: MarkShipmentProcessingInput!) {
    markShipmentProcessing(input: $input) {
      orders {
        trackingNo
      }
    }
  }
`;
