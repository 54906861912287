import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { handleInputChange } from 'react-select/dist/declarations/src/utils';

export default function DiscreteSliderLabel ({ min = 1, max = 100, marks = [], handleChange = (e: any) => { }, defaultVal = 1 }) {
  return (
    <Box sx={{ width: 800 }}>
      <Slider
        aria-label="Always visible"
        min={min}
        max={max}
        defaultValue={defaultVal}
        step={1}
        marks={marks}
        onChange={(e: any) => {
          handleChange(e?.target?.value)
        }}
      />
    </Box>
  );
}