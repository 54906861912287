import useLazyQuery from "hooks/useLazyQuery";
import { useLazyQuery as apolloUseLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { GridFilterListIcon } from "@mui/x-data-grid";
import MuiFilterButton from "../../../components/common/FilterButton/MuiFilterButton";
import { GET_ALL_INVENTORY_LOGS } from "../../../api/queries/WMSqueries";
import { wmsOptions } from "../../../constants/apollo";
import { logger } from "../../../helper/logger";
import { objFlatten } from "../../../helper/objFlatten";
import PaginatedTable from "../../../components/Table/paginatedTable";
import { useDebounce } from "../../../hooks/useDebounce";
import useInventoryLog from "./useInventoryHook";
import NewSearch from "../../../components/common/Search/new-search";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import Modal from "../../../components/common/Modal";
import FilterList from "../../../components/common/Filter/FilterList";
import {
  WMS_FILTER_LOCATIONS,
  WMS_FILTER_MERCHANTS,
  WMS_FILTER_PRODUCTS,
} from "../../../api/queries/WMSFilterQueries";
import { useSearchParams } from "react-router-dom";
import FiltersList from "../../../components/FiltersList";

const cols = [
  {
    name: "Inventory Log Id",
    value: "id",
    canBeSorted: true,
  },
  {
    name: "Product Name",
    type: "capitalize",
    value: "product.name",
    canBeSorted: true,
  },
  {
    name: "Merchant Name",
    value: "merchant.name",
    secondVal: "merchant.referenceName",
    canBeSorted: true,
  },
  {
    name: "Source Location",
    value: "sourceLocation.referenceName",
    secondVal: "sourceLocation.locationCode",
    styles: "text-warningColor",
    canBeSorted: true,
  },
  {
    name: "Source Location Type",
    value: "sourceLocation.type",
    type: "capitalize",
    canBeSorted: true,
  },
  {
    name: "Destination Location",
    value: "destinationLocation.referenceName",
    secondVal: "destinationLocation.locationCode",
    styles: "text-warningColor",
    canBeSorted: true,
  },
  {
    name: "Destination Location Type",
    value: "destinationLocation.type",
    canBeSorted: true,
    type: "capitalize",
  },
  {
    name: "Serials",
    value: "serialsLength",
    styles: "text-warningColor",
    type: "serial",
    serialsList: "serials",
  },
  {
    name: "Created At",
    value: "createdAt",
    canBeSorted: true,
  },
];

const filters = {
  productId: "all",
  merchantId: "all",
  destinationLocationId: "all",
  destinationLocationType: "all",
  sourceLocationId: "all",
  sourceLocationType: "all",
};

export default function InventoryLog() {
  const localStorageFilters = JSON.parse(
    window?.localStorage?.getItem("invLogFilters") || "{}"
  );
  const [searchParams, setSearchParams] = useSearchParams(
    localStorageFilters || {}
  );
  const [searchText, setSearchText] = useState(
    localStorageFilters?.searchText ||
      Object.fromEntries([...searchParams]).searchText ||
      ""
  );
  const [filterOpen, setFilterOpen] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 500);
  const [productFilters, setProductFilters] = useState({
    ...filters,
    ...Object.fromEntries([...searchParams]),
  });
  const [page, setPage] = useState(0);
  const [itemsCount, setItemsCount] = useState();
  const [filterByBin, setFilterByBin] = React.useState("");
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [getInventoryLogs] = useLazyQuery(GET_ALL_INVENTORY_LOGS, wmsOptions);
  const [inventoryLogs, setInventoryLogs] = useState([]);
  
  const [merchants, setMerchants] = useState([]);
  const [locations, setLocations] = useState([]);
  const [products, setProducts] = useState([]);

  const [getMerchants] = apolloUseLazyQuery(WMS_FILTER_MERCHANTS, wmsOptions);
  const [getLocations] = apolloUseLazyQuery(WMS_FILTER_LOCATIONS, wmsOptions);
  const [getProducts] = apolloUseLazyQuery(WMS_FILTER_PRODUCTS, wmsOptions);

  const [sortCol, setSortCol] = useState({
    label: "createdAt",
    value: 1,
  });

  const { getSortOrder, getOrderObj, getSearchObj, getFilterObj } =
    useInventoryLog();

  const handleQueryChange = (obj: any) => {
    // updare router query based on implemented filters
    let mergedObj: any = { ...Object.fromEntries([...searchParams]), ...obj };
    let modified: any = {};

    Object.keys(mergedObj).map((key) => {
      if (mergedObj[key] != "all" && mergedObj[key] != "") {
        if (key in obj) {
          modified[key] = obj[key];
        } else {
          modified[key] = mergedObj[key];
        }
      }
    });

    setSearchParams(modified);
    localStorage.setItem("invLogFilters", JSON.stringify({ ...modified }));

  };

  useEffect(() => {
    handleSearch(searchText);
    handleQueryChange({ searchText: searchText });
  }, [debouncedSearchText]);

  const fetchInventoryLogs = (
    skip = 0,
    search = searchText,
    sortName = sortCol.label,
    sortOrder = getSortOrder(sortCol.value),
    filters = productFilters
  ): any => {
    logger("sortName", sortName);

    let orderObj = getOrderObj(sortName, sortOrder);
    let searchObj = getSearchObj(search);
    let filterObj = getFilterObj(filters);

    getInventoryLogs({
      variables: {
        skip: skip,
        ...searchObj,
        ...filterObj,
        ...orderObj,
      },
    }).then((res: any) => {
      setItemsCount(res.data.inventoryLogs.totalCount);

      const inventoryLogList = res.data.inventoryLogs.items;
      const inventoryLogListFlattened = inventoryLogList.map((item: any) => {
        let time = "";
        if (item.createdAt) {
          time = moment(item.createdAt).format("D-M-YYYY / HH:mm");
        }
        let serialsLength = item?.serials?.length || 0;

        return objFlatten({
          ...item,
          createdAt: time,
          serialsLength: serialsLength,
        });
      });

      setInventoryLogs(inventoryLogListFlattened);
      logger(inventoryLogList, inventoryLogListFlattened);
    });
  };

  const fetch_merchants = ({ page = 1, searchQuery = "" } = {}) => {
    logger("fetch_merchants", page, searchQuery);

    const searchObj = getSearchObj(searchQuery);

    return getMerchants({
      variables: {
        where: {},
        skip: (page - 1) * 20,
        ...searchObj,
      },
    }).then((res) => {
      setMerchants(res?.data?.merchants?.items);

      return {
        options: res?.data?.merchants?.items,
        hasMore: res?.data?.merchants?.totalCount >= page * 20,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    });
  };

  const fetch_merchant = ({ id = "0" } = {}) => {
    return getMerchants({
      variables: {
        where: { id: { eq: parseInt(id) } },
      },
    }).then((res) => {
      let data = res?.data?.merchants?.items[0];
      return data;
    });
  };

  const fetch_products = ({ page = 1, searchQuery = "" } = {}) => {
    logger("fetch_merchants", page, searchQuery);

    const searchObj = getSearchObj(searchQuery);

    return getProducts({
      variables: {
        where: {},
        skip: (page - 1) * 20,
        ...searchObj,
      },
    }).then((res) => {
      setProducts(res?.data?.products?.items);

      return {
        options: res?.data?.products?.items,
        hasMore: res?.data?.products?.totalCount >= page * 20,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    });
  };

  const fetch_product = ({ id = "0" } = {}) => {
    return getProducts({
      variables: {
        where: { id: { eq: parseInt(id) } },
      },
    }).then((res) => {
      let data = res?.data?.products?.items[0];
      return data;
    });
  };

  const fetch_locations = ({ page = 1, searchQuery = "" } = {}) => {
    logger("fetch_merchants", page, searchQuery);

    const searchObj = getSearchObj(searchQuery);

    return getLocations({
      variables: {
        where: {},
        skip: (page - 1) * 20,
        ...searchObj,
      },
    }).then((res) => {
      let locationList = res?.data?.locations?.items;
      let locationsModified: any = [];
      locationList.map((item: any) => {
        if (!item.label) {
          locationsModified.push({
            value: item.value,
            label: item.locationCode,
          });
        } else {
          locationsModified.push(item);
        }
        setLocations(locationsModified);
      });
      return {
        options: locationsModified,
        hasMore: res?.data?.locations?.totalCount >= page * 20,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    });
  };

  const fetch_location = ({ id = "0" } = {}) => {
    return getLocations({
      variables: {
        where: { id: { eq: parseInt(id) } },
      },
    }).then((res) => {
      let data = res?.data?.locations?.items[0];
      return data;
    });
  };

  const handleSort = (sortVal = "") => {
    if (sortCol.label === sortVal) {
      // sort Desc
      const sortOrder = getSortOrder(sortCol.value + 1);
      sortCol.value += 1;

      fetchInventoryLogs(0, searchText, sortVal, sortOrder);
    } else {
      setSortCol((old: any) => ({
        ...old,
        label: sortVal,
        value: 0,
      }));
      fetchInventoryLogs(0, searchText, sortVal, "ASC");
    }

    setPage(0);
  };
  function handleSearch(search: any) {
    fetchInventoryLogs(0, search, sortCol.label, getSortOrder(sortCol.value));
    setPage(0);
  }

  function handleFilter() {
    handleQueryChange(productFilters);
    fetchInventoryLogs(
      0,
      searchText,
      sortCol.label,
      getSortOrder(sortCol.value),
      productFilters
    );
  }

  function handleFilterReset() {
    handleQueryChange(filters);
    setProductFilters(filters);
    fetchInventoryLogs(
      0,
      searchText,
      sortCol.label,
      getSortOrder(sortCol.value),
      filters
    );
    setFilterOpen(false);
  }
  return (
    <>
      <div>
        <div className="main-wrapper flex justify-between space-x-3">
          <div className="w-1/2 flex space-x-3">
            <NewSearch
              hasValue={true}
              value={searchText}
              filterName="Search"
              infoText=""
              placeholder="Type here..."
              onChange={(e: any) => setSearchText(e.target.value)}
              handleClearSearch={() => setSearchText("")}
            />
            <div
              onClick={() => setFilterOpen(true)}
              className="flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg"
            >
              <GridFilterListIcon color="warning" />
              <span className="text-orange-500 font-medium uppercase ml-2">{`Filters By`}</span>
            </div>
            {/* <NormalSelect
              filter={{
                name: "Filter by",
                options: [{ value: 1, label: "Product" }],
              }}
              defaultValue={{ value: 1, label: "Product" }}
              handleChange={() => {}}
            />
            <NormalSelect
              filter={{
                name: "Location Type",
                options: [{ value: 1, label: "shelf" }],
              }}
              defaultValue={{ value: 1, label: "Shelf" }}
              handleChange={() => {}}
            /> */}
          </div>
        </div>
        <PaginatedTable
          sortCol={sortCol}
          count={itemsCount}
          page={page}
          setPage={setPage}
          onPageChange={fetchInventoryLogs}
          cols={cols}
          data={inventoryLogs}
          selectedRows={selectedRows}
          loading={false}
          filterByBin={filterByBin}
          onSortClick={(sortVal) => {
            handleSort(sortVal);
          }}
        />
      </div>

      <Modal
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        footer={(handleClose: any) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={handleFilter}
            handleReset={handleFilterReset}
          />
        )}
        onClose={() => {}}
        title={"Filter Products"}
      >
        <FiltersList
          selectedFilters={productFilters}
          filters={[
            {
              name: "Filter by Product",
              value: "productId",
              id: 1,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  let sel = fetch_product({ id: id });
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: any, page: any) =>
                fetch_products({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Merchant",
              value: "merchantId",
              id: 2,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  let sel = fetch_merchant({ id: id });
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: any, page: any) =>
                fetch_merchants({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Source Location",
              value: "sourceLocationId",
              id: 5,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  let sel = fetch_location({ id: id });
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: any, page: any) =>
                fetch_locations({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Source Location Type",
              value: "sourceLocationType",
              id: 6,
              options: [
                { value: "all", label: "All" },
                { value: "WAREHOUSE", label: "Warehouse" },
                { value: "SHELF", label: "Shelf" },
                { value: "CART", label: "Cart" },
                { value: "DRIVER", label: "Driver" },
                { value: "ORDER", label: "Order" },
              ],
            },
            {
              name: "Filter by Destination Location",
              value: "destinationLocationId",
              id: 3,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  let sel = fetch_location({ id: id });
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: any, page: any) =>
                fetch_locations({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Destination Location Type",
              value: "destinationLocationType",
              id: 4,
              options: [
                { value: "all", label: "All" },
                { value: "WAREHOUSE", label: "Warehouse" },
                { value: "SHELF", label: "Shelf" },
                { value: "CART", label: "Cart" },
                { value: "DRIVER", label: "Driver" },
                { value: "ORDER", label: "Order" },
              ],
            },
          ]}
          handleChange={(filterVal: any, filterName: string) => {
            setProductFilters((old) => ({
              ...old,
              [filterName]: filterVal,
            }));
          }}
        />
      </Modal>
    </>
  );
}
