import { useLazyQuery, useMutation } from '@apollo/client';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { GET_DRIVER } from 'api/queries/Queries';
import { stagingOptions } from 'constants/apollo';
import { logger } from 'helper/logger';
import { objFlatten } from 'helper/objFlatten';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { blue, green, pink } from '@mui/material/colors';
import { AvatarIcon, DriverAddresIcon, PeopleIcon, VehicleIcon, VerifiedDriverIcon } from '../../../components/svg';
import InfoCard from '../../../components/common/InfoCard';
import MuiFilterButton from '../../../components/common/FilterButton/MuiFilterButton';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DriverHomeTab from '../components/DriverHomeTab';
import DriverInfoTab from '../components/DriverInfoTab';
import DriverPaymentTab from '../components/DriverPaymentTab';
import { UPDATE_DRIVER } from 'api/mutations/Mutations';
import { toast } from 'react-toastify';
import DriverAllowancesTab from '../components/DriverAllowancesTab';
import DriverUserCredentialsTab from '../components/DriverUserCredentialsTab';
import DriverOperationOptions from '../components/DriverOperationOptions';
import DriverBonusesTab from '../components/DriverBonusesTab';
import DriverLegalDocsTab from '../components/DriverLegalDocsTab';
import Modal from '../../../components/common/Modal'
import ResetPassword from '../components/ResetPassword';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Tooltip } from 'react-tooltip';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel (props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps (index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

const actions = ["Actions", "Ban Courier", "Unban Courier", "Change Password"]

export default function KnightDetails () {
  const { knightId: driverId } = useParams();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false)
  const [tab, setTab] = useState(0);
  const [homeTableData, setHomeTableData] = useState([])
  const [userCredentialsTableData, setUserCredentialsTableData] = useState([])
  const [vehicleAssignmentsTableData, setVehicleAssignmentsTableData] = useState([])
  const [driverData, setDriverData] = useState<any>({})
  const [getDriver, { data, error }] = useLazyQuery(GET_DRIVER, stagingOptions);

  const [
    updateDriver,
    {
      data: updateRes,
      loading: updateloading,
      error: updateError,
    },
  ] = useMutation(UPDATE_DRIVER, stagingOptions);

  const handleUpdateDriver = (attributes: any) => {

    if (Object.keys(attributes).length > 0)
      updateDriver({
        variables: {
          input: {
            courierId: parseInt(driverData.id),
            attributes
          }
        }
      })
  }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleAction = (action: String) => {
    switch (action) {
      case "Ban Courier":
        return handleUpdateDriver({ isBanned: true })
      case "Unban Courier":
        return handleUpdateDriver({ isBanned: false })
      case "Change Password":
        return setModalOpen(true)
    }
  }

  function fetchData (id = driverId) {
    getDriver({
      variables: {
        id,
      },
    })
  }

  const reload = () => {
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (updateRes) {
      toast.success(`Successfully updated Courier ${driverData.id}`)
      setModalOpen(false)
      fetchData()
    }
  }, [updateRes])

  useEffect(() => {
    if (updateError) {
      toast.error(`Failed to update Courier ${driverData.id}`)
      fetchData()
    }
  }, [updateError])

  useEffect(() => {
    if (data) {
      // logger("Driver DATA HERE", data.fetchCourier);
      const driver = data.fetchCourier;
      if (driver?.activeShipments) {
        let dataFlattened = driver?.activeShipments?.map((item: any) => {
          let orderCount = 0;
          if (item?.orders) {
            orderCount = item?.orders?.length || 0
          }
          let drivingTime = '';
          if (item.actualDeliveryDuration && item.fullTripDuration) {
            drivingTime = `<div style="font-size:12px">Required <b>${item.actualDeliveryDuration}</b> <span style="font-size:8px">HH:MM:SS</span></div> <div style="font-size:12px">
                    Given  <b>${item.fullTripDuration}</b> <span style="font-size:8px">HH:MM:SS</span> </div>`
          }
          let distance = item.firstOrderDistanceKm + " km"
          let reward = "SAR " + item.potentialReward

          let trackingNo = `<div>${item.trackingNo}</div><div style="font-size: 12px">Shipment ID: ${item.id}</div>`
          return objFlatten({ ...item, orderCount, drivingTime, distance, reward, trackingNo });
        });
        setHomeTableData(dataFlattened);
      }

      if (driver?.courierExternalIds) {
        let dataFlattened = driver?.courierExternalIds?.map((item: any) => {
          return objFlatten({ ...item });
        });
        logger("FLATTENED<", dataFlattened)

        setUserCredentialsTableData(dataFlattened);
      }

      if (driver?.vehicleAssignments) {
        let dataFlattened = driver?.vehicleAssignments?.map((item: any) => {
          return objFlatten({ ...item });
        });
        setVehicleAssignmentsTableData(dataFlattened);
      }

      setDriverData(driver);
    }
  }, [data])

  return (
    <>
      <div className="color-black  w-full bg-gray-50 h-full flex flex-col">
        {/* <OrderDeta/> */}
        <div className="flex w-full justify-between p-4 mt-1 bg-white">

          <div className='w-full flex space-x-20 justify-between'>
            <div className='flex space-x-5'>
              {driverData?.avatarUrl && (
                <img
                  className="h-10 w-10 mr-1 rounded-full border border-transparent "
                  src={driverData?.avatarUrl}
                />
              )}
              <div className={`flex flex-col items-start w-48`} onClick={() => { }}>
                <span className={`text-sm text-gray-500 flex items-center justify-center space-x-1`}>
                  <AvatarIcon /> <span>Name</span>
                </span>
                <div className={`capitalize flex items-center space-x-2`}> {driverData && !driverData?.onProbation &&

                  <>
                    <div className='-ml-1' ><Tooltip id={'32'} place="bottom" /></div>
                    <a
                      data-tooltip-id={'32'}
                      // variant='info'
                      data-tooltip-html={'verified driver'}
                      className='cursor-pointer'
                    // placement='bottom'
                    >
                      <VerifiedDriverIcon />
                    </a>

                  </>

                }<span className='flex'>{`${driverData?.firstName} ${driverData?.lastName}`}</span></div>
                <span className="text-sm text-gray-400 flex items-center justify-center space-x-1">
                  {`Knight ID: ${driverData?.id}`}
                </span>
              </div>

            </div>
            <InfoCard
              title="Contractor"
              icon={<PeopleIcon />}
              info={driverData?.partner?.name}
            />
            <InfoCard
              title="Vehicle"
              icon={<VehicleIcon />}
              info={driverData?.vehicleType}
            />
            <InfoCard
              title="City"
              icon={<DriverAddresIcon />}
              info={driverData?.city?.name}
            />
            <div className='flex flex-col '>
              <div className='flex space-x-2 items-center'>
                <div className={`h-4 w-4  rounded-full ${driverData?.isBanned ? 'bg-red-500' : 'bg-gray-300'}`}>
                </div>
                <span className='w-40'>Blocked / Banned</span>
              </div>
              <div className='flex space-x-2 items-center'>
                <div className={`h-4 w-4  rounded-full ${driverData?.isOnline ? 'bg-successColor' : 'bg-gray-300'}`}>
                </div>
                <span>Online</span>
              </div>
              <div className='flex space-x-2 items-center'>
                <div className={`h-4 w-4  rounded-full ${driverData?.isBusy ? 'bg-blue-700' : 'bg-gray-300'}`}>
                </div>
                <span>Busy</span>
              </div>
              {/* <div className='flex space-x-2 items-center'>
                  <div className={`h-4 w-4  rounded-full ${driverData?.onProbation ? 'bg-warningColor' : 'bg-gray-300'}`}>
                  </div>
                  <span>Under Test</span>
                </div> */}
              {/* <div>
                <div className='flex space-x-2 items-center'>
                  <div className={`h-4 w-4  rounded-full ${driverData?.isOnline ? 'bg-successColor' : 'bg-gray-300'}`}>
                  </div>
                  <span>Online</span>
                </div>
                <div className='flex space-x-2 items-center'>
                  <div className={`h-4 w-4  rounded-full ${driverData?.isBusy ? 'bg-blue-700' : 'bg-gray-300'}`}>
                  </div>
                  <span>Busy</span>
                </div>
              </div> */}
            </div>
          </div>
          {tab == -1 &&
            <>
              <FormGroup>
                <div className='flex'>
                  <FormControlLabel control={<PinkSwitch checked={driverData?.isBanned} onClick={(e) => { e.preventDefault() }} />} label="Blocked/Banned" />
                  <FormControlLabel control={<Switch checked={driverData?.onProbation} onClick={(e) => { e.preventDefault() }} />} label="Under Test" />
                </div>
              </FormGroup>
              <MuiFilterButton
                options={actions}
                onClick={i => handleAction(actions[i])}
              />
            </>}
        </div>
        <div className='bg-white mt-1 h-full'>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 4,
                borderColor: "rgb(249 250 251)",
              }}
            >
              <Tabs
                value={tab}
                onChange={handleChange}
                aria-label='outbound tabs'
                variant='fullWidth'
              >
                <Tab
                  onClick={(e) => handleChange(e, 0)}
                  label={<span>HOME</span>}
                  {...a11yProps(0)}
                />
                <Tab
                  onClick={(e) => handleChange(e, 1)}
                  label={<span>KNIGHT INFO</span>}
                  {...a11yProps(1)}
                />
                <Tab
                  onClick={(e) => handleChange(e, 2)}
                  label={<span>PAYMENT</span>}
                  {...a11yProps(2)}
                />
                <Tab
                  onClick={(e) => handleChange(e, 3)}
                  label={<span>LEGAL DOCS</span>}
                  {...a11yProps(3)}
                />
                <Tab
                  onClick={(e) => handleChange(e, 4)}
                  label={<span>USER CREDENTIALS</span>}
                  {...a11yProps(4)}
                />
                {/* <Tab
                  onClick={(e) => handleChange(e, 5)}
                  label={<span>ALOWANCES</span>}
                  {...a11yProps(5)}
                /> */}
                {/* <Tab
                  onClick={(e) => handleChange(e, 6)}
                  label={<span>OPERATION OPTIONS</span>}
                  {...a11yProps(5)}
                /> */}
                <Tab
                  onClick={(e) => handleChange(e, 7)}
                  label={<span>BONUS / DEDUCTION</span>}
                  {...a11yProps(5)}
                />
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
              <DriverHomeTab driver={driverData} handleUpdateDriver={handleUpdateDriver} tableData={homeTableData} openChangePasswordModal={() => setModalOpen(true)} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <DriverInfoTab handleUpdateDriver={handleUpdateDriver} driver={driverData} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <DriverPaymentTab handleUpdateDriver={handleUpdateDriver} driver={driverData} />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <DriverLegalDocsTab driver={driverData} />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <DriverUserCredentialsTab reload={reload} tableData={userCredentialsTableData} openChangePasswordModal={() => setModalOpen(true)} driver={driverData} />
            </TabPanel>
            <TabPanel value={tab} index={5}>
              <DriverAllowancesTab reload={reload} driver={driverData} vehiclesTableData={vehicleAssignmentsTableData} />
            </TabPanel>
            <TabPanel value={tab} index={6}>
              <DriverOperationOptions handleUpdateDriver={handleUpdateDriver} driver={driverData} />
            </TabPanel>
            <TabPanel value={tab} index={7}>
              <DriverBonusesTab reload={reload} handleUpdateDriver={handleUpdateDriver} driver={driverData} />
            </TabPanel>
          </Box>
        </div>

      </div>
      <Modal
        footer={() => (
          // <ModalFooter
          //   handleClose={handleClose}
          //   handleSubmit={handleFilter}
          //   handleReset={handleFilterReset}
          // />
          <></>
        )}
        onDismiss={() => {
          setModalOpen(false);
        }}
        hidden
        autoOpen={modalOpen}
        onClose={() => { }}
        title={"Reset password"}
      >
        <ResetPassword handleUpdateDriver={handleUpdateDriver} handleClose={() => setModalOpen(false)} />
      </Modal>
    </>
  )
}
