import { getSortationOrder } from "../../../helper/GetSortOrder";

export default function useInventoryLog() {
    const getOrderObj = (sortName, sortOrder) => {
        if (sortName !== "" && sortOrder != "DEFAULT") {
            switch (sortName) {
                case "id":
                    return {
                        product: {
                            id: sortOrder,
                        },
                    };
                case "product.name":
                    return {
                        product: {
                            name: sortOrder,
                        },
                    };
                case "merchant.name":
                    return {
                        order: {
                            merchant: {
                                name: sortOrder,
                                referenceName: sortOrder
                            },
                        },
                    };
                case "sourceLocation.referenceName":
                    return {
                        order: {
                            sourceLocation: {
                                referenceName: sortOrder,
                                locationCode: sortOrder
                            },
                        },
                    };
                case "sourceLocation.type":
                    return {
                        order: {
                            sourceLocation: {
                                type: sortOrder,
                            },
                        },
                    };
                case "destinationLocation.referenceName":
                    return {
                        order: {
                            destinationLocation: {
                                referenceName: sortOrder,
                                locationCode: sortOrder
                            },
                        },
                    };
                case "destinationLocation.type":
                    return {
                        order: {
                            destinationLocation: {
                                type: sortOrder,
                            },
                        },
                    };
                case "createdAt":
                    return {
                        order: {
                            createdAt: sortOrder,
                        },
                    };
            }
        }
        else {
            return {
                order: {
                    id: "ASC",
                },
            };
        }
    }
    const getSortOrder = (num) => {
        return getSortationOrder(num)
    }

    const getFilterObj = (filters) => {
        let filterList = [
        ]

        if (filters?.productId != "all") {
            filterList.push({
                product: {
                    id: {
                        eq: parseInt(filters.productId)
                    },
                },
            });
        }

        if (filters?.merchantId != "all") {
            filterList.push({
                merchant: {
                    id: {
                        eq: parseInt(filters.merchantId)
                    },
                },
            });
        }

        if (filters?.destinationLocationId != "all") {
            filterList.push({
                destinationLocationId: {
                    eq: parseInt(filters.destinationLocationId),
                },
            });
        }
        if (filters?.destinationLocationType != "all") {
            filterList.push({
                destinationLocation: {
                    type:
                    {
                        eq: filters.destinationLocationType,
                    }
                }
            });
        }
        if (filters?.sourceLocationId != "all") {
            filterList.push({
                sourceLocationId: {
                    eq: parseInt(filters.sourceLocationId),
                },
            });
        }
        if (filters?.sourceLocationType != "all") {
            filterList.push({
                sourceLocation: {
                    type:
                    {
                        eq: filters.sourceLocationType,
                    }
                }
            });
        }
        return {
            where: {
                and: [...filterList],
            }
        }
    }

    const getSearchObj = (search) => {
        let searchObj = { query: "" }

        if (search.trim() != "") {
            searchObj = {
                query: search
            }
        }
        return searchObj
    }

    return {
        getOrderObj,
        getSortOrder,
        getFilterObj,
        getSearchObj
    };
}