function Logo() {
	return (
		<svg
			width='20'
			height='32'
			viewBox='0 0 20 32'
			fill='none'
			className='sidebar__logo'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M0.236476 2.969C0.697818 1.0647 1.989 0.544974 2.57857 0.523438H6.06918C7.39676 0.523438 8.49163 1.56025 8.87312 2.07865C10.75 4.20412 10.4563 7.11151 10.0748 8.29952L4.14679 22.0452C4.10966 22.1313 4.16502 22.2317 4.24963 22.2317H8.93413C9.01478 22.2317 9.07013 22.3236 9.04066 22.4086L6.40937 29.9985C6.36004 30.1408 6.53083 30.2456 6.60913 30.1211L13.7228 18.8076C13.7767 18.7218 13.7226 18.6028 13.6295 18.6028H9.72238C9.62979 18.6028 9.57553 18.4848 9.62863 18.3989L12.3065 14.0668C15.012 9.55186 14.7778 6.31495 14.0533 4.3191C14.0011 4.17548 14.1862 4.03365 14.2707 4.15593C16.9522 8.03495 15.8442 12.1122 14.8047 13.9089C14.7603 13.9856 14.7976 14.0905 14.876 14.1136C19.1696 15.3795 19.915 20.0784 19.7455 22.2965C19.7455 28.3525 15.6381 30.6918 13.5749 31.1075C13.5684 31.1088 13.5624 31.1094 13.5557 31.1094H2.57857C1.31002 31.1094 0.497314 29.7131 0.240292 28.9933C0.23491 28.9782 0.232422 28.9626 0.232422 28.9463V3.00307C0.232422 2.99165 0.233808 2.98002 0.236476 2.969Z' />
		</svg>
	);
}

export default Logo;
