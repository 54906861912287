import React from "react";
import cn from "classnames";
import DriverCard from "../../../components/DriverCard";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/common/Button";

function AllDriversList ({
  drivers,
  handleClick,
  selectedRef,
  selectedDriverId,
}) {
  const { t } = useTranslation("common");

  return (
    <>
      <div className={`flex items-center gap-4 ${cn({ hidden: drivers?.length == 0 })}`}>
        <h3>
          {t("all_drivers")}
        </h3>
        <span className="text-orange-500 bg-white px-2 rounded-xl">{drivers?.length}</span>
      </div>
      {drivers?.map((driver) => (
        <DriverCard
          selectedRef={selectedRef}
          key={driver.id}
          data={driver}
          onClick={handleClick}
          selectedDriverId={selectedDriverId}
        />
      ))}
    </>
  );
}

export default React.memo(AllDriversList);
