import { useContext, useEffect, useRef, useState } from "react";
import { SearchIcon } from "../../svg";
import CloseIcon from "@mui/icons-material/Close";
import { logger } from "../../../helper/logger";
import { useDebounce } from "../../../hooks/useDebounce";
import MapIsTypingContext from "context/MapIsTypingContext";

export default function SearchBar ({
  placeholder = "Search shipments",
  onChange,
  value
}) {

  const { setIsInputFocused } = useContext(MapIsTypingContext);
  const [isOpen, setIsOpen] = useState(value ? true : false);
  const [text, setText] = useState(value || "");
  const debouncedSearchTerm = useDebounce(text, 500);
  const handleClose = () => {
    setText("");
    onChange("");
    setIsOpen(false);
  };

  const inputRef = useRef(null)
  const handleOpen = () => {
    setIsOpen(true);
  };

  const onFocus = () => {
    setIsInputFocused(true)
  }

  const onBlur = () => {
    setIsInputFocused(false)
  }

  useEffect(() => {
    if (isOpen)
      inputRef.current.focus()
  }, [isOpen])

  useEffect(() => {
    onChange(debouncedSearchTerm);
    
  }, [debouncedSearchTerm]);

  return (
    <>
      {!isOpen && (
        <span
          className={`ml-0 hover:bg-muiLightBlue p-2 pb-1 mt-1 rounded-sm hover:cursor-pointer transform scale-110`}
          onClick={handleOpen}
        >
          <SearchIcon />
        </span>
      )}
      <div
        className={`flex items-center justify-between bg-lightBgColor ${isOpen ? "w-[360px] absolute left-0 z-10" : "w-0"
          } `}
      >
        <div
          className={`flex items-center ${isOpen
            ? "w-[320px] border-2 border-primaryColor"
            : " bg-transparent"
            } bg-white rounded-md text-sm`}
        >
          <input
            onFocus={onFocus}
            onBlur={onBlur}
            ref={inputRef}
            className={`${isOpen ? "flex" : "hidden"
              } w-36 rounded-lg py-2 ml-2 flex-1 ouline-none focus:outline-none`}
            placeholder={placeholder}
            onChange={(e) => setText(e?.target?.value)}
            value={text}
          />
          {isOpen && (
            <span
              className={`px-3 hover:cursor-pointer`}
              onClick={() => {
                logger("searching");
              }}
            >
              <SearchIcon />
            </span>
          )}
        </div>
        {isOpen && (
          <div
            className="hover:cursor-pointer hover:bg-muiLightBlue p-1 rounded-md"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        )}
      </div>
    </>
  );
}
