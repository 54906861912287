
import React, { useState } from "react";
import { GridFilterListIcon } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSearchParams } from "react-router-dom";

import NewSearch from "../../../components/common/Search/new-search";
import Modal from "../../../components/common/Modal";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import { useDebounce } from "hooks/useDebounce";
import WalletsTable from './components/WalletsTable';
import FiltersList from '../../../components/FiltersList';

export default function Wallets () {
  const [value, setValue] = useState("1");
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchText, setSearchText] = useState(
    Object.fromEntries([...searchParams]).searchText || ""
  );
  const debouncedSearchText = useDebounce(searchText, 500);

  const [filterOpen, setFilterOpen] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="main-wrapper flex justify-between space-x-3 border-t-2 border-gray-50 border-b-2">
        <div className="w-[450px] flex space-x-3">
          <NewSearch
            hasValue={true}
            filterName="Search"
            infoText=""
            placeholder="Type here..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            handleClearSearch={() => setSearchText("")}
          />
          <div
            onClick={() => setFilterOpen(true)}
            className="flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg"
          >
            <GridFilterListIcon color="primary" />
            <span className="text-[#FF9800] font-medium uppercase ml-2 min-w-[100px]">{`Filters By`}</span>
          </div>
        </div>
      </div>

      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 2, borderColor: "rgb(249 250 251)" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="fullWidth"
            >
              <Tab label="All Wallets" value="1" />
              <Tab label="Last Delivered" value="2" />
            </TabList>
          </Box>

          <TabPanel value="1" sx={{ padding: "0" }}>
            <WalletsTable value="1" searchText={debouncedSearchText} />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0" }}>
            <WalletsTable value="2" searchText={debouncedSearchText} />
          </TabPanel>
        </TabContext>
      </Box>
      <Modal
        footer={(handleClose: any) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={() => { }
              // handleFilter
            }
            handleReset={() => { }
              // handleFilterReset
            }
          />
        )}
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        onClose={() => { }}
        title={"Filter Wallets"}
      >
        <FiltersList
          selectedFilters={{}}
          handleChange={(filterVal: any, filterName: any) => { }
            //   setDriverFilters((old) => ({
            //     ...old,
            //     [filterName]: filterVal,
            //   }));
            // }
          }
          filters={[
            // {
            //   name: "Filter by City",
            //   value: "driverCity",
            //   id: 1,
            //   isAsync: true,
            //   isSearchable: true,
            //   getSelectedVal: (id) => {
            //     if (id && id != "all") {
            //       let sel = fetch_city(id);
            //       return sel;
            //     } else return { value: "all", label: "All" };
            //   },
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_cities({ page: page, searchQuery: searchQuery }),
            // },
            // {
            //   name: "Filter by Vehicle Model",
            //   value: "driverVehicleModel",
            //   id: 122,
            //   isAsync: true,
            //   isSearchable: false,
            //   getSelectedVal: (id: any) => {
            //     if (id && id != "all") {
            //       return { value: id, label: id };
            //     } else return { value: "all", label: "All" };
            //   },
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_vehicleModels({ page: page, searchQuery: searchQuery }),
            // },
            // {
            //   name: "Filter by Nationality",
            //   value: "driverNationality",
            //   id: 12,
            //   isAsync: true,
            //   isSearchable: false,
            //   getSelectedVal: (id: any) => {
            //     if (id && id != "all") {
            //       return { value: id, label: id };
            //     } else return { value: "all", label: "All" };
            //   },
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_nationalities({ page: page, searchQuery: searchQuery }),
            // },
            // {
            //   name: "Filter by Partner",
            //   value: "driverPartner",
            //   id: 11,
            //   isAsync: true,
            //   isSearchable: true,
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_driver_partners({ page: page, searchQuery: searchQuery }),
            // },

            {
              name: "Filter by Merchant",
              value: "driverDevice",
              id: 4,
              options: [
                { value: "all", label: "All" },
                { value: "android", label: "Android" },
                { value: "ios", label: "Ios" },
              ],
            },
          ]}
        />
      </Modal>
    </>
  );
}
