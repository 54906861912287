export const getSortationOrder = (num) => {
    const remainder = num % 3;

    switch (remainder) {
        case 0:
            return "ASC";
        case 1:
            return "DESC";
        case 2:
            return "DEFAULT"
    }
}