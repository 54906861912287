import NormalSelect from '../../../components/common/Select/NormalSelect';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import CustomInput from "../../../components/common/Input";
import moment from 'moment';
import CustomButton from '../../../components/common/Button';
import * as Yup from 'yup'

export default function DriverPaymentTab ({ driver, handleUpdateDriver }) {
    const [bankIbanConfirmation, setBankIbanConfirmation] = useState("")
    const formik = useFormik({
        initialValues: {
            bankIbanNo: driver?.bankIbanNo || "",
            bankSwift: driver?.bankSwift || "",
            bankCountry: driver?.bankCountry || "",
            stcPayQrUrl: driver?.stcPayQrUrl || "",
        },
        validationSchema: Yup.object({
            bankIbanNo: Yup.string().required("Required"),
            bankSwift: Yup.string().required("Required"),
            bankCountry: Yup.string().required("Required"),
            stcPayQrUrl: Yup.number("Should contain numbers only"),
        }),
        onSubmit: (values) => {
            if (values.bankIbanNo === bankIbanConfirmation)
                handleUpdateDriver(values)
            else formik.setFieldError("bankIbanNo", "Please make sure that you filled in IBAN number  and IBAN number confirmation arre the same")
            // setIsSubmitting(true)
            // alert(JSON.stringify(values, null, 2));
        },
    });
    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit()
                }}
                className='main-wrapper flex flex-col justify-between space-y-5'
            >
                <div
                    className="flex space-x-2 py-4"
                >
                    <div className="w-1/4 flex flex-col">
                        <CustomInput
                            id="bankIbanNo"
                            name="bankIbanNo"
                            type="text"
                            inputName="IBAN Number *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bankIbanNo}
                        />
                        {formik.touched.bankIbanNo && formik.errors.bankIbanNo &&
                            <div className="error" key={0}>{formik.errors.bankIbanNo}</div>
                        }
                    </div>
                    <div className="w-1/4 flex flex-col">
                        <CustomInput
                            id="bankIbanConfirmation"
                            name="bankIbanConfirmation"
                            type="text"
                            inputName="IBAN Number Confirmation *"
                            onChange={(e) => setBankIbanConfirmation(e.target.value)}
                            onBlur={formik.handleBlur}
                            value={bankIbanConfirmation}
                        />
                        {formik.touched.bankIbanNo && formik.errors.bankIbanNo &&
                            <div className="error" key={0}>{formik.errors.bankIbanNo}</div>
                        }
                    </div>
                    <div className="w-1/4 flex flex-col">
                        <CustomInput
                            id="bankCountry"
                            name="bankCountry"
                            type="text"
                            inputName="Bank Country *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bankCountry}
                        />
                        {formik.touched.bankCountry && formik.errors.bankCountry &&
                            <div className="error" key={0}>{formik.errors.bankCountry}</div>
                        }
                    </div>
                    <div className="w-1/4 flex flex-col">
                        <CustomInput
                            id="bankSwift"
                            name="bankSwift"
                            type="text"
                            inputName="Bank Swift *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bankSwift}
                        />
                        {formik.touched.bankSwift && formik.errors.bankSwift &&
                            <div className="error" key={0}>{formik.errors.bankSwift}</div>
                        }
                    </div>
                </div>

                <div className='flex py-6 border-t-2 border-gray-100'>
                    <div className="w-1/4 flex flex-col pr-2">
                        <CustomInput
                            id="stcPayQrUrl"
                            name="stcPayQrUrl"
                            type="text"
                            inputName="STCPay Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.stcPayQrUrl}
                        />
                        {formik.touched.stcPayQrUrl && formik.errors.stcPayQrUrl &&
                            <div className="error" key={0}>{formik.errors.stcPayQrUrl}</div>
                        }

                    </div>
                </div>
                <div className='flex w-full justify-end space-x-6'>
                    <div className="w-1/8">
                        <CustomButton
                            type='submit'
                            variant='outlined'
                            color="warning"
                            onClick={() => {
                                // formik.resetForm()
                            }}
                            style={{ width: "100%", height: "38px" }}
                        >
                            Cancel
                        </CustomButton>
                    </div>
                    <div className="w-1/7">
                        <CustomButton
                            type='submit'
                            variant='contained'
                            color="warning"
                            onClick={() => { }}
                            style={{ width: "100%", height: "38px" }}
                        >
                            Update
                        </CustomButton>
                    </div>
                </div>
            </form>
        </div>
    )
}
