import CustomButton from '../../../../components/common/Button'
import React, { useState, useEffect } from 'react'
import { Router, useParams } from 'react-router-dom'
import InfoCard from '../../../../components/common/InfoCard';
import CustomInput from "../../../../components/common/Input";
import MUIDatePicker from '../../../../components/common/DatePicker';
import moment from 'moment';
import NormalSelect from '../../../../components/common/Select/NormalSelect';
import PaginatedTable from '../../../../components/Table/paginatedTable';
import { LOAD_BILLING } from 'api/queries/FinanceQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { stagingOptions } from 'constants/apollo';
import { toast } from 'react-toastify';
import { objFlatten } from 'helper/objFlatten';


const cols = [
    { name: "Source", value: "source" },
    {
        name: "COD Fees",
        value: "cod",
        type: 'number'
    },
    {
        name: "Delivery Fees",
        value: "totalDeliveryFee",
        type: 'number'

    },
    {
        name: "Addition Prod. Fees",
        value: "totalAdditionalProductsFee",
        type: 'number'

    },
    {
        name: "Return Fees",
        value: "totalReturnFee",
        type: 'number'

    },
    {
        name: "Total cost",
        value: "totalCost",
        type: 'number'

    },
    {
        name: "Discount",
        value: "discount",
        type: 'number'

    },
    {
        name: "Total fees with VAT",
        value: "totalFeesWithVat",
        type: 'number'

    }]

const orderCols = [
    {
        name: "Tracking number",
        value: "trackingNo"
    },
    {
        name: "Merchant Order ID",
        value: "merchantOrderId"
    },
    {
        name: "Neighborhood",
        value: "neighborhood"
    },
    {
        name: "Zone ID",
        value: "zone"
    },
    {
        name: "Customer Name",
        value: "customerName"
    },
    {
        name: "Customer Mobile",
        value: "customerDetails.mobile"
    },
    {
        name: "Grand Total",
        value: "grandTotal",
        type: 'number'
    },
    {
        name: "COD Fee",
        value: "codFee",
        type: 'number'
    },
    {
        name: "Delivery Fee",
        value: "deliveryFee",
        type: 'number'
    },
    {
        name: "Add. Prod. Fee",
        value: "additionalProductsFee",
        type: 'number'
    },
    {
        name: "Return Fee",
        value: "returnFee",
        type: 'number'
    },
    {
        name: "Order Status",
        value: "orderStatus"
    },
    {
        name: "Payment Type",
        value: "paymentType",
        type: "status"
    },
    {
        name: "Payment Status",
        value: "paymentStatus",
        type: "status"
    },
]

export default function BillingInfo ({ itemsCount = 2 }) {
    const { billing: id } = useParams();
    const [page, setPage] = useState(0)
    const [billingData, setBillingData] = useState(null);
    const [discountVal, setDiscountVal] = useState(0)
    const [discountMode, setDiscountMode] = useState('percent')
    const [tableData, setTableData] = useState([])
    const [fetchData, { data, error }] = useLazyQuery(LOAD_BILLING, { ...stagingOptions, fetchPolicy: "network-only" });
    // const [
    //     applyDiscount,
    //     {
    //       data: applyDisRes,
    //       loading: applyDisLoad,
    //       error: applyDisErr,
    //     },
    //   ] = useMutation(APPLY_DISCOUNT, stagingOptions);

    const fetch = (
    ) => {
        try {

            fetchData(
                {
                    variables: {
                        id: parseInt(id)
                    }
                }
            )
            // setLoading(true)
        } catch (e) {
            toast.error("An error occured");
            // setLoading(false)
        }
    };

    useEffect(() => {
        if (data) {
            setBillingData(data.fetchBilling);

            const dataList = data?.fetchBilling?.orders;
            const dataListFlattened = dataList?.map((item) => {
                let customerName = item?.customerDetails?.firstName || ""
                if (item?.customerDetails?.firstName && item?.customerDetails?.lastName) {
                    customerName = item?.customerDetails?.firstName + " " + item?.customerDetails?.lastName
                }

                return objFlatten({
                    customerName,
                    ...item,
                });
            });

            setTableData(dataListFlattened);
        }
    }, [data]);

    useEffect(() => {
        fetch()
    }, [])

    const handleApplyDiscount = () => {
        // apply disc mutation logic goes here
    }

    return (
        <>
            <div className="flex flex-col">
                <div className="main-wrapper flex justify-between border-t-2 border-gray-50 border-b-2">
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">
                            Created at {moment(billingData?.createdAt).format("DD/MM/Y HH:SS")}
                        </span>
                        <span className="text-sm text-gray-500">
                            ID: {billingData?.id}
                        </span>
                    </div>
                    <div className='flex'>
                        <CustomButton
                            onClick={() => { }}>
                            Open Invoice PDF
                        </CustomButton>
                        <CustomButton
                            onClick={() => { }}>
                            open invoice qr code
                        </CustomButton>
                        <CustomButton
                            onClick={() => { }}>
                            open zatca invoice qr code
                        </CustomButton>
                        <CustomButton
                            onClick={() => { }}>
                            send invoice email to merchant
                        </CustomButton>
                    </div>
                </div>
                <div className='flex bg-gray-50 space-x-2 pt-2 pb-2  items-stretch'>
                    <div className='w-3/4 flex flex-col space-y-2 items-stretch justify-stretch'>
                        <div className='main-wrapper bg-white flex justify-between h-1/2'>
                            <InfoCard
                                additionalStyles="w-32"
                                title="Period"
                                info={`From ${moment(billingData?.issueDate).format("DD/MM/Y")} to ${moment(billingData?.dueDate).format("DD/MM/Y")}`}
                            />
                            <InfoCard
                                title="Created at"
                                info={moment(billingData?.createdAt).format("DD/MM/Y")} />
                            <InfoCard
                                title="Issue Date"
                                info={`${moment(billingData?.issueDate).format("DD/MM/Y")} / Week # ${billingData?.week}`} />
                            <InfoCard
                                title="Due Date"
                                info={moment(billingData?.dueDate).format("DD/MM/Y")} />

                            <div className='w-48'>
                                <CustomButton
                                    variant="contained"
                                    color="warning">
                                    Mark invoice as paid
                                </CustomButton>
                            </div>
                        </div>
                        <div className='main-wrapper bg-white flex max-w-full justify-between h-1/2'>
                            <div className=''>upload file</div>
                            <div className='flex space-x-2 items-center '>

                                <MUIDatePicker
                                    id="from"
                                    name="from"
                                    label="From"
                                // value={moment(formik.values.from, "YYYY MM DD")}
                                // onChange={(date) => {
                                //     formik.setFieldValue("from", moment(date).format("YYYY-MM-DD"))
                                // }}
                                // onBlur={formik.handleBlur}
                                />
                                <MUIDatePicker
                                    id="to"
                                    name="to"
                                    label="To"
                                // value={moment(formik.values.to, "YYYY MM DD")}
                                // onChange={(date) => {
                                //     formik.setFieldValue("to", moment(date).format("YYYY-MM-DD"))
                                // }}
                                // onBlur={formik.handleBlur}
                                />

                                <CustomButton
                                    variant="contained"
                                    color="warning">
                                    Search
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 main-wrapper bg-white space-y-4 justify-between'>
                        <h3>Discount</h3>
                        <NormalSelect
                            styles={{ width: "100%" }}
                            key={11}
                            filter={
                                {
                                    name: "Discount mode",
                                    value: "discountMode",
                                    id: 1,
                                    options: [
                                        { value: "percent", label: "Percent" },
                                        { value: "fixed_per_order", label: "Fixed per Order" },
                                        { value: "fixed_per_invoice", label: "Fixed per Invoice" },
                                    ],
                                }
                            }
                            defaultValue={{ value: "percent", label: "Percent" }}
                            handleChange={(e) => setDiscountMode(e.target.value)}
                        />
                        <CustomInput
                            id="discountValue"
                            name="discountValue"
                            type="text"
                            inputName="Discount value"
                            value={discountVal}
                            onChange={e => setDiscountVal(e.target.value)}

                        />
                        <div className='flex space-x-3'>
                            <CustomButton
                                onClick={() => {
                                    setDiscountVal('0')
                                }}
                                additionalStyles='min-w-fit px-2 w-10'>
                                Clear discount
                            </CustomButton>
                            <CustomButton
                                onClick={handleApplyDiscount}
                                additionalStyles='min-w-fit px-2'
                                variant="contained">
                                Apply
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div >
                    <div className='main-wrapper flex space-x-6 font-bold uppercase'>
                        <h3>Grand Total 12.00 SAR</h3>
                        <h3>Invoice Total 0.00 SAR</h3>
                    </div>
                    <PaginatedTable
                        tableHeadStyles={"bg-orange-200"}
                        count={itemsCount}
                        page={page}
                        setPage={setPage}
                        onPageChange={() => { }
                            //fetchCurrentInventory
                        }
                        showPagination={false}
                        cols={cols}
                        data={[{
                            source: "Orders data",
                            cod: billingData?.totalCod,
                            totalDeliveryFee: billingData?.totalDeliveryFee,
                            totalAdditionalProductsFee: billingData?.totalAdditionalProductsFee,
                            totalReturnFee: billingData?.totalReturnFee,
                            totalFeesWithVat: billingData?.totalFeesWithVat,
                            discount: billingData?.discountComputedAmount,
                        },
                        {
                            source: "Invoice data",
                            cod: billingData?.totalCod,
                            totalDeliveryFee: billingData?.totalDeliveryFee,
                            totalAdditionalProductsFee: billingData?.totalAdditionalProductsFee,
                            totalReturnFee: billingData?.totalReturnFee,
                            totalFeesWithVat: billingData?.totalFeesWithVat,
                            discount: billingData?.discountComputedAmount,
                        }]}
                        loading={false}
                    />
                </div>
                <div className='mt-3'>
                    <div className='main-wrapper flex space-x-6 font-bold uppercase'>
                        <h3>Billing Orders</h3>
                    </div>
                    <PaginatedTable
                        showPagination={false}
                        tableHeadStyles={"bg-gray-100"}
                        count={itemsCount}
                        page={page}
                        setPage={setPage}
                        onPageChange={() => { }
                            //fetchCurrentInventory
                        }
                        cols={orderCols}
                        data={tableData}
                        loading={false}
                    />
                </div>
            </div>
        </>
    )
}
