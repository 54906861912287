import React from "react";
import Select from "react-select";
import { CheckedPinIcon, PinIcon } from "../../svg";

const options = [

];
export default function CustomSelect({
  defaultValue,
  pinnedFilterIds,
  filter,
  hasPin = true,
  onPinClick=()=>{},
  handleChange,
}) {
  const [isSelected, setIsSelected] = React.useState(
    pinnedFilterIds?.includes(filter.id)
  );
  return (
    <div className="relative w-full">
      <Select
        value={defaultValue || null}
        maxMenuHeight={100}
        options={filter?.options || options}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          valueContainer: (base) => ({ ...base, marginLeft: hasPin ? 30 : 0 }),
        }}
        onChange={handleChange}
      />
      {hasPin && (
        <div
          onClick={() => {
            onPinClick(filter.id);
            setIsSelected(!isSelected);
          }}
          className="absolute top-1 left-[5px] h-auto w-auto hover:cursor-pointer p-1"
        >
          {isSelected ? <CheckedPinIcon /> : <PinIcon />}
        </div>
      )}
      <div className="absolute w-auto h-4 bg-white -top-[11px] px-1 left-3 text-sm text-gray-500 cursor-default select-none">
        {filter?.name}
      </div>
    </div>
  );
}
