import { getSortationOrder } from "../../../helper/GetSortOrder";

export default function useWarehouse() {
    const getOrderObj = (sortName, sortOrder) => {
        if (sortName !== "" && sortOrder != "DEFAULT") {
            switch (sortName) {
                case "id":
                    return {
                        order: {
                            id: sortOrder,
                        },
                    };
                case "referenceName":
                    return {
                        order: {
                            referenceName: sortOrder
                        },
                    };
                case "city":
                    return {
                        order: {
                            city: sortOrder,
                        },
                    };
                case "status":
                    return {
                        order: {
                            isActive: sortOrder,
                        },
                    };

            }
        }
        else {
            return {
                order: [
                    {
                        id: "ASC",
                    },
                ],
            }

        }
    }
    const getSortOrder = (num) => {
        return getSortationOrder(num)
    }

    const getFilterObj = (filters) => {
        let filterList = [
            {
                type: {
                    eq: "WAREHOUSE",
                }
            },
        ]

        if (filters?.cityId != "all") {
            filterList.push({
                cityId: {
                    eq: parseInt(filters.cityId),
                },
            });
        }

        if (filters?.merchantId != "all") {
            filterList.push({
                currentInventory: {
                    all: {
                        merchantId: { eq: parseInt(filters.merchantId) }
                    }
                }
            });
        }

        if (filters?.status != "all") {
            filterList.push({
                isActive: {
                    eq: filters.status,
                }
            });
        }

        return {
            where: {
                and: [...filterList],
            }
        }
    }

    const getSearchObj = (search) => {

        let searchRes = { query: "" }
        if (search.trim() != "") {
            searchRes.query = search
        }
        return searchRes
    }

    return {
        getOrderObj,
        getSortOrder,
        getFilterObj,
        getSearchObj
    };
}