import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// import i18n (needs to be bundled ;))
import "./i18n";
import FallBackComponent from "./components/FallBackComponent";

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<FallBackComponent />}>
			<App />
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);
