export const downloadExcelFromBinaryData = (data, fileName = 'Untitled') => {
	const blob = new Blob([data], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});

	const url = URL.createObjectURL(blob);
	const downloadLink = document.createElement('a');
	downloadLink.href = url;
	downloadLink.download = fileName + '.xlsx';
	downloadLink.click();
	URL.revokeObjectURL(url);
}
