import React, { useTransition } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

export default function InboundTable({
  data,
  onScanShipment,
}: InboundTableProps) {

  const {t} = useTranslation("common")

  const TableItem = ({ row }: { row: any }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (id: string) => {
      onScanShipment(id, true);
      setAnchorEl(null);
    };

    console.log(row);


    
    return (
      <TableRow
        key={row.tracking_id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {row.id || "N/A"} | {row.trackingNo}
        </TableCell>
        <TableCell>
          {row.merchantTrackingNo || "N/A"} | {row.merchantOrderId || "N/A"}
        </TableCell>
        <TableCell>
          {row.merchant?.name || "N/A"} | {"N/A"}
        </TableCell>
        <TableCell>{row?.customerDetails?.city || "N/A"} | {row?.neighborhood || "N/A"}</TableCell>
        <TableCell>{row?.products?.length}</TableCell>
        <TableCell className="text-center">
          <Chip
            label={
              row.status === "not_scanned"
                ? "NOT SCANNED"
                : row.status === "wrong_order"
                ? "WRONG ORDER"
                : "SCANNED"
            }
            color={
              row.status === "not_scanned"
                ? "default"
                : row.status === "wrong_order"
                ? "error"
                : "success"
            }
          />
          {/* We can change color with color attribute */}
        </TableCell>
        <TableCell>
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                key={"remove"}
                onClick={() => handleClose(row.trackingNo)}
              >
                {`${t("remove")}`}
              </MenuItem>
            </Menu>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <p className="font-bold">{`${t("order_number")}`}</p>
            </TableCell>
            <TableCell>
              <p className="font-bold">{`${t("merchant_number")}`}</p>
            </TableCell>
            <TableCell>
              <p className="font-bold">{`${t("merchant_name")}`}</p>
            </TableCell>
            <TableCell>
              <p className="font-bold">{`${t("city_neighborhood")}`}</p>
            </TableCell>
            <TableCell>
              <p className="font-bold">{`${t("products")}`}</p>
            </TableCell>
            <TableCell>
              <p className="font-bold">{`${t("scan_status")}`}</p>
            </TableCell>
            <TableCell>
              <p className="font-bold"></p>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableItem key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type InboundTableProps = {
  data: any[];
  onScanShipment: (id: string, isRemove: boolean) => void;
};
