import React, { ReactNode, useContext } from "react";
import cn from "classnames";
import "./Sidebar.scss";
import CloseIcon from "../../assets/icons/CloseIcon";
import routes from "../../constants/routes";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import Header from "../header/Header";
import Divider from "@mui/material/Divider";
import { ChevronRight } from "@mui/icons-material";
import Logo from "../../assets/icons/Logo";
import { useTranslation } from "react-i18next";
import SearchIcon from "assets/icons/SearchIcon";
import { LoadingContext } from "hooks/useLoading";
import { LinearProgress } from "@mui/material";

type SidebarProps = {
	children: React.ReactElement;
};

function Sidebar () {
	const location = useLocation();
	const { t } = useTranslation(["common", "drivers", "shipments"]);

	return (
		<main className='main'>
			<aside
				className={
					window.location.search.includes("closeSidebar=1") ||
						window.top !== window.self
						? "sidebar_hidden"
						: "sidebar"
				}
			>
				<Logo />
				<div className='sidebar__header'>
					<img
						src={require("../../assets/images/logo.png")}
						alt='Sidebar logo'
						className='sidebar-logo'
					/>
					<CloseIcon />
				</div>
				<div className='sidebar__search'>
					<input
						type='search'
						className='sidebar__search-input'
						placeholder={`${t("search")}`}
					/>
					<SearchIcon />
				</div>
				<SearchIcon />
				<NavBar />
			</aside>
			<div className='page_view'>
				<PageRender />
			</div>
		</main>
	);
}

const NavBar = () => (
	<nav className='sidebar__nav'>
		<ul className='sidebar__nav-list'>
			{routes.map((category, index) => {
				return (
					<li className='sidebar__nav-item' key={index}>
						<div className='sidebar__nav-menu'>
							<p className='sidebar__nav-header'>
								{category.name}
							</p>

							<ul className='sidebar__nav__sub-list'>
								{category.accordions.map((accordion, index) => {
									return (
										<li
											className='sidebar__nav__sub-item'
											key={index}
										>
											<NavLink
												className={({ isActive }) =>
													isActive
														? `sidebar__nav__sub-item__button active`
														: "sidebar__nav__sub-item__button"
												}
												to={
													accordion.path ||
													accordion.routes[0].path
												}
											>
												<div className='sidebar__nav__sub-item__icon'>
													<accordion.icon fontSize='small' />
												</div>
												<p>{accordion.name}</p>
												<ChevronRight
													className='chevron-icon'
													sx={{
														marginLeft: "auto",
													}}
												/>
											</NavLink>
											<ul className='sidebar__nav__sub-s_list'>
												{accordion.routes.length > 1 &&
													accordion.routes.map(
														(route, index) => {
															if (!route.hidden) {
																return (
																	<li className='sidebar__nav__sub-s_item' key={index}>
																		{!route.hidden && (
																			<NavLink
																				to={
																					route.path
																				}
																			>
																				{
																					route.name
																				}
																			</NavLink>
																		)}
																	</li>
																);
															}
														}
													)}
											</ul>
										</li>
									);
								})}
							</ul>
						</div>
					</li>
				);
			})}
		</ul>
	</nav>
);

const PageRender = () => {
	const { loading } = useContext(LoadingContext);

	return (
		<Routes>
			{getRoutes(routes).map((route: RouteType, index: number) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							<React.Fragment>
								{route.options.headerShown && (
									<Header
										title={route.name}
										canBack={route?.options?.canBack}
										user={{}}
									/>
								)}
								<LinearProgress className='progress_bar' />
								<route.component />
							</React.Fragment>
						}
					></Route>
				);
			})}
		</Routes>
	);
};

function getRoutes (categories: CategoryType[]): RouteType[] {
	let routes: RouteType[] = [];
	for (let category of categories) {
		for (let accordion of category.accordions) {
			routes = routes.concat(accordion.routes);
		}
	}
	return routes;
}

export default Sidebar;
