import { useLazyQuery, useMutation } from '@apollo/client';
import { UPLOAD_DRIVER_IMAGE } from 'api/mutations/Mutations';
import { GET_DRIVER_DOCS } from 'api/queries/Queries';
import { stagingOptions } from 'constants/apollo';
import { logger } from 'helper/logger';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Dropzone from '../../../components/common/Dropzone'

export default function DriverLegalDocsTab ({ driver }) {
  const [attachments, setAttachments] = useState([])

  const [
    uploadImageCourier,
    {
      data: uploadImageCourierRes,
      loading: uploadImageCourierLoading,
      error: uploadImageCourierError,
    },
  ] = useMutation(UPLOAD_DRIVER_IMAGE, stagingOptions);

  const [getDriverDocs, { data, error }] = useLazyQuery(GET_DRIVER_DOCS, stagingOptions);

  const handleUpload = (data, type) => {

    uploadImageCourier({
      variables: {
        input: {
          courierId: parseInt(driver?.id),
          data,
          type,
        }
      }
    })

  }

  function fetchData (id = driver?.id) {
    getDriverDocs({
      variables: {
        id,
      },
    })
  }

  const reload = () => {
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      logger("Driver DATA HERE", data.fetchCourier);
      const attachments = data?.fetchAllCourierDocuments;
      if (attachments) {
        setAttachments(attachments)
      }
    }
  }, [data])

  useEffect(() => {
    if (uploadImageCourierRes?.uploadImageCourier?.status) {
      toast.success("Succesfully uploaded!")
      reload()
    }
  }, [uploadImageCourierRes])

  useEffect(() => {
    if (uploadImageCourierError?.message) {
      toast.error("An error occured! Try again")
    }
  }, [uploadImageCourierError])

  useEffect(() => {
    if (uploadImageCourierLoading) {
      toast.info("Uploading...", {
        hideProgressBar: uploadImageCourierError || uploadImageCourierRes,
        progress: undefined,
      })
    }
  }, [uploadImageCourierLoading])

  return (
    <div className='bg-gray-50 h-[70vh] flex flex-col px-4'>
      <div className='flex'>
        <div className='w-1/3 p-2'>
          <div className='bg-white p-6 flex flex-col space-y-2 rounded-lg'>
            <h3 className='font-bold'>Profile Image <span className='text-orange-400 text-sm font-normal ml-2'>required</span></h3>
            <Dropzone oldImage={attachments.find(file => file.type == "profile")} label="Profile image" handleUpload={(file) => handleUpload(file, 'profile')} />
          </div>
        </div>
        <div className='w-2/3 p-2'>
          <div className='bg-white p-6 flex flex-col space-y-2 rounded-lg'>
            <h3 className='font-bold'>Vehicle</h3>
            <div className='flex space-x-2'>
              <Dropzone oldImage={attachments.find(file => file.type == "car_front_image")} label="Front side" handleUpload={(file) => handleUpload(file, 'car_front_image')} />
              <Dropzone oldImage={attachments.find(file => file.type == "car_back_image")} label="Back side" handleUpload={(file) => handleUpload(file, 'car_back_image')} />
              <Dropzone oldImage={attachments.find(file => file.type == "car_side_image")} label="Right side" handleUpload={(file) => handleUpload(file, 'car_side_image')} />
              <Dropzone oldImage={attachments.find(file => file.type == "car_side_b_image")} label="Left side" handleUpload={(file) => handleUpload(file, 'car_side_b_image')} />
            </div>

          </div>
        </div>
      </div>
      <div className='flex'>
        <div className='w-1/3 p-2'>
          <div className='bg-white p-6 flex flex-col space-y-2 rounded-lg'>
            <h3 className='font-bold'>Driver Licence</h3>
            <div className='flex space-x-2'>
              <Dropzone oldImage={attachments.find(file => file.type == "driver_license_image")} label="Front side" handleUpload={(file) => handleUpload(file, 'driver_license_image')} />
              <Dropzone oldImage={attachments.find(file => file.type == "driver_license_back_image")} label="Back side" handleUpload={(file) => handleUpload(file, 'driver_license_back_image')} />
            </div>
          </div>
        </div>
        <div className='w-1/3 p-2'>
          <div className='bg-white p-6 flex flex-col space-y-2 rounded-lg'>
            <h3 className='font-bold'>Identity Card - ID/IQAMA</h3>
            <div className='flex space-x-2'>
              <Dropzone oldImage={attachments.find(file => file.type == "id_image")} label="Front side" handleUpload={(file) => handleUpload(file, 'id_image')} />
              <Dropzone oldImage={attachments.find(file => file.type == "id_back_image")} label="Back side" handleUpload={(file) => handleUpload(file, 'id_back_image')} />
            </div>
          </div>
        </div>
        <div className='w-1/3 p-2'>
          <div className='bg-white p-6 flex flex-col space-y-2 rounded-lg'>
            <h3 className='font-bold'>Car Registration Card</h3>
            <div className='flex space-x-2'>
              <Dropzone oldImage={attachments.find(file => file.type == "car_registration_image")} label="Front side" handleUpload={(file) => handleUpload(file, 'car_registration_image')} />
              <Dropzone oldImage={attachments.find(file => file.type == "car_registration_back_image")} label="Back side" handleUpload={(file) => handleUpload(file, 'car_registration_back_image')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
