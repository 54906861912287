import useLazyQuery from "hooks/useLazyQuery";
import {
	useMutation,
	useLazyQuery as apolloUseLazyQuery,
} from "@apollo/client";

import React, { useEffect, useState } from "react";
import _ from "lodash";
import CustomButton from "../../../components/common/Button";
import { GridFilterListIcon } from "@mui/x-data-grid";
import MuiFilterButton from "../../../components/common/FilterButton/MuiFilterButton";
import { GET_ALL_CURRENT_INVENTORY } from "../../../api/queries/WMSqueries";
import { wmsOptions } from "../../../constants/apollo";
import { logger } from "../../../helper/logger";
import { objFlatten } from "../../../helper/objFlatten";
import { useDebounce } from "../../../hooks/useDebounce";
import useCurrentInventory from "./useCurrentInventory";
import NewSearch from "../../../components/common/Search/new-search";
import PaginatedTable from "../../../components/Table/paginatedTable";
import moment from "moment";
import MoveInventory from "./moveInventory";
import MoveInventoryNew from "./moveInventoryNew";
import { getSortationOrder } from "../../../helper/GetSortOrder";
import FiltersList from "../../../components/FiltersList";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import Modal from "../../../components/common/Modal";
import {
	WMS_FILTER_LOCATIONS,
	WMS_FILTER_MERCHANTS,
	WMS_FILTER_PRODUCTS,
} from "../../../api/queries/WMSFilterQueries";
import { useSearchParams } from "react-router-dom";

const cols = [
	{ name: "Product Name", value: "product.name", canBeSorted: true },
	{
		name: "Serials",
		value: "serialsLength",
		styles: "text-warningColor",
		type: "serial",
		serialsString: "serialsString"
	},
	{
		name: "Merchant name",
		value: "merchant.name",
		canBeSorted: true,
	},
	{
		name: "Location",
		value: "location.referenceName",
		secondVal: "location.locationCode",
		styles: "text-warningColor",
		canBeSorted: true,
	},
	{ name: "Location Type", value: "location.type", canBeSorted: true },
	{ name: "Updated At", value: "updatedAt", canBeSorted: true },
];

const filters = {
	productId: "all",
	locationId: "all",
	locationType: "all",
	merchantId: "all",
};

export default function CurrentInventory () {
	const localStorageFilters = JSON.parse(
		window?.localStorage?.getItem("currInvFilters") || "{}"
	);
	const [searchParams, setSearchParams] = useSearchParams(
		localStorageFilters || {}
	);

	const [filterOpen, setFilterOpen] = useState(false);
	const [searchText, setSearchText] = useState(
		localStorageFilters?.searchText ||
		Object.fromEntries([...searchParams]).searchText ||
		""
	);

	const debouncedSearchText = useDebounce(searchText, 500);
	const [productFilters, setProductFilters] = useState(
		{
			...filters,
			...Object.fromEntries([...searchParams]),
		}
		// || { ...filters, ...localStorageFilters }
	);

	const [page, setPage] = useState(0);
	const [itemsCount, setItemsCount] = useState(0);
	const [barcode, setBarcode] = React.useState();
	const [rows, setRows] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState(true);
	const [filterByBin, setFilterByBin] = React.useState("");
	const [moveInventoryNewModalOpen, setMoveInventoryNewModalOpen] =
		React.useState(false);

	const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
	const [newPurchaseOrder, setNewPurchaseOrder] = React.useState(false);

	const [merchants, setMerchants] = useState([]);
	const [products, setProducts] = useState([]);
	const [locations, setLocations] = useState([]);

	const [getProducts] = apolloUseLazyQuery(WMS_FILTER_PRODUCTS, wmsOptions);
	const [getLocations] = apolloUseLazyQuery(WMS_FILTER_LOCATIONS, wmsOptions);
	const [getMerchants] = apolloUseLazyQuery(WMS_FILTER_MERCHANTS, wmsOptions);

	const [getCurrentIventory] = useLazyQuery(GET_ALL_CURRENT_INVENTORY, {
		...wmsOptions,
	});
	const [currentInventory, setCurrentInventory] = useState([]);
	const [sortCol, setSortCol] = useState({
		label: "updatedAt",
		value: 1,
	});
	const lockedFields = searchParams?.get("locked")?.split("-") || [];
	const isMerchantDash = searchParams?.get("merchantDashboard") == "true";

	if (isMerchantDash) {
		lockedFields.push("merchantId");
	}
	const { getOrderObj, getSearchObj, getFilterObj } = useCurrentInventory();

	const handleQueryChange = (obj: any) => {
		// updare router query based on implemented filters
		let mergedObj: any = {
			...Object.fromEntries([...searchParams]),
			...obj,
		};
		let modified: any = {};

		Object.keys(mergedObj).map((key) => {
			if (mergedObj[key] != "all" && mergedObj[key] != "") {
				if (key in obj) {
					modified[key] = obj[key];
				} else {
					modified[key] = mergedObj[key];
				}
			}
		});

		setSearchParams(modified);
		localStorage.setItem("currInvFilters", JSON.stringify({ ...modified }));
	};
	useEffect(() => {
		handleSearch(searchText);
		handleQueryChange({ searchText: searchText });
	}, [debouncedSearchText]);

	const fetchCurrentInventory = (
		skip = 0,
		search = searchText,
		sortName = sortCol.label,
		sortOrder = getSortationOrder(sortCol.value),
		filters = productFilters
	): any => {
		let orderObj = getOrderObj(sortName, sortOrder);
		let searchObj = getSearchObj(search);
		let filterObj = getFilterObj(filters);

		getCurrentIventory({
			variables: {
				skip: skip,
				...filterObj,
				...searchObj,
				...orderObj,
			},
		}).then((res) => {
			const inventoryLogList = res.data.currentInventory.items;

			setItemsCount(res.data.currentInventory.totalCount);

			const inventoryLogListFlattened = inventoryLogList.map(
				(item: any) => {
					let time = "";
					if (item.updatedAt) {
						time = moment(item.updatedAt).format(
							"D-M-YYYY / hh:mm"
						);
					}
					let serialsLength = item?.serials?.length || 0;

					let serStr = ''
					let serList: any[] = []
					if (serialsLength > 0) {
						item?.serials?.map((el: any) =>
							serList.push(el)
						)
					}
					serStr = serList.join(', ')

					return objFlatten({
						...item,
						updatedAt: time,
						serialsLength: serialsLength,
						serialsString: serStr
					});
				}
			);

			setCurrentInventory(inventoryLogListFlattened);
			logger(inventoryLogList, inventoryLogListFlattened);
		});
	};

	const handleSort = (sortVal = "") => {
		if (sortCol.label === sortVal) {
			// sort Desc
			const sortOrder = getSortationOrder(sortCol.value + 1);
			sortCol.value += 1;

			fetchCurrentInventory(0, searchText, sortVal, sortOrder);
		} else {
			setSortCol((old: any) => ({
				...old,
				label: sortVal,
				value: 0,
			}));
			fetchCurrentInventory(0, searchText, sortVal, "ASC");
		}

		setPage(0);
	};
	function handleSearch (search: any) {
		fetchCurrentInventory(
			0,
			search,
			sortCol.label,
			getSortationOrder(sortCol.value)
		);
		setPage(0);
	}

	const fetch_merchants = ({ page = 1, searchQuery = "" } = {}) => {
		logger("fetch_merchants", page, searchQuery);

		const searchObj = getSearchObj(searchQuery);

		return getMerchants({
			variables: {
				where: {},
				skip: (page - 1) * 20,
				...searchObj,
			},
		}).then((res) => {
			setMerchants(res?.data?.merchants?.items);

			return {
				options: res?.data?.merchants?.items,
				hasMore: res?.data?.merchants?.totalCount >= page * 20,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		});
	};

	const fetch_merchant = ({ id = "0" } = {}) => {
		return getMerchants({
			variables: {
				where: { id: { eq: parseInt(id) } },
			},
		}).then((res) => {
			let data = res?.data?.merchants?.items[0];
			return data;
		});
	};

	const fetch_products = ({ page = 1, searchQuery = "" } = {}) => {
		logger("fetch_merchants", page, searchQuery);

		const searchObj = getSearchObj(searchQuery);

		return getProducts({
			variables: {
				where: {},
				skip: (page - 1) * 20,
				...searchObj,
			},
		}).then((res) => {
			setProducts(res?.data?.products?.items);

			return {
				options: res?.data?.products?.items,
				hasMore: res?.data?.products?.totalCount >= page * 20,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		});
	};

	const fetch_product = ({ id = "0" } = {}) => {
		return getProducts({
			variables: {
				where: { id: { eq: parseInt(id) } },
			},
		}).then((res) => {
			let data = res?.data?.products?.items[0];
			return data;
		});
	};

	const fetch_locations = ({ page = 1, searchQuery = "" } = {}) => {
		logger("fetch_merchants", page, searchQuery);

		const searchObj = getSearchObj(searchQuery);

		return getLocations({
			variables: {
				where: {},
				skip: (page - 1) * 20,
				...searchObj,
			},
		}).then((res) => {
			let locationList = res?.data?.locations?.items;
			let locationsModified: any = [];
			locationList.map((item: any) => {
				if (!item.label) {
					locationsModified.push({
						value: item.value,
						label: item.locationCode,
					});
				} else {
					locationsModified.push(item);
				}
				setLocations(locationsModified);
			});
			return {
				options: locationsModified,
				hasMore: res?.data?.locations?.totalCount >= page * 20,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		});
	};

	const fetch_location = ({ id = "0" } = {}) => {
		return getLocations({
			variables: {
				where: { id: { eq: parseInt(id) } },
			},
		}).then((res) => {
			let data = res?.data?.locations?.items[0];
			return data;
		});
	};

	function handleFilter () {
		handleQueryChange(productFilters);

		fetchCurrentInventory(
			0,
			searchText,
			sortCol.label,
			getSortationOrder(sortCol.value),
			productFilters
		);
	}
	function handleFilterReset () {
		const additional: any = {}
		// lockedFields?.map((field) => (newFilters[field] = productFilters[field]));
		if (isMerchantDash) {
			additional["merchantId"] = productFilters.merchantId
			additional["merchantDashboard"] = true
		}
		handleQueryChange({ ...filters, ...additional });
		setProductFilters({ ...filters, ...additional });
		fetchCurrentInventory(
			0,
			searchText,
			sortCol.label,
			getSortationOrder(sortCol.value),
			{ ...filters, ...additional }
		);
		setFilterOpen(false);
	}

	useEffect(() => {
		getMerchants().then((res) => setMerchants(res?.data?.merchants?.items));
		getProducts().then((res) => setProducts(res?.data?.products?.items));
		getLocations().then((res) => {
			let locationList = res?.data?.locations?.items;
			let locationsModified: any = [];
			locationList.map((item: any) => {
				if (!item.label) {
					locationsModified.push({
						value: item.value,
						label: item.locationCode,
					});
				} else {
					locationsModified.push(item);
				}
				setLocations(locationsModified);
			});
		});
	}, []);

	return (
		<>
			<div>
				<div className='main-wrapper flex justify-between space-x-3'>
					<div className='w-[450px] flex space-x-3'>
						<NewSearch
							hasValue={true}
							value={searchText}
							filterName='Search'
							infoText=''
							placeholder='Type here...'
							onChange={(e: any) => setSearchText(e.target.value)}
							handleClearSearch={() => setSearchText("")}
						/>
						<div
							onClick={() => setFilterOpen(true)}
							className='flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg'
						>
							<GridFilterListIcon color='warning' />
							<span className='text-orange-500 font-medium uppercase ml-2'>{`Filters By`}</span>
						</div>
					</div>
					<div className='flex space-x-3'>
						{/* <CustomButton
              variant="contained"
              color="warning"
              onClick={() => {
                setMoveInventoryModalOpen(true);
              }}
              style={{ display: "flex" }}
            >
              move inventory
            </CustomButton> */}
						{isMerchantDash ? (
							<></>
						) : (
							<CustomButton
								variant='contained'
								color='warning'
								onClick={() => {
									if (!moveInventoryNewModalOpen) {
										setMoveInventoryNewModalOpen(true);
									}
								}}
								style={{
									display: "flex",
									alignItems: "center",
									justifyConttent: "center",
								}}
							>
								move inventory
							</CustomButton>
						)}
					</div>
				</div>
				<PaginatedTable
					sortCol={sortCol}
					count={itemsCount}
					page={page}
					setPage={setPage}
					onPageChange={fetchCurrentInventory}
					cols={cols}
					data={currentInventory}
					selectedRows={selectedRows}
					loading={false}
					filterByBin={filterByBin}
					onSortClick={(sortVal) => {
						handleSort(sortVal);
					}}
				/>
			</div>

			<Modal
				onDismiss={() => {
					setFilterOpen(false);
				}}
				hidden
				autoOpen={filterOpen}
				footer={(handleClose: any) => (
					<ModalFooter
						handleClose={handleClose}
						handleSubmit={handleFilter}
						handleReset={handleFilterReset}
					/>
				)}
				onClose={() => { }}
				title={"Filter Products"}
			>
				<FiltersList
					selectedFilters={productFilters}
					lockedFields={lockedFields}
					filters={[
						{
							name: "Filter by Product",
							value: "productId",
							id: 1,
							isAsync: true,
							isSearchable: true,
							getSelectedVal: (id: any) => {
								if (id && id != "all") {
									let sel = fetch_product({ id: id });
									return sel;
								} else return { value: "all", label: "All" };
							},
							loadMore: (searchQuery: any, page: any) =>
								fetch_products({
									page: page,
									searchQuery: searchQuery,
								}),
						},

						{
							name: "Filter by Merchant",
							value: "merchantId",
							id: 2,
							isAsync: true,
							isSearchable: true,
							getSelectedVal: (id: any) => {
								if (id && id != "all") {
									let sel = fetch_merchant({ id: id });
									return sel;
								} else return { value: "all", label: "All" };
							},
							loadMore: (searchQuery: any, page: any) =>
								fetch_merchants({
									page: page,
									searchQuery: searchQuery,
								}),
						},

						{
							name: "Filter by Location",
							value: "locationId",
							id: 3,
							isAsync: true,
							isSearchable: true,
							getSelectedVal: (id: any) => {
								if (id && id != "all") {
									let sel = fetch_location({ id: id });
									return sel;
								} else return { value: "all", label: "All" };
							},
							loadMore: (searchQuery: any, page: any) =>
								fetch_locations({
									page: page,
									searchQuery: searchQuery,
								}),
						},
						{
							name: "Filter by Location Type",
							value: "locationType",
							id: 4,
							options: [
								{ value: "all", label: "All" },
								{ value: "WAREHOUSE", label: "Warehouse" },
								{ value: "SHELF", label: "Shelf" },
								{ value: "CART", label: "Cart" },
								{ value: "DRIVER", label: "Driver" },
								{ value: "ORDER", label: "Order" },
							],
						},
					]}
					handleChange={(filterVal: any, filterName: string) => {
						setProductFilters((old: any) => ({
							...old,
							[filterName]: filterVal,
						}));
					}}
				/>
			</Modal>
			<MoveInventoryNew
				setModalOpen={setMoveInventoryNewModalOpen}
				modalOpen={moveInventoryNewModalOpen}
			/>
		</>
	);
}
