import React from "react";

export const LoadingContext = React.createContext({
	loading: [],
	setLoading: () => {},
});

export const LoadingProvider = ({ children, props }) => {
	return (
		<LoadingContext.Provider value={props.value}>
			<LoadingContext.Consumer>{() => children}</LoadingContext.Consumer>
		</LoadingContext.Provider>
	);
};
