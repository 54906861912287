import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useRef, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import PurchaseOrderFilters from "./components/PurchaseOrderFilters";
import PurchaseOrderTable from "./components/PurchaseOrderTable";
import CustomSearch from "../../../components/common/Search";
import CustomSelect from "../../../components/common/Select";
import NormalSelect from "../../../components/common/Select/NormalSelect";
import CustomInput from "../../../components/common/Input";
import CustomButton from "../../../components/common/Button";
import Modal from "../../../components/common/Modal";
import { logger } from "../../../helper/logger";
import SelectAsyncPaginate from "../../../components/common/Select/AsyncPaginateSelect";
import {
	GET_ALL_MERCHANTS_WMS,
	GET_ALL_PRODUCTS,
	GET_ALL_WAREHOUSES,
} from "../../../api/queries/WMSqueries";
import { wmsOptions } from "../../../constants/apollo";
import NewSearch from "../../../components/common/Search/new-search";
import { ADD_UPDATE_PURCHASE_ORDER } from "../../../api/mutations/WMSMutations";
import moment from "moment";
import { toast } from "react-toastify";
import { Box, Tooltip } from "@mui/material";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import CustomCheckBox from "../../../components/common/Checkbox";
import isNumeric from "helper/isNumeric";
import range from "helper/range";

export default function CreatePuchaseOrderForm({
	handleCreatePurchaseOrder,
	setPurchaseOrders,
}) {
	// Pass the useFormik() hook initial form values and a submit function that will
	// be called when the form is submitted

	const [createOrUpdatePurchaseOrder] = useMutation(
		ADD_UPDATE_PURCHASE_ORDER,
		wmsOptions
	);

	const [selectedRows, setSelectedRows] = React.useState([]);
	const [selectedMerchant, setSelectedMerchant] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedCart, setSelectedCart] = useState(null);
	const [serialText, setSerialText] = useState("");
	const [serialList, setSerialList] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isGenerationLoading, setIsGenerationLoading] = useState(false)

	const productSelect = useRef();
	const priceInput = useRef();
	const serialCode = useRef();
	const barCodeSelect = useRef();


	const [serialStart, setSerialStart] = useState()
	const [quantity, setQuantity] = useState()
	const [isIncremented, setIsIncremented] = useState(false)
	const [generationError, setGenerationError] = useState("")


	const [getAllMerchants] = useLazyQuery(GET_ALL_MERCHANTS_WMS, wmsOptions);
	const [getAllProducts] = useLazyQuery(GET_ALL_PRODUCTS, wmsOptions);
	const [getAllCarts] = useLazyQuery(GET_ALL_WAREHOUSES, wmsOptions);

	const handleMerchantLoadMore = ({ searchQuery = "", page }) => {
		return getAllMerchants({
			variables: {
				skip: page * 20 - 20,
				query: searchQuery || ""
			},
		}).then((res) => {
			let newOptions = res.data.merchants.items;
			return {
				options: newOptions,
				hasMore: newOptions.length >= 1,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		});
	};
	const handleCartLoadMore = ({ searchQuery = "", page }) => {
		return getAllCarts({
			variables: {
				where: {
					type: {
						eq: "CART",
					},
				},
				skip: page * 20 - 20,
				query: searchQuery || ""
			},
		}).then((res) => {
			let newOptions = res.data.locations.items;
			return {
				options: newOptions,
				hasMore: newOptions.length >= 1,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		});
	};

	const handleProductLoadMore = ({ searchQuery = "", page }) => {
		return getAllProducts({
			variables: {
				skip: page * 20 - 20,
				where: {
					isActive: {
						eq: true,
					},
				},
				query: searchQuery || ""

			},
		}).then((res) => {
			let newOptions = res.data.products.items;
			return {
				options: newOptions,
				hasMore: newOptions.length >= 1,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		});
	};

	const handleAddSerial = (serialText) => {
		const currentSerial = serialText
		if (currentSerial.trim() != "") {
			setSerialList((old) => [...old, currentSerial]);
			setPurchaseOrders((old) => [
				[
					currentSerial,
					selectedProduct?.name,
					selectedProduct?.categoryType,
					selectedProduct?.weightInGramms,
					selectedProduct?.lengthInMM,
					moment().format(),
					"Scanned",
					// "Serial",
					// "Product Name",
					// "Product Type",
					// "Weight",
					// "Size",
					// "Created at",
					// "Status",
				],
				...old,

			]);

			// clean up
			setSerialText("");
		}
	};

	const handleAddSerialList = (list) => {

		let POList = []
		let time = moment().format()
		list?.map((item) =>
			POList.push([
				item,
				selectedProduct?.name,
				selectedProduct?.categoryType,
				selectedProduct?.weightInGramms,
				selectedProduct?.lengthInMM,
				time,
				"Scanned",
				// "Serial",
				// "Product Name",
				// "Product Type",
				// "Weight",
				// "Size",
				// "Created at",
				// "Status",
			])
		)
		setSerialList((old) => [...list, ...old]);
		setPurchaseOrders((old) => [
			...POList,
			...old,

		]);

	};

	const formik = useFormik({
		initialValues: {
			// id: 0,
			status: "IN_CART",

			itemPriceInSAR: "",
			productId: "",
			merchantId: "",
			serials: [],
			locationId: "",
		},
		validationSchema: Yup.object({
			itemPriceInSAR: Yup.number().required("Required"),
			productId: Yup.number().required("Required"),
			merchantId: Yup.number().required("Required"),
			locationId: Yup.number().required("Required"),
			serials: Yup.array().of(Yup.string()).required("Required"),
		}),
		onSubmit: (values) => {
			createOrUpdatePurchaseOrder({
				variables: {
					input: [
						{
							...values,
							itemPriceInSAR: Number(values.itemPriceInSAR),
							productId: Number(values.productId),
							merchantId: Number(values.merchantId),
							locationId: Number(values.locationId),
						},
					],
				},
			}).then((res) => {
				handleCreatePurchaseOrder();
				if (res.data?.createOrUpdatePurchaseOrders[0]?.id)
					toast.success(
						`Purchase order ${res.data?.createOrUpdatePurchaseOrders[0]?.id} has been successfully created`
					);
			});
		},
	});

	const handleGenerateAdvancedSerials = () => {
		try {
			setGenerationError("")
			setIsGenerationLoading(true)
			if (!quantity || !serialStart || !selectedMerchant || !selectedCart || !selectedProduct || !formik?.values?.itemPriceInSAR) {
				throw Error("Error occured. Make sure no fields are left empty")
			}

			let start = +serialStart
			const diff = +quantity


			if (isIncremented) {
				if (serialStart.toString()?.length > 15 || !isNumeric(serialStart)) {
					let bigIntEnd = serialStart.toString().substr(serialStart.length - 5)
					let bigIntStart = serialStart.toString().substr(0, serialStart.length - 5)
					let end = +bigIntEnd + diff
					let start = +bigIntEnd
					let strLen = bigIntEnd.length

					let list = range(end - 1, start - 1, -1)
					let newList = []
					list?.map(item => {
						let strNum = item.toString()
						if (strNum.length < strLen) {
							for (let i = 0; i < strLen - strNum.length; i++) {
								strNum = "0" + strNum
							}
						}
						item = bigIntStart + strNum
						newList.push(item)
						return item
					})

					logger("new", newList)
					if (newList.length == 0) {
						throw Error('Cannot generate these serials')
					}
					formik.setFieldValue("serials", [
						...newList,
						...serialList,
					]);
					handleAddSerialList(newList);
				}
				else {
					let end = start + diff

					let list = range(end - 1, start - 1, -1)


					formik.setFieldValue("serials", [
						...list,
						...serialList,
					]);
					handleAddSerialList(list);
				}


			} else {

				let list = Array(diff).fill(serialStart)

				formik.setFieldValue("serials", [
					...list,
					...serialList,
				]);
				handleAddSerialList(list);
			}
			setIsGenerationLoading(false)
			setSerialStart("")
			setQuantity("")
			setIsModalOpen(false)
			toast.success("Successfully generated the serials")
		}

		catch (e) {
			console.log("ERRROR", e.message)
			// setIsModalOpen(false)
			setIsIncremented(false)
			setIsGenerationLoading(false)
			setGenerationError(e.message)
		}
	}

	const openAdvancedMode = () => {
		if (formik.values.itemPriceInSAR && formik.values.locationId && formik.values.productId && formik.values.merchantId)
			setIsModalOpen(true)
	}
	return (
		<>
			<form
				onSubmit={formik.handleSubmit}
				className='main-wrapper flex flex-col justify-between space-y-6 mb-6'
			>
				<div className='w-full flex space-x-4 justify-between'>
					<div className='w-1/4'>
						<Tooltip title='Select merchant'>
							<Box>
								<SelectAsyncPaginate
									isSearchable
									id='merchantId'
									value={selectedMerchant}
									onChange={(option) => {
										setSelectedMerchant(option);
										formik.setFieldValue(
											"merchantId",
											option.id
										);
										productSelect?.current?.focus();
									}}
									loadMore={(searchQuery, page) =>
										handleMerchantLoadMore({ page: page, searchQuery: searchQuery })}
									filterName={"Select Merchant"}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
								{formik.touched.merchantId &&
									formik.errors.merchantId ? (
									<div className='error'>
										{formik.errors.merchantId}
									</div>
								) : null}
							</Box>
						</Tooltip>
					</div>

					<div className='w-1/4'>
						<Tooltip title='Select product'>
							<Box>
								<SelectAsyncPaginate
									isSearchable
									id='productId'
									ref={productSelect}
									value={selectedProduct}
									onChange={(option) => {
										setSelectedProduct(option);
										formik.setFieldValue(
											"productId",
											option.id
										);
										barCodeSelect?.current?.focus();
									}}
									loadMore={(searchQuery, page) =>
										handleProductLoadMore({ page: page, searchQuery: searchQuery })}
									filterName={"Select Product"}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
								{formik.touched.productId &&
									formik.errors.productId ? (
									<div className='error'>
										{formik.errors.productId}
									</div>
								) : null}
							</Box>
						</Tooltip>
					</div>
					<div className='w-1/4'>
						<Tooltip title='Select cart barcode'>
							<Box>
								<SelectAsyncPaginate
									isSearchable
									ref={barCodeSelect}
									value={selectedCart}
									onChange={(option) => {
										setSelectedCart(option);
										formik.setFieldValue(
											"locationId",
											option.id
										);
										priceInput?.current?.focus();
									}}
									loadMore={(searchQuery, page) =>
										handleCartLoadMore({ page: page, searchQuery: searchQuery })}
									filterName={"Cart Barcode"}
									getOptionLabel={(option) => option.locationCode}
									getOptionValue={(option) => option.id}
								/>
								{formik.touched.locationId &&
									formik.errors.locationId ? (
									<div className='error'>
										{formik.errors.locationId}
									</div>
								) : null}
							</Box>
						</Tooltip>
					</div>

					<div className='w-1/4'>
						<Tooltip title='Enter price'>
							<Box>
								<CustomInput
									ref={priceInput}
									id='itemPriceInSAR'
									name='itemPriceInSAR'
									type='text'
									inputName='Price'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.itemPriceInSAR}
									style={{ height: "38px" }}
								/>
								{formik.touched.itemPriceInSAR &&
									formik.errors.itemPriceInSAR ? (
									<div className='error'>
										{formik.errors.itemPriceInSAR}
									</div>
								) : null}
							</Box>
						</Tooltip>
					</div>
				</div>

				<div className='w-full flex space-x-4 justify-between'>

					<div className='w-1/2'>
						<Tooltip title='Scan barcode'>
							<Box>
								<NewSearch
									ref={serialCode}
									hasValue={true}
									value={serialText}
									filterName='Serial Code'
									placeholder='Scan each item'
									onChange={(e) => setSerialText(e.target.value)}
									handleClearSearch={() => setSerialText("")}
									infoText='Press Enter to Submit'
									onKeyPress={(ev) => {
										console.log(`Pressed keyCode ${ev.key}`);
										if (ev.key === "Enter") {
											// Do code here
											ev.preventDefault();
											formik.setFieldValue("serials", [
												...serialList,
												serialText,
											]);
											handleAddSerial(serialText);
										}
									}}
									style={{ height: "38px" }}
								/>
								{formik.errors.serials ? (
									<div className='error'>
										{formik.errors.serials}
									</div>
								) : null}
							</Box>
						</Tooltip>
					</div>
					<div className='w-1/2 flex space-x-4'>

						<div className='w-1/2 text-center flex items-center opacity-75'>
							<Tooltip title='Available after all upper fields are filled'>
								<span
									className="hover:underline cursor-pointer"
									onClick={() => openAdvancedMode()}
									style={{ width: "100%", height: "38px" }}
								>
									Add advanced
								</span>
							</Tooltip>
						</div><div className='w-1/2'>
							<CustomButton
								type='submit'
								variant='contained'
								color='success'
								onClick={() => { }}
								style={{ width: "100%", height: "38px" }}
							>
								COMPLETE SCANNING
							</CustomButton>
						</div>
					</div>
				</div>
			</form>

			<Modal
				onDismiss={() => {
					setIsModalOpen(false);
				}}
				hidden
				autoOpen={isModalOpen}
				onClose={() => { }}
				title={"Add Serials"}
			>


				<div className="w-full flex space-x-4">

					<div className='w-1/3'>

						<Tooltip title='Serial Start'>
							<Box>
								<NewSearch
									hasIcon={false}
									hasValue={true}
									value={serialStart}
									filterName='Serial Start'
									placeholder='Serial start'
									onChange={(e) => setSerialStart(e.target.value)}
									handleClearSearch={() => setSerialStart("")}
									style={{ height: "38px" }}
								/>
								{/* {formik.errors.serials ? (
									<div className='error'>
										{formik.errors.serials}
									</div>
								) : null} */}
							</Box>
						</Tooltip>
					</div>
					<div className='w-1/3 flex space-x-4'>

						<div className='w-1/2'>
							<Tooltip title='Quantity of products'>
								<Box>
									<NewSearch
										hasIcon={false}
										// ref={serialCode}
										hasValue={true}
										value={quantity}
										filterName='Quantity'
										placeholder=''
										onChange={(e) => setQuantity(e.target.value)}
										handleClearSearch={() => setQuantity("")}
										style={{ height: "38px" }}
									/>
									{/* {formik.errors.serials ? (
										<div className='error'>
											{formik.errors.serials}
										</div>
									) : null} */}
								</Box>
							</Tooltip>
						</div>
						<div className='w-1/2'>
							{/* <Tooltip title='Increment Serials'> */}

							<Box>
								<CustomCheckBox label="Increment" checked={isIncremented} setChecked={setIsIncremented} />
							</Box>
							{/* </Tooltip> */}
						</div>

					</div>

					<div className='w-1/3 pl-4'>
						<CustomButton
							type='button'
							variant='contained'
							color='warning'
							onClick={() => {
								setIsGenerationLoading(true)
								handleGenerateAdvancedSerials()
							}}
							style={{ width: "100%", height: "38px" }}
						>
							{isGenerationLoading ? "Loading..." : "Generate"}
						</CustomButton>
						{/* <LoadingButton
							onClick={() => {
								setIsGenerationLoading(true)
								handleGenerateAdvancedSerials()
							}}
							style={{ width: "100%", height: "38px" }}
							loading={isGenerationLoading}
							// loadingIndicator="Generating…" 
							variant="contained">
							Generate
						</LoadingButton> */}
					</div>
				</div>
				<span className="text-red-500">{generationError}</span>
			</Modal>
		</>
	);
}
