import "./SortationFilters.scss";

import React from "react";
import CustomModal from "../../../components/common/Modal";
import CustomSearch from "../../../components/common/Search";
import SortationSelect from "./SortationSelect";
import {
	Alert,
	AlertTitle,
	Autocomplete,
	Box,
	Button,
	FormControl,
	Input,
	InputLabel,
	LinearProgress,
	MenuItem,
	Popper,
	Select,
	Table,
	TextField,
} from "@mui/material";
import CustomButton from "../../../components/common/Button";
import { broadcastTypes, options } from "../../../constants/sortation";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
	AddOrdersToBinPayload,
	AddOrdersToWarehouse,
	AssignOrdersToCourierPayload,
	AssignOrdersToPartnerPayload,
	CreateShipmentPayload,
	RemoveOrderFromWarehousePayload,
	TransitToWarehousePayload,
} from "../../../api/mutations/ShipmentMutation";
import {
	ASSIGN_ORDERS_TO_PARTNER_PROGRESS,
	FETCH_DRIVER,
	FETCH_DRIVERS,
	FETCH_PARTNERS,
	FETCH_WAREHOUSES,
} from "../../../api/queries/Queries";
import { stagingOptions } from "../../../constants/apollo";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function SortationFilters({
	onScanShipment,
	selectedRows,
	bins,
	reload,
	setSearchStr,
}: SortationFiltersProps) {
	const [modalOpen, setModalOpen] = React.useState(false);
	const [sectionType, setSectionType] = React.useState("");
	const [addNewBin, setAddNewBin] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const [binName, setBinName] = React.useState("");
	const [newBinName, setNewBinName] = React.useState("");
	const [warehouseName, setWarehouseName] = React.useState("");
	const [driverName, setDriverName] = React.useState("");
	const [partnerName, setPartnerName] = React.useState("");
	const [trackingNos, setTrackingNos] = React.useState<any>([]);
	const [process, setProcess] = React.useState({
		pending: 0,
		processed: 0,
		total: 0,
	});
	const [broadcastName, setBroadcastName] = React.useState(broadcastTypes.na);

	const { t } = useTranslation("common");

	const [setBinMutation] = useMutation(AddOrdersToBinPayload, stagingOptions);

	const [readyForTransit] = useMutation(
		TransitToWarehousePayload,
		stagingOptions
	);
	const [assignDriver] = useMutation(
		AssignOrdersToCourierPayload,
		stagingOptions
	);

	const [addOrdersToWarehouse] = useMutation(
		AddOrdersToWarehouse,
		stagingOptions
	);

	const [assignPartner] = useMutation(
		AssignOrdersToPartnerPayload,
		stagingOptions
	);

	const [removeOrders] = useMutation(
		RemoveOrderFromWarehousePayload,
		stagingOptions
	);

	const [createShipment] = useMutation(CreateShipmentPayload, stagingOptions);

	const {
		data: warehouses,
		refetch: wereRefetch,
		loading: wereLoading,
	} = useQuery(FETCH_WAREHOUSES, stagingOptions);
	const {
		data: drivers,
		refetch: driverRefetch,
		loading: driversLoading,
	} = useQuery(FETCH_DRIVERS, stagingOptions);
	const { data: partners } = useQuery(FETCH_PARTNERS, stagingOptions);
	const [loadProgress] = useLazyQuery(
		ASSIGN_ORDERS_TO_PARTNER_PROGRESS,
		stagingOptions
	);

	const onChangeBarCode = (event: any) => {
		if (event?.keyCode === 13) {
			onScanShipment(event.target.value.trim());
			event.target.value = "";
		}
	};

	const modalFooter = () => {
		if (success) {
			return (
				<div className='w-[50%] flex'>
					<div className='px-2'></div>
					<CustomButton
						variant='contained'
						color='warning'
						disabled={loading}
						onClick={() => {
							setLoading(false);
							setSuccess(false);
							setModalOpen(false);
						}}
					>
						<div className='flex items-center justify-center'>
							{`${t("thanks")!}`}
						</div>
					</CustomButton>
				</div>
			);
		}

		return (
			<div className='w-[100%] flex'>
				<CustomButton
					variant='outlined'
					color='warning'
					disabled={loading}
					onClick={() => setModalOpen(false)}
				>
					<div className='flex items-center justify-center'>
						<p>{`${t("cancel")}`}</p>
					</div>
				</CustomButton>
				<div className='px-2'></div>
				<CustomButton
					variant='contained'
					color='warning'
					disabled={loading}
					onClick={async () => {
						if (sectionType === "addOrderToWarehouse") {
							try {
								if (!trackingNos.length)
									throw new Error("Empty data");

								setLoading(true);
								let data = await addOrdersToWarehouse({
									variables: {
										input: {
											trackingNos: trackingNos,
										},
									},
								});
								toast.info(
									`${data.data.addOrdersToWarehouseHard.updatedOrders} orders are updated.`
								);
								setSuccess(true);
							} catch (error) {
							} finally {
								setLoading(false);
								setTrackingNos([]);
								reload(Math.random());
							}
						} else if (
							sectionType === options.set_bin &&
							!addNewBin
						) {
							try {
								setLoading(true);
								let data = await setBinMutation({
									variables: {
										input: {
											binName: binName,
											trackingNumbers: selectedRows,
										},
									},
								});
								setSuccess(true);
							} catch (error) {
								toast(error + "", {
									type: "error",
								});
							} finally {
								setLoading(false);
								reload(Math.random());
							}
						} else if (sectionType === options.set_bin) {
							try {
								if (!newBinName.length) return 0;
								setLoading(true);
								let data = await setBinMutation({
									variables: {
										input: {
											binName: newBinName,
											trackingNumbers: selectedRows,
										},
									},
								});
								setSuccess(true);
							} catch (error) {
								toast(error + "", {
									type: "error",
								});
							} finally {
								setLoading(false);
								reload(Math.random());
							}
						} else if (sectionType === options.transit_middle) {
							try {
								console.log(
									"1",
									driverName,
									"2",
									warehouseName
								);

								setLoading(true);
								let data = await readyForTransit({
									variables: {
										input: {
											warehouseId: Number(warehouseName),
											trackingNos: selectedRows,
											courierId: Number(driverName),
										},
									},
								});
								console.log(data);
								setSuccess(true);
							} catch (error) {
								toast(error + "", {
									type: "error",
								});
							} finally {
								setLoading(false);
								reload(Math.random());
							}
						} else if (sectionType === options.assign_driver) {
							try {
								console.log(Number(driverName));
								setLoading(true);
								let data = await assignDriver({
									variables: {
										input: {
											trackingNos: selectedRows,
											courierId: Number(driverName),
										},
									},
								});
								console.log(data);
								setSuccess(true);
							} catch (error) {
								toast(error + "", {
									type: "error",
								});
							} finally {
								setLoading(false);
								reload(Math.random());
							}
						} else if (sectionType === options.partner) {
							try {
								setLoading(true);
								let data = await assignPartner({
									variables: {
										input: {
											trackingNos: selectedRows,
											partnerId: partnerName,
										},
									},
								});
								if (
									data?.data?.assignOrdersToPartner?.processId
								) {
									setProcess({
										pending: selectedRows.length,
										processed: 0,
										total: selectedRows.length,
									});
									let interval: any;
									interval = setInterval(async () => {
										let prog = await loadProgress({
											variables: {
												processId: Number(
													data?.data
														?.assignOrdersToPartner
														?.processId
												),
											},
										});
										if (
											prog?.data
												?.assignOrdersToPartnerProgress
										) {
											setProcess(
												prog.data
													.assignOrdersToPartnerProgress
											);
											if (
												prog.data
													.assignOrdersToPartnerProgress
													.processed ==
												prog.data
													.assignOrdersToPartnerProgress
													.total
											) {
												clearInterval(interval);
												setLoading(false);
											}
										}
									}, 1500);
								}
								setSuccess(true);
							} catch (error) {
								toast(error + "", {
									type: "error",
								});
								alert("test");
								setLoading(false);
							} finally {
								setProcess({
									pending: 0,
									processed: 0,
									total: 0,
								});
								reload(Math.random());
							}
						} else if (sectionType === options.remove) {
							try {
								setLoading(true);
								let data = await removeOrders({
									variables: {
										input: {
											trackingNos: selectedRows,
										},
									},
								});

								if (
									data?.data?.removeOrdersFromWarehouse
										.updatedOrders
								) {
									setSuccess(true);
									setLoading(false);
								}
							} catch (error) {
								toast(error + "", {
									type: "error",
								});
								alert("test");
								setLoading(false);
							} finally {
								setProcess({
									pending: 0,
									processed: 0,
									total: 0,
								});
								reload(Math.random());
							}
						} else if (sectionType === options.broadcast) {
							try {
								setLoading(true);
								let data = await createShipment({
									variables: {
										input: {
											trackingNos: selectedRows,
											notificationMethod: broadcastName,
										},
									},
								});

								console.log(data);

								setSuccess(true);
								setLoading(false);
							} catch (error) {
								toast(error + "", {
									type: "error",
								});
								setLoading(false);
							} finally {
								setProcess({
									pending: 0,
									processed: 0,
									total: 0,
								});
								reload(Math.random());
							}
						} else {
							setLoading(true);
							setTimeout(() => {
								setLoading(false);
								setSuccess(true);
							}, 4000);
						}
					}}
				>
					<div className='flex items-center justify-center'>
						{sectionType === options.set_bin && !addNewBin ? (
							<p>{`${t("set_bin")}`}</p>
						) : sectionType === options.set_bin ? (
							<p>{`${t("create_new_bin_and_set")}`}</p>
						) : sectionType === options.transit ? (
							<p>{`${t("confirm")}`}</p>
						) : sectionType === options.partner ? (
							<p>{`${t("assign")}`}</p>
						) : (
							<p>{`${t("submit")}`}</p>
						)}
					</div>
				</CustomButton>
			</div>
		);
	};

	return (
		<>
			<div className='main-wrapper flex justify-between space-x-3'>
				<div className='w-[450px]'>
					<CustomSearch
						filterName='Barcode'
						infoText='Scan or Type Shipment barcode. For example, AN258213115'
						onChange={onChangeBarCode}
					/>
				</div>
				<div className='w-[450px]'>
					<CustomSearch
						filterName='Search'
						infoText='You can add multiple ids with comma'
						onChange={(event) => {
							if (event.keyCode === 13) {
								let words = event?.target?.value
									?.split(",")
									.map((i: any) => i.trim());
								setSearchStr(words);

								if (event.target.value.length == 0) {
									setSearchStr([]);
								}
							}
						}}
					/>
				</div>
				<SortationSelect
					disabled={!selectedRows.length}
					setSectionType={setSectionType}
					setModalOpen={setModalOpen}
				/>
			</div>

			{/* Modal section */}
			<CustomModal
				autoOpen={modalOpen}
				hidden
				title={
					sectionType == "addOrderToWarehouse"
						? "Add orders to warehouse"
						: `Processing ${selectedRows.length} orders`
				}
				onClose={undefined}
				footer={modalFooter}
				onDismiss={() => setModalOpen(false)}
			>
				<div className='w-full flex flex-col space-y-5 mb-12 mt-5'>
					{loading && process?.total > 0 ? (
						<>
							<div>
								<LinearProgress
									color='warning'
									variant='determinate'
									value={
										(100 * process.processed) /
										process.total
									}
								/>
								<p className='mt-2'>
									{process.processed} orders out of{" "}
									{process.total}
								</p>
							</div>
						</>
					) : loading ? (
						<LinearProgress color='warning' />
					) : success ? (
						<Alert variant='outlined' severity='success'>
							<AlertTitle>{`${t(
								"operation_successfully_finished"
							)}`}</AlertTitle>
							{`${t("operation_successfully_finished")}`}
							{`${t("you_are_awesome")}`}
						</Alert>
					) : (
						<>
							<h2>
								{sectionType != "addOrderToWarehouse" &&
									sectionType}
							</h2>
							{sectionType == "addOrderToWarehouse" ? (
								<>
									<FormControl fullWidth>
										<TextField
											id='action-id'
											variant='outlined'
											placeholder='Barcode'
											onKeyUp={(event: any) => {
												if (
													event.keyCode == 13 &&
													event.target?.value?.length
												) {
													setTrackingNos([
														...trackingNos,
														event.target.value,
													]);
													event.target.value = "";
												}
											}}
										></TextField>
									</FormControl>
									<div className='table_scan'>
										<div className='table_header'>
											<p>Barcode number</p>
											<p>Actions</p>
										</div>
										<ul>
											{trackingNos.map(
												(item: any, index: number) => {
													return (
														<li key={index}>
															<div>{item}</div>
															<div>
																<Button
																	variant='contained'
																	color='error'
																	onClick={(
																		event: any
																	) => {
																		setTrackingNos(
																			trackingNos.filter(
																				(
																					i: any
																				) =>
																					i !==
																					item
																			)
																		);
																	}}
																>
																	Delete
																</Button>
															</div>
														</li>
													);
												}
											)}

											{!trackingNos.length && (
												<li>
													<div>Empty list</div>
												</li>
											)}
										</ul>
									</div>
								</>
							) : (
								<FormControl fullWidth>
									<InputLabel id='action-id'>{`${t(
										"action"
									)}`}</InputLabel>
									<Select
										labelId='action-id'
										value={Object.keys(options).find(
											(i) => options[i] === sectionType
										)}
										label={`${t("action")}`}
										onChange={(event) => {
											setSectionType(
												options[event.target.value]
											);
										}}
									>
										{Object.keys(options).map(
											(item, index) => {
												return (
													<MenuItem
														key={index}
														value={item}
													>
														{options[item]}
													</MenuItem>
												);
											}
										)}
									</Select>
								</FormControl>
							)}
							{sectionType === options.set_bin && (
								<>
									<FormControl fullWidth>
										<InputLabel id='action-id'>{`${t(
											"select_bin"
										)}`}</InputLabel>
										<Select
											labelId='action-id'
											id='demo-simple-select'
											// value={}
											label='Select bin'
											onChange={(event) => {
												if (
													event.target.value ==
													"add_new_bin"
												) {
													setAddNewBin(true);
												} else {
													setAddNewBin(false);
												}
												setBinName(
													event.target.value + ""
												);
											}}
										>
											{Object.keys(bins).map(
												(item, index) => {
													if (item !== "null") {
														return (
															<MenuItem
																key={index}
																value={item}
															>
																{item}
															</MenuItem>
														);
													}
												}
											)}
											<MenuItem
												key={999}
												value={"add_new_bin"}
											>
												{`${t("add_new_bin")}...`}
											</MenuItem>
										</Select>
									</FormControl>
									{addNewBin && (
										<TextField
											label='New Bin'
											variant='outlined'
											onChange={(event) =>
												setNewBinName(
													event.target.value
												)
											}
										/>
									)}
								</>
							)}
							{sectionType === options.transit && (
								<>
									<FormControl fullWidth>
										<Autocomplete
											PopperComponent={({
												style,
												...props
											}) => (
												<Popper
													{...props}
													style={{
														...style,
														height: 0,
													}} // width is passed in 'style' prop
												/>
											)}
											// Set menu max height (optional)
											ListboxProps={{
												style: { maxHeight: "30vh" },
											}}
											id='combo-box-dem'
											options={
												warehouses?.fetchAllWarehouses ||
												[]
											}
											getOptionLabel={(option: any) =>
												`${option.code} ${option.id}`
											}
											onKeyUp={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onFocus={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onBlur={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onClose={(event: any) => {
												driverRefetch({
													query: "",
												});
											}}
											onInputChange={(
												event,
												item?: string
											) => {
												if (item) {
													let s = item?.split(" ");
													setWarehouseName(
														s[s.length - 1]
													);
												} else {
													setWarehouseName("");
												}
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label={`${t(
														"select_warehouse"
													)}`}
												/>
											)}
										/>
									</FormControl>
								</>
							)}
							{sectionType === options.transit_middle && (
								<>
									<FormControl fullWidth>
										<Autocomplete
											PopperComponent={({
												style,
												...props
											}) => (
												<Popper
													{...props}
													style={{
														...style,
														height: 0,
													}} // width is passed in 'style' prop
												/>
											)}
											// Set menu max height (optional)
											ListboxProps={{
												style: { maxHeight: "30vh" },
											}}
											id='combo-box-dem'
											options={
												warehouses?.fetchAllWarehouses ||
												[]
											}
											getOptionLabel={(option: any) =>
												`${option.code} ${option.id}`
											}
											onKeyUp={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onFocus={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onBlur={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onClose={(event: any) => {
												driverRefetch({
													query: "",
												});
											}}
											onInputChange={(
												event,
												item?: string
											) => {
												if (item) {
													let s = item?.split(" ");
													setWarehouseName(
														s[s.length - 1]
													);
												} else {
													setWarehouseName("");
												}
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label={`${t(
														"select_warehouse"
													)}`}
												/>
											)}
										/>
									</FormControl>
									<FormControl fullWidth>
										<Autocomplete
											PopperComponent={({
												style,
												...props
											}) => (
												<Popper
													{...props}
													style={{
														...style,
														height: 0,
													}} // width is passed in 'style' prop
												/>
											)}
											// Set menu max height (optional)
											ListboxProps={{
												style: { maxHeight: "30vh" },
											}}
											id='combo-box-dem'
											options={
												drivers?.fetchAllCouriers?.items || []
											}
											getOptionLabel={(option: any) =>
												`${option.firstName} ${option.lastName} ${option.id}`
											}
											onKeyUp={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onFocus={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onBlur={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onClose={(event: any) => {
												driverRefetch({
													query: "",
												});
											}}
											onInputChange={(
												event,
												item?: string
											) => {
												if (item) {
													let s = item?.split(" ");
													setDriverName(
														s[s.length - 1]
													);
												} else {
													setDriverName("");
												}
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label='Select driver'
												/>
											)}
										/>
									</FormControl>
								</>
							)}
							{sectionType === options.assign_driver && (
								<FormControl fullWidth>
									<Autocomplete
										id='combo-box-demo'
										options={
											drivers?.fetchAllCouriers?.items || []
										}
										getOptionLabel={(option: any) =>
											`${option.firstName} ${option.lastName} ${option.id}`
										}
										onKeyUp={(event: any) => {
											console.log(event.target.value);
											driverRefetch({
												query: event?.target?.value,
											});
										}}
										onFocus={(event: any) => {
											console.log(event.target.value);
											driverRefetch({
												query: event?.target?.value,
											});
										}}
										onBlur={(event: any) => {
											console.log(event.target.value);
											driverRefetch({
												query: event?.target?.value,
											});
										}}
										onClose={(event: any) => {
											console.log(event.target.value);
											driverRefetch({
												query: "",
											});
										}}
										onInputChange={(
											event,
											item?: string
										) => {
											if (item) {
												let s = item?.split(" ");
												setDriverName(s[s.length - 1]);
											} else {
												setDriverName("");
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label='Select driver'
											/>
										)}
									/>
								</FormControl>
							)}
							{sectionType === options.partner && (
								<>
									<FormControl fullWidth>
										<Autocomplete
											PopperComponent={({
												style,
												...props
											}) => (
												<Popper
													{...props}
													style={{
														...style,
														height: 0,
													}} // width is passed in 'style' prop
												/>
											)}
											// Set menu max height (optional)
											ListboxProps={{
												style: { maxHeight: "30vh" },
											}}
											id='combo-box-dem'
											options={
												warehouses?.fetchAllWarehouses ||
												[]
											}
											getOptionLabel={(option: any) =>
												`${option.code} ${option.id}`
											}
											onKeyUp={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onFocus={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onBlur={(event: any) => {
												driverRefetch({
													query: event?.target?.value,
												});
											}}
											onClose={(event: any) => {
												driverRefetch({
													query: "",
												});
											}}
											onInputChange={(
												event,
												item?: string
											) => {
												if (item) {
													let s = item?.split(" ");
													setWarehouseName(
														s[s.length - 1]
													);
												} else {
													setWarehouseName("");
												}
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label={`${t(
														"select_warehouse"
													)}`}
												/>
											)}
										/>
									</FormControl>
									<FormControl fullWidth>
										<InputLabel id='action-id'>
											Select partner
										</InputLabel>
										<Select
											labelId='action-id'
											id='demo-simple-select'
											value={partnerName}
											label='Select partner'
										>
											{drivers &&
												partners.fetchAllPartners.map(
													(item: any, index: any) => {
														return (
															<MenuItem
																key={index}
																value={item}
																onClick={() =>
																	setPartnerName(
																		item
																	)
																}
															>
																{item}
															</MenuItem>
														);
													}
												)}
										</Select>
									</FormControl>
								</>
							)}
							{sectionType === options.broadcast && (
								<FormControl fullWidth>
									<InputLabel id='action-id'>{`${t(
										"action"
									)}`}</InputLabel>
									<Select
										labelId='action-id'
										value={Object.keys(broadcastTypes).find(
											(i) =>
												broadcastTypes[i] ===
												broadcastName
										)}
										label={`${t("action")}`}
										onChange={(event) => {
											setBroadcastName(
												broadcastTypes[
													event.target.value
												]
											);
										}}
									>
										{Object.keys(broadcastTypes).map(
											(item, index) => {
												return (
													<MenuItem
														key={index}
														value={item}
													>
														{broadcastTypes[item]}
													</MenuItem>
												);
											}
										)}
									</Select>
								</FormControl>
							)}
						</>
					)}
				</div>
			</CustomModal>
		</>
	);
}

type SortationFiltersProps = {
	onScanShipment: (id?: string) => void;
	selectedRows: any[];
	bins: any;
	reload: (n: number) => void;
	setSearchStr: any;
};
