import PaginatedTable from "../../../../components/Table/paginatedTable";
import React from "react";
import Modal from "../../../../components/common/Modal";
import {
	Alert,
	Button,
	Checkbox,
	Chip,
	Divider,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TextField,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { Box } from "@mui/system";
import { SearchIcon } from "../../../../components/svg";
import useLazyQuery from "hooks/useLazyQuery";
import { useLazyQuery as useLazyQueryWithoutLoadingAnimation } from "@apollo/client";
import {
	GET_PICKERS,
	GET_RESTOCKED_JOBS,
	GET_SALE_ORDERS,
} from "api/queries/WMSqueries";
import { wmsOptions } from "constants/apollo";
import { objFlatten } from "helper/objFlatten";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { UPDATE_RESTOCK_JOBS } from "api/mutations/WMSMutations";
import { toast } from "react-toastify";
import { GET_LOCATIONS } from "api/queries/Queries";

const cols = [
	{
		name: "Sale Order Id",
		value: "id",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Shipment Id",
		value: "shipment.id",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Product name",
		value: "saleOrderItems.0.product.name",
		canBeSorted: true,
	},
	{ name: "Date", value: "createdAt", canBeSorted: true },
	{
		name: "Amount",
		value: "amount",
		canBeSorted: true,
	},
	{ name: "Status", value: "status", type: "status", canBeSorted: true },
];

export default function Cancellation() {
	const [items, setItems] = React.useState([]);
	const [itemsCount, setItemsCount] = React.useState(0);
	const [activeItem, setActiveItem] = React.useState(0);
	const [checked, setChecked] = React.useState([]);
	const [sortCol, setSortCol] = React.useState<any>([]);
	const [page, setPage] = React.useState(0);
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [filterByBin, setFilterByBin] = React.useState("");
	const [modalOpen, setModalOpen] = React.useState(false);
	const [restockModalOpen, setRestockModalOpen] = React.useState(false);
	const [fetchSaleOrders] = useLazyQuery(GET_SALE_ORDERS, {
		...wmsOptions,
	});
	const [reset, setReset] = React.useState(0);
	const [completed, setCompleted] = React.useState(false);
	const [saleOrderItemId, setSaleOrderItemId] = React.useState(0);

	React.useEffect(() => {
		fetchSaleOrders({
			variables: {
				where: {
					status: {
						eq: "CANCELLATION",
					},
				},
				skip: page * 10,
			},
		}).then((data) => {
			if (data.data?.saleOrders?.items?.length) {
				let flattenData = data.data.saleOrders?.items?.map(
					(item: any) => {
						let time = "";
						if (item.createdAt) {
							time = moment(item.createdAt).format(
								"D-M-YYYY / HH:mm"
							);
						}

						return objFlatten({
							...item,
							createdAt: time,
						});
					}
				);

				setItemsCount(data?.data?.saleOrders?.totalCount);

				setItems(flattenData);
			}
		});
	}, [reset, page]);

	const handleSort = (sortVal = "") => {
		if (sortCol.label === sortVal) {
			// sort Desc
			// const sortOrder = getSortationOrder(sortCol.value + 1);
			sortCol.value += 1;

			// fetchPurchaseOrder(0, searchText, sortVal, sortOrder);
		} else {
			setSortCol((old: any) => ({
				...old,
				label: sortVal,
				value: 0,
			}));
			// fetchPurchaseOrder(0, searchText, sortVal, "ASC");
		}

		setPage(0);
	};

	return (
		<>
			<PaginatedTable
				// @ts-ignore
				onSortClick={(sortVal: any) => handleSort(sortVal)}
				count={itemsCount}
				page={page}
				cols={cols}
				setPage={setPage}
				onPageChange={() => {}}
				hasCheckbox
				data={items}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				loading={false}
				filterByBin={filterByBin}
				canBeEdited
				editMenuItems={[
					{
						actionName: "Re-Stock",
						action: (data: any) => {
							console.log(data);
							setActiveItem(data.id);
							setSaleOrderItemId(data["saleOrderItems.0.id"]);
							setModalOpen(true);
						},
						isDisabled: false,
					},
				]}
			/>
			<Modal
				footer={() => (
					<ModalFooter
						close={() => setModalOpen(false)}
						activeItem={activeItem}
						checked={checked}
						setReset={setReset}
						saleOrderItemId={saleOrderItemId}
					/>
				)}
				onClose={() => {
					setModalOpen(false);
				}}
				hidden
				onDismiss={() => {
					setModalOpen(false);
				}}
				autoOpen={modalOpen}
				maxWidth='xs'
				title='Assign a cart'
			>
				<ModalContent
					activeItem={activeItem}
					checked={checked}
					setChecked={setChecked}
				/>
			</Modal>
			<Modal
				footer={() => (
					<ModalFooterRestock
						activeItem={activeItem}
						setReset={setReset}
						close={() => setRestockModalOpen(false)}
					/>
				)}
				onClose={() => {
					setRestockModalOpen(false);
				}}
				hidden
				onDismiss={() => {
					setRestockModalOpen(false);
				}}
				autoOpen={restockModalOpen}
				maxWidth='xs'
				title='Complete Restock'
			>
				<ModalContent
					activeItem={activeItem}
					checked={checked}
					setChecked={setChecked}
				/>
			</Modal>
		</>
	);
}

function ModalFooter({
	close,
	checked,
	activeItem,
	setReset,
	saleOrderItemId,
}: {
	close: any;
	checked: any;
	activeItem: any;
	setReset: any;
	saleOrderItemId: any;
}) {
	const [updateRestockJobs] = useMutation(UPDATE_RESTOCK_JOBS, {
		...wmsOptions,
	});

	return (
		<Box sx={{ width: "100%" }}>
			<Divider />

			<div
				style={{
					marginTop: 20,
					justifyContent: "flex-end",
					display: "flex",
				}}
			>
				<Button
					onClick={() => close()}
					variant='outlined'
					color='warning'
				>
					Cancel
				</Button>
				<Button
					sx={{ marginLeft: 2 }}
					variant='contained'
					color='warning'
					onClick={() => {
						if (checked.length === 0) return;

						updateRestockJobs({
							variables: {
								restockJob: [
									{
										cartId: checked[0].id - 0,
										id: activeItem - 0,
										status: "RE_STOCKING",
										saleOrderItemId: saleOrderItemId - 0,
									},
								],
							},
						})
							.then(() => {
								setReset(Math.random());
								close();
								toast.success("Order is on restocking");
							})
							.catch((e) => {
								toast.error(e + "");
							});
					}}
				>
					Assign
				</Button>
			</div>
		</Box>
	);
}

function ModalFooterRestock({
	close,
	activeItem,
	setReset,
}: {
	close: any;
	activeItem: any;
	setReset: any;
}) {
	const [updateRestockJobs] = useMutation(UPDATE_RESTOCK_JOBS, {
		...wmsOptions,
	});

	return (
		<Box sx={{ width: "100%" }}>
			<Divider />

			<div
				style={{
					marginTop: 20,
					justifyContent: "flex-end",
					display: "flex",
				}}
			>
				<Button
					onClick={() => close()}
					variant='outlined'
					color='warning'
				>
					Cancel
				</Button>
				<Button
					sx={{ marginLeft: 2 }}
					variant='contained'
					color='warning'
					onClick={() => {
						updateRestockJobs({
							variables: {
								restockJob: [
									{
										id: activeItem - 0,
										status: "RE_STOCK_COMPLETE",
									},
								],
							},
						}).then(() => {
							setReset(Math.random());
							close();
						});
					}}
				>
					Re-stock
				</Button>
			</div>
		</Box>
	);
}

function ModalContent({
	activeItem,
	checked,
	setChecked,
}: {
	activeItem: any;
	checked: any;
	setChecked: any;
}) {
	const [pickers, setPickers] = React.useState([]);
	const [fetchPickers] = useLazyQueryWithoutLoadingAnimation(GET_LOCATIONS, {
		...wmsOptions,
	});
	const [searchText, setSearchText] = React.useState("");
	const [status, setStatus] = React.useState<any>({ neq: "INACTIVE" });

	React.useEffect(() => {
		fetchPickers({
			variables: {
				where: {
					type: { eq: "CART" },
				},
			},
		}).then((data) => {
			if (data?.data?.locations?.items?.length) {
				setPickers(data.data.locations.items);
			}
		});
	}, [searchText, status]);

	const handleToggle = (value: number) => () => {
		// const currentIndex = checked.indexOf(value);

		// if (currentIndex === -1) {
		// 	newChecked.push(value);
		// } else {
		// 	newChecked.splice(currentIndex, 1);
		// }

		setChecked([value]);
	};

	return (
		<>
			<TextField
				fullWidth
				title='Search cart'
				placeholder='Scan or type...'
				variant='outlined'
				label='Search cart'
				size='small'
				sx={{ marginY: 1 }}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<SearchIcon />
						</InputAdornment>
					),
				}}
				onKeyUp={(event: any) => setSearchText(event.target.value)}
			/>
			<Box>
				<Chip
					clickable
					color={
						JSON.stringify(status) ==
						JSON.stringify({ neq: "INACTIVE" })
							? "primary"
							: "default"
					}
					label='All'
					onClick={(e) => setStatus({ neq: "INACTIVE" })}
				/>
				<Chip
					clickable
					color={
						JSON.stringify(status) ==
						JSON.stringify({ eq: "AVAILABLE" })
							? "primary"
							: "default"
					}
					onClick={(e) => setStatus({ eq: "AVAILABLE" })}
					label='Available'
					sx={{
						marginLeft: 1,
					}}
				/>
			</Box>
			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
				}}
			>
				{pickers.map((value: any, index: any) => {
					const labelId = `checkbox-list-label-${value}`;

					return (
						<>
							{index !== 0 && <Divider />}

							<ListItem
								sx={{
									width: "100%",
									bgcolor:
										checked.indexOf(value) !== -1
											? "#FF980014"
											: "background.paper",
								}}
								key={value?.id}
								disablePadding
							>
								<ListItemButton
									role={undefined}
									onClick={handleToggle(value)}
									dense
									sx={{
										width: "100%",
									}}
								>
									<ListItemIcon>
										<Checkbox
											edge='start'
											checked={
												checked.indexOf(value) !== -1
											}
											color='warning'
											tabIndex={-1}
											disableRipple
											inputProps={{
												"aria-labelledby": labelId,
											}}
										/>
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={
											value?.referenceName ||
											"" + " " + value?.locationCode ||
											""
										}
									/>
								</ListItemButton>
							</ListItem>
						</>
					);
				})}
			</List>
			{/* <Divider />
			<Alert severity='warning' sx={{ marginTop: 3 }}>
				You have more than <strong>30 items</strong>. You need{" "}
				<strong>1 more</strong> employee
			</Alert> */}
		</>
	);
}

function ModalContentRestock() {
	const [checked, setChecked] = React.useState([0]);

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	return (
		<>
			<TextField
				fullWidth
				title='Scan or search cart barcodes'
				placeholder='Scan or type...'
				variant='outlined'
				label='Scan or search cart barcodes'
				size='small'
				sx={{ marginY: 1 }}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
		</>
	);
}
