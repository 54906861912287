import PaginatedTable from '../../../../components/Table/paginatedTable';
import React, { useEffect, useState } from 'react'
import { LOAD_BILLINGS } from 'api/queries/FinanceQueries';
import { stagingOptions } from 'constants/apollo';
import { toast } from 'react-toastify';
import { logger } from 'helper/logger';
import { objFlatten } from 'helper/objFlatten';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';

const cols = [
    { name: "Invoice ID", value: "invoiceId", type: "link", link: "id", },
    {
        name: "Vendor name",
        value: "merchant.name",

    },
    {
        name: "# Orders",
        value: "numOfOrders",
        type: "number"
    },
    {
        name: "Grand Total",
        value: "totalCost",
        type: "number"
    },
    {
        name: "COD Fee",
        value: "totalCod",
        type: "number"
    },
    {
        name: "Delivery Fee",
        value: "totalDeliveryFee",
        type: "number"
    },
    {
        name: "Week #",
        value: "week",
        type: "number"
    },
    { name: "Issue/Due Date", value: "time" },
    {
        name: "Status",
        value: "paymentState",
        type: "status",
        styles: "uppercase"
    },
];

export default function BillingsTable ({
    value = "1",
    paymentState = "",
    searchText = "",
    fetchFilters
}) {
    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState([])
    const [itemsCount, setItemsCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [filterByBin, setFilterByBin] = useState("");
    const [moveInventoryNewModalOpen, setMoveInventoryNewModalOpen] =
        useState(false);
    const [moveInventoryModalOpen, setMoveInventoryModalOpen] =
        useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [newPurchaseOrder, setNewPurchaseOrder] = useState(false);
    const [sortCol, setSortCol] = useState({
        label: "updatedAt",
        value: 1,
    });
    const [fetchData, { data, error }] = useLazyQuery(LOAD_BILLINGS, { ...stagingOptions, fetchPolicy: "network-only" });
    const fetch = (
        skip = 0,
        search = searchText,
        filters = fetchFilters
    ) => {
        try {
            // let filterObj = getBillin;
            let paymentStateFilter = {}
            if (paymentState !== "") {
                paymentStateFilter["paymentState"] = paymentState
            }
            fetchData(
                {
                    variables: {
                        offset: skip,
                        query: search,
                        ...paymentStateFilter

                        //         showBanned: true,
                        //         // ...filterObj,
                    }
                }
            )
            setLoading(true)
        } catch (e) {
            toast.error("An error occured");
            setLoading(false)
        }
    };

    useEffect(() => {
        if (data) {
            setLoading(false)
            setItemsCount(data?.fetchAllBillings?.totalCount);

            const dataList = data?.fetchAllBillings?.items;
            const dataListFlattened = dataList?.map((item) => {
                let time = "";
                if (item.issueDate && item.dueDate) {
                    time = moment(item.issueDate).format("D-M-YYYY") + ' / ' + moment(item.dueDate).format("D-M-YYYY");
                }
                let totalCost = "SR " + item.totalCost
                let totalCod = "SR " + item.totalCod
                let totalDeliveryFee = "SR " + item.totalDeliveryFee


                return objFlatten({
                    time,
                    ...item,
                    totalCost,
                    totalCod,
                    totalDeliveryFee
                });
            });

            setTableData(dataListFlattened);
        }
    }, [data]);

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        fetch()
    }, [searchText])
    return (
        <div>
            <PaginatedTable
                canBeEdited
                editMenuItems={[
                    {
                        actionName: "Edit",
                        action: (data) => {
                            console.log("editing", data)
                        },
                        isDisabled: false,
                    },
                ]}
                hasCheckbox
                sortCol={sortCol}
                count={itemsCount}
                page={page}
                setPage={setPage}
                onPageChange={(skip) => fetch(skip)}
                cols={cols}
                data={tableData}
                selectedRows={selectedRows}
                filterByBin={""}
                onSortClick={(sortVal) => {
                    // handleSort(sortVal);
                    { }
                }}
            /></div>
    )
}
