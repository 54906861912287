import React from "react";
import cn from "classnames";
import CustomSearch from "../../../components/common/Search";
import { FilterButton } from "../../../components/common/FilterButton";
import CustomButton from "../../../components/common/Button";
import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/common/Modal";
import { Alert, AlertTitle, Button, LinearProgress } from "@mui/material";
import { useMutation } from "@apollo/client";
import useLazyQuery from "hooks/useLazyQuery";

// import { scanOrdersIntoWarehouseMutation } from "../../../api/mutations/InboundMutation";
import { SHIPMENT_WAREHOUSE_SCAN_PROGRESS } from "../../../api/queries/Queries";
import { stagingOptions } from "../../../constants/apollo";
import { scanOrdersIntoWarehouseMutation } from "../../../api/mutations/InboundMutation";
import { Link, useNavigate } from "react-router-dom";

export default function InboundFilters({
	onScanShipment,
	rows,
	shipmentId,
}: {
	onScanShipment: (id: string) => void;
	rows: any[];
	shipmentId: string;
}) {
	let navigate = useNavigate();

	const { t } = useTranslation("common");
	const [modalOpen, setModalOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [scanOrdersIntoWarehouse] = useMutation(
		scanOrdersIntoWarehouseMutation,
		stagingOptions
	);
	const [shipmentWarehouseScanProgress] = useLazyQuery(
		SHIPMENT_WAREHOUSE_SCAN_PROGRESS,
		stagingOptions
	);
	const [process, setProcess] = React.useState({
		pending: 0,
		processed: 0,
		total: 0,
	});

	const onChangeBarCode = (event: any) => {
		if (event?.keyCode === 13) {
			onScanShipment(event.target.value.trim());
			event.target.value = "";
		}
	};

	const getShipmentWarehouseProgress = async function (processId: number) {
		let result = await shipmentWarehouseScanProgress({
			variables: {
				processId: processId,
			},
		});

		return result;
	};

	const submitScanOrders = async (shipmentId: string, orders: any[]) => {
		setProcess({
			pending: 0,
			processed: 0,
			total: 0,
		});

		let result = await scanOrdersIntoWarehouse({
			variables: {
				input: {
					orderTrackingNos: orders.map((e) => e.trackingNo),
					shipmentTrackingNo: shipmentId,
				},
			},
		});

		setProcess({
			pending: rows.length,
			processed: 0,
			total: rows.length,
		});

		let interval: any;

		interval = setInterval(async () => {
			let data = await getShipmentWarehouseProgress(
				result.data.scanOrdersIntoWarehouse.processId
			);

			if (data) {
				setProcess(data?.data?.shipmentWarehouseScanProgress);
			}

			if (process.processed == process.total) {
				clearInterval(interval);
			}
		}, 2000);
	};

	const modalFooter = () => {
		return (
			<div className='w-[100%] flex'>
				{process.total === 0 &&
				process.total === process.processed &&
				rows.filter((e) => e.status !== "scanned").length ? (
					<>
						<CustomButton
							disabled={loading}
							variant='outlined'
							color='warning'
							onClick={() => setModalOpen(false)}
						>
							<div className='flex items-center justify-center'>
								<p className='uppercase'>{`${t(
									"no_cancel"
								)}`}</p>
							</div>
						</CustomButton>
						<div className='px-2'></div>
						<CustomButton
							disabled={loading}
							variant='contained'
							color='warning'
							onClick={() => {
								submitScanOrders(shipmentId, rows);
							}}
						>
							<div className='flex items-center justify-center'>
								<p className='uppercase'>{`${t(
									"yes_sure"
								)}`}</p>
							</div>
						</CustomButton>
					</>
				) : (
					<CustomButton
						disabled={loading}
						variant='contained'
						color='warning'
						onClick={() => {
							window.localStorage.removeItem("trackingNo");
							navigate("/assets/sortation");
						}}
					>
						<div className='flex items-center justify-center'>
							<p className='uppercase'>Go to sortation</p>
						</div>
					</CustomButton>
				)}
			</div>
		);
	};

	return (
		<div className='main-wrapper flex justify-between items-start space-x-3 bg-white'>
			<CustomSearch
				filterName='Barcode'
				onChange={onChangeBarCode}
				infoText='Scan or Type Shipment barcode. For example, AN258213115'
			/>
			<div className='flex space-x-3'>
				<FilterButton
					count={
						rows.filter((e) => e.status === "scanned").length + ""
					}
					filterName='scanned'
					key='2'
					color='success'
				/>
				<FilterButton
					count={
						rows.filter((e) => e.status === "not_scanned").length +
						""
					}
					filterName='not scanned'
					key='1'
					color='gray'
				/>
				<FilterButton
					count={
						rows.filter((e) => e.status === "wrong_order").length +
						""
					}
					filterName='wrong orders'
					key='3'
					color='error'
				/>
			</div>
			<div className='w-[288px] flex'>
				<CustomButton
					variant='contained'
					color={"success"}
					onClick={() => {
						if (rows.filter((e) => e.status !== "scanned").length) {
							setModalOpen(true);
						} else {
							submitScanOrders(shipmentId, rows);
						}
						setModalOpen(true);
					}}
				>
					{t("complate_scanning")}
				</CustomButton>
				<Button
					variant='contained'
					color='error'
					onClick={() => {
						localStorage.removeItem("trackingNo");
						window.location.reload();
					}}
					sx={{ marginLeft: 1 }}
				>
					X
				</Button>
			</div>

			{/* Modal section */}
			{modalOpen && (
				<CustomModal
					autoOpen={true}
					hidden
					title={"Complete scanning"}
					onClose={undefined}
					footer={modalFooter}
					onDismiss={() => setModalOpen(false)}
				>
					<div className='w-full flex flex-col space-y-5 mb-12 mt-5'>
						{process.total === 0 &&
						process.total === process.processed &&
						rows.filter((e) => e.status === "scanned").length !==
							rows.length ? (
							<Alert variant='outlined' severity='warning'>
								<AlertTitle>{`${t(
									"sure_to_complete"
								)}`}</AlertTitle>
								{`${t("finished_scanning")}`}
							</Alert>
						) : process.total !== process.processed &&
						  process.total > 0 ? (
							<>
								<div>
									<LinearProgress
										color='success'
										variant='determinate'
										value={
											(100 * process.processed) /
											process.total
										}
									/>
									<p className='mt-2'>
										{process.processed}{" "}
										{`${t("orders_out_of")}`}{" "}
										{process.total}
									</p>
								</div>
							</>
						) : process.total === 0 ? (
							<LinearProgress color='success' variant='query' />
						) : (
							<Alert variant='outlined' severity='success'>
								<AlertTitle>{`${t(
									"operation_successfully_finished"
								)}`}</AlertTitle>
								{`${t("you_are_awesome")}`}
							</Alert>
						)}
					</div>
				</CustomModal>
			)}
		</div>
	);
}
