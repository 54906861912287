import PaginatedTable from "../../../../components/Table/paginatedTable";
import React, { useEffect, useState } from "react";
import useLazyQuery from "../../../../hooks/useLazyQuery";
import Modal from "../../../../components/common/Modal";
import {
	Box,
	Button,
	Checkbox,
	Divider,
	InputAdornment,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TextField,
	ListItem,
	Chip,
	Grid,
	Alert,
	Typography,
} from "@mui/material";
import { SearchIcon } from "../../../../components/svg";
import { logger } from "helper/logger";
import LinearWithValueLabel from "../../../../components/common/ProgressLine";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
	GET_PICKERS,
	GET_SALE_ORDERS,
} from "../../../../api/queries/WMSqueries";
import { wmsOptions } from "../../../../constants/apollo";
import { useDebounce } from "hooks/useDebounce";
import { getSortationOrder } from "helper/GetSortOrder";
import useSaleOrders from "./useSaleOrders";
import moment from "moment";
import { objFlatten } from "helper/objFlatten";
import {
	useLazyQuery as useApolloLazyQuery,
	useMutation,
} from "@apollo/client";
import {
	ASSIGN_SALEORDERS_TO_PICKERS,
	CREATE_OR_UPDATE_SALEORDERS,
} from "api/mutations/WMSMutations";
import { toast } from "react-toastify";
import NewSearch from "../../../../components/common/Search/new-search";
import { isDate } from "lodash";

const cols = [
	{
		name: "Sale Order ID",
		value: "id",
		canBeSorted: true,
	},
	{
		name: "Shipment ID",
		value: "shipment.id",
		canBeSorted: true,
	},
	{
		name: "Products",
		value: "productCount",
		type: "serial",
		serialsString: "productList",
	},
	{
		name: "Merchant",
		value: "merchant.name",
		canBeSorted: true,
	},
	{ name: "Created At", value: "createdAt", canBeSorted: true },
	{ name: "Updated At", value: "updatedAt", canBeSorted: true },
	{ name: "Amount", value: "amount" },
	{
		name: "Status",
		value: "status",
		type: "status",
		styles: "uppercase",
		canBeSorted: true,
	},
];

function SaleOrders ({
	productFilters,
	isFilterApplied,
	setIsFilterApplied,
	searchText,
}: any) {
	const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
	const [assignRes, setAssignRes] = useState({});
	const [page, setPage] = useState(0);
	const [itemsCount, setItemsCount] = useState(0);
	const [sortCol, setSortCol] = useState({
		label: "createdAt",
		value: 1,
	});
	const [data, setData] = useState([]);
	const [getData] = useLazyQuery(GET_SALE_ORDERS, wmsOptions);
	const [assignSaleOrdersToPickers] = useMutation(
		ASSIGN_SALEORDERS_TO_PICKERS,
		wmsOptions
	);

	const params = useParams();

	const { getOrderObj, getSearchObj, getFilterObj } = useSaleOrders();

	const fetch_data = (
		skip = 0,
		search = searchText,
		sortName = sortCol.label,
		sortOrder = getSortationOrder(sortCol.value),
		filters = productFilters
	): any => {
		if (params.pages && params.pages !== "sale_orders") return 0;

		let orderObj = getOrderObj(sortName, sortOrder);
		let searchObj = getSearchObj(search);
		let filterObj = getFilterObj(filters);

		getData({
			variables: {
				skip: skip,
				...searchObj,
				...filterObj,
				...orderObj,
			},
		}).then((res: any) => {
			setItemsCount(res.data.saleOrders.totalCount);
			logger("DATA HERE", res.data);

			const dataList = res.data.saleOrders.items;
			const dataListFlattened = dataList.map((item: any) => {
				let time = "";
				if (item.updatedAt) {
					time = moment(item.updatedAt).format("D-M-YYYY / HH:mm");
				}
				let createTime = "";
				if (item.createdAt) {
					createTime = moment(item.createdAt).format(
						"D-M-YYYY / HH:mm"
					);
				}

				let amount = 0;
				if (item?.saleOrderItems) {
					item?.saleOrderItems.map(
						(item: any) => (amount += +item.quantity)
					);
				}

				let productList: any[] = [];
				let productCount = 0;
				if (item?.saleOrderItems) {
					productCount = item.saleOrderItems.length;
					item?.saleOrderItems?.map((el: any) =>
						productList.push(el?.product?.name)
					);
				}
				let list = productList.join(" ");

				return objFlatten({
					...item,
					updatedAt: time,
					createdAt: createTime,
					productCount: productCount,
					amount: amount,
					productList: list,
				});
			});

			setData(dataListFlattened);
		});
	};

	const handleSort = (sortVal = "") => {
		if (sortCol.label === sortVal) {
			// sort Desc
			const sortOrder = getSortationOrder(sortCol.value + 1);
			sortCol.value += 1;

			fetch_data(0, searchText, sortVal, sortOrder);
		} else {
			setSortCol((old: any) => ({
				...old,
				label: sortVal,
				value: 0,
			}));
			fetch_data(0, searchText, sortVal, "ASC");
		}

		setPage(0);
	};
	function handleSearch (search: any) {
		fetch_data(0, search, sortCol.label, getSortationOrder(sortCol.value));
		setPage(0);
	}

	useEffect(() => {
		if (isFilterApplied) {
			fetch_data(
				0,
				searchText,
				sortCol.label,
				getSortationOrder(sortCol.value),
				productFilters
			);
			setIsFilterApplied(false);
		} else {
			fetch_data();
		}
	}, [isFilterApplied, productFilters]);

	useEffect(() => {
		handleSearch(searchText);
	}, [searchText]);

	return (
		<>
			<div>
				<PaginatedTable
					sortCol={sortCol}
					count={itemsCount}
					page={page}
					setPage={setPage}
					onPageChange={fetch_data}
					cols={cols}
					data={data}
					filterByBin={() => { }}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
					loading={false}
					onSortClick={(sortVal) => {
						handleSort(sortVal);
					}}
				// canBeEdited
				// editMenuItems={[
				// 	{
				// 		actionName: "To Picking",
				// 		action: (el: any) => {
				// 			setAssignModalOpen(true);
				// 			setSelectedPickerIDs([]);
				// 			setSelectedOrderIDs([parseInt(el.id)]);
				// 		},
				// 		isDisabled: false,
				// 		type: "purchaseOrder",
				// 	},
				// ]}
				/>
			</div>

			{/* <Modal
				title='Assign Picker'
				footer={() => (
					<ModalFooter
						count={selectedPickerIDs?.length || 0}
						assign={() => {
							setFeedbackModalOpen(true);
							handleAssignPicker({ assignBySelectedRows: false });
							setAssignModalOpen(false);

							// setSelectedRows([])
						}}
						close={() => setAssignModalOpen(false)}
					/>
				)}
				onClose={() => {
					setAssignModalOpen(false);
				}}
				hidden
				onDismiss={() => {
					setAssignModalOpen(false);
				}}
				color='primary'
				autoOpen={assignModalOpen}
				maxWidth='xs'
			>
				<ModalContent
					checked={selectedPickerIDs}
					setChecked={setSelectedPickerIDs}
				/>
			</Modal>

			<Modal
				title={
					isModalActionLoading
						? `Assigning ${selectedOrderIDs?.length} picklists`
						: "Assigning finished"
				}
				footer={() => (
					<FeedbackModalFooter
						isHidden={isModalActionLoading}
						handleClick={() => {
							setAssignModalOpen(false)
							setFeedbackModalOpen(false);
							setActiveTab((prev: number) => prev + 1)
						}

						}
						close={() => {
							setIsModalActionLoading(true);
							setAssignModalOpen(false)
							setFeedbackModalOpen(false);
							fetch_data();
						}}
					/>
				)}
				onClose={() => {
					setIsModalActionLoading(true);
					setFeedbackModalOpen(false);
					fetch_data();
				}}
				hidden
				onDismiss={() => {
					setIsModalActionLoading(true);
					setFeedbackModalOpen(false);
					fetch_data();
				}}
				color='primary'
				autoOpen={feedbackModalOpen && selectedOrderIDs.length > 0}
				maxWidth='xs'
			>
				<FeedbackModal
					count={selectedOrderIDs.length}
					loading={isModalActionLoading}
					setLoading={setIsModalActionLoading}
					data={assignRes}
				/>
			</Modal> */}
		</>
	);
}

// function ModalContent ({ checked, setChecked }: any) {
// 	const [isActionComplete, setIsActionComplete] = useState(false);

// 	const [selected, setSelected] = useState("available");
// 	const [searchText, setSearchText] = useState("");

// 	const handleToggle = (value: number) => () => {
// 		const currentIndex = checked.indexOf(value);
// 		const newChecked = [...checked];

// 		if (currentIndex === -1) {
// 			newChecked.push(+value);
// 		} else {
// 			newChecked.splice(currentIndex, 1);
// 		}

// 		setChecked(newChecked);
// 	};

// 	const [refetchFunction, { data, error }] = useApolloLazyQuery(
// 		GET_PICKERS,
// 		wmsOptions
// 	);
// 	const [pickerList, setPickerList] = useState([]);
// 	const newSearchQuery = useDebounce(searchText, 500);

// 	const fetchPickers = (query = newSearchQuery, status = selected) => {
// 		setChecked([]);
// 		let filterObj: any = {
// 			where: { role: { eq: "PICKER" } },
// 		};

// 		if (status == "available") {
// 			filterObj = {
// 				where: { role: { eq: "PICKER" }, status: { eq: "AVAILABLE" } },
// 			};
// 		}
// 		let queryObj = { query: "" };
// 		if (newSearchQuery?.trim() != "") {
// 			queryObj = {
// 				query: newSearchQuery,
// 			};
// 		}
// 		refetchFunction({
// 			variables: {
// 				...filterObj,
// 				...queryObj,
// 			},
// 		});
// 	};
// 	useEffect(() => {
// 		if (data) setPickerList(data?.users?.items);
// 	}, [data]);

// 	useEffect(() => {
// 		fetchPickers();
// 	}, []);

// 	useEffect(() => {
// 		fetchPickers();
// 	}, [newSearchQuery, selected]);

// 	return (
// 		<>
// 			{/* <TextField
//         fullWidth
//         title="Search employee"
//         placeholder="Type here..."
//         variant="outlined"
//         label="Scan or search pickers"
//         size="small"
//         sx={{ marginY: 1 }}
//         InputProps={{
//           endAdornment: (
//             <InputAdornment position="end">
//               <SearchIcon />
//             </InputAdornment>
//           ),
//         }}
//       /> */}

// 			<NewSearch
// 				hasValue={true}
// 				value={searchText}
// 				filterName='Search'
// 				infoText=''
// 				placeholder='Type here...'
// 				onChange={(e: any) => setSearchText(e.target.value)}
// 				handleClearSearch={() => setSearchText("")}
// 			/>
// 			<div className='m-5'></div>

// 			<Grid container spacing={2}>
// 				<Grid item>
// 					<Chip
// 						id={"0"}
// 						color={selected === "all" ? "primary" : "default"}
// 						label='All'
// 						variant='filled'
// 						onClick={() => setSelected("all")}
// 					/>
// 				</Grid>
// 				<Grid item>
// 					<Chip
// 						id={"0"}
// 						color={selected !== "all" ? "primary" : "default"}
// 						label='Available'
// 						onClick={() => setSelected("available")}
// 					/>
// 				</Grid>
// 			</Grid>

// 			{pickerList.length > 0 ? (
// 				<List
// 					sx={{
// 						width: "100%",
// 						bgcolor: "background.paper",
// 					}}
// 				>
// 					{pickerList?.map((item: any, index: any) => {
// 						const labelId = `checkbox-list-label-${item?.id}`;

// 						return (
// 							<>
// 								{index !== 0 && <Divider />}

// 								<ListItem
// 									sx={{
// 										width: "100%",
// 										bgcolor:
// 											checked.indexOf(item?.id) !== -1
// 												? "#FF980014"
// 												: "background.paper",
// 									}}
// 									key={item?.id}
// 									disablePadding
// 								>
// 									<ListItemButton
// 										role={undefined}
// 										onClick={handleToggle(item?.id)}
// 										dense
// 										sx={{
// 											width: "100%",
// 										}}
// 									>
// 										<ListItemIcon>
// 											<Checkbox
// 												edge='start'
// 												checked={
// 													checked.indexOf(
// 														+item?.id
// 													) !== -1
// 												}
// 												color='warning'
// 												tabIndex={-1}
// 												disableRipple
// 												inputProps={{
// 													"aria-labelledby": labelId,
// 												}}
// 											/>
// 										</ListItemIcon>
// 										<ListItemText
// 											id={labelId}
// 											primary={item?.name}
// 										/>
// 										<Chip
// 											sx={{
// 												textAlign: "right",
// 												backgroundColor: "#4CAF5014",
// 												border: "none",
// 												color: "#4CAF50",
// 											}}
// 											id={labelId}
// 											color='success'
// 											label={item?.status || "Available"}
// 											variant='outlined'
// 											size='small'
// 										/>
// 									</ListItemButton>
// 								</ListItem>
// 							</>
// 						);
// 					})}
// 				</List>
// 			) : (
// 				<Box mt={2}>
// 					<Alert variant='standard' severity='warning'>
// 						<span>No Pickers found!</span>
// 					</Alert>
// 				</Box>
// 			)}
// 			{/* <Alert variant="standard" severity="warning">
//         <span>
//           You have more than <BoldText>30</BoldText> items. You need{" "}
//           <BoldText>1 more</BoldText> employee
//         </span>
//       </Alert> */}
// 		</>
// 	);
// }
// function FeedbackModal ({ setLoading, loading, count, data }: any) {
// 	const [seeMore, setSeeMore] = React.useState(false);

// 	return (
// 		<>
// 			<LinearWithValueLabel
// 				totalProgressCount={parseInt(count)}
// 				onFinish={() => setLoading(false)}
// 			/>
// 			{!loading && (
// 				<>
// 					{data &&
// 						data.successedSaleOrderItems &&
// 						data.successedSaleOrderItems.length > 0 && (
// 							<Box sx={{ color: "success.main" }}>
// 								Succcessful picklists:{" "}
// 								{data.successedSaleOrderItems.length}
// 							</Box>
// 						)}
// 					<Box display='flex' justifyContent='space-between'>
// 						{data &&
// 							data.failedSaleOrderItems &&
// 							data.failedSaleOrderItems.length > 0 && (
// 								<Typography sx={{ color: "error.main" }}>
// 									Failed picklists: $
// 									{data.failedSaleOrderItems.length}
// 								</Typography>
// 							)}
// 						{data?.errorMessage ||
// 							data?.failedSaleOrderItems?.length > 0 ||
// 							(data?.successedSaleOrderItems?.length > 0 && (
// 								<Typography
// 									sx={{
// 										textDecoration: "underline",
// 										color: "text.disabled",
// 										textAlign: "right",
// 									}}
// 									onClick={() =>
// 										setSeeMore((prevState) => !prevState)
// 									}
// 								>
// 									See Details
// 								</Typography>
// 							))}
// 					</Box>
// 					{seeMore && (
// 						<div>
// 							{data &&
// 								data.successedSaleOrderItems &&
// 								data.successedSaleOrderItems.length > 0 && (
// 									<Box>
// 										Succcessfuly Assigned Sale Order Items:{" "}
// 										{data.successedSaleOrderItems.map(
// 											(item: any) => (
// 												<span>{item.id + ", "}</span>
// 											)
// 										)}
// 									</Box>
// 								)}
// 							{data &&
// 								data.failedSaleOrderItems &&
// 								data.failedSaleOrderItems.length > 0 && (
// 									<Typography>
// 										Failed Sale Order Items:{" "}
// 										{data.failedSaleOrderItems.join(" ,")}
// 									</Typography>
// 								)}
// 							{data && data.errorMessage && (
// 								<Typography>
// 									Error Message: {data.errorMessage}
// 								</Typography>
// 							)}
// 							{data && data.errors && (
// 								<Typography>
// 									Error Message: {data.errors[0]?.message}
// 								</Typography>
// 							)}

// 						</div>
// 					)}
// 				</>
// 			)}
// 		</>
// 	);
// }

// function ModalFooter ({ close, assign, count }: any) {
// 	return (
// 		<Box sx={{ width: "100%" }}>
// 			<Divider />

// 			<div
// 				style={{
// 					marginTop: 20,
// 					justifyContent: "flex-end",
// 					display: "flex",
// 				}}
// 			>
// 				<Button
// 					onClick={() => close()}
// 					variant='outlined'
// 					color='primary'
// 				>
// 					Cancel
// 				</Button>
// 				<Button
// 					onClick={() => assign()}
// 					sx={{ marginLeft: 2 }}
// 					variant='contained'
// 					color='primary'
// 					disabled={count < 1}
// 				>
// 					<span className='text-white'>Assign</span>
// 				</Button>
// 			</div>
// 		</Box>
// 	);
// }

// function FeedbackModalFooter ({ close, handleClick, isHidden }: any) {
// 	return !isHidden ? (
// 		<Box sx={{ width: "100%" }}>
// 			<Divider />

// 			<div
// 				style={{
// 					marginTop: 20,
// 					justifyContent: "flex-end",
// 					display: "flex",
// 				}}
// 			>
// 				<Button
// 					onClick={() => close()}
// 					variant='outlined'
// 					color='primary'
// 				>
// 					Cancel
// 				</Button>
// 				<Button
// 					onClick={() => handleClick()}
// 					sx={{ marginLeft: 2 }}
// 					variant='contained'
// 					color='primary'
// 				>
// 					<span className='text-white'>Go To Picklist</span>
// 				</Button>
// 			</div>
// 		</Box>
// 	) : (
// 		<></>
// 	);
// }

// function BoldText ({ children }: any) {
// 	return <span style={{ fontWeight: "bold" }}>{children}</span>;
// }

export default SaleOrders;
