import { gql } from "apollo-boost";

export const AddOrdersToBinPayload = gql`
	mutation AddOrdersToBinPayload($input: AddOrdersToBinInput!) {
		addOrdersToBin(input: $input) {
			notFoundCount
			updatedCount
		}
	}
`;

export const AssignOrdersToPartnerPayload = gql`
	mutation AssignOrdersToPartnerPayload($input: AssignOrdersToPartnerInput!) {
		assignOrdersToPartner(input: $input) {
			clientMutationId
			processId
		}
	}
`;

export const AssignOrdersToCourierPayload = gql`
	mutation AssignOrdersToCourierPayload($input: AssignOrdersToCourierInput!) {
		assignOrdersToCourier(input: $input) {
			shipment {
				id
			}
			clientMutationId
		}
	}
`;

export const TransitToWarehousePayload = gql`
	mutation TransitToWarehousePayload($input: TransitToWarehouseInput!) {
		transitToWarehouse(input: $input) {
			shipment {
				id
			}
			clientMutationId
		}
	}
`;

export const RemoveOrderFromWarehousePayload = gql`
	mutation RemoveOrdersFromWarehouse($input: OrdersRemoveWarehouseInput!) {
		removeOrdersFromWarehouse(input: $input) {
			clientMutationId
			updatedOrders
		}
	}
`;

export const CreateShipmentPayload = gql`
	mutation CreateShipment($input: CreateShipmentInput!) {
		createShipment(input: $input) {
			clientMutationId
		}
	}
`;

export const AddOrdersToWarehouse = gql`
	mutation AddOrdersToWarehouseHard($input: OrdersAddWarehouseHardInput!) {
		addOrdersToWarehouseHard(input: $input) {
			clientMutationId
			updatedOrders
		}
	}
`;
