function SearchIcon() {
	return (
		<svg
			width='12'
			height='14'
			viewBox='0 0 12 14'
			className='sidebar__search-icon'
			fill='#424242'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.33333 8.34863H7.80667L7.62 8.14455C8.27333 7.28287 8.66667 6.1642 8.66667 4.94727C8.66667 2.23373 6.72667 0.0341797 4.33333 0.0341797C1.94 0.0341797 0 2.23373 0 4.94727C0 7.6608 1.94 9.86035 4.33333 9.86035C5.40667 9.86035 6.39333 9.41439 7.15333 8.67365L7.33333 8.88529V9.48242L10.6667 13.2542L11.66 12.1279L8.33333 8.34863ZM4.33333 8.34863C2.67333 8.34863 1.33333 6.82936 1.33333 4.94727C1.33333 3.06518 2.67333 1.5459 4.33333 1.5459C5.99333 1.5459 7.33333 3.06518 7.33333 4.94727C7.33333 6.82936 5.99333 8.34863 4.33333 8.34863Z'
				fill='#424242'
			/>
		</svg>
	);
}

export default SearchIcon;
