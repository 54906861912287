function CloseIcon() {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2.07716L12.59 0.478516L7 6.8164L1.41 0.478516L0 2.07716L5.59 8.41504L0 14.7529L1.41 16.3516L7 10.0137L12.59 16.3516L14 14.7529L8.41 8.41504L14 2.07716Z"
        fill="white"
      />
    </svg>
  );
}

export default CloseIcon;
