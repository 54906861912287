import React from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../components/common/Button";
import CustomInput from "../../../components/common/Input";
import CustomSwitch from "../../../components/common/Switch";
import { RefreshIcon, SaveIcon } from "../../../components/svg";
import NormalSelect from "../../../components/common/Select/NormalSelect";
import { wmsOptions } from "../../../constants/apollo";
import { logger } from "../../../helper/logger";
import { ADD_UPDATE_PRODUCT } from "../../../api/mutations/WMSMutations";
import { toast } from "react-toastify";

export default function CreateProductForm({ handleClose }) {
	// Pass the useFormik() hook initial form values and a submit function that will
	// be called when the form is submitted
	const [AddOrUpdateProductMutation] = useMutation(
		ADD_UPDATE_PRODUCT,
		wmsOptions
	);

	const categoryOptions = [
		{ value: "CLOTHES", label: "Clothes" },
		{ value: "ELECTRONICS", label: "Electronics" },
		{ value: "GROCERIES", label: "Groceries" },
		{ value: "PERFUME", label: "Perfume" },
		{ value: "SIM_CARDS", label: "Sim Cards" },
	];
	const formik = useFormik({
		initialValues: {
			id: 0,
			categoryType: "ELECTRONICS",
			name: "",
			isActive: true,
			description: "",
			sKU: "",
			uPC: "",
			purchaseOrders: [],
			inventoryLogs: [],
			currentInventory: [],
			weightInGrams: 0,
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.max(15, "Must be 15 characters or less")
				.required("Required"),
			sKU: Yup.string()
				.max(15, "Must be 15 characters or less")
				.required("Required"),
			uPC: Yup.string()
				.max(15, "Must be 15 characters or less")
				.required("Required"),
		}),
		onSubmit: (values) => {
			AddOrUpdateProductMutation({
				variables: {
					products: [
						{
							...values,
							weightInGrams: parseInt(values.weightInGrams),
						},
					],
				},
			}).then((res) => {
				if (res?.data?.createOrUpdateProducts?.length > 0) toast.success(`Product ${res?.data?.createOrUpdateProducts[0]?.id} is created successfully`)
				handleClose();
			});
		},
	});
	return (
		<form onSubmit={formik.handleSubmit}>
			<div className='w-full flex flex-col space-y-5 mb-12 mt-5'>
				<h3>General Information</h3>
				<div className='flex justify-between items-start space-x-3'>
					<div className='w-1/2 flex flex-col'>
						<CustomInput
							id='name'
							name='name'
							type='text'
							inputName='Product name'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.name}
						/>
						{formik.touched.name && formik.errors.name ? (
							<div className='error'>{formik.errors.name}</div>
						) : null}
					</div>
					<div className='w-1/2'>
						<NormalSelect
							filter={{
								name: "Product type",
								options: categoryOptions,
							}}
							id='categoryType'
							// value={categoryOptions.find(
							//   (option) => option.value == formik.values.categoryType
							// )}
							defaultValue={"Electronics"}
							handleChange={(selected) => {
								logger("SELECTED", selected);
								formik.setFieldValue(
									"categoryType",
									selected.value
								);
							}}
							required
						/>
					</div>
				</div>
				<div className='flex space-x-3'>
					<CustomInput
						id='description'
						name='description'
						type='text'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.description}
						inputName='Product Description'
					/>
				</div>

				<h3>Product Specification</h3>
				<div className='flex items-start space-x-3'>
					{/* // TODO: add input-mask */}
					<CustomInput
						id='weightInGrams'
						name='weightInGrams'
						type='text'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.weightInGrams}
						inputName='Weight'
					/>
					<CustomInput
						inputName='Size'
						infoText='e.g 71.5 mm / 146.7 mm / 7.65 mm'
						onChange={() => { }}
					/>
				</div>
				<div className='flex items-start space-x-3'>
					<div className='w-1/2 flex flex-col'>
						<CustomInput
							id='sKU'
							name='sKU'
							type='text'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.sKU}
							inputName='SKU'
							icon={<RefreshIcon />}
						/>
						{formik.touched.sKU && formik.errors.sKU ? (
							<div className='error'>{formik.errors.sKU}</div>
						) : null}
					</div>

					<div className='w-1/2 flex flex-col'>
						<CustomInput
							id='uPC'
							name='uPC'
							type='text'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.uPC}
							inputName='UPC'
						/>
						{formik.touched.uPC && formik.errors.uPC ? (
							<div className='error'>{formik.errors.uPC}</div>
						) : null}
					</div>
				</div>

				<div className='flex items-start justify-between space-x-3'>
					<CustomSwitch
						handleChange={(isChecked) =>
							formik.setFieldValue("isActive", isChecked)
						}
						color='warning'
					/>
					<div className='flex space-x-3 mt-14'>
						<CustomButton
							type='button'
							onClick={handleClose}
							variant='outlined'
							color='warning'
						>
							cancel
						</CustomButton>
						<CustomButton
							type='submit'
							variant='contained'
							color='warning'
							onClick={() => { }}
						>
							<div className='flex space-x-2'>
								<SaveIcon />
								<span>save</span>
							</div>
						</CustomButton>
					</div>
				</div>
			</div>
		</form>
	);
}
