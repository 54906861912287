import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import "react-tooltip/dist/react-tooltip.css";

import { createContext, useEffect, useState } from "react";
import Router from "./router/router";
import { ToastContainer } from "react-toastify";
import { ApolloProvider } from "@apollo/client";
import Tracking from "./pages/Tracking";
import { ApolloClientConfig } from "./api/configs/apollo";
import { LoadingProvider } from "hooks/useLoading";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo, orange, purple } from "@mui/material/colors";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const UserContext = createContext({});

const theme = createTheme({
	palette: {
		primary: { main: "#ff9800", contrastText: "#fff" },
		secondary: { main: `#3f51b5`, contrastText: "#fff" },
		// success: { main: "#4CAF50", contrastText: "rgba(76, 175, 80, 0.6)" },
	},
});

function App () {
	const [loading, setLoading] = useState([]);

	return (
		<ThemeProvider theme={theme}>
			<ApolloProvider
				client={ApolloClientConfig({ loading, setLoading })}
			>
				{!Object.fromEntries(
					new URLSearchParams(window.location.search)
				)?.trackingId ? (
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<div className='w-full min-h-screen'>
							<Router />
							<ToastContainer limit={2} />
						</div>
					</LocalizationProvider>
				) : (
					<Tracking />
				)}
			</ApolloProvider>
		</ThemeProvider>
	);
}

export default App;
