import React, { useEffect, useState } from "react";
import { GridFilterListIcon } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSearchParams } from "react-router-dom";

import NewSearch from "../../../components/common/Search/new-search";
import Modal from "../../../components/common/Modal";
import ModalFooter from "../../../components/common/Modal/ModalFooter";
import { useDebounce } from "hooks/useDebounce";
import BillingsTable from "./components/BillingsTable";
import { LOAD_BILLINGS } from 'api/queries/FinanceQueries';
import FiltersList from "../../../components/FiltersList";

export default function Billings() {
  const [selectedTab, setSelectedTab] = useState("1");
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchText, setSearchText] = useState(
    Object.fromEntries([...searchParams]).searchText || ""
  );
  const debouncedSearchText = useDebounce(searchText, 500);

  const [filterOpen, setFilterOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleQueryChange = (obj) => {
    // update router query based on implemented filters
    let mergedObj = { ...Object.fromEntries([...searchParams]), ...obj };
    let modified= {};

    Object.keys(mergedObj).map((key) => {
      if (mergedObj[key] != "all" && mergedObj[key] != "") {
        if (key in obj) {
          modified[key] = obj[key];
        } else {
          modified[key] = mergedObj[key];
        }
      }
    });

    setSearchParams(modified);
  };

  useEffect(() => {
    handleQueryChange({ searchText: searchText });
  }, [debouncedSearchText]);

  return (
    <>
      <div className="main-wrapper flex justify-between space-x-3 border-t-2 border-gray-50 border-b-2">
        <div className="w-[450px] flex space-x-3">
          <NewSearch
            hasValue={true}
            filterName="Search"
            infoText=""
            placeholder="Type here..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            handleClearSearch={() => setSearchText("")}
          />
          <div
            onClick={() => setFilterOpen(true)}
            className="flex items-center cursor-pointer hover:bg-gray-50 h-[42px] px-3 rounded-lg"
          >
            <GridFilterListIcon color="primary" />
            <span className="text-[#FF9800] font-medium uppercase ml-2 min-w-[100px]">{`Filters By`}</span>
          </div>
        </div>
      </div>

      <Box sx={{ width: "100%" }}>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 2, borderColor: "rgb(249 250 251)" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="invoices tabs"
              variant="fullWidth"
            >
              <Tab sx={{fontWeight:700}} label="All Invoices" value="1" />
              <Tab sx={{fontWeight:700}} label="Paid invoices" value="2" />
              <Tab sx={{fontWeight:700}} label="Unpaid invoices" value="3" />
            </TabList>
          </Box>

          <TabPanel value="1" sx={{ padding: "0" }}>
            <BillingsTable value="1" searchText={debouncedSearchText} />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0" }}>
            <BillingsTable value="2" paymentState="paid" searchText={debouncedSearchText} />
          </TabPanel>
          <TabPanel value="3" sx={{ padding: "0" }}>
            <BillingsTable value="3" paymentState="unpaid" searchText={debouncedSearchText} />
          </TabPanel>
        </TabContext>
      </Box>
      <Modal
        footer={(handleClose) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={()=>{}
              // handleFilter
            }
            handleReset={()=>{}
              // handleFilterReset
            }
          />
        )}
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        onClose={() => { }}
        title={"Filter Billings"}
      >
        <FiltersList
          selectedFilters={{}}
          handleChange={(filterVal, filterName) => {}
          //   setDriverFilters((old) => ({
          //     ...old,
          //     [filterName]: filterVal,
          //   }));
          // }
        }
          filters={[
            // {
            //   name: "Filter by City",
            //   value: "driverCity",
            //   id: 1,
            //   isAsync: true,
            //   isSearchable: true,
            //   getSelectedVal: (id) => {
            //     if (id && id != "all") {
            //       let sel = fetch_city(id);
            //       return sel;
            //     } else return { value: "all", label: "All" };
            //   },
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_cities({ page: page, searchQuery: searchQuery }),
            // },
            // {
            //   name: "Filter by Vehicle Model",
            //   value: "driverVehicleModel",
            //   id: 122,
            //   isAsync: true,
            //   isSearchable: false,
            //   getSelectedVal: (id: any) => {
            //     if (id && id != "all") {
            //       return { value: id, label: id };
            //     } else return { value: "all", label: "All" };
            //   },
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_vehicleModels({ page: page, searchQuery: searchQuery }),
            // },
            // {
            //   name: "Filter by Nationality",
            //   value: "driverNationality",
            //   id: 12,
            //   isAsync: true,
            //   isSearchable: false,
            //   getSelectedVal: (id: any) => {
            //     if (id && id != "all") {
            //       return { value: id, label: id };
            //     } else return { value: "all", label: "All" };
            //   },
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_nationalities({ page: page, searchQuery: searchQuery }),
            // },
            // {
            //   name: "Filter by Partner",
            //   value: "driverPartner",
            //   id: 11,
            //   isAsync: true,
            //   isSearchable: true,
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_driver_partners({ page: page, searchQuery: searchQuery }),
            // },
            
            {
              name: "Filter by Merchant",
              value: "driverDevice",
              id: 4,
              options: [
                { value: "all", label: "All" },
                { value: "android", label: "Android" },
                { value: "ios", label: "Ios" },
              ],
            },
          ]}
        />
      </Modal>
    </>
  );
}
