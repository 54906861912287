import { Slider } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import { CompletedNeigbourhoodIcon } from "../../svg";

const NewSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-track": {
    backgroundColor: "#4CAF50",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#E0E0E0",
  },
  "& .MuiSlider-markLabel": {
    fontSize: "12px",
    color: "#A4A4A4",
  },
  "& .MuiSlider-thumb": {
    opacity: 0,
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#E0E0E0",
    height: 10,
    width: 10,
    borderRadius: "50%",
  },
}));

// [{city: "Hittin", mark: "<TickIcon/>"}, {city: "Hittin", mark: "2/4"}]

export default function CustomSlider ({ data }) {
  const fakeData = {
    orders: [
      { neighborhood: "Hittin", isCompleted: true, isActive: false },
      { neighborhood: "Hittin", isCompleted: true, isActive: false },
      { neighborhood: "Hittin", isCompleted: false, isActive: true },
      { neighborhood: "Malqa", isCompleted: false, isActive: false },
      { neighborhood: "Malqa", isCompleted: false, isActive: false },
      { neighborhood: "Malqa", isCompleted: false, isActive: false },
      { neighborhood: "Malqa", isCompleted: false, isActive: false },
      { neighborhood: "Malqa", isCompleted: false, isActive: false },
      { neighborhood: "Alyasmin", isCompleted: false, isActive: false },
      { neighborhood: "Alyasmin", isCompleted: false, isActive: false },
      { neighborhood: "Alyasmin", isCompleted: false, isActive: false },
      { neighborhood: "Qurtubah", isCompleted: false, isActive: false },
    ],
  };
  // get isActive, isCompleted, count
  let neighborhoods = [];
  let formattedData = [];
  let activeNeighborhood = 1;

  data?.forEach((item) => {
    if (neighborhoods.indexOf(item.neighborhood) === -1) {
      neighborhoods.push(item.neighborhood);
    }
  });

  neighborhoods?.forEach((neighborhood) => {
    let maxCount = 0;
    let isCompleted = false;
    let isActive = false;
    let completedCount = 0;
    data?.forEach((item) => {
      if (item.neighborhood === neighborhood) {
        maxCount++;
        if (item.orderStatus === "completed") {
          isCompleted = true;
          completedCount++;
        } else if (item.orderStatus === "assigned") {
          isActive = true;
          isCompleted = false;
        }
      }
    });
    if (maxCount === completedCount) {
      activeNeighborhood++;
    }
    formattedData.push({
      neighborhood,
      maxCount,
      isCompleted,
      isActive,
      completedCount,
    });
  });


  const progress = (100 / (formattedData.length + 1)) * activeNeighborhood;

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex relative justify-evenly items-center z-10">
        {formattedData.map((order, index) => (
          <div key={index} className="flex flex-col items-center justify-center  w-14">
            {getNeighborhoodIcon(
              order.isCompleted,
              order.isActive,
              order.maxCount,
              order.completedCount

            )}
          </div>
        ))}

        {/* Progress Line */}
        <div className="absolute w-full h-full flex items-center">
          <div className="w-full h-[1px] bg-gray-300 -z-10 relative">
            <div
              style={{ width: `${progress}%` }}
              className="absolute h-[1px] bg-successColor"
            ></div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-evenly items-start mt-2 text-gray-400">
        {formattedData.map((order, index) => (
          <span key={index} className="w-14 text-[10px] text-center">{order.neighborhood}</span>
        ))}
      </div>
    </div>
    // <NewSlider
    //   disabled
    //   aria-label="neighbourhoods"
    //   size="small"
    //   track="inverted"
    //   defaultValue={50} // TODO: last completed order value
    //   marks={data} // [{ value: 0, label: "Hittin" }, { value: 100, label: "Malqa" }]
    // />
  );
}

const getNeighborhoodIcon = (
  isCompleted,
  isActive,
  maxCount,
  completedCount
) => {
  if (isCompleted) {
    return (
      <div className="w-auto h-auto rounded-full bg-white transform scale-125 border border-white">
        <CompletedNeigbourhoodIcon />
      </div>
    );
  }
  if (isActive) {
    return (
      <div className="w-9 h-9 rounded-full  border border-successColor bg-lightSuccessColor text-successColor font-bold flex items-center justify-center">
        {completedCount}/{maxCount}
      </div>
    );
  }
  if (maxCount > 1) {
    return (
      <div className="w-8 h-8 rounded-full  border border-gray-400 bg-lightBgColor text-gray-400 font-bold flex items-center justify-center">
        {maxCount}
      </div>
    );
  }

  return (
    <div className="w-4 h-4 bg-gray-200 rounded-full border-[2px] border-white"></div>
  );
};
