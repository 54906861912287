import NormalSelect from '../../../components/common/Select/NormalSelect';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import CustomInput from "../../../components/common/Input";
import moment from 'moment';
import CustomButton from '../../../components/common/Button';
import PaginatedTable from '../../../components/Table/paginatedTable';
import { bonusTypeOptions } from '../../../constants/enums';
import MUIDatePicker from '../../../components/common/DatePicker';
import { CREATE_DRIVER_BONUS } from 'api/mutations/Mutations';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { stagingOptions } from 'constants/apollo';
import * as Yup from 'yup'

const cols = [
    { name: "Type", value: "bonusType" },
    { name: "Date", value: "forDate" },
    { name: "Amount", value: "amount" },
    { name: "Note", value: "note" },
    { name: "Applied By", value: "appliedBy" },
];
export default function DriverBonusesTab ({ driver, handleUpdateDriver, reload }) {
    const [totalCount, setTotalCount] = useState(0)

    const [
        createCourierBonus,
        {
            data: createBonusRes,
            loading: createBonusloading,
            error: createBonusError,
        },
    ] = useMutation(CREATE_DRIVER_BONUS, stagingOptions);

    const handleCreateDriverBonus = (attributes) => {
        console.log("HERE", attributes, parseInt(driver?.id), moment(attributes.forDate).format("YYYY-MM-DD"))
        if (Object.keys(attributes).length > 0)
            try {
                createCourierBonus({
                    variables: {
                        input: {
                            attributes: {
                                courierId: parseInt(driver?.id),
                                ...attributes,
                                amount: parseInt(attributes.amount),
                                forDate: moment(attributes.forDate).format("YYYY-MM-DD")
                            }
                        }
                    }
                }).then((res) => {
                    console.log(res)
                    if (res?.error) {
                        toast.error(res?.error?.message)
                    }
                })
            }
            catch (e) {
                toast.error(e.message)
            }
    }
    const formik = useFormik({
        initialValues: {
            bonusType: "",
            forDate: moment(),
            amount: "",
            note: ""
        },
        validationSchema: Yup.object({
            bonusType: Yup.string().required("Required"),
            forDate: Yup.string().required("Required"),
            amount: Yup.number().required("Required"),
            note: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            handleCreateDriverBonus(values)
            // setIsSubmitting(true)
            // alert(JSON.stringify(values, null, 2));
        },
    });

    useEffect(() => {
        if (createBonusRes?.createCourierBonus) {
            toast.success("Successfully added!")
            formik.resetForm()
            reload()
        }
    }, [createBonusRes])

    useEffect(() => {

        if (createBonusError)
            toast.error(createBonusError)
    }, [createBonusError])
    return (
        <div className='flex flex-col space-y-2 bg-gray-50'>
            <div className="flex flex-col w-full justify-between mt-1 bg-white">
                <div className='flex w-ful justify-between p-4'>
                    <span className="text-xl">Add New</span>
                </div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit()
                    }}
                    className='section-wrapper flex flex-col justify-between space-y-5'
                >
                    <div
                        className="flex space-x-2 py-4"
                    >
                        <div className="w-1/4 flex flex-col">

                            <NormalSelect
                                styles={{ width: "100%" }}
                                key={44}
                                filter={
                                    {
                                        name: "Bonus Type",
                                        value: "bonusType",
                                        id: 1,
                                        options: bonusTypeOptions,
                                    }
                                }
                                defaultValue={bonusTypeOptions.find(option => option.value == formik.values.bonusType
                                )}
                                handleChange={(e) => formik.setFieldValue("bonusType", e.value)}
                            />

                            {formik.touched.bonusType && formik.errors.bonusType &&
                                <div className="error" key={0}>{formik.errors.bonusType}</div>
                            }
                        </div>
                        <div className="w-1/4 flex flex-col">
                            <MUIDatePicker
                                id="forDate"
                                name="forDate"
                                label="Date"
                                value={moment(formik.values.forDate)}
                                onChange={(date) => {
                                    formik.setFieldValue("forDate", date)
                                }}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.forDate && formik.errors.forDate &&
                                <div className="error" key={0}>{formik.errors.forDate}</div>
                            }
                        </div>
                        <div className="w-1/4 flex flex-col">
                            <CustomInput
                                id="amount"
                                name="amount"
                                type="text"
                                inputName="Amount"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.amount}
                            />
                            {formik.touched.amount && formik.errors.amount &&
                                <div className="error" key={0}>{formik.errors.amount}</div>
                            }
                        </div>
                        <div className="w-1/4 flex flex-col">
                            <CustomInput
                                id="note"
                                name="note"
                                type="text"
                                inputName="Note"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.note}
                            />
                            {formik.touched.note && formik.errors.note &&
                                <div className="error" key={0}>{formik.errors.note}</div>
                            }
                        </div>
                        <div className="w-1/7 disabled">
                            <CustomButton
                                diabled
                                type='button'
                                variant='contained'
                                // color="warning"
                                color='default'
                                onClick={() => { }}
                                style={{ width: "100%", height: "38px" }}
                            >
                                Add
                            </CustomButton>
                        </div>
                    </div>
                </form>
            </div>
            <div className="flex flex-col w-full justify-between mt-1 bg-white">
                <span className="text-xl p-4">Bonuses History</span>
                <PaginatedTable
                    // hasCheckbox
                    filterByBin={''}
                    sortCol={{ label: "", value: 0 }}
                    onSortClick={(sortVal) => {
                        // handleSort(sortVal);
                    }}
                    count={driver?.courierBonuses?.length || 0}
                    page={0}
                    setPage={() => { }}
                    onPageChange={() => { }}
                    cols={cols}
                    data={driver.courierBonuses}
                    selectedRows={[]}
                    setSelectedRows={() => { }}
                    loading={false}
                />
            </div>
        </div>
    )
}
