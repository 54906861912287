import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from 'react-toastify'
import { logger } from '../../helper/logger'
import { LOAD_DRIVERS_FOR_MAP, LOAD_SHIPMENTS_FOR_MAP } from '../../api/queries/Queries'
import { stagingOptions } from '../../constants/apollo'
import DensityLeafletMap from './components/DensityLeafletMap'

export default function DensityMap({}) {
  const [allShipments, setAllShipments] = useState([])
  const [allDrivers, setAllDrivers] = useState([])

  const [
    getAllDrivers,
    {
      data: fetchedDrivers,
      error: driversFetchError,
      loading: driversFetchLoading
    }
  ] = useLazyQuery(LOAD_DRIVERS_FOR_MAP, {
    ...stagingOptions
  })
  const [
    getAllShipments,
    {
      data: fetchedShipments,
      error: shipmentsFetchError,
      loading: shipmentsFetchLoading
    }
  ] = useLazyQuery(LOAD_SHIPMENTS_FOR_MAP, {
    ...stagingOptions
  })

  const [filters, setFilters] = useState({
    shipment: {
      apply: false,
      showOnMap: true,
      sort_order: 'asc'
    },
    driver: {
      apply: false,
      showOnMap: true
    }
  })
  const [mapBounds, setMapBounds] = useState({
    ne: '24.75026098689889, 46.74015820026398',
    sw: '24.688200182518774, 46.597335934638984'
  })

  const fetch_shipments = () => {
    try {
      getAllShipments({
        variables: {
          limit: 999,
          offset: 0,
          query: '',
          ...mapBounds,
          shipmentStatuses: [0],
          includeInactive: false
        }
      })
    } catch (e) {
      toast.error('Error while loading shipments:\n', e?.message)
    }
  }

  useEffect(() => {
    if (fetchedShipments) {
      logger('nts: ', fetchedShipments.fetchAllShipments?.items)
      const allShipmentsList = fetchedShipments.fetchAllShipments?.items

      setAllShipments(allShipmentsList)
    }
  }, [fetchedShipments])

  const fetch_drivers = () => {
    try {
      getAllDrivers({
        variables: {
          limit: 99,
          offset: 0,
          query: '',
          ...mapBounds
        }
      })
    } catch (e) {
      toast.error('Error while loading drivers:\n', e?.message)
    }
  }

  useEffect(() => {
    if (fetchedDrivers) {
      const allDriversList = fetchedDrivers.fetchAllCouriers?.items

      setAllDrivers(allDriversList)
    }
  }, [fetchedDrivers])

  useEffect(() => {
    fetch_shipments()
    fetch_drivers()
  }, [mapBounds])

  return (
    <div className='flex h-screen w-full bg-lightBgColor justify-between overflow-y-hidden relative'>
      {driversFetchLoading ||
        (shipmentsFetchLoading && (
          <div
            className='absolute w-full h-full bg-white/50 z-10
            flex flex-col items-center justify-center'
          >
            <CircularProgress />
          </div>
        ))}
      <DensityLeafletMap
        mapBounds={mapBounds}
        setMapBounds={setMapBounds}
        filters={filters}
        setFilters={setFilters}
        shipments={allShipments}
        drivers={allDrivers}
      />
    </div>
  )
}
