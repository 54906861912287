import PaginatedTable from "../../../../components/Table/paginatedTable";
import React from "react";
import Modal from "../../../../components/common/Modal";
import {
	Button,
	Checkbox,
	Chip,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	LinearProgress,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TextField,
	Typography,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { Box } from "@mui/system";
import { SearchIcon } from "../../../../components/svg";
import CustomButton from "../../../../components/common/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_ALL_LOCATIONS } from "api/queries/WMSqueries";
import { wmsOptions } from "constants/apollo";
import {
	COMPLETE_PACKING_JOB,
	COMPLETE_RESTOCK_JOBS,
	PICKING_JOBS_TO_PACKING,
} from "api/mutations/WMSMutations";
import { toast } from "react-toastify";
import SelectAsyncPaginate from "../../../../components/common/Select/AsyncPaginateSelect";

const cols = [
	{
		name: "Picklist ID",
		value: "id",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Sale Order Id",
		value: "sale.order.id",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Location",
		value: "location.locationCode",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{ name: "Product name", value: "product.name", canBeSorted: true },
	{
		name: "Count",
		value: "count",
		canBeSorted: true,
	},
	{ name: "Date", value: "createdAt", canBeSorted: true },
	{
		name: "Picker",
		value: "picker",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{ name: "Status", value: "status", type: "status", canBeSorted: true },
];

export default function Proceed() {
	const location: any = useLocation();
	const [itemsCount, setItemsCount] = React.useState(0);
	const [sortCol, setSortCol] = React.useState<any>([]);
	const [page, setPage] = React.useState(1);
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [filterByBin, setFilterByBin] = React.useState("");
	const [modalOpen, setModalOpen] = React.useState(false);
	const [items, setItems] = React.useState<string[]>([]);
	const [cartId, setCartId] = React.useState(0);
	const [loading, setLoading] = React.useState(false);
	const [pickingJobToPackingJob] = useMutation(PICKING_JOBS_TO_PACKING, {
		...wmsOptions,
	});
	const [completePackingJob] = useMutation(COMPLETE_PACKING_JOB, {
		...wmsOptions,
	});
	const [completeRestockingMutation] = useMutation(COMPLETE_RESTOCK_JOBS, {
		...wmsOptions,
	});

	const navigate = useNavigate();

	const completePicking = async () => {
		try {
			setLoading(true);
			if (location?.state?.saleOrderItemId && items.length) {
				pickingJobToPackingJob({
					variables: {
						pickingJobId: location?.state?.id - 0,
						serials: items,
					},
				}).then((data) => {
					if (data?.data?.completePickingJobScanning?.id) {
						toast.success(
							`#${data?.data?.completePickingJobScanning?.id} is moved successfully`
						);
						navigate("/wms/outbound/on_picking");
					}
				});
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const completePacking = async () => {
		try {
			setLoading(true);
			if (location?.state?.packingJobId && items.length) {
				completePackingJob({
					variables: {
						packingJobId: location?.state?.packingJobId - 0,
						serials: items,
					},
				}).then((data) => {
					if (data?.data?.completePackingJobScanning?.id) {
						toast.success(`Item is moved successfully`);
						navigate("/wms/outbound/on_packing");
					}
				});
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const completeRestocking = async () => {
		try {
			setLoading(true);
			if (location?.state?.restockJobId && items.length) {
				completeRestockingMutation({
					variables: {
						restockJob: location?.state?.packingJobId - 0,
						serials: items,
					},
				}).then((data) => {
					if (data?.data?.completeRestockJobScanning?.id) {
						toast.success(`Item is moved successfully`);
						navigate("/wms/outbound/restocking");
					}
				});
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const handleSort = (sortVal = "") => {
		if (sortCol.label === sortVal) {
			// sort Desc
			// const sortOrder = getSortationOrder(sortCol.value + 1);
			sortCol.value += 1;

			// fetchPurchaseOrder(0, searchText, sortVal, sortOrder);
		} else {
			setSortCol((old: any) => ({
				...old,
				label: sortVal,
				value: 0,
			}));
			// fetchPurchaseOrder(0, searchText, sortVal, "ASC");
		}

		setPage(0);
	};

	const addItem = (item: string) => {
		let itemIndex = items.findIndex((y: any) => y == item);

		if (itemIndex === -1) {
			setItems([...items, item]);
		} else {
			setItems(items.filter((i) => i !== item));
		}
	};

	return (
		<div className='main-wrapper'>
			<Grid
				container
				sx={{
					fontSize: 24,
					marginTop: 1,
				}}
			>
				<Grid
					sx={{
						flexGrow: 1,
					}}
				>
					<strong
						style={{
							fontWeight: 500,
						}}
					>
						Locations:{" "}
					</strong>

					<span className='text-orange-500'>
						{location?.state?.cartCode || "-"}
					</span>
				</Grid>
				<Grid
					sx={{
						flexGrow: 1,
					}}
				>
					<strong
						style={{
							fontWeight: 500,
						}}
					>
						Merchant:{" "}
					</strong>
					<span className='text-orange-500'>
						{location?.state?.merchantName || "-"}
					</span>{" "}
				</Grid>
				<Grid
					sx={{
						flexGrow: 1,
						textAlign: "center",
					}}
				>
					<strong
						style={{
							fontWeight: 500,
						}}
					>
						Product:{" "}
					</strong>
					<span>{location?.state?.productName || "-"}</span>{" "}
				</Grid>
				<Grid
					sx={{
						flexGrow: 1,
						textAlign: "right",
					}}
				>
					<strong
						style={{
							fontWeight: 500,
						}}
					>
						Status:{" "}
					</strong>
					<Chip
						label={
							location?.state?.status?.split("_").join(" ") || "-"
						}
					/>
				</Grid>
				<Grid
					sx={{
						flexGrow: 1,
						textAlign: "right",
					}}
				>
					<strong
						style={{
							fontWeight: 500,
						}}
					>
						To pick:{" "}
					</strong>
					<span>
						{items?.length} /{" "}
						{location?.state?.saleOrderItemQuantity} item(s)
					</span>
				</Grid>
			</Grid>
			<LinearProgress
				variant='buffer'
				color='success'
				sx={{ marginTop: 2 }}
				valueBuffer={20}
				value={
					items?.length > location?.state?.saleOrderItemQuantity
						? 100
						: (items?.length /
								location?.state?.saleOrderItemQuantity) *
						  100
				}
			/>
			<div style={{ marginTop: 20, display: "flex" }}>
				<TextField
					size='small'
					sx={{ width: "80%" }}
					variant='outlined'
					color='success'
					label='Scan each item'
					onKeyDown={(event: any) => {
						if (event.key === "Enter") {
							if (event.target.value) {
								addItem(event.target.value);
								event.target.value = "";
							}
						}
					}}
				/>
				<Button
					variant='contained'
					color='success'
					sx={{ width: "18%", marginLeft: "2%" }}
					disabled={
						loading ||
						location?.state?.saleOrderItemQuantity !== items.length
					}
					onClick={() => {
						if (location?.state?.type == "packing") {
							completePacking();
						} else if (location?.state?.type == "restocking") {
							completeRestocking();
						} else {
							completePicking();
						}
					}}
				>
					{loading ? "Processing..." : "Complate scanning"}
				</Button>
			</div>
			<Box sx={{ marginTop: 3 }}>
				<Typography
					sx={{
						fontSize: 20,
						fontWeight: 500,
					}}
				>
					Items:
				</Typography>
				<Box sx={{ marginTop: 2 }}>
					{location?.state?.type !== "packing"
						? items.map((item: any) => {
								return (
									<Chip
										label={item}
										key={item}
										sx={{ m: 0.5 }}
										color={"default"}
										clickable
										onClick={() => {}}
										onDelete={() => {
											addItem(item);
										}}
									/>
								);
						  })
						: location?.state?.serials?.map((item: any) => (
								<Chip
									label={item}
									key={item}
									sx={{ m: 0.5 }}
									color={
										items.findIndex((a) => a == item) !== -1
											? "success"
											: "default"
									}
									clickable
									onClick={() => {}}
									onDelete={() => {
										addItem(item);
									}}
								/>
						  ))}

					{items
						.filter(
							(item) =>
								location?.state?.serials?.findIndex(
									(i: string) => item == i
								) == -1
						)
						.map((item) => (
							<Chip
								label={item}
								key={item}
								sx={{ m: 0.5 }}
								color='error'
								clickable
								onClick={() => {}}
								onDelete={() => {
									addItem(item);
								}}
							/>
						))}
				</Box>
			</Box>
		</div>
	);
}
