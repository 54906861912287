import { gql } from "@apollo/client";

export const WMS_FILTER_LOCATIONS = gql`
	query getAllWarehouses(
		$skip: Int
		$where: LocationFilterInput
		$order: [LocationSortInput!]
		$query: String
	) {
		locations(skip: $skip, where: $where, order: $order, query: $query) {
			items {
				value: id
				label: referenceName
				locationCode
			}
			totalCount
		}
	}
`;

export const WMS_FILTER_PRODUCTS = gql`
	query getAllProducts(
		$skip: Int
		$where: ProductFilterInput
		$order: [ProductSortInput!]
		$query: String
	) {
		products(skip: $skip, where: $where, order: $order, query: $query) {
			items {
				value: id
				label: name
				
			}
			totalCount
		}
	}
`;

export const WMS_FILTER_MERCHANTS = gql`
	query fetchAllMerchants(
		$skip: Int
		$where: MerchantFilterInput
		$order: [MerchantSortInput!]
		$query: String
	) {
		merchants(skip: $skip, where: $where, order: $order, query: $query) {
			items {
				label: name
				value: id
			}
            totalCount
		}
	}
`;

export const WMS_INVENTORY_SUMMARY_PER_MERCHANT = gql`
	query fetchInventorySummaryPerMerchant(
		$merchantId: Int!
		$query: String
	) {
		inventorySummaryPerMerchant(merchantId : $merchantId, query: $query) {
			items {
			 merchantName
			 locationType
			 productCount
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
	  }
	}
`;
