import PaginatedTable from "../../../../components/Table/paginatedTable";
import React from "react";
import Modal from "../../../../components/common/Modal";
import {
	Alert,
	Button,
	Checkbox,
	Chip,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Radio,
	TextField,
	Typography,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { Box } from "@mui/system";
import { SearchIcon } from "../../../../components/svg";
import useLazyQuery from "hooks/useLazyQuery";
import {
	GET_ALL_LOCATIONS,
	GET_PICKERS,
	GET_PICKING_JOBS,
} from "api/queries/WMSqueries";
import { wmsOptions } from "constants/apollo";
import moment from "moment";
import { objFlatten } from "helper/objFlatten";
import { useDebounce } from "hooks/useDebounce";
import {
	useLazyQuery as useApolloLazyQuery,
	useMutation,
} from "@apollo/client";
import {
	ASSIGN_PICKING_JOBS_TO_CARTS,
	ASSIGN_SALEORDERS_TO_PICKERS,
	UPDATE_PICKING_JOBS,
} from "api/mutations/WMSMutations";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import usePicking from "./usePicking";
import { getSortationOrder } from "helper/GetSortOrder";
import NewSearch from "../../../../components/common/Search/new-search";
import { logger } from "helper/logger";
import LinearWithValueLabel from "../../../../components/common/ProgressLine";

const cols = [
	{
		name: "Picking Job ID",
		value: "id",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Sale Order Item Id",
		value: "saleOrderItem.id",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Sale Order Id",
		value: "saleOrderItem.saleOrder.id",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Merchant name",
		canBeSorted: true,
		value: "saleOrderItem.saleOrder.merchant.name",
		styles: "text-warningColor",
	},
	{
		name: "Product name",
		value: "saleOrderItem.product.name",
		canBeSorted: true,

		styles: "text-warningColor",
	},

	{
		name: "Count",
		value: "serialsLength",
		canBeSorted: true,
	},
	{ name: "Created at", value: "createdAt", canBeSorted: true },
	{ name: "Updated at", value: "updatedAt", canBeSorted: true },
	{
		name: "Cart",
		value: "cart.referenceName",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{
		name: "Picker",
		value: "assignedTo.name",
		canBeSorted: true,
		styles: "text-warningColor",
	},
	{ name: "Status", value: "status", type: "status", canBeSorted: true },
];

export default function Picking({
	productFilters,
	isFilterApplied,
	setIsFilterApplied,
	assignModalOpen,
	setAssignModalOpen,
	autoAssign,
	setAutoAssign,
	setActiveTab,
	manualAssign,
	setManualAssign,
	feedbackModalOpen,
	setFeedbackModalOpen,
	searchText,
}: any) {
	const [items, setItems] = React.useState([]);
	const [itemsCount, setItemsCount] = React.useState(0);
	const [sortCol, setSortCol] = React.useState<any>([]);
	const [page, setPage] = React.useState(0);
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [filterByBin, setFilterByBin] = React.useState("");
	const [modalOpen, setModalOpen] = React.useState(false);
	const [pickingJobs] = useLazyQuery(GET_PICKING_JOBS, {
		...wmsOptions,
		fetchPolicy: "network-only",
	});
	const [activeCartId, setActiveCardId] = React.useState(0);
	const [activeSaleOrderItem, setActiveSaleOrderItem] = React.useState(0);
	const [activeItemId, setActiveItemId] = React.useState(0);
	const [reload, setReload] = React.useState(0);
	const [selectedPickerIDs, setSelectedPickerIDs] = React.useState<number[]>(
		[]
	);
	const [activeData, setActiveData] = React.useState<any>({});
	const [isModalActionLoading, setIsModalActionLoading] =
		React.useState(true);

	const [assignRes, setAssignRes] = React.useState({});
	const [selectedOrderIDs, setSelectedOrderIDs] = React.useState<number[]>(
		[]
	);

	const [assignSaleOrdersToPickers] = useMutation(
		ASSIGN_SALEORDERS_TO_PICKERS,
		wmsOptions
	);

	const debouncedSearchText = useDebounce(searchText, 500);

	const { getOrderObj, getSearchObj, getFilterObj } = usePicking();

	React.useEffect(() => {
		fetchData();
	}, [reload, debouncedSearchText, productFilters]);

	React.useEffect(() => {
		if (manualAssign) {
			if (selectedRows.length == 0) {
				toast.error("There are no selected sale orders to assign");
				setManualAssign(false);
				return;
			}

			setSelectedOrderIDs(selectedRows);
			setAssignModalOpen(true);
			setManualAssign(false);
		}
	}, [manualAssign]);

	React.useEffect(() => {
		if (autoAssign) {
			if (selectedRows.length == 0) {
				toast.error("There are no selected sale orders to assign");
				setIsModalActionLoading(true);
				setAutoAssign(false);
				return;
			}
			// let list: numer[] = [];
			// data?.map((item: any) => list.push(parseInt(item?.id)));
			setSelectedOrderIDs(selectedRows);
			// fetchPickers();
			handleAssignPicker({
				assignBySelectedRows: true,
				autoAssignMode: true,
			});
			setFeedbackModalOpen(true);
			// setSelectedOrderIDs(list);

			setAutoAssign(false);
		}
	}, [autoAssign]);

	const fetchData = (
		skip = 0,
		search = searchText,
		sortName = sortCol.label,
		sortOrder = getSortationOrder(sortCol.value),
		filters = productFilters
	) => {
		let orderObj = getOrderObj(sortName, sortOrder);
		let searchObj = getSearchObj(search);
		let filterObj = getFilterObj(filters);

		pickingJobs({
			variables: {
				skip,
				...orderObj,
				...searchObj,
				...filterObj,
			},
		}).then((data) => {
			if (data?.data?.pickingJobs?.items?.length) {
				let flattenedData = data?.data?.pickingJobs?.items?.map(
					(item: any) => {
						let time = "";
						if (item.createdAt) {
							time = moment(item.createdAt).format(
								"D-M-YYYY / HH:mm"
							);
						}
						let serialsLength = item?.saleOrderItem?.quantity || 0;

						return {
							...objFlatten({
								...item,
								createdAt: time,
								serialsLength: `${serialsLength}`,
							}),
							saleOrderItems: item.saleOrderItem.serials,
						};
					}
				);
				setItemsCount(data?.data?.pickingJobs?.totalCount);
				setItems(flattenedData);
			} else {
				setItems([]);
				setItemsCount(0);
			}
		});
	};

	const handleSort = (sortVal = "") => {
		if (sortCol.label === sortVal) {
			// sort Desc
			const sortOrder = getSortationOrder(sortCol.value + 1);
			sortCol.value += 1;

			fetchData(0, searchText, sortVal, sortOrder);
		} else {
			setSortCol((old: any) => ({
				...old,
				label: sortVal,
				value: 0,
			}));
			fetchData(0, searchText, sortVal, "ASC");
		}

		setPage(0);
	};

	const handleAssignPicker = ({
		saleOrders = selectedOrderIDs,
		pickerIds = selectedPickerIDs,
		assignBySelectedRows = true,
		autoAssignMode = false,
	}) => {
		if (assignBySelectedRows) {
			saleOrders = selectedRows;
		}

		if (saleOrders.length < 1) {
			toast.error(`There are no selected sale orders`);
			return;
		}

		let filterObj: any = {};
		if (autoAssignMode) {
			pickerIds = [];
			// filterObj.isAuto = true
		}

		assignSaleOrdersToPickers({
			variables: {
				saleOrderIds: saleOrders,
				pickerIds: pickerIds,
				...filterObj,
			},
		}).then((res: any) => {
			// logger("Assign action response", res);
			if (res.data) {
				setAssignRes(res.data?.assignPickersToSaleOrder);
				// if (!res.error) {
				//   if (selectedOrderIDs.length > 1) {
				//     toast.success(
				//       `Sale order ${selectedOrderIDs[0]} is assigned successfully`
				//     );
				//   } else {
				//     toast.success(`Sale orders are assigned successfully`);
				//     fetch_data();
				//   }
				// } else {
				//   if (selectedOrderIDs.length > 1) {
				//     toast.success(
				//       `Sale order ${selectedOrderIDs[0]} cannot be assigned`
				//     );
				//   } else {
				//     toast.success(`Sale orders cannot be assigned`);
				//   }
				// }
			}
		});
	};

	return (
		<>
			<PaginatedTable
				// @ts-ignore
				onSortClick={(sortVal: any) => handleSort(sortVal)}
				count={itemsCount}
				page={page}
				cols={cols}
				sortCol={sortCol}
				setPage={setPage}
				onPageChange={(number: any) => {
					fetchData(number);
				}}
				hasCheckbox
				data={items}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				loading={false}
				filterByBin={filterByBin}
				canBeEdited
				editMenuItems={[
					{
						actionName: "Start picking",
						action: (data: any) => {
							if (data["id"]) {
								setActiveItemId(data["id"]);
							}
							setActiveData(data);
							if (data["cart.id"]) {
								setActiveCardId(data["cart.id"]);
								setActiveSaleOrderItem(
									data["saleOrderItem.id"]
								);
							}

							setModalOpen(true);
						},
						isDisabled: false,
						sx: {
							background: "#EFF7EE",
							color: "#4CAF50",
						},
					},
					{
						actionName: "To packing",
						action: (data: any) => {},
						isDisabled: true,
					},
					{
						actionName: "Assign picker",
						action: (data: any) => {
							setAssignModalOpen(true);
							setSelectedPickerIDs([]);
							setSelectedOrderIDs([
								parseInt(data["saleOrderItem.saleOrder.id"]),
							]);
						},
						isDisabled: false,
					},
				]}
			/>

			<Modal
				title={
					isModalActionLoading
						? `Assigning ${selectedOrderIDs?.length} picklists`
						: "Assigning finished"
				}
				footer={() => (
					<FeedbackModalFooter
						isHidden={isModalActionLoading}
						handleClick={() => {
							setAssignModalOpen(false);
							setFeedbackModalOpen(false);
							// setActiveTab((prev: number) => prev + 1);
							setSelectedOrderIDs([]);
							setSelectedPickerIDs([]);
							setReload(Math.random());
						}}
						close={() => {
							setIsModalActionLoading(true);
							setAssignModalOpen(false);
							setFeedbackModalOpen(false);
						}}
					/>
				)}
				onClose={() => {
					setIsModalActionLoading(true);
					setFeedbackModalOpen(false);
				}}
				hidden
				onDismiss={() => {
					setIsModalActionLoading(true);
					setFeedbackModalOpen(false);
				}}
				color='primary'
				autoOpen={feedbackModalOpen && selectedOrderIDs.length > 0}
				maxWidth='xs'
			>
				<FeedbackModal
					count={selectedOrderIDs.length}
					loading={isModalActionLoading}
					setLoading={setIsModalActionLoading}
					data={assignRes}
				/>
			</Modal>

			<Modal
				title='Assign Picker'
				footer={() => (
					<AssignPickerModalFooter
						count={selectedPickerIDs?.length || 0}
						assign={() => {
							setFeedbackModalOpen(true);
							handleAssignPicker({ assignBySelectedRows: false });
							setAssignModalOpen(false);
							setReload(Math.random());

							// setSelectedRows([])
						}}
						close={() => setAssignModalOpen(false)}
					/>
				)}
				onClose={() => {
					setAssignModalOpen(false);
				}}
				hidden
				onDismiss={() => {
					setAssignModalOpen(false);
				}}
				color='primary'
				autoOpen={assignModalOpen}
				maxWidth='xs'
			>
				<AssignPickerModalContent
					checked={selectedPickerIDs}
					setChecked={setSelectedPickerIDs}
				/>
			</Modal>
			<Modal
				footer={() => (
					<ModalFooter
						data={activeData}
						close={() => setModalOpen(false)}
						cartId={activeCartId}
						activeItemId={activeItemId}
						setReload={setReload}
						activeSaleOrderItem={activeSaleOrderItem}
					/>
				)}
				onClose={() => {
					setModalOpen(false);
				}}
				hidden
				onDismiss={() => {
					setModalOpen(false);
				}}
				autoOpen={modalOpen}
				maxWidth='xs'
				title='Assign a cart'
			>
				<ModalContent
					cartId={activeCartId}
					setCartId={setActiveCardId}
				/>
			</Modal>
		</>
	);
}

function ModalFooter({
	close,
	cartId,
	activeItemId,
	setReload,
	activeSaleOrderItem,
	data,
}: {
	close: any;
	cartId: number;
	activeItemId: number;
	setReload: any;
	activeSaleOrderItem: number;
	data: any;
}) {
	const [updatePickingJobs] = useMutation(ASSIGN_PICKING_JOBS_TO_CARTS, {
		...wmsOptions,
	});
	const navigate = useNavigate();

	const handleClick = () => {
		if (cartId !== 0 && activeItemId !== 0) {
			updatePickingJobs({
				variables: {
					pickingJobs: [activeItemId - 0],
					cartIds: [cartId - 0],
				},
			}).then((response: any) => {
				if (
					response?.data?.assignCartsToPickingJobs
						?.successedPickingJobs?.length
				) {
					navigate("/wms/outbound/proceed/picking", {
						state: {
							id: data.id,
							cartCode: data["location.locationCode"],
							merchantName:
								data["saleOrderItem.saleOrder.merchant.name"],
							productName: data["saleOrderItem.product.name"],
							status: data["status"],
							saleOrderItems: data.saleOrderItems,
							saleOrderItemId: data["saleOrderItem.id"],
							saleOrderItemQuantity:
								data["saleOrderItem.quantity"],
						},
					});
					close();
					setReload(Math.random());
				}
			});
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Divider />

			<div
				style={{
					marginTop: 20,
					justifyContent: "flex-end",
					display: "flex",
				}}
			>
				<Button
					onClick={() => close()}
					variant='outlined'
					color='warning'
				>
					Cancel
				</Button>
				<Button
					sx={{ marginLeft: 2 }}
					variant='contained'
					color='warning'
					onClick={handleClick}
				>
					Assign cart
				</Button>
			</div>
		</Box>
	);
}

function ModalContent({
	cartId,
	setCartId,
}: {
	cartId: number;
	setCartId: any;
}) {
	const [checked, setChecked] = React.useState([0]);
	const [getCarts] = useLazyQuery(GET_ALL_LOCATIONS, {
		...wmsOptions,
	});
	const [carts, setCarts] = React.useState([]);
	const [searchText, setSearchText] = React.useState("");
	const searchDebounceText = useDebounce(searchText, 500);

	React.useEffect(() => {
		getCarts({
			variables: {
				where: {
					and: [
						{
							type: {
								eq: "CART",
							},
						},
						{
							and: [
								{ locationCode: { neq: null } },
								{
									locationCode: {
										contains: searchDebounceText,
									},
								},
							],
						},
					],
				},
			},
		}).then((data) => {
			if (data?.data?.locations?.items?.length) {
				setCarts(data.data.locations.items);
			}
		});
	}, [searchDebounceText]);

	const handleToggle = (value: number) => () => {
		setCartId(value);
	};

	return (
		<>
			<TextField
				fullWidth
				title='Scan or search cart barcodes'
				placeholder='Scan or type...'
				variant='outlined'
				label='Scan or search cart barcodes'
				size='small'
				sx={{ marginY: 1 }}
				onKeyDown={(event: any) => setSearchText(event.target.value)}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
				}}
			>
				{carts.map((value: any, index: number) => {
					const labelId = `checkbox-list-label-${value}`;

					return (
						<>
							{index !== 0 && <Divider />}

							<ListItem
								sx={{
									width: "100%",
									bgcolor:
										value?.id == cartId
											? "#FF980014"
											: "background.paper",
								}}
								key={value?.id}
								disablePadding
							>
								<ListItemButton
									role={undefined}
									onClick={handleToggle(value?.id)}
									dense
									sx={{
										width: "100%",
									}}
								>
									<ListItemIcon>
										<Radio
											edge='start'
											checked={cartId == value?.id}
											color='warning'
											tabIndex={-1}
											disableRipple
											inputProps={{
												"aria-labelledby": labelId,
											}}
										/>
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={`${
											value?.referenceName ||
											value?.locationCode
										}`}
									/>
								</ListItemButton>
							</ListItem>
						</>
					);
				})}
			</List>
		</>
	);
}

function AssignPickerModalFooter({ close, assign, count }: any) {
	return (
		<Box sx={{ width: "100%" }}>
			<Divider />

			<div
				style={{
					marginTop: 20,
					justifyContent: "flex-end",
					display: "flex",
				}}
			>
				<Button
					onClick={() => close()}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={() => assign()}
					sx={{ marginLeft: 2 }}
					variant='contained'
					color='primary'
					disabled={count < 1}
				>
					<span className='text-white'>Assign</span>
				</Button>
			</div>
		</Box>
	);
}

function AssignPickerModalContent({ checked, setChecked }: any) {
	const [isActionComplete, setIsActionComplete] = React.useState(false);

	const [selected, setSelected] = React.useState("available");
	const [searchText, setSearchText] = React.useState("");

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(+value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const [refetchFunction, { data, error }] = useApolloLazyQuery(
		GET_PICKERS,
		wmsOptions
	);
	const [pickerList, setPickerList] = React.useState([]);
	const newSearchQuery = useDebounce(searchText, 500);

	const fetchPickers = (query = newSearchQuery, status = selected) => {
		setChecked([]);
		let filterObj: any = {
			where: { role: { eq: "PICKER" } },
		};

		if (status == "available") {
			filterObj = {
				where: { role: { eq: "PICKER" }, status: { eq: "AVAILABLE" } },
			};
		}
		let queryObj = { query: "" };
		if (newSearchQuery?.trim() != "") {
			queryObj = {
				query: newSearchQuery,
			};
		}
		refetchFunction({
			variables: {
				...filterObj,
				...queryObj,
			},
		});
	};
	React.useEffect(() => {
		if (data) setPickerList(data?.users?.items);
	}, [data]);

	React.useEffect(() => {
		fetchPickers();
	}, []);

	React.useEffect(() => {
		fetchPickers();
	}, [newSearchQuery, selected]);

	return (
		<>
			{/* <TextField
        fullWidth
        title="Search employee"
        placeholder="Type here..."
        variant="outlined"
        label="Scan or search pickers"
        size="small"
        sx={{ marginY: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      /> */}

			<NewSearch
				hasValue={true}
				value={searchText}
				filterName='Search'
				infoText=''
				placeholder='Type here...'
				onChange={(e: any) => setSearchText(e.target.value)}
				handleClearSearch={() => setSearchText("")}
			/>
			<div className='m-5'></div>

			<Grid container spacing={2}>
				<Grid item>
					<Chip
						id={"0"}
						color={selected === "all" ? "primary" : "default"}
						label='All'
						variant='filled'
						onClick={() => setSelected("all")}
					/>
				</Grid>
				<Grid item>
					<Chip
						id={"0"}
						color={selected !== "all" ? "primary" : "default"}
						label='Available'
						onClick={() => setSelected("available")}
					/>
				</Grid>
			</Grid>

			{pickerList.length > 0 ? (
				<List
					sx={{
						width: "100%",
						bgcolor: "background.paper",
					}}
				>
					{pickerList?.map((item: any, index: any) => {
						const labelId = `checkbox-list-label-${item?.id}`;

						return (
							<>
								{index !== 0 && <Divider />}

								<ListItem
									sx={{
										width: "100%",
										bgcolor:
											checked.indexOf(item?.id) !== -1
												? "#FF980014"
												: "background.paper",
									}}
									key={item?.id}
									disablePadding
								>
									<ListItemButton
										role={undefined}
										onClick={handleToggle(item?.id)}
										dense
										sx={{
											width: "100%",
										}}
									>
										<ListItemIcon>
											<Checkbox
												edge='start'
												checked={
													checked.indexOf(
														+item?.id
													) !== -1
												}
												color='warning'
												tabIndex={-1}
												disableRipple
												inputProps={{
													"aria-labelledby": labelId,
												}}
											/>
										</ListItemIcon>
										<ListItemText
											id={labelId}
											primary={item?.name}
										/>
										<Chip
											sx={{
												textAlign: "right",
												backgroundColor: "#4CAF5014",
												border: "none",
												color: "#4CAF50",
											}}
											id={labelId}
											color='success'
											label={item?.status || "Available"}
											variant='outlined'
											size='small'
										/>
									</ListItemButton>
								</ListItem>
							</>
						);
					})}
				</List>
			) : (
				<Box mt={2}>
					<Alert variant='standard' severity='warning'>
						<span>No Pickers found!</span>
					</Alert>
				</Box>
			)}
			{/* <Alert variant="standard" severity="warning">
        <span>
          You have more than <BoldText>30</BoldText> items. You need{" "}
          <BoldText>1 more</BoldText> employee
        </span>
      </Alert> */}
		</>
	);
}

function FeedbackModalFooter({ close, handleClick, isHidden }: any) {
	return !isHidden ? (
		<Box sx={{ width: "100%" }}>
			<Divider />

			<div
				style={{
					marginTop: 20,
					justifyContent: "flex-end",
					display: "flex",
				}}
			>
				<Button
					onClick={() => close()}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={() => handleClick()}
					sx={{ marginLeft: 2 }}
					variant='contained'
					color='primary'
				>
					<span className='text-white'>Continue</span>
				</Button>
			</div>
		</Box>
	) : (
		<></>
	);
}

function FeedbackModal({ setLoading, loading, count, data }: any) {
	const [seeMore, setSeeMore] = React.useState(false);

	return (
		<>
			<LinearWithValueLabel
				totalProgressCount={parseInt(count)}
				onFinish={() => setLoading(false)}
			/>
			{!loading && (
				<>
					{data &&
						data.successedSaleOrderItems &&
						data.successedSaleOrderItems.length > 0 && (
							<Box sx={{ color: "success.main" }}>
								Succcessful picklists:{" "}
								{data.successedSaleOrderItems.length}
							</Box>
						)}
					<Box display='flex' justifyContent='space-between'>
						{data &&
							data.failedSaleOrderItems &&
							data.failedSaleOrderItems.length > 0 && (
								<Typography sx={{ color: "error.main" }}>
									Failed picklists: $
									{data.failedSaleOrderItems.length}
								</Typography>
							)}
						{data?.errorMessage ||
							data?.failedSaleOrderItems?.length > 0 ||
							(data?.successedSaleOrderItems?.length > 0 && (
								<Typography
									sx={{
										textDecoration: "underline",
										color: "text.disabled",
										textAlign: "right",
									}}
									onClick={() =>
										setSeeMore((prevState) => !prevState)
									}
								>
									See Details
								</Typography>
							))}
					</Box>
					{seeMore && (
						<div>
							{data &&
								data.successedSaleOrderItems &&
								data.successedSaleOrderItems.length > 0 && (
									<Box>
										Succcessfuly Assigned Sale Order Items:{" "}
										{data.successedSaleOrderItems.map(
											(item: any) => (
												<span>{item.id + ", "}</span>
											)
										)}
									</Box>
								)}
							{data &&
								data.failedSaleOrderItems &&
								data.failedSaleOrderItems.length > 0 && (
									<Typography>
										Failed Sale Order Items:{" "}
										{data.failedSaleOrderItems.join(" ,")}
									</Typography>
								)}
							{data && data.errorMessage && (
								<Typography>
									Error Message: {data.errorMessage}
								</Typography>
							)}
						</div>
					)}
				</>
			)}
		</>
	);
}
