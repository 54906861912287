import { gql } from "apollo-boost";

export const ADD_UPDATE_PRODUCT = gql`
	mutation AddOrUpdateProduct($products: [ProductInput!]!) {
		createOrUpdateProducts(products: $products) {
			id
		}
	}
`;

export const ADD_UPDATE_WAREHOUSE = gql`
	mutation AddOrUpdateProduct($locations: [LocationInput!]!) {
		createOrUpdateLocations(locations: $locations) {
			id
		}
	}
`;

export const ADD_UPDATE_SHELF = gql`
	mutation CreateOrUpdateShelves($locations: [LocationInput!]) {
		createOrUpdateLocations(locations: $locations) {
			id
		}
	}
`;
export const ADD_UPDATE_CART = gql`
	mutation CreateOrUpdateCarts($locations: [LocationInput!]!) {
		createOrUpdateLocations(locations: $locations) {
			id
		}
	}
`;

export const ADD_UPDATE_PURCHASE_ORDER = gql`
	mutation CreateOrUpdatePurchaseOrders($input: [PurchaseOrderInput!]!) {
		createOrUpdatePurchaseOrders(purchaseOrders: $input) {
			id
		}
	}
`;

export const CREATE_OR_UPDATE_INVENTORY_LOGS = gql`
	mutation CreateOrUpdateInventoryLogs($input: [InventoryLogInput!]!) {
		createOrUpdateInventoryLogs(inventoryLogs: $input) {
			id
		}
	}
`;

export const CREATE_OR_UPDATE_LOCATIONS = gql`
	mutation CreateOrUpdateLocations($locations: [LocationInput!]!) {
		createOrUpdateLocations(locations: $locations) {
			id
			isActive
		}
	}
`;

export const DELETE_LOCATIONS = gql`
	mutation DeleteLocations($locations: [LocationInput!]!) {
		deleteLocations(locations: $locations) {
			id
		}
	}
`;

export const ASSIGN_SALEORDERS_TO_PICKERS = gql`
	mutation AssignSaleOrderToPickers(
		$pickerIds: [Int!]
		$saleOrderIds: [Int!]
	) {
		assignPickersToSaleOrder(
			pickerIds: $pickerIds
			saleOrderIds: $saleOrderIds
		) {
			successedPickingJobs {
				id
			}
			failedPickingJobs {
				id
			}
			errorMessage
		}
	}
`;

export const CREATE_OR_UPDATE_SALEORDERS = gql`
	mutation UpdateSaleOrderStatus(
		$saleOrderId: Int!
		$status: SaleOrderStatus!
	) {
		updateSaleOrderStatus(saleOrderId: $saleOrderId, status: $status) {
			id
			status
		}
	}
`;
export const CREATE_OR_UPDATE_WMS_SHIPMENT_STATUS = gql`
	mutation UpdateShipmentStatus($id: Int!, $shipmentStatus: ShipmentStatus!, $saleOrderStatus: SaleOrderStatus!) {
		updateShipmentStatus(id: $id, shipmentStatus: $shipmentStatus, saleOrderStatus: $saleOrderStatus) {
			id
			status
		}
	}
`;

export const UPDATE_RESTOCK_JOBS = gql`
	mutation RestockUpdateMutation($restockJob: [RestockJobInput]) {
		createOrUpdateRestockJobs(restockJobs: $restockJob) {
			id
			status
		}
	}
`;

export const COMPLETE_RESTOCK_JOBS = gql`
	mutation CompleteRestockJob($restockJob: Int!, $serials: [String!]) {
		completeRestockJobScanning(restockJobId: $restockJob, serials: $serials)
	}
`;

export const UPDATE_PICKING_JOBS = gql`
	mutation updatePickingJobs($pickingJobs: [PickingJobInput]) {
		createOrUpdatePickingJobs(pickingJobs: $pickingJobs) {
			id
			cartId
		}
	}
`;

export const PICKING_JOBS_TO_PACKING = gql`
	mutation pickingJobToPacking($pickingJobId: Int!, $serials: [String!]) {
		completePickingJobScanning(
			pickingJobId: $pickingJobId
			serials: $serials
		) {
			id
			status
			code
			assignedToId
			saleOrderId
			createdAt
			updatedAt
			cart {
				locationCode
				referenceName
			}
			assignedTo {
				name
				status
				id
			}
			saleOrder {
				id
			}
		}
	}
`;

export const ASSIGN_PICKING_JOBS_TO_CARTS = gql`
	mutation AssignPickingJobsToCarts($cartIds: [Int!], $pickingJobs: [Int!]) {
		assignCartsToPickingJobs(
			cartIds: $cartIds
			pickingJobIds: $pickingJobs
		) {
			successedPickingJobs {
				id
			}
			failedPickingJobs {
				id
			}
			errorMessage
		}
	}
`;

export const ASSIGN_PACKER_TO_PACKING_JOBS = gql`
	mutation AssignPackerToPackingJobs($packerId: Int!, $packingJobId: Int!) {
		assignPackerToPackingJob(
			packerId: $packerId
			packingJobId: $packingJobId
		) {
			id
		}
	}
`;

export const COMPLETE_PACKING_JOB = gql`
	mutation completePacking($packingJobId: Int!, $serials: [String!]) {
		completePackingJobScanning(
			packingJobId: $packingJobId
			serials: $serials
		) {
			id
			status
			saleOrderId
			createdAt
			updatedAt

			saleOrder {
				id
			}
		}
	}
`;

// [
//   {
//     id: 0
//     # ASK:
//     locationCode:  "" #editable

//     isActive: true #editable but not createable

//     parentLocationId: 1
//     # city: parentLocation.city
//     type: SHELF #SHELF

//   }
// ]
