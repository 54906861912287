import CloseIcon from "@mui/icons-material/Close";
import { FilterIcon } from "../../../components/svg";

export default function MapFilterMenu({ open, setOpen }) {
  return (
    <>
      {!open ? (
        <span
          className={`flex items-center justify-center ml-0 hover:bg-muiLightBlue px-2 mt-1 rounded-sm hover:cursor-pointer transform scale-110`}
          onClick={() => setOpen(true)}
        >
          <FilterIcon />
        </span>
      ) : (
        <span
          className={`flex items-center justify-center ml-0 hover:bg-muiLightBlue px-2 mt-1 rounded-sm hover:cursor-pointer transform scale-110`}
          onClick={() => setOpen(false)}
        >
          <CloseIcon sx={{ width: "20px", height: "20px" }} />
        </span>
      )}
    </>
  );
}
