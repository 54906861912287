export default function useSaleOrders () {
	const getOrderObj = (sortName, sortOrder) => {
		if (sortName !== "" && sortOrder != "DEFAULT") {
			switch (sortName) {
				case "id":
					return {
						order: {
							id: sortOrder,
						},
					};
				case "saleOrder.id":
					return {
						order: {
							saleOrder: { id: sortOrder },
						},
					};
				case "createdAt":
					return {
						order: {
							createdAt: sortOrder,
						},
					};
				case "status":
					return {
						order: {
							status: sortOrder,
						},
					};
				case "updatedAt":
					return {
						order: {
							updatedAt: sortOrder,
						},
					};
			}
		} else {
			return {};
		}
	};

	const getFilterObj = (filters, status) => {
		let filterList = [];

		// if (filters?.productId != "all") {
		//     filterList.push({
		//         saleOrderItems: {
		//             product: {
		//                 id: {
		//                     eq: parseInt(filters.productId),
		//                 }
		//             }
		//         },
		//     });
		// }

		if (filters?.merchantId && filters?.merchantId != "all") {
			filterList.push({
				merchant: {
					id: {
						eq: parseInt(filters.merchantId),
					}
				},
			});
		}

		// if (filters?.locationId != "all") {
		//     filterList.push({
		//         locationId: {
		//             eq: parseInt(filters.locationId),
		//         },
		//     });
		// }
		// if (filters?.locationType != "all") {
		//     filterList.push({
		//         location: {
		//             type:
		//             {
		//                 eq: filters.locationType,
		//             }
		//         }
		//     });
		// }

		if (filters?.status && filters?.status != "all") {
			filterList.push({
				status: {
					eq: filters.status,
				}
			});
		}

		return {
			where: {
				and: [
					...filterList,
					// {
					// 	status: {
					// 		eq: status,
					// 	},
					// },
				],
			},
		};
	};

	const getSearchObj = (search) => {
		let searchRes = { query: "" };
		if (search?.trim() != "") {
			searchRes.query = search;
		}
		return searchRes;
	};
	return {
		getOrderObj,
		getFilterObj,
		getSearchObj,
	};
}
