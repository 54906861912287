import { useEffect, useState } from 'react'
import { useMapEvents } from 'react-leaflet'
import { logger } from '../../../helper/logger'
import L from '../../../helper/CustomLeaflet'
import polyline from '@mapbox/polyline'

import Route from '../../../services/Route'
import { CustomTooltip } from '../../../components/common/Tooltip/index'

import {
    LeafletDriverOfflineIconNew,
    LeafletDriverOnlineBusyIconNew,
    LeafletDriverOnlineFreeIconNew,
    LeafletOrderPickupIcon,

    LeafletDriverAnalyticsBusyIcon,
    LeafletDriverAnalyticsDefaultIcon,
    LeafletDriverAnalyticsFreeIcon,
    LeafletDriverAnalyticsIMEIIcon,
    LeafletDriverIcon,
    LeafletDriverIMEIIcon,
    LeafletShipmentIcon,
    LeafletShipmentLocationIcon
} from '../../../components/common/leaflet-icons'

export default function useDensityMap ({
    isDensityViewOpen = true,
    selectedShipmentIds,
    filters,
    drivers,
    shipments
}) {
    const [map, setMap] = useState(null)
    const [shipmentMarkers, setShipmentMarkers] = useState([])
    const [driverMarkers, setDriverMarkers] = useState([])
    const [driverMarkersMap, setDriverMarkersMap] = useState({})
    const [directionMarkers, setDirectionMarkers] = useState([])


    const MapControls = () => {
        const map = useMapEvents({})
        setMap(map)

        return null
    }

    const removeMapDecorations = (decorationList) => {
        decorationList.forEach((decoration) => {
            map.removeLayer(decoration)
        })
    }

    const showShipmentMarker = (shipment) => {
        if (!filters.shipment.showOnMap) {
            // remove shipmentMarkers if any
            if (shipmentMarkers && shipmentMarkers.length > 0) {
                removeMapDecorations(shipmentMarkers)
                setShipmentMarkers([])
            }
            return
        }

        // if some shipments are selected, gray icon will be chosen
        if (shipment.latitude && shipment.longitude) {
            const markerIcon = LeafletOrderPickupIcon // gray colored icon
            let newMarker = window.L.marker([shipment.latitude, shipment.longitude], {
                icon: markerIcon
            })
                .addTo(map)

            setShipmentMarkers((prevMarkerList) => [...prevMarkerList, newMarker])
        }
    }

    const showDriverMarkers = (list) => {
        try {
            if (map && drivers && drivers?.length >= 0) {
                // removeMapDecorations(driverMarkers);
                // setDriverMarkers([]);

                driverMarkers?.map((marker) => {
                    if (!drivers.find((d) => d.id == marker.id)) {
                        removeMapDecorations([marker])
                        delete driverMarkersMap[marker.id]
                        setDriverMarkers((old) => old.filter((d) => d.id !== marker.id))
                    }
                })

                drivers?.map((driver) => {
                    // logger("driver", driver);
                    // set non-selected driver markers

                    showDriverMarker(driver)
                })
            }
        } catch (error) {
            logger('error', error)
        }
    }

    const showDriverMarker = (driver) => {
        if (!filters.driver.showOnMap) {
            // remove driverMarkers if any

            if (driverMarkers && driverMarkers.length > 0) {
                removeMapDecorations(driverMarkers)
            }
            setDriverMarkers([])
            setDriverMarkersMap({})
            return
        }

        // if driver is already in the map, animate it
        if (driverMarkersMap[driver.id]) {
            const marker = driverMarkersMap[driver.id]
            // marker.slideTo([24.63291, 46.460296], {
            marker.slideTo([driver.latitude, driver.longitude], {
                duration: 300
            })

            let driverMarker = LeafletDriverOfflineIconNew
            if (driver.isOnline && !driver.isBusy) {
                driverMarker = LeafletDriverOnlineFreeIconNew
            } else if (driver.isOnline && driver.isBusy) {
                driverMarker = LeafletDriverOnlineBusyIconNew
            }

            if (driver.imei) {
                if (driver.isOnline && !driver.isBusy) {
                    driverMarker = LeafletDriverAnalyticsFreeIcon
                } else if (driver.isOnline && driver.isBusy) {
                    driverMarker = LeafletDriverAnalyticsBusyIcon
                } else {
                    driverMarker = LeafletDriverAnalyticsDefaultIcon
                }

            }
            marker.setIcon(driverMarker)
            return
        }

        // if some drivers are selected, gray icon will be chosen
        if (driver?.latitude && driver?.longitude) {
            let driverMarker = LeafletDriverOfflineIconNew
            if (driver.isOnline && !driver.isBusy) {
                driverMarker = LeafletDriverOnlineFreeIconNew
            } else if (driver.isOnline && driver.isBusy) {
                driverMarker = LeafletDriverOnlineBusyIconNew
            }

            if (driver.imei) {
                if (driver.isOnline && !driver.isBusy) {
                    driverMarker = LeafletDriverAnalyticsFreeIcon
                } else if (driver.isOnline && driver.isBusy) {
                    driverMarker = LeafletDriverAnalyticsBusyIcon
                } else {
                    driverMarker = LeafletDriverAnalyticsDefaultIcon
                }

            }

            let newMarker = window.L.marker([driver?.latitude, driver?.longitude], {
                icon: driverMarker,
                zIndexOffset: driver.imei ? 2000 : 1000
            })
                .addTo(map)
                .on('click', () => {
                    map.panTo([driver?.latitude, driver?.longitude], {
                        animate: true,
                        duration: 3000
                    })
                })
            // newMarker.on('mouseover', customTooltip(driver.id))
            if (isDensityViewOpen)
                newMarker.bindTooltip(
                    "<div style='background:white; padding:1px 3px 1px 3px'><b>" +
                    'Id: ' +
                    driver.id +
                    '</b></div>',
                    {
                        direction: 'top',
                        sticky: false,
                        offset: [10, 0],
                        opacity: 0.75,
                        className: 'leaflet-tooltip-own'
                    }
                )
            newMarker.id = driver.id
            setDriverMarkersMap((prev) => ({
                ...prev,
                [driver.id]: newMarker
            }))

            setDriverMarkers((prevMarkerList) => [...prevMarkerList, newMarker])
        }
    }

    const showShipmentMarkers = () => {
        try {
            if (map && shipments && shipments?.length >= 0) {
                removeMapDecorations(shipmentMarkers)
                setShipmentMarkers([])

                shipments?.map((shipment) => {
                    // set non-selected shipment markers
                    showShipmentMarker(shipment)
                })
            }
        } catch (error) {
            logger('error', error)
        }
    }

    return {
        map,
        setMap,
        MapControls,
        showDriverMarkers,
        showShipmentMarkers,
    }
}
