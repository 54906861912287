import React, { useEffect, useState } from "react";
import KnightFilters from "./components/KnightFilters";
import KnightsMenu from "./components/KnightsMenu";
import PaginatedTable from '../../components/Table/paginatedTable';
import { logger } from 'helper/logger';
import { useDebounce } from 'hooks/useDebounce';
import { getSortationOrder } from 'helper/GetSortOrder';
import { toast } from 'react-toastify';
import { useLazyQuery } from '@apollo/client';
import { stagingOptions } from 'constants/apollo';
import moment from 'moment';
import { objFlatten } from 'helper/objFlatten';
import { GET_ALL_COURIER_PARTNERS, LOAD_DRIVERS } from 'api/queries/Queries';
import { useSearchParams } from 'react-router-dom';
import Modal from '../../components/common/Modal'
import FiltersList from '../../components/FiltersList';
import { useTranslation } from 'react-i18next';
import { GET_ALL_CITIES_FOR_FILTER, GET_CITY_FOR_FILTER } from 'api/queries/FilterQueries';
import ModalFooter from '../../components/common/Modal/ModalFooter';
import { getDriverFetchFilters } from 'helper/getFetchFilters';
import CreateKnight from './components/CreateKnight';
import useAsyncFilterFns from 'helper/asyncFilterFns';


const cols = [
  {
    name: "Knight ID",
    value: "id",
    type: "link",
    link: "id",
    href:
      process.env.REACT_APP_MODE === "production"
        ? "https://barqfleet.com/admin/dashboard/couriers?courier_id="
        : "https://staging.barqfleet.com/admin/dashboard/couriers?courier_id="
  },
  {
    name: "Name",
    value: "name",
  },
  {
    name: "Employment Type",
    value: "courierType",
  },
  { name: "Vehicle Type", value: "vehicleType" },

  {
    name: "Last Seen",
    value: "lastSeen",
  },
  { name: "City", value: "city.name" },
  { name: "Status", value: "status", type: "status", styles: "uppercase" },
];

const defaultFilters = {
  driverCity: "all",
  // driverPartner: "all",
  driverDevice: "all",
  driverNationality: "all",
  driverVehicle: "all",
  driverVehicleModel: "all",
  driverUniform: "all",
  driverStatus: "all",
};

export default function Knights () {
  const localStorageFilters = JSON.parse(
    window?.localStorage?.getItem("kinghtDashboardFilters") || "{}"
  );
  const [searchParams, setSearchParams] = useSearchParams(
    localStorageFilters || {}
  );
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([])
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [createKnightModalOpen, setCreateKnightModalOpen] = useState(false)

  const [driverFilters, setDriverFilters] = useState({
    ...defaultFilters,
    ...Object.fromEntries([...searchParams]),
  });
  const { t } = useTranslation('common')
  const [sortCol, setSortCol] = useState({
    label: "id",
    value: 1,
  });
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [fetchAllDrivers, { data, error }] = useLazyQuery(LOAD_DRIVERS, { ...stagingOptions, fetchPolicy: "network-only" });
  const [searchText, setSearchText] = useState(
    Object.fromEntries([...searchParams]).searchText || ""
  );
  const debouncedSearchText = useDebounce(searchText, 500);
  const [getCity] = useLazyQuery(GET_CITY_FOR_FILTER, stagingOptions);

  const [getCities] = useLazyQuery(GET_ALL_CITIES_FOR_FILTER, stagingOptions);
  const [fetchDriverPartners, { data: driverPartners }] = useLazyQuery(GET_ALL_COURIER_PARTNERS, stagingOptions)

  const {
    fetch_cities,
    fetch_city,
    fetch_nationalities,
    fetch_vehicleModels
  } = useAsyncFilterFns()

  const fetchDrivers = (
    skip = 0,
    search = searchText,
    sortName = sortCol.label,
    sortOrder = getSortationOrder(sortCol.value),
    filters = driverFilters
  ): any => {
    try {
      let filterObj = getDriverFetchFilters(filters);
      fetchAllDrivers({
        variables: {
          offset: skip,
          query: search,
          showBanned: true,
          ...filterObj,
        },
      })
    } catch (e) {
      toast.error("An error occured");
    }
  };

  const handleQueryChange = (obj: any) => {
    // update router query based on implemented filters
    let mergedObj = { ...Object.fromEntries([...searchParams]), ...obj };
    let modified: any = {};

    Object.keys(mergedObj).map((key) => {
      if (mergedObj[key] != "all" && mergedObj[key] != "") {
        if (key in obj) {
          modified[key] = obj[key];
        } else {
          modified[key] = mergedObj[key];
        }
      }
    });

    setSearchParams(modified);
  };

  function handleSearch (search: any) {
    fetchDrivers(
      0,
      search,
      sortCol.label,
      getSortationOrder(sortCol.value)
    );
    setPage(0);
  }
  function handleFilter () {
    handleQueryChange(driverFilters);
    fetchDrivers(
      0,
      searchText,
      sortCol.label,
      getSortationOrder(sortCol.value),
      driverFilters
    );
  }

  async function handleFilterReset () {
    handleQueryChange(defaultFilters);
    setDriverFilters((old) => ({ ...old, ...defaultFilters }));

    await fetchDrivers(
      0,
      searchText,
      sortCol.label,
      getSortationOrder(sortCol.value),
      defaultFilters
    );

    setFilterModalOpen(false);
  }

  const reload = () => {
    fetchDrivers()
  }

  // check whether courier profile was open, do this by 

  useEffect(() => {
    if (data) {
      setTotalCount(data?.fetchAllCouriers?.totalCount);
      logger("DATA HERE", data);

      const dataList = data?.fetchAllCouriers?.items;
      const dataListFlattened = dataList?.map((item: any) => {
        let time = "";
        if (item.updatedAt) {
          time = moment(item.updatedAt).format("D-M-YYYY / HH:mm");
        }
        let lastSeen = "";
        if (item.lastSeen) {
          lastSeen = moment(item.lastSeen).format(
            "D-M-YYYY / HH:mm"
          );
        }
        let name = ""
        if (item.firstName || item.lastName) {
          name = `${item.firstName || ""} ${item.lastName || ""}`
        }

        let status = ""
        if (item.isBanned) {
          status = "banned"
        } else {
          status = "active"
        }

        return objFlatten({
          ...item,
          name,
          lastSeen,
          status
        });
      });

      setTableData(dataListFlattened);
    }
  }, [data]);

  useEffect(() => {
    handleSearch(searchText);
    handleQueryChange({ searchText: searchText });
  }, [debouncedSearchText]);

  useEffect(() => {
    fetchDrivers()
  }, [])

  return (
    <>
      <div>
        {/* <KnightsMenu totalCount={totalCount} /> */}
        <KnightFilters setFilterModalOpen={setFilterModalOpen} search={searchText} setSearch={setSearchText} setCreateKnightModalOpen={setCreateKnightModalOpen} />
        <PaginatedTable
          // hasCheckbox
          filterByBin={''}
          sortCol={sortCol}
          onSortClick={(sortVal) => {
            // handleSort(sortVal);
          }}
          count={totalCount || 0}
          page={page}
          setPage={setPage}
          onPageChange={fetchDrivers}
          cols={cols}
          data={
            tableData ||
            [{
              id: "130",
              name: "Tim Davidson",
              type: "Paid",
              vehicleType: "Car",
              lastSeen: "12-04-2022 / 9:41",
              city: "Jeddah",
              status: "Busy"
            }]}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          loading={false}
        />
      </div>
      <Modal
        footer={(handleClose: any) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={handleFilter}
            handleReset={handleFilterReset}
          />
        )}
        onDismiss={() => {
          setFilterModalOpen(false);
        }}
        hidden
        autoOpen={filterModalOpen}
        onClose={() => { }}
        title={"Filter Drivers"}
      >
        <FiltersList
          selectedFilters={driverFilters}
          handleChange={(filterVal: any, filterName: string) => {
            setDriverFilters((old) => ({
              ...old,
              [filterName]: filterVal,
            }));
          }}
          filters={[
            {
              name: "Filter by City",
              value: "driverCity",
              id: 1,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  let sel = fetch_city(id);
                  return sel;
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: string, page: number) =>
                fetch_cities({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Vehicle Model",
              value: "driverVehicleModel",
              id: 122,
              isAsync: true,
              isSearchable: false,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  return { value: id, label: id };
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: string, page: number) =>
                fetch_vehicleModels({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Nationality",
              value: "driverNationality",
              id: 12,
              isAsync: true,
              isSearchable: false,
              getSelectedVal: (id: any) => {
                if (id && id != "all") {
                  return { value: id, label: id };
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery: string, page: number) =>
                fetch_nationalities({ page: page, searchQuery: searchQuery }),
            },
            // {
            //   name: "Filter by Partner",
            //   value: "driverPartner",
            //   id: 11,
            //   isAsync: true,
            //   isSearchable: true,
            //   loadMore: (searchQuery: string, page: number) =>
            //     fetch_driver_partners({ page: page, searchQuery: searchQuery }),
            // },
            {
              name: "Filter by Vehicle",
              value: "driverVehicle",
              id: 2,
              options: [
                { value: "all", label: "All" },
                { value: "0", label: t("car") },
                { value: "1", label: t("motorbike") },
                { value: "2", label: t("van") },
                { value: "3", label: t("minivan") },
                { value: "4", label: t("pickup") },
                { value: "5", label: t("small_truck") },
                { value: "6", label: t("large_truck") },
              ],
            },
            {
              name: t("filter_by_device"),
              value: "driverDevice",
              id: 4,
              options: [
                { value: "all", label: "All" },
                { value: "android", label: t("android") },
                { value: "ios", label: t("ios") },
              ],
            },
            {
              name: "Filter by Uniform",
              value: "driverUniform",
              id: 6,
              options: [
                { value: "all", label: "All" },
                { value: "0", label: t("branded") },
                { value: "1", label: t("unbranded") },
              ],
            },
            {
              name: "Filter By Availability",
              value: "driverStatus",
              id: 7,
              options: [
                { value: "all", label: "All" },
                { value: "free", label: t("Online available") },
                { value: "busy", label: t("Online busy") },
                { value: "offline", label: t("Offline") },
              ],
            },
          ]}
        />
      </Modal>

      <Modal
        onDismiss={() => {
          setCreateKnightModalOpen(false);
        }}
        footer={() => <></>}
        hidden
        autoOpen={createKnightModalOpen}
        onClose={() => { }}
        title={"Add New Courier"}
      >
        <CreateKnight reload={reload} handleClose={() => setCreateKnightModalOpen(false)} />
      </Modal>
    </>
  );
}
