import CustomButton from '../../../components/common/Button';
import { Alert, AlertTitle, Divider } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import CustomInput from "../../../components/common/Input";
import { SaveIcon } from '../../../components/svg';
import * as Yup from 'yup';

export default function ResetPassword ({ handleUpdateDriver, handleClose }) {
    const [step, setStep] = useState(1)
    const formik = useFormik({
        initialValues: {
            password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('No password provided.')
                .min(8, 'Password is too short - should be 8 chars minimum.')
                .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
        }),
        onSubmit: (values) => {
            handleUpdateDriver(values)
        },
    });

    return (
        <div>
            {step <= 1 ?
                <div className='flex flex-col space-y-5'>
                    <Alert variant='outlined' severity='error'>
                        <AlertTitle>Are you sure to reset password?</AlertTitle>
                    </Alert>
                    <Divider />
                    <div className='flex space-x-3'>
                        <CustomButton
                            onClick={handleClose}
                            type='button'
                            variant='outlined'
                            color='default'> <span className='text-gray-500'>No, cancel</span>
                        </CustomButton>
                        <CustomButton
                            onClick={() => setStep(2)}
                            type='button'
                            variant='contained'
                            color='danger'>yes, sure
                        </CustomButton>
                    </div>
                </div>
                : <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit()
                    }} className="w-full flex flex-col">
                    <CustomInput
                        id="password"
                        name="password"
                        type="text"
                        inputName="New Password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password &&
                        <div className="error" key={0}>{formik.errors.password}</div>
                    }
                    <div className='w-full flex justify-end space-x-3 mt-5'>
                        <CustomButton
                            type='button'
                            onClick={handleClose}
                            variant='outlined'
                            color='warning'
                        >
                            cancel
                        </CustomButton>

                        <CustomButton
                            type='submit'
                            variant='contained'
                            color='warning'
                        >
                            <div className='flex space-x-2 w-full justify-center'>
                                <SaveIcon />
                                <span>save</span>
                            </div>
                        </CustomButton>
                    </div>
                </form>}
        </div>
    )
}
